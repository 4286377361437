import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

window.$linkserver = "https://apibajaj.curbe.com.ec/"; 
// window.$linkserver = "http://192.168.1.134:5000/";
// window.$linkserver = "http://192.168.68.60:5000/";
// window.$linkserver = "http://192.168.1.127:5001/";
// window.$linkserver = "http://192.168.40.102:5001/";
// window.$linkserver = "http://192.168.1.127:5000/";
// window.$linkserver = 'http://192.168.18.13:5000/'
document.title = "Administrador Bajaj";

ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
