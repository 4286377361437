import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup, {
  contextTypes,
} from "react-addons-css-transition-group";
import BootstrapTable, {
  ROW_SELECT_DISABLED,
} from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import filterFactory, {
  textFilter,
  selectFilter,
  numberFilter,
  Comparator,
  dateFilter
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import PageTitle from "../Layout/PageTitle";
import optionsPagination from "../../helper/SetupComponents";
import Map from "../../componentes/screens/Map";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow,
  Polyline,
} from "react-google-maps";
import * as moment from "moment";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faCalendarCheck,
  faPenSquare,
  faHandHoldingUsd,
  faInfoCircle,
  faMapMarkedAlt,
  faHistory,
  faLocationArrow,
  faImages,
  faUserEdit,
  faDirections
} from "@fortawesome/free-solid-svg-icons";
import {
  Row,
  Col,
  Container,
  Card,
  Image,
  Form,
  FormGroup,
  Button,
  Modal,
  Carousel,
  Spinner,
  Tabs,
  Tab,
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
// registerLocale("es", es);
import "react-datepicker/dist/react-datepicker.css";
import { findByDisplayValue } from "@testing-library/react";
import { scryRenderedDOMComponentsWithClass } from "react-dom/test-utils";
import Swal from "sweetalert2";
import * as firebase from "firebase";
registerLocale("es", es);

function Content() {
  const [showModalCargando, setShowModalCargando] = useState(false);

  const [products, setproducts] = useState([]);
  const server = window.$linkserver;
  // const [isLoading, setIsLoading] = useState("invisible");
  // const [nombreBanco, setNombreBanco] = useState("Banco del Austro");
  // const { SearchBar } = Search;
  const { SearchBar, ClearSearchButton } = Search;
  // const { ExportCSVButton } = CSVExport;
  const [modalShow, setModalShow] = React.useState(false);
  // const [cartera, setCartera] = useState();
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const [startDate, setStartDate] = useState(new Date(year, month, day - 1));
  const [lastDate, setLastDate] = useState(new Date());
  const selectOptions = {
    "PDI": "PDI",
    "GPS": "GPS",
  };


  const columns = [
    {
      dataField: "sta_fecha",
      text: "Fecha activación",
      headerAlign: "center",
      footer: "",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        textAlign: "center",
        minWidth: "190px",
      },
      formatter: (cell, row, index) => fecha(row, index),
      // formatter: (cell, row, index) => semaforoEstado(row, index),
    },

    {
      dataField: "sta_tipo",
      text: "Tipo",
      headerAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },

      formatter: (cell) => selectOptions[cell],
      filter: selectFilter({
        options: selectOptions,
        placeholder: "TODO",
        withoutEmptyOption: false,
        style: {
          // backgroundColor: "pink",
        },
        className: "test-classname",
        datamycustomattr: "datamycustomattr",
      }),
      footer: "",
    },
    {
      dataField: "sta_origen",
      text: "Origen",
      headerAlign: "center",
      footer: "",
      headerStyle: {
        textAlign: "center",
        minWidth: "190px",
        backgroundColor: "rgba(255, 255, 255,1)"
      },
      filter: textFilter({
        placeholder: 'Nombre del Taller'
      }),

      formatter: fechaFormatter,
      // filter: textFilter({
      //   placeholder: 'Nombres/Apellido'
      // }),
      footer: ""
    },
    {
      dataField: "sta_marca",
      text: "Marca",
      footer: "",
      headerAlign: "center",
      headerStyle: {
        textAlign: "center",
        minWidth: "150px",
        backgroundColor: "rgba(255, 255, 255,1)"
      },
      filter: textFilter({
        placeholder: 'Marca'
      }),
      formatter: fechaFormatter,
      footer: ""
    },
    {
      dataField: "sta_modelo",
      text: "Modelo",
      headerAlign: "center",
      footer: "",
      headerStyle: {
        textAlign: "center",
        minWidth: "190px", backgroundColor: "rgba(255, 255, 255,1)"
      },
      filter: textFilter({
        placeholder: 'Modelo'
      }),

      formatter: fechaFormatter,
      // filter: textFilter({
      //   placeholder: 'Nombres/Apellido'
      // }),
      footer: ""
    },
    {
      dataField: "sta_anio",
      text: "Año",
      headerAlign: "center",
      footer: "",
      headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
      formatter: fechaFormatter,
      filter: textFilter({
        placeholder: 'Año'
      }),
      footer: ""
    },
    {
      dataField: "sta_color",
      text: "Color",
      // formatter: priceFormatter,
      headerAlign: "center",
      footer: "",
      headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
      filter: textFilter({
        placeholder: 'Color'
      }),
      formatter: fechaFormatter,
      footer: ""
    },
    {
      dataField: "sta_chasis",
      text: "Chasis",
      headerAlign: "center",
      footer: "",
      headerStyle: {
        textAlign: "center",
        minWidth: "190px", backgroundColor: "rgba(255, 255, 255,1)"
      },
      filter: textFilter({
        placeholder: 'Chasis'
      }),

      formatter: fechaFormatter,
      // filter: textFilter({
      //   placeholder: 'Nombres/Apellido'
      // }),
      footer: ""
    }
    // ,
    // {
    //   dataField: "std_cantidad_activado",
    //   text: "Activados",
    //   footer: "",
    //   headerStyle: (colum, colIndex) => {
    //     return {
    //       textAlign: "center",
    //       minWidth: "150px",
    //       backgroundColor: "rgba(255, 255, 255,1)",
    //     };
    //   },
    //   formatter: fechaFormatter,
    //   footer: (columnData) => "Activos: " + columnData.reduce((acc, item) => acc + item, 0),
    // },

    // {
    //   dataField: "std_cantidad_disponible",
    //   text: "Disponibles",
    //   footer: "",
    //   headerStyle: (colum, colIndex) => {
    //     return {
    //       textAlign: "center",
    //       minWidth: "150px",
    //       backgroundColor: "rgba(255, 255, 255,1)",
    //     };
    //   },
    //   formatter: fechaFormatter,
    //   footer: (columnData) => "Disponibles: " + columnData.reduce((acc, item) => acc + item, 0),
    // },
    // {
    //   dataField: "std_cantidad",
    //   text: "Total",
    //   footer: "",
    //   headerStyle: (colum, colIndex) => {
    //     return {
    //       textAlign: "center",
    //       minWidth: "150px",
    //       backgroundColor: "rgba(255, 255, 255,1)",
    //     };
    //   },
    //   formatter: fechaFormatter,
    //   footer: (columnData) => "Total: " + columnData.reduce((acc, item) => acc + item, 0),
    // }
  ];


  const getFechaStock = async (inicio, fin) => {
    // console.log(server + "api/inventario/getListStockConsolidado/" + inicio + "/" + fin);
    setShowModalCargando(true)
    await axios
      .get(server + "api/inventario/getListStockActivaciones/" + inicio + "/" + fin)
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        console.log(response.data.size);
        setproducts(response.data);
        setShowModalCargando(false)
      })
      .catch(function (error) { })
      .then(function () { });
  };

  function fechaFormatter(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "115px",
        }}
      >
        {cell}
      </Form.Label>
    );
  }
  const updateDate = (date) => {
    setStartDate(date);
    // setStartDate(date);
  };

  const updateLastDate = (date) => {
    setLastDate(date);
  };

  function fecha(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "190px",
        }}
      >
        { moment(cell.sta_fecha).format("DD-MM-YYYY")}
      </Form.Label>
    );
  }

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>

          <Modal
            show={showModalCargando}
            onHide={() => setShowModalCargando(false)}
            centered
          >
            <Modal.Body class="text-center">
              <p></p>
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
              <p class="text-center">
                Cargando datos, espere por favor...
            </p>
            </Modal.Body>
          </Modal>

          <PageTitle
            heading="Inventario/Activaciones"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          <Container fluid={true} className="content-body">
            <Row>
              <Col md="12" lg="12">
                <ToolkitProvider
                  keyField="id"
                  data={products}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <div>
                      <Card
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      >
                        <Card.Header
                          as="h3"
                          style={{
                            backgroundColor: "rgba(236,236,236,0.6)",
                            color: "black",
                          }}
                        >
                          Parámetros de busqueda para stock
                        </Card.Header>
                        <Card.Body
                          style={{
                            backgroundColor: "rgba(236,236,236,0.3)",
                          }}
                        >
                          {/* <Container> */}


                          <Row>
                            <Col className="align-middle" md="auto">
                              Fecha inicial
                            </Col>
                            <Col md="auto">
                              <DatePicker
                                locale="es"
                                selected={startDate}
                                dateFormat="dd/MM/yyyy"
                                onChange={updateDate}
                              />
                            </Col>

                            <Col className="align-middle" md="auto">
                              Fecha final
                            </Col>
                            <Col md="auto">
                              <DatePicker
                                locale="es"
                                dateFormat="dd/MM/yyyy"
                                selected={lastDate}
                                onChange={updateLastDate}
                              />
                            </Col>
                            <Col>
                              <Button
                                onClick={() =>
                                  getFechaStock(
                                    moment(startDate).format("yyyy-MM-DD"),
                                    moment(lastDate).format("yyyy-MM-DD")
                                  )
                                }
                              >
                                Buscar por fecha
                              </Button>
                            </Col>
                          </Row>
                          {/* </Container> */}
                        </Card.Body>
                        {/* {products.length > 0 ? (
                          <Card.Footer
                            style={{
                              backgroundColor: "rgba(236,236,236,0.3)",
                            }}
                          >
                            <Col className="align-middle" md="auto">
                              Buscar cliente:
                            </Col>
                            <Col md="auto">
                              <SearchBar
                                class="align-middle"
                                placeholder="Ingrese cédula"
                                style={{ height: 25, width: 150 }}
                                {...props.searchProps}
                              />
                            </Col>
                            <Col className="align-middle" md="auto">
                              Buscar gestor:
                            </Col>
                            <Col md="auto">
                              <SearchBar
                                class="align-middle"
                                placeholder="Ingrese nombre"
                                style={{ height: 25, width: 150 }}
                                {...props.searchProps}
                              />
                            </Col>
                          </Card.Footer>
                        ) : null} */}
                      </Card>
                      <Card
                        className="mt-3"
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      >
                        <Card.Header
                          as="h3"
                          style={{
                            backgroundColor: "rgba(236,236,236,0.9)",
                            color: "black",
                          }}
                        >
                          Listado general
                        </Card.Header>
                        <Card.Body
                          style={{
                            backgroundColor: "rgba(236,236,236,0.6)",
                          }}
                        >
                          <Form>
                            {/* <Col> */}
                            {/* <Row>
                              <Form.Check>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  size="lg"
                                  color="green"
                                />
                                <Form.Check.Label
                                  column
                                  style={{ fontSize: 11 }}
                                >
                                  Sin días de retraso
                                </Form.Check.Label>
                              </Form.Check>

                              <Form.Check>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  size="lg"
                                  color="orange"
                                />
                                <Form.Check.Label
                                  column
                                  style={{ fontSize: 11 }}
                                >
                                  Retraso de entre 10 a 30 días
                                </Form.Check.Label>
                              </Form.Check>

                              <Form.Check>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  size="lg"
                                  color="#b30000"
                                />
                                <Form.Check.Label
                                  column
                                  style={{ fontSize: 11 }}
                                >
                                  Retraso de más de 30 días
                                </Form.Check.Label>
                              </Form.Check>
                            </Row> */}
                          </Form>
                          <Col className="mt-3"></Col>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            wrapperClasses="table-responsive"
                            // keyField="ci"
                            // class="align-middle"
                            filter={filterFactory()}
                            pagination={paginationFactory(optionsPagination)}
                            {...props.baseProps}
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </Container>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
