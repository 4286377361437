import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Modal,
  Button,
  FormGroup,
  Form,
  Card,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import * as SecureLS from "secure-ls";

function Content() {
  const server = window.$linkserver;

  const [modulos_nombre, setmodulos_nombre] = useState("");
  const [modulos_descripcion, setmodulos_descripcion] = useState("");

  const [dataTable, setdataTable] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [modulos, setmodulos] = useState();
  const [index, setIndex] = useState();

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const { data } = infoUsuario;
  const columns = [
    {
      dataField: "mo_id",
      text: "Codigo",
      sort: true,
    },
    {
      dataField: "mo_nombre",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "mo_descripcion",
      text: "Descripción",
    },
    {
      dataField: "mo_estado",
      text: "Estado",
      formatter: (cell, row, index) => activeDesactive(row, index),
      sort: true,
    },

    {
      dataField: "MO_EDITAR",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const [error, seterror] = useState({
    nombre: "",
    descripcion: "",
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const changeEstado = async (item, index) => {
    if (item.mo_estado === 1) {
      item.mo_estado = 0;
    } else if (item.mo_estado === 0) {
      item.mo_estado = 1;
    }
    update(item, index);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const editmodulos = (row, index) => {
    setmodulos_nombre(row.mo_nombre);
    setmodulos_descripcion(row.mo_descripcion);
    setmodulos(row);
    setIndex(index);
    setshowModal(true);
  };

  const setDatamodulos = () => {
    modulos.mo_nombre = modulos_nombre;
    modulos.mo_descripcion = modulos_descripcion;

    seterror((error) => {
      return { ...error, nombre: "", descripcion: "" };
    });

    setmodulos_nombre("");
    setmodulos_descripcion("");
    setshowModal(false);
    update(modulos, index);
  };

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          editmodulos(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const activeDesactive = (row, index) => {
    return (
      <input
        type="checkbox"
        name="something"
        value="asa"
        checked={setEstado(row.mo_estado)}
        onChange={() => changeEstado(row, index)}
      />
    );
  };

  const createmodulos = async () => {
    seterror((error) => {
      return { ...error, nombre: "", descripcion: "" };
    });

    if (modulos_nombre !== "" && modulos_descripcion !== "") {
      const dataForm = new FormData();
      dataForm.append("mo_nombre", modulos_nombre);
      dataForm.append("mo_descripcion", modulos_descripcion);
      dataForm.append("usuario", data.US_CODIGO);

      await axios
        .post(server + "modulo/createmodulo", {
          mo_nombre: modulos_nombre,
          mo_descripcion: modulos_descripcion,
        })

        .then(function (response) {
          if (response.status === 200) {
            showMessage("Modulo Creado Correctamente", "success");

            setmodulos_descripcion("");
            setmodulos_nombre("");

            getData();
          } else {
            showMessage("Intentelo de Nuevo", "error");
          }
        })
        .catch(function (error) {});
    } else {
      if (modulos_nombre === "") {
        seterror((error) => {
          return { ...error, nombre: "Campo requerido" };
        });
      }

      if (modulos_descripcion === "") {
        seterror((error) => {
          return { ...error, descripcion: "Campo requerido" };
        });
      }
      showMessage("Campos incompletos", "error");
    }
  };

  useEffect(() => {
    const getDataInicial = async () => {
      await axios
        .get(server + "modulo/getModulos")
        .then(function (response) {
          setdataTable(response.data);
        })
        .catch(function (error) {})
        .then(function () {});
    };

    getDataInicial();
  }, [server]);

  const getData = async () => {
    await axios
      .get(server + "modulo/getModulos")
      .then(function (response) {
        setdataTable(response.data);
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const update = (dataUpdate, index) => {
    const dataForm = new FormData();
    dataForm.append("mo_id", dataUpdate.MO_CODIGO);
    dataForm.append("mo_nombre", dataUpdate.MO_NOMBRE);
    dataForm.append("mo_descripcion", dataUpdate.MO_DESCRIPCION);
    dataForm.append("mo_estado", dataUpdate.MO_ESTADO);
    dataForm.append("usuario", data.US_CODIGO);
    dataForm.append("_method", "PUT");

    axios
      .post(server + "modulo/updatemodulo", {
        mo_id: dataUpdate.mo_id,
        mo_nombre: dataUpdate.mo_nombre,
        mo_descripcion: dataUpdate.mo_descripcion,
        mo_estado: dataUpdate.mo_estado,
      })

      .then(function (response) {
        if (response.status === 200) {
          showMessage("Modulo actualizado correctamente", "success");

          getData();
        } else {
          showMessage("Error en el Server Intentelo de nuevo", "error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Administracion/ Configuracion"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <div>
                <div>
                  <Modal
                    show={showModal}
                    onHide={!showModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header>
                      <Modal.Title id="contained-modal-title-vcenter">
                        Editar modulos
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <FormGroup as={Row}>
                          <Form.Label column sm="2">
                            {" "}
                            Nombre:{" "}
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              maxLength={150}
                              type="text"
                              placeholder="Nombre de modulos"
                              value={modulos_nombre}
                              onChange={(event) =>
                                setmodulos_nombre(event.target.value)
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Form.Label column sm="2">
                            {" "}
                            Descripción:{" "}
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              maxLength={100}
                              type="text"
                              placeholder="Descripción de modulos"
                              value={modulos_descripcion}
                              onChange={(event) =>
                                setmodulos_descripcion(event.target.value)
                              }
                            />
                          </Col>
                        </FormGroup>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button onClick={() => setDatamodulos()}>
                        Actualizar
                      </Button>
                      <Button
                        onClick={() => {
                          setshowModal(false);
                          setmodulos_nombre("");
                          seterror((error) => {
                            return { ...error, nombre: "", descripcion: "" };
                          });

                          setmodulos_descripcion("");
                        }}
                      >
                        Cerrar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div></div>
                <h3 className="text-center">Gestión de "modulos"</h3>
                <Card>
                  <Card.Header as="h5">Creación de "modulos" </Card.Header>
                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Nombre:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={150}
                            type="text"
                            placeholder="Nombre de Modulos"
                            value={modulos_nombre}
                            onChange={(event) =>
                              setmodulos_nombre(event.target.value)
                            }
                          />
                          <span style={{ color: "red" }}>{error.nombre}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Descripción:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={100}
                            type="text"
                            placeholder="Descripción de modulos"
                            value={modulos_descripcion}
                            onChange={(event) =>
                              setmodulos_descripcion(event.target.value)
                            }
                          />
                          <span style={{ color: "red" }}>
                            {error.descripcion}
                          </span>
                        </Col>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          variant="primary"
                          onClick={() => createmodulos()}
                        >
                          Guardar cambios
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>

                <br></br>

                <Card>
                  <Card.Header as="h5">
                    Listado general de "modulos"
                  </Card.Header>
                  <Card.Body>
                    <BootstrapTable
                      keyField="MO_CODIGO"
                      wrapperClasses="table-responsive"
                      data={dataTable}
                      columns={columns}
                    />
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
