import React, { Fragment, useState } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import filterFactory, {
  selectFilter,
  numberFilter,
  Comparator,
  textFilter
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import PageTitle from "../Layout/PageTitle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import optionsPagination from "../../helper/SetupComponents";
import Map from "../../componentes/screens/Map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as moment from "moment";
import axios from "axios";
import {
  faClock,
  faImages,
  // faMapMarkedAlt,
  faInfoCircle,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Row,
  Col,
  Container,
  Card,
  DropdownButton,
  FormGroup,
  Form,
  Button,
  Modal,
  Carousel,
} from "react-bootstrap";

function Content(props) {
  const server = window.$linkserver;
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const [modalShow, setModalShow] = React.useState(false);
  const [cartera, setCartera] = useState();
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const [startDate, setStartDate] = useState(new Date(year, month, day - 1));
  const [lastDate, setLastDate] = useState(new Date());

  const [showModalMap, setshowModalMap] = useState(false);
  const [showModalFotos, setshowModalFotos] = useState(false);

  const [products, setproducts] = useState([]);
  const [imgs, setIMGS] = useState([]);
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [horaMapa, sethoraMapa] = useState("");

  // const setCartera = useState()
  let qualityFilter;
  // const selectOptions = {
  //   1: "Éxitosa",
  //   0: "Fallida",
  // };
  const selectOptions = {
    1: "GESTIONADO",//"Éxitosa",
    0: "NO GESTIONADO",//"Fallida",
    2: "NO UBICADO",
    3: "FALLECIDO",
    4: "MOTO RETIRADA"
  };

  const columns = [
    {
      dataField: "gestor",
      text: "Gestor",
      headerAlign: "center",
      headerStyle: {
        textAlign: "center",
        minWidth: "160px",
        backgroundColor: "rgba(255, 255, 255, 1)"
      },
      filter: textFilter({
        placeholder: 'Nombre gestor'
      }),
      formatter: (cell, row, index) => infoGestor(row, index),
    },
    {
      dataField: "ccli_cedula",
      text: "Cédula",
      headerStyle: {
        textAlign: "center",
        minWidth: "160px",
        backgroundColor: "rgba(255, 255, 255, 1)"
      },
      filter: textFilter({
        placeholder: '# cédula'
      }),
    },
    // {
    //   dataField: "ccli_nombre",
    //   text: "Nombres",
    //   headerStyle: {
    //     textAlign: "center",
    //     minWidth: "190px",
    //     backgroundColor: "rgba(255, 255, 255, 1)"
    //   },
    //   filter: textFilter({
    //     placeholder: 'Nombre/Apellido'
    //   }),
    // },
    // {
    //   dataField: "ccli_apellido",
    //   text: "Apellidos",
    // },
    {
      dataField: "ccli_fecha",
      text: "Fecha de visita",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "130px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      formatter: (cell, row, index) => fechaVisita(row, index),
    },
    {
      dataField: "ccli_estado",
      text: "Estado",
      headerAlign: 'center',
      formatter: (cell) => selectOptions[cell],
      filter: selectFilter({
        options: selectOptions,
        withoutEmptyOption: false,
        style: {
          width: "100%",
          minWidth: "115px",
          alignItems: "center",
          textAlign: 'center'
        },
        className: "test-classname",
        datamycustomattr: "datamycustomattr",
      }),
    },
    {
      dataField: "ccli_observacion",
      text: "Observaciones",
      formatter: (cell, row, index) => observacionesFormatter(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "200px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "fotos",
      text: "Fotos",
      formatter: (cell, row, index) => showFotos(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "cdp_ubicacion",
      text: "Ubicación",
      formatter: (cell, row, index) => locationCliente(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
  ];
  //getVisitasByFecha
  // const products = [
  //   {
  //     ci: "010101010",
  //     name: "Juan Andres",
  //     lastname: "Perez",
  //     fechavisita: "12-10-2020",
  //     estado: 0,
  //     valor: "1223",
  //     observaciones: "Observaciones del compromiso de pago!!!",
  //   },
  //   {
  //     ci: "012344354",
  //     name: "Pedro Cristian",
  //     lastname: "Sanchez",
  //     fechavisita: "12-10-2020",
  //     estado: 1,
  //     valor: "2123",
  //     observaciones: "Observaciones del compromiso de pago!!!",
  //   },
  //   {
  //     ci: "032344354",
  //     name: "Martin Cristian",
  //     lastname: "Fernandez",
  //     fechavisita: "12-10-2020",
  //     estado: 0,
  //     valor: "1321",
  //     observaciones: "Observaciones del compromiso de pago!!!",
  //   },
  // ];
  const fechaVisita = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "130px",
        }}
      >
        {moment(row.ccli_fecha).format("DD-MM-YYYY | HH:mm ")}
      </Form.Label>
    );
  };

  const getFechaVisita = async (inicio, fin) => {
    // console.log(server + "api/asignacionapp/getVisitasByFecha/" +
    //   inicio +
    //   "/" +
    //   fin)
    await axios
      .get(server + "api/asignacionapp/getVisitasByFecha/" + inicio + "/" + fin)
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        // console.log(response.data);
        setproducts(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const infoGestor = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {row.ccli_visita.gestor.ges_nombre}{" "}
        {row.ccli_visita.gestor.ges_apellido}
      </Form.Label>
    );
  };
  function observacionesFormatter(cell, row) {
    return (
      <Form.Label
        className="text-justify"
        style={{ width: "100%", fontSize: 11 }}
      >
        {cell.ccli_observacion}
      </Form.Label>
    );
  }

  function priceFormatter(cell, row) {
    if (row.onSale) {
      return (
        <span>
          <strong style={{ color: "black" }}>$ {cell}</strong>
        </span>
      );
    }

    return <span>$ {cell}</span>;
  }

  const showFotos = (row, index) => {
    return (
      <Button
        onClick={() => openModalFotos(row)}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faImages} size="2x" />
      </Button>
    );
  };

  const openModalMap = async (row) => {
    setshowModalMap(true);
    setLat(row.ccli_visita.vis_ubicacion_actual_lat);
    setLon(row.ccli_visita.vis_ubicacion_actual_lon);
    sethoraMapa(row.ccli_fecha);
    // setIsLoading("visible");
    //getOpcionesApp
    // await axios
    //   .get(
    //     "http://192.168.1.13:5001/gestores/getLstOpcionesbyPerfil/" + row.per_id
    //   )
    //   .then(function (response) {
    //     setperfiles(row);
    //     // console.log(row);,
    //     setRowsModal(response.data);
    //     setIsModalVisible(true);
    //     setIsLoading("invisible");
    //   })
    //   .catch(function (error) {})
    //   .then(function () {});
  };

  function openModalFotos(item) {
    setshowModalFotos(true);
    setIMGS(item.ccli_visita);
  }

  const locationCliente = (row, index) => {
    return (
      <Button
        onClick={() => openModalMap(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon
          icon={faMapMarkedAlt}
          size="2x"
          textAlign="center"
          style={{ alignSelf: "center", justifyContent: "center" }}
        />
      </Button>
    );
  };

  function openModalVisitas(item) {
    console.log(item);
    setModalShow(true);
  }

  const updateDate = (date) => {
    setStartDate(date);
  };

  const updateLastDate = (date) => {
    setLastDate(date);
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Visitas"
            icon="pe-7s-info icon-gradient bg-mean-fruit"
          />

          <Modal
            show={showModalMap}
            onHide={!showModalMap}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              <Map
                lat={lat}
                lon={lon}
                titulo={moment(horaMapa).format("LLLL")}
                url="https://firebasestorage.googleapis.com/v0/b/proyecto-bajaj.appspot.com/o/Bajaj%2Fpersonamarker.png?alt=media&token=d943d61c-39fd-4983-acf4-42e8e5dde71b"
                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyBWQwWMO0El5LZPg5eECmCQ7bh5o2Zg738"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              ></Map>
            </Modal.Body>

            <Modal.Footer>
              <Button
                onClick={() => {
                  setshowModalMap(false);
                }}
              >
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showModalFotos}
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Title id="contained-modal-title-vcenter">
              Imagenes tomadas
            </Modal.Title>
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={imgs.vis_img1}
                  alt="First slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={imgs.vis_img2}
                  alt="Third slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={imgs.vis_img3}
                  alt="Third slide"
                />
              </Carousel.Item>
            </Carousel>
            <Modal.Footer>
              <Button onClick={() => setshowModalFotos(false)}>Cerrar</Button>
            </Modal.Footer>
          </Modal>

          <Container fluid={true} className="content-body">
            <Row>
              <Col md="12" lg="12">
                <Modal
                  show={modalShow}
                  {...props}
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Title id="contained-modal-title-vcenter">
                    Imagenes tomadas en la visita
                  </Modal.Title>
                  <Carousel>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgs.vis_img1}
                        alt="First slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgs.vis_img2}
                        alt="Third slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgs.vis_img3}
                        alt="Third slide"
                      />
                    </Carousel.Item>
                  </Carousel>
                  <Modal.Footer>
                    <Button onClick={() => setModalShow(false)}>Cerrar</Button>
                  </Modal.Footer>
                </Modal>

                <ToolkitProvider
                  keyField="ccli_cedula"
                  data={products}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <div>
                      <Card
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      >
                        <Card.Header
                          as="h3"
                          style={{
                            backgroundColor: "rgba(236,236,236,0.6)",
                            color: "black",
                          }}
                        >
                          Parámetros de busqueda para visitas
                        </Card.Header>
                        <Card.Body
                          style={{
                            backgroundColor: "rgba(236,236,236,0.3)",
                          }}
                        >
                          <Row>
                            <Col className="align-middle" md="auto">
                              Fecha inicial
                            </Col>
                            <Col md="auto">
                              <DatePicker
                                locale="es"
                                selected={startDate}
                                dateFormat="dd/MM/yyyy"
                                onChange={updateDate}
                              />
                            </Col>

                            <Col className="align-middle" md="auto">
                              Fecha final
                            </Col>
                            <Col md="auto">
                              <DatePicker
                                locale="es"
                                dateFormat="dd/MM/yyyy"
                                selected={lastDate}
                                onChange={updateLastDate}
                              />
                            </Col>
                            <Col>
                              <Button
                                onClick={() =>
                                  getFechaVisita(
                                    moment(startDate).format("yyyy-MM-DD"),
                                    moment(lastDate).format("yyyy-MM-DD")
                                  )
                                }
                              >
                                Buscar por fecha
                              </Button>
                            </Col>
                          </Row>
                        </Card.Body>
                        {/* {products.length > 0 ? (
                          <Card.Footer
                            style={{
                              backgroundColor: "rgba(236,236,236,0.3)",
                            }}
                          >
                            <Col className="align-middle" md="auto">
                              Buscar cliente:
                            </Col>
                            <Col md="auto">
                              <SearchBar
                                class="align-middle"
                                placeholder="Ingrese cédula"
                                style={{ height: 25, width: 150 }}
                                {...props.searchProps}
                              />
                            </Col>
                            <Col className="align-middle" md="auto">
                              Buscar gestor:
                            </Col>
                            <Col md="auto">
                              <SearchBar
                                class="align-middle"
                                placeholder="Ingrese nombre"
                                style={{ height: 25, width: 150 }}
                                {...props.searchProps}
                              />
                            </Col>
                          </Card.Footer>
                        ) : null} */}
                      </Card>
                      <Card
                        className="mt-3"
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      >
                        <Card.Header
                          as="h3"
                          style={{
                            backgroundColor: "rgba(236,236,236,0.9)",
                            color: "black",
                          }}
                        >
                          Listado general de "Visitas"
                        </Card.Header>
                        <Card.Body
                          style={{
                            backgroundColor: "rgba(236,236,236,0.6)",
                          }}
                        >
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            class="align-middle"
                            filter={filterFactory()}
                            pagination={paginationFactory(optionsPagination)}
                            {...props.baseProps}
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </Container>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
