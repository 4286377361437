import React from "react";
import { Modal, Button, Spinner ,Col, Row, Form} from 'react-bootstrap';


function ModalInfoSelection(props) {



    return (


        <Modal
           
            show={props.show}
            onHide={props.hideModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Eliga una de las Opciones
          </Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Form.Group as={Row}>
                <Col sm={10}>


                    {props.listItems}
             
            
                </Col>
            </Form.Group>
                <div className="text-center">
                    <div className={"invisible"}>
                        <Spinner animation="border" role="status">
                        </Spinner>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    );
}



export default ModalInfoSelection;