import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import { Row, Col, Modal, FormGroup, Form, Card } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as SecureLS from "secure-ls";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Button } from "primereact/button";
import { Spin } from "react-loading-io";
import DatePicker, { registerLocale } from "react-datepicker";
import * as moment from "moment";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import ReactExport from "react-export-excel";

import {
  faClock,
  faFileInvoice,
  faFile,
  faFileInvoiceDollar,
  faFileAlt,
  faMapMarkedAlt,
  faHistory,
  faLocationArrow,
  faImages,
  faUserEdit,
  faEdit,
  faCashRegister,
} from "@fortawesome/free-solid-svg-icons";

function Content() {
  const server = window.$linkserver;

  const [cargo_nombre, setcargo_nombre] = useState("");
  const [cargo_descripcion, setcargo_descripcion] = useState("");
  const [dataTable, setdataTable] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [cargo, setcargo] = useState();
  const [index, setIndex] = useState();
  const [lastDate, setLastDate] = useState(new Date());
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const date = new Date();

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const [startDate, setStartDate] = useState(new Date(year, month, day - 1));
  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  const [lstPedidos, setlstPedidos] = useState([
    {
      name: "Inventario detallado",
      estado: 0,
      img: faFileInvoice,
      color: "lightblue",
      data: [],
    },
    {
      name: "Inventario consolidado",
      estado: 0,
      img: faFileAlt,
      color: "orange",
      data: [],
    },
    {
      name: "Inventario de Activaciones",
      estado: 0,
      img: faFileInvoiceDollar,
      color: "green",
      data: [],
    },
  ]);

  const updateLastDate = (date) => {
    setLastDate(date);
  };
  const updateDate = (date) => {
    setStartDate(date);
  };
  const columns = [
    {
      dataField: "CAR_CODIGO",
      text: "Codigo",
      sort: true,
    },
    {
      dataField: "CAR_NOMBRE",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "CAR_DESCRIPCION",
      text: "Descripción",
    },
    {
      dataField: "CAR_ESTADO",
      text: "Estado",
      formatter: (cell, row, index) => activeDesactive(row, index),
      sort: true,
    },

    {
      dataField: "CAR_EDITAR",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const [error, seterror] = useState({
    nombre: "",
    descripcion: "",
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const changeEstado = async (item, index) => {
    if (item.CAR_ESTADO === 1) {
      item.CAR_ESTADO = 0;
    } else if (item.CAR_ESTADO === 0) {
      item.CAR_ESTADO = 1;
    }
    update(item, index);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const editcargo = (row, index) => {
    setcargo_nombre(row.CAR_NOMBRE);
    setcargo_descripcion(row.CAR_DESCRIPCION);
    setcargo(row);
    setIndex(index);
    setshowModal(true);
  };

  const setDatacargo = () => {
    cargo.CAR_NOMBRE = cargo_nombre;
    cargo.CAR_DESCRIPCION = cargo_descripcion;
    setcargo_nombre("");
    setcargo_descripcion("");
    setshowModal(false);
    update(cargo, index);
  };

  const editRow = (row, index) => {
    return (
      <Button icon="pi pi-shopping-cart" label="Add to Cart">
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const activeDesactive = (row, index) => {
    return (
      <input
        type="checkbox"
        name="something"
        value="asa"
        checked={setEstado(row.CAR_ESTADO)}
        onChange={() => changeEstado(row, index)}
      />
    );
  };

  const createcargo = async () => {
    seterror((error) => {
      return { ...error, nombre: "", descripcion: "" };
    });

    if (cargo_nombre !== "" && cargo_descripcion !== "") {
      const dataForm = new FormData();
      dataForm.append("car_nombre", cargo_nombre);
      dataForm.append("car_descripcion", cargo_descripcion);
      dataForm.append("usuario", data.US_CODIGO);

      await axios
        .post("http://192.168.1.13:8000/api/cargo/create", dataForm)

        .then(function (response) {
          console.log(response);
          if (response.data.success === true) {
            showMessage(response.data.message, "success");

            setcargo_descripcion("");
            setcargo_nombre("");

            getData();
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) { });
    } else {
      if (cargo_nombre === "") {
        seterror((error) => {
          return { ...error, nombre: "Campo requerido" };
        });
      }

      if (cargo_descripcion === "") {
        seterror((error) => {
          return { ...error, descripcion: "Campo requerido" };
        });
      }
      showMessage("Campos incompletos", "error");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getColor = () => {
    const item = data.us_empresa.listaParametros.find(
      (element) => element.pae_tipo === 4
    );

    if (item === undefined) {
      return "#137ad1";
    } else {
      return "#" + item.pae_parametro;
    }
  };

  const getReporteActivaciones = async (inicio, fin) => {
    let newArrT = [...lstPedidos];
    newArrT[2].estado = 1;
    setlstPedidos(newArrT);

    await axios
      .get(
        server +
        "api/inventario/getListStockActivaciones/" +
        inicio +
        "/" +
        fin
      )
      .then(function (response) {
        let newArr = [...lstPedidos];

        if (response.data.length > 0) {
          newArr[2].data = response.data;
          newArr[2].estado = 2;
        } else {
          newArr[2].estado = 3;
        }

        setlstPedidos(newArr);
      })
      .catch(function (error) { })
      .then(function () { });
  };


  const getReporteConsolidado = async (inicio, fin) => {
    let newArrT = [...lstPedidos];
    newArrT[1].estado = 1;
    setlstPedidos(newArrT);

    await axios
      .get(
        server +
        "api/inventario/getListStockConsolidado/" +
        inicio +
        "/" +
        fin
      )
      .then(function (response) {
        let newArr = [...lstPedidos];

        if (response.data.length > 0) {
          newArr[1].data = response.data;
          newArr[1].estado = 2;
        } else {
          newArr[1].estado = 3;
        }

        setlstPedidos(newArr);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getReporteDetallado = async (inicio, fin) => {
    let newArrT = [...lstPedidos];
    newArrT[0].estado = 1;
    setlstPedidos(newArrT);

    await axios
      .get(
        server +
        "api/inventario/getListStockDistribuidor/" +
        inicio +
        "/" +
        fin
      )
      .then(function (response) {
        let newArr = [...lstPedidos];

        if (response.data.length > 0) {
          newArr[0].data = response.data;
          newArr[0].estado = 2;
        } else {
          newArr[0].estado = 3;
        }

        setlstPedidos(newArr);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getReportes = (inicio, fin) => {
    getReporteActivaciones(inicio, fin);
    getReporteConsolidado(inicio, fin);
    getReporteDetallado(inicio, fin)
    // getReporteCompromiso(inicio, fin);
    // getReporteVisita(inicio, fin);
    // getReportePagos(inicio, fin);
  };

  const getData = async () => {
    await axios
      .get("http://192.168.1.13:8000/api/cargo/getallcargos")
      .then(function (response) {
        setdataTable(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const update = (dataUpdate, index) => {
    seterror((error) => {
      return { ...error, nombre: "", descripcion: "" };
    });

    const dataForm = new FormData();
    dataForm.append("car_id", dataUpdate.CAR_CODIGO);
    dataForm.append("car_nombre", dataUpdate.CAR_NOMBRE);
    dataForm.append("car_descripcion", dataUpdate.CAR_DESCRIPCION);
    dataForm.append("car_estado", dataUpdate.CAR_ESTADO);
    dataForm.append("usuario", data.US_CODIGO);
    dataForm.append("_method", "PUT");

    axios
      .post("http://192.168.1.13:8000/api/cargo/updateCargo", dataForm)

      .then(function (response) {
        if (response.data.success === true) {
          showMessage(response.data.message, "success");

          let newArr = [...dataTable];
          newArr[index] = dataUpdate;
          setdataTable(newArr);
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const renderGridItem = (data) => {
    return (
      <div className="p-col-12 p-md-3">
        <div className="product-grid-item card">
          <div className="product-grid-item-top">
            <div>
              <i className="pi pi-tag product-category-icon"></i>
              <span
                className="product-category"
                style={{ fontSize: 16, color: getColor() }}
              >
                Reporte
              </span>
            </div>
            {/* <span className={`product-badge status-${data.inventoryStatus.toLowerCase()}`}>{data.inventoryStatus}</span> */}
          </div>
          <div className="product-grid-item-content">
            <FontAwesomeIcon
              style={{ marginTop: 8, marginBottom: 10 }}
              icon={data.img}
              color={data.color}
              size="6x"
            />
            {/* <img
              src={data.img}
              style={{ padding: 35 }}
              onError={(e) =>
                (e.target.src =
                  "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
              }
              alt={"hola"}
            /> */}

            <div className="product-name">{data.name}</div>

            {/* <Rating value={data.rating} readonly cancel={false}></Rating> */}
          </div>

          {tipo(data.estado, data.data, data.name)}
          {/* <Button icon="pi pi-shopping-cart" label="Add to Cart" disabled={data.inventoryStatus === 'OUTOFSTOCK'}></Button> */}
        </div>
      </div>
    );
  };

  const tipo = (newTipo, datos, nombres) => {
    if (newTipo === 0) {
      return (
        <div>
          <span className="product-description">Selecciona una fecha</span>
        </div>
      );
    } else if (newTipo === 1) {
      return (
        <div className="product-grid-item-content">
          <Spin size={50} color={getColor()} />
        </div>
      );
    } else if (newTipo === 2) {
      return (
        <div className="product-grid-item-content">
          <ExcelFile
            element={
              <Button
                // onClick={() => console.log(datos)}
                className="p-button-success"
                label="Descargar Excel"
              ></Button>
            }
            filename={nombres}
          >
            <ExcelSheet data={datos} name={nombres}>
              {/* {noticias.map((noticia) => (
            <Col key={noticia.NOT_CODIGO} md={4} className="mt-3">
              {tipoUrl(noticia)}
            </Col>
          ))} */}

              {Object.keys(datos[0]).map((key) => (
                <ExcelColumn label={key} value={key} />
              ))}
            </ExcelSheet>
          </ExcelFile>
        </div>
      );
    } else if (newTipo === 3) {
      return (
        <div>
          <span style={{ color: "red" }} className="product-description">
            No existe datos
          </span>
        </div>
      );
    }
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Reportes/ Descargas"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {/* <Container fluid={true} className="content-body"> */}

          <Col md="12" lg="12">
            <div className="dataview-demo">
              <div className="card">
                <Card
                  style={{
                    borderWidth: 1,
                    borderColor: "rgba(128,128,128,0.4)",
                  }}
                >
                  <Card.Body
                    style={{
                      backgroundColor: "rgba(236,236,236,0.3)",
                    }}
                  >
                    <FormGroup as={Row}>
                      <Row>
                        <Col className="align-middle" md="auto">
                          Fecha inicial
                        </Col>
                        <Col md="auto">
                          <DatePicker
                            locale="es"
                            dateFormat="dd/MM/yyyy"
                            selected={startDate}
                            // startDate={startDate}
                            // endDate={lastDate}
                            // minDate={startDate}
                            // selectsStart
                            onChange={updateDate}
                          />
                        </Col>

                        <Col className="align-middle" md="auto">
                          Fecha final
                        </Col>
                        <Col md="auto">
                          <DatePicker
                            locale="es"
                            dateFormat="dd/MM/yyyy"
                            selected={lastDate}
                            // selectsEnd
                            // startDate={startDate}
                            // endDate={lastDate}
                            // minDate={startDate}
                            onChange={updateLastDate}
                          />
                        </Col>
                        <Col>
                          <Button
                            style={{ backgroundColor: getColor() }}
                            onClick={() =>
                              getReportes(
                                moment(startDate).format("yyyy-MM-DD"),
                                moment(lastDate).format("yyyy-MM-DD")
                              )
                            }
                            label=" Buscar por fecha"
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Card.Body>
                </Card>

                <DataView
                  value={lstPedidos}
                  layout={"grid"}
                  itemTemplate={renderGridItem}
                />
              </div>
            </div>
          </Col>

          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
