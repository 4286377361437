import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Modal,
  Button,
  FormGroup,
  Form,
  Card,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import * as SecureLS from "secure-ls";

function Content() {
  const [cargo_nombre, setcargo_nombre] = useState("");
  const [cargo_descripcion, setcargo_descripcion] = useState("");
  const [dataTable, setdataTable] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [cargo, setcargo] = useState();
  const [index, setIndex] = useState();
  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const { data } = infoUsuario;
  const columns = [
    {
      dataField: "CAR_CODIGO",
      text: "Codigo",
      sort: true,
    },
    {
      dataField: "CAR_NOMBRE",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "CAR_DESCRIPCION",
      text: "Descripción",
    },
    {
      dataField: "CAR_ESTADO",
      text: "Estado",
      formatter: (cell, row, index) => activeDesactive(row, index),
      sort: true,
    },

    {
      dataField: "CAR_EDITAR",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const [error, seterror] = useState({
    nombre: "",
    descripcion: "",
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const changeEstado = async (item, index) => {
    if (item.CAR_ESTADO === 1) {
      item.CAR_ESTADO = 0;
    } else if (item.CAR_ESTADO === 0) {
      item.CAR_ESTADO = 1;
    }
    update(item, index);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const editcargo = (row, index) => {
    setcargo_nombre(row.CAR_NOMBRE);
    setcargo_descripcion(row.CAR_DESCRIPCION);
    setcargo(row);
    setIndex(index);
    setshowModal(true);
  };

  const setDatacargo = () => {
    cargo.CAR_NOMBRE = cargo_nombre;
    cargo.CAR_DESCRIPCION = cargo_descripcion;
    setcargo_nombre("");
    setcargo_descripcion("");
    setshowModal(false);
    update(cargo, index);
  };

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          editcargo(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const activeDesactive = (row, index) => {
    return (
      <input
        type="checkbox"
        name="something"
        value="asa"
        checked={setEstado(row.CAR_ESTADO)}
        onChange={() => changeEstado(row, index)}
      />
    );
  };

  const createcargo = async () => {
    seterror((error) => {
      return { ...error, nombre: "", descripcion: "" };
    });

    if (cargo_nombre !== "" && cargo_descripcion !== "") {
      const dataForm = new FormData();
      dataForm.append("car_nombre", cargo_nombre);
      dataForm.append("car_descripcion", cargo_descripcion);
      dataForm.append("usuario", data.US_CODIGO);

      await axios
        .post("http://192.168.1.13:8000/api/cargo/create", dataForm)

        .then(function (response) {
          console.log(response);
          if (response.data.success === true) {
            showMessage(response.data.message, "success");

            setcargo_descripcion("");
            setcargo_nombre("");

            getData();
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) { });
    } else {
      if (cargo_nombre === "") {
        seterror((error) => {
          return { ...error, nombre: "Campo requerido" };
        });
      }

      if (cargo_descripcion === "") {
        seterror((error) => {
          return { ...error, descripcion: "Campo requerido" };
        });
      }
      showMessage("Campos incompletos", "error");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await axios
      .get("http://192.168.1.13:8000/api/cargo/getallcargos")
      .then(function (response) {
        setdataTable(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const update = (dataUpdate, index) => {
    seterror((error) => {
      return { ...error, nombre: "", descripcion: "" };
    });

    const dataForm = new FormData();
    dataForm.append("car_id", dataUpdate.CAR_CODIGO);
    dataForm.append("car_nombre", dataUpdate.CAR_NOMBRE);
    dataForm.append("car_descripcion", dataUpdate.CAR_DESCRIPCION);
    dataForm.append("car_estado", dataUpdate.CAR_ESTADO);
    dataForm.append("usuario", data.US_CODIGO);
    dataForm.append("_method", "PUT");

    axios
      .post("http://192.168.1.13:8000/api/cargo/updateCargo", dataForm)

      .then(function (response) {
        if (response.data.success === true) {
          showMessage(response.data.message, "success");

          let newArr = [...dataTable];
          newArr[index] = dataUpdate;
          setdataTable(newArr);
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Administracion/ Configuracion"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <div>
                <div>
                  <Modal
                    show={showModal}
                    onHide={!showModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header>
                      <Modal.Title id="contained-modal-title-vcenter">
                        Editar cargo
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <FormGroup as={Row}>
                          <Form.Label column sm="2">
                            {" "}
                            Nombre:{" "}
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              maxLength={150}
                              type="text"
                              placeholder="Nombre de cargo"
                              value={cargo_nombre}
                              onChange={(event) =>
                                setcargo_nombre(event.target.value)
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Form.Label column sm="2">
                            {" "}
                            Descripción:{" "}
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              maxLength={100}
                              type="text"
                              placeholder="Descripción de cargo"
                              value={cargo_descripcion}
                              onChange={(event) =>
                                setcargo_descripcion(event.target.value)
                              }
                            />
                          </Col>
                        </FormGroup>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button onClick={() => setDatacargo()}>Actualizar</Button>
                      <Button
                        onClick={() => {
                          setshowModal(false);
                          setcargo_nombre("");
                          setcargo_descripcion("");
                          seterror((error) => {
                            return { ...error, nombre: "", descripcion: "" };
                          });
                        }}
                      >
                        Cerrar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div></div>
                <h3 className="text-center">Gestión de "Cargo"</h3>
                <Card>
                  <Card.Header as="h5">Creción de "Cargo" </Card.Header>
                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Nombre:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={150}
                            type="text"
                            placeholder="Nombre de Cargo"
                            value={cargo_nombre}
                            onChange={(event) =>
                              setcargo_nombre(event.target.value)
                            }
                          />
                          <span style={{ color: "red" }}>{error.nombre}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Descripción:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={100}
                            type="text"
                            placeholder="Descripción de cargo"
                            value={cargo_descripcion}
                            onChange={(event) =>
                              setcargo_descripcion(event.target.value)
                            }
                          />
                          <span style={{ color: "red" }}>
                            {error.descripcion}
                          </span>
                        </Col>
                      </FormGroup>

                      <div className="text-center">
                        <Button variant="primary" onClick={() => createcargo()}>
                          Guardar cambios
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>

                <br></br>

                <Card>
                  <Card.Header as="h5">Listado general de "Cargos"</Card.Header>
                  <Card.Body>
                    <BootstrapTable
                      wrapperClasses="table-responsive"

                      keyField="CAR_CODIGO"
                      data={dataTable}
                      columns={columns}
                    />
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
