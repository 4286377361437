import React, { Fragment, useState } from "react";
import MetisMenu from "react-metismenu";
import * as SecureLS from "secure-ls";
import Logo2 from "../../images/assets/logo2.png";
import { Row, Col, Form } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Logobajaj from "../../images/assets/logo_desarrollo.png";

function Navbarmenu() {
  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const { us_modulo } = infoUsuario.data;
  const [data] = useState(infoUsuario.data);

  const getLogo = () => {
    const item = data.us_empresa.listaParametros.find(
      (element) => element.pae_tipo === 3
    );

    if (item === undefined) {
      return "https://firebasestorage.googleapis.com/v0/b/proyecto-bajaj.appspot.com/o/Predeterminados%2Flogo2.png?alt=media&token=3ce3d3b4-2c8a-4931-9d41-6f29605b770c";
    } else {
      return item.pae_parametro;
    }
  };

  return (
    <Fragment>
      {us_modulo.map((modulo) => (
        <Fragment key={modulo.mo_id}>
          <h5 className="app-sidebar__heading">{modulo.mo_nombre}</h5>
          {modulo.mo_menu.map((menu) => (
            <Fragment key={menu.men_id}>
              <MetisMenu
                key={menu.men_id}
                content={[
                  {
                    icon: menu.men_icono,
                    label: menu.men_nombre,
                    content: menu.men_opciones,
                  },
                ]}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
            </Fragment>
          ))}
        </Fragment>
      ))}

      <div style={{ marginBottom: 50 }}>
        <Col
          md={12}
          onClick={() =>
            window.open(
              "http://www.tecnocyclo.com/division-industrial.php",
              "_blank"
            )
          }
        >
          <Col xs={6} md={10} className="mx-auto text-center p-3">
            <Image src={Logobajaj} alt="logo hyundai" fluid />
          </Col>
        </Col>
      </div>
    </Fragment>
  );
}

export default Navbarmenu;
