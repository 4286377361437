import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Card,
  FormGroup,
  Form,
  Button,
  Spinner,
  Modal,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import axios from "axios";
import Swal from "sweetalert2";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
// import * as SecureLS from "secure-ls";

function Content() {
  const server = window.$linkserver;
  const [dataTable, setdataTable] = useState([]);

  const [isLoadingkilometrajeCreada, setisLoadingkilometrajeCreada] = useState(
    "invisible"
  );
  const [isLoadingTable, setisLoadingTable] = useState("invisible");
  const [isLoadingActualizar] = useState("invisible");
  const [showModal, setshowModal] = useState(false);

  // const ls = new SecureLS({
  //   encodingType: "des",
  //   isCompression: false,
  //   encryptionSecret: "admin-hyundai",
  // });
  // const { infoUsuario } = ls.get("ask-hy");
  // const { data } = infoUsuario;

  const [kilometraje, setkilometraje] = useState({
    kmt_id: 0,
    kmt_Kilometraje: "",
    kmt_descripcion: "",
    kmt_valor: "",
  });

  const [error, seterror] = useState({
    kmt_Kilometraje: "",
    kmt_descripcion: "",
    kmt_valor: "",
  });

  const columns = [
    {
      dataField: "kmt_id",
      text: "Codigo",
      sort: true,
    },
    {
      dataField: "kmt_kilometrajes",
      text: "Kilometraje",
      sort: true,
    },

    {
      dataField: "kmt_valor",
      text: "Valor",
      sort: true,
    },
    {
      dataField: "kmt_editar",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          edit(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const edit = (row, index) => {
    setkilometraje((kilometraje) => {
      return {
        ...kilometraje,
        kmt_id: row.kmt_id,
        kmt_Kilometraje: row.kmt_kilometrajes,
        kmt_descripcion: row.kmt_descripcion,
        kmt_valor: row.kmt_valor,
      };
    });

    setshowModal(true);
  };

  const getkilometrajes = async () => {
    setisLoadingTable("visible");
    await axios
      .get(server + "taller/getkilometraje")
      .then(function (response) {
        setisLoadingTable("invisible");
        setdataTable(response.data);
      })
      .catch(function (error) {
        setisLoadingTable("invisible");
      })
      .then(function () {});
  };

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const createkilometraje = async () => {
    seterror((error) => {
      return {
        ...error,
        kmt_Kilometraje: "",
        kmt_descripcion: "",
        kmt_valor: "",
      };
    });

    if (kilometraje.kmt_Kilometraje !== "" && kilometraje.kmt_valor !== "") {
      setisLoadingkilometrajeCreada("visible");
      await axios

        .post(server + "taller/createkilometraje", {
          kmt_Kilometrajes: kilometraje.kmt_Kilometraje,
          kmt_valor: parseFloat(kilometraje.kmt_valor),
        })

        .then(function (response) {
          setisLoadingkilometrajeCreada("invisible");

          if (response.status === 200) {
            showMessage("Kilometraje creada con éxito", "success");

            setkilometraje((kilometraje) => {
              return {
                ...kilometraje,
                kmt_id: 0,
                kmt_Kilometraje: "",
                kmt_descripcion: "",
                kmt_valor: "",
              };
            });

            getkilometrajes();

            //getData()
          } else {
            showMessage("Error el crear", "error");
          }
        })
        .catch(function (error) {
          showMessage("Campos incompletos", "error");
        });
    } else {
      if (kilometraje.kmt_Kilometraje === "") {
        seterror((error) => {
          return { ...error, kmt_Kilometraje: "Campo requerido" };
        });
      }

      if (kilometraje.kmt_valor === "") {
        seterror((error) => {
          return { ...error, kmt_valor: "Campo requerido" };
        });
      }

      showMessage("Campos incompletos", "error");
    }
  };

  const updatekilometraje = async () => {
    seterror((error) => {
      return {
        ...error,
        kmt_Kilometraje: "",
        kmt_descripcion: "",
        kmt_valor: "",
      };
    });

    if (kilometraje.kmt_valor !== "") {
      setisLoadingkilometrajeCreada("visible");
      await axios

        .post(server + "taller/updatekilometraje", {
          kmt_id: kilometraje.kmt_id,
          kmt_valor: parseFloat(kilometraje.kmt_valor),
        })

        .then(function (response) {
          setisLoadingkilometrajeCreada("invisible");

          if (response.status === 200) {
            showMessage("Kilometraje actualizada con éxito", "success");

            setkilometraje((kilometraje) => {
              return {
                ...kilometraje,
                kmt_id: 0,
                kmt_Kilometraje: "",
                kmt_valor: "",
              };
            });

            setshowModal(false);

            getkilometrajes();
          } else {
            showMessage("Error el crear", "error");
          }
        })
        .catch(function (error) {
          showMessage("Campos incompletos", "error");
        });
    } else {
      if (kilometraje.kmt_Kilometraje === "") {
        seterror((error) => {
          return { ...error, kmt_Kilometraje: "Campo requerido" };
        });
      }

      if (kilometraje.kmt_descripcion === "") {
        seterror((error) => {
          return { ...error, kmt_descripcion: "Campo requerido" };
        });
      }

      if (kilometraje.kmt_valor === "") {
        seterror((error) => {
          return { ...error, kmt_valor: "Campo requerido" };
        });
      }

      showMessage("Campos incompletos", "error");
    }
  };

  useEffect(() => {
    const getkilometrajesInicial = async () => {
      setisLoadingTable("visible");
      await axios
        .get(server + "taller/getkilometraje")
        .then(function (response) {
          setisLoadingTable("invisible");
          setdataTable(response.data);
        })
        .catch(function (error) {
          setisLoadingTable("invisible");
        })
        .then(function () {});
    };

    getkilometrajesInicial();
  }, [server]);

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle heading="Kilometraje" icon="pe-7s-like2" />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <div>
                <Card>
                  <div className="text-center">
                    <div
                      className={isLoadingkilometrajeCreada}
                      style={{ marginTop: 10 }}
                    >
                      <Spinner
                        animation="border"
                        role="status"
                        variant="secondary"
                      ></Spinner>
                    </div>
                  </div>

                  <Card.Header as="h5">
                    Creación de Nuevo Kilometraje{" "}
                  </Card.Header>
                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Kilometraje:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese un Kilometraje"
                            id="kmt_Kilometraje"
                            value={kilometraje.kmt_Kilometraje}
                            onChange={(e) => {
                              const val = e.target.value;
                              setkilometraje((kilometraje) => {
                                return { ...kilometraje, kmt_Kilometraje: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {error.kmt_Kilometraje}
                          </span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Valor:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese un valor"
                            id="kmt_valor"
                            value={kilometraje.kmt_valor}
                            onChange={(e) => {
                              const val = e.target.value;
                              setkilometraje((kilometraje) => {
                                return { ...kilometraje, kmt_valor: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {error.kmt_valor}
                          </span>
                        </Col>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          onClick={() => {
                            createkilometraje();
                          }}
                          variant="primary"
                        >
                          Crear una Kilometraje
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </div>
              <div>
                <Card className="mt-2">
                  <Card.Header as="h5">
                    Listado general de "Kilometraje"
                  </Card.Header>
                  <Card.Body>
                    <BootstrapTable
                      keyField="kmt_id"
                      wrapperClasses="table-responsive"
                      data={dataTable}
                      columns={columns}
                      pagination={paginationFactory()}
                    />

                    <div className="text-center">
                      <div className={isLoadingTable} style={{ marginTop: 10 }}>
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                        ></Spinner>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>

              <div>
                <Modal
                  show={showModal}
                  // onHide={!showModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Actualización de datos de: {kilometraje.kmt_Kilometraje}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="text-center">
                      <div className={isLoadingActualizar}>
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                        ></Spinner>
                      </div>
                    </div>

                    <div style={{ marginTop: 10 }}>
                      <Form>
                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Valor:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              placeholder="Ingrese un valor"
                              id="kmt_valor"
                              value={kilometraje.kmt_valor}
                              onChange={(e) => {
                                const val = e.target.value;
                                setkilometraje((kilometraje) => {
                                  return { ...kilometraje, kmt_valor: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {error.kmt_valor}
                            </span>
                          </Col>
                        </FormGroup>
                      </Form>
                    </div>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        updatekilometraje();
                      }}
                    >
                      Actualizar
                    </Button>
                    <Button
                      onClick={() => {
                        setshowModal(false);

                        seterror((error) => {
                          return {
                            ...error,
                            kmt_Kilometraje: "",
                            kmt_descripcion: "",
                            kmt_valor: "",
                          };
                        });

                        setkilometraje((kilometraje) => {
                          return {
                            ...kilometraje,
                            kmt_id: 0,
                            kmt_Kilometraje: "",
                            kmt_descripcion: "",
                            kmt_valor: "",
                          };
                        });
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
