import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./global.css";
import Index from "./pages/Index";
import RutaLogged from "./componentes/login/RutaLogged";
import ControlPanel from "./pages/ControlPanel";
import SubirDatos from "./componentes/subirdatos/SubirDatos";
import ListaCartera from "./componentes/listacartera/ListaCartera";
import CompromisoPago from "./componentes/pagos/CompromisoPago";
import Visitas from "./componentes/visitas/Visitas";
import Cobros from "./componentes/cobros/Cobros";
import Opciones from "./componentes/opciones/Opciones";
import Modulos from "./componentes/modulos/Modulos";
import Menu from "./componentes/menu/Menu";
import Gestores from "./componentes/mantenimiento/gestores/Gestores";
import OpcionesGestor from "./componentes/mantenimiento/opcionesgestor/OpcionesGestor";
import Cargo from "./componentes/cargo/Cargo";
import Roles from "./componentes/roles/Roles";
import Permisos from "./componentes/permisos/Permisos";
import Usuarios from "./componentes/usuarios/Usuarios";
import GestionarEmpresa from "./componentes/empresas/GestionarEmpresas";
import PerfilGestor from "./componentes/mantenimiento/perfilgestor/PerfilGestor";
import AsignarCartera from "./componentes/asignarcartera/AsignarCartera";
import Talleres from "./componentes/talleres/Talleres";
import ManodeObra from "./componentes/manodeobra/Manodeobra";
import Repuestos from "./componentes/repuestos/Repuestos";
import TipoRegistros from "./componentes/tiporegistros/Tiporegistros";
import Kilometraje from "./componentes/kilometraje/Kilometraje";
import GestionarAlmacenes from "./componentes/gestionaralmacenes/GestionarAlmacenes";
import Intermediarios from "./componentes/intermediarios/Intermediarios";
import ClienteFinal from "./componentes/clientefinal/ClienteFinal";
import StockDistribuidor from "./componentes/stockdistribuidor/StockDistribuidor";
import StockConsolidado from "./componentes/stockconsolidado/StockConsolidado";
import Activaciones from "./componentes/activaciones/Activaciones";
import Retail from "./componentes/retail/Retail";
import OrdendePago from "./componentes/ordendepago/OrdendePago";
import Descargarreportes from "./componentes/descargarreportes/Descargarreportes";
import SubirImagenes from "./componentes/subirimagenes/SubirImagenes";
import OrdenLiquidadas from "./componentes/ordendeliquidadas/Ordendeliquidadas";
import Descargarreportesorden from "./componentes/descargarreportesorden/Descargarreportesorden";
import InventarioReportes from "./componentes/inventarioreportes/InventarioReportes";
import GarantiasAprobadas from "./componentes/garantiasaprobadas/GarantiasAprobadas";
import Garantiasaporaprobar from "./componentes/garantiasaporaprobar/Garantiasaporaprobar";
import DashBoardPostVenta from "./componentes/dashdoardpostventa/DashBoardPostVenta";
import DashboardCartera from "./componentes/dashboardcartera/DashboardCartera";
import DashboardInventario from "./componentes/dashboardinventario/DashboardInventario";
import GarantiasRechazadas from "./componentes/garantiasrechazadas/GarantiasRechazadas";
import Noticias from "./componentes/noticias/Noticias";
import MapaTalleres from "./componentes/mapatalleres/MapaTalleres"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route component={Index} exact path="/" />
          <RutaLogged component={ControlPanel} path="/dashboard" />

          <RutaLogged
            component={GarantiasRechazadas}
            path="/garantiasrechazadas"
          />

          <RutaLogged component={DashboardCartera} path="/cartera" />
          <RutaLogged component={DashboardInventario} path="/inventario" />

          <RutaLogged component={SubirDatos} path="/subirdatos" />
          <RutaLogged component={ListaCartera} path="/listacartera" />
          <RutaLogged component={DashBoardPostVenta} path="/postventa" />

          <RutaLogged component={CompromisoPago} path="/pagos" />
          <RutaLogged component={Cobros} path="/cobros" />
          <RutaLogged component={Visitas} path="/visitas" />
          <RutaLogged component={Opciones} path="/opciones" />
          <RutaLogged component={Modulos} path="/modulos" />
          <RutaLogged component={Usuarios} path="/usuarios" />
          <RutaLogged component={Menu} path="/menu" />
          <RutaLogged component={Cargo} path="/cargo" />
          <RutaLogged component={Roles} path="/roles" />
          <RutaLogged component={Permisos} path="/permisos" />
          <RutaLogged component={GestionarEmpresa} path="/empresas" />
          <RutaLogged component={Gestores} path="/gestores" />
          <RutaLogged component={OpcionesGestor} path="/opcionesgestor" />
          <RutaLogged component={PerfilGestor} path="/perfilgestor" />
          <RutaLogged component={AsignarCartera} path="/asignarcartera" />
          <RutaLogged component={Talleres} path="/listatalleres" />
          <RutaLogged component={ManodeObra} path="/listaobra" />
          <RutaLogged component={Repuestos} path="/listaderepuestos" />
          <RutaLogged component={TipoRegistros} path="/tiporegistros" />
          <RutaLogged component={Kilometraje} path="/kilometraje" />
          <RutaLogged component={Intermediarios} path="/intermediario" />
          <RutaLogged component={ClienteFinal} path="/clientefinal" />
          <RutaLogged component={Retail} path="/retail" />
          <RutaLogged component={OrdendePago} path="/ordenpago" />
          <RutaLogged
            component={Garantiasaporaprobar}
            path="/garantiasporaprobar"
          />
          <RutaLogged
            component={Descargarreportesorden}
            path="/reportesordenespago"
          />

          <RutaLogged component={OrdenLiquidadas} path="/ordenLiquidadas" />

          <RutaLogged
            component={StockDistribuidor}
            path="/stockdistribuidores"
          />
          <RutaLogged component={Activaciones} path="/activaciones" />
          <RutaLogged component={Descargarreportes} path="/descargarreportes" />
          <RutaLogged component={StockConsolidado} path="/stockconsolidado" />
          <RutaLogged component={SubirImagenes} path="/subirimagenes" />
          <RutaLogged
            component={GestionarAlmacenes}
            path="/gestionaralmaccenes"
          />
          <RutaLogged
            component={InventarioReportes}
            path="/inventarioreportes"
          ></RutaLogged>
          <RutaLogged
            component={GarantiasAprobadas}
            path="/garantiasaprobadas"
          ></RutaLogged>
          <RutaLogged
            component={Noticias}
            path="/noticias"
          ></RutaLogged>
          <RutaLogged
            component={MapaTalleres}
            path="/mapatalleres"
          ></RutaLogged>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
