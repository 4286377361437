import React, { Fragment, useState } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import axios from "axios";
import {
  Row,
  Col,
  Container,
  Card,
  DropdownButton,
  Dropdown,
  Form,
  Button,
  FormGroup,
  Modal,
  Spinner,
  FormLabel,
} from "react-bootstrap";

import Swal from "sweetalert2";

function Content() {
  const server = window.$linkserver;
  const [nombreBanco, setNombreBanco] = useState("Banco");
  const [pathDocumento, setDocumentPath] = useState("");
  const [documento, setDocument] = useState([]);
  const [showModalHistorial, setShowModalHistorial] = useState(false);
  const [showModalCargando, setShowModalCargando] = useState(false);

  const selectFile = (event) => {
    setDocumentPath(URL.createObjectURL(event.target.files[0]));
    setDocument(event.target.files[0]);
  };

  const uploadDocument = async () => {
    const dataForm = new FormData();
    dataForm.append("file", documento);

    if (nombreBanco == "Banco") {
      showMessage("Seleccione una entidad bancaria", "error");
    } else if (nombreBanco == "Banco Solidario") {
      dataForm.append("entidad_bancaria", "solidario");
      if (pathDocumento != "") {
        setShowModalHistorial(true);
        await axios
          .post(server + "api/cartera/subir", dataForm)

          .then(function (response) {
            // updateRowsRol(response.data.data, row);
            console.log(response);
            if (response.status === 200) {
              showMessage("Datos cargados con éxito", "success");
              setDocumentPath("");
              setDocument([]);
              setShowModalHistorial(false);
            } else {
              showMessage("Error al subir datos", "error");
              setShowModalHistorial(false);
            }
          })
          .catch(function (error) {})
          .then(function () {});
      } else {
        showMessage("Seleccione un archivo excel", "error");
      }
    } else if (nombreBanco == "Banco Austro") {
      dataForm.append("entidad_bancaria", "austro");
      if (pathDocumento != "") {
        setShowModalHistorial(true);
        await axios
          .post(server + "api/cartera/subir", dataForm)

          .then(function (response) {
            // updateRowsRol(response.data.data, row);
            console.log(response);
            if (response.status === 200) {
              showMessage("Datos cargados con éxito", "success");
              setDocumentPath("");
              setDocument([]);
              setShowModalHistorial(false);
            } else {
              showMessage("Error al subir datos", "error");
              setShowModalHistorial(false);
            }
          })
          .catch(function (error) {})
          .then(function () {});
      } else {
        showMessage("Seleccione un archivo excel", "error");
      }
    }
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });
  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Subir datos de entidades bancarias"
            icon="pe-7s-cloud-upload icon-gradient bg-mean-fruit"
          />
          <Modal
            show={showModalCargando}
            onHide={() => setShowModalCargando(false)}
            centered
          >
            <Modal.Body class="text-center">
              <p></p>
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
              <p class="text-center">
                Subiendo datos de cartera, espere por favor.
              </p>
            </Modal.Body>
          </Modal>
          <Modal
            show={showModalHistorial}
            onHide={() => setShowModalHistorial(false)}
            centered
          >
            <Modal.Body class="text-center">
              <p></p>
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
              <p class="text-center">
                Cargando datos de cartera, espere por favor.
              </p>
            </Modal.Body>
          </Modal>

          {/* <div> */}

          <Container fluid={true} className="content-body">
            <Row>
              <Col md="12" lg="12">
                <Card>
                  <Card.Body>
                    <Form>
                      <Form.Group as={Row}>
                        <Form.Label column sm="6">
                          Selecciona el banco para subir la información de
                          cartera:
                        </Form.Label>
                        <DropdownButton
                          id="dropdown-basic-button"
                          title={nombreBanco}
                        >
                          <Dropdown.Item
                            href="#/action-1"
                            onClick={() => setNombreBanco("Banco Solidario")}
                          >
                            Banco Solidario
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#/action-2"
                            onClick={() => setNombreBanco("Banco Austro")}
                          >
                            Banco Austro
                          </Dropdown.Item>
                        </DropdownButton>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="6">
                          Selecciona el archivo de datos de cartera:
                        </Form.Label>
                        <FormGroup as={Row}>
                          <label className="btn btn-default">
                            <input
                              type="file"
                              onChange={selectFile}
                              onClick={(e) => (e.target.value = null)}
                            />
                          </label>
                        </FormGroup>
                        <Button
                          variant="secondary"
                          icon="pe-7s-cloud-upload icon-gradient bg-mean-fruit"
                          onClick={() => uploadDocument()}
                        >
                          Cargar archivo
                        </Button>{" "}
                      </Form.Group>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          {/* </div> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
