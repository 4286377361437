import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Modal,
  Button,
  FormGroup,
  Form,
  Card,
  Spinner,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import * as SecureLS from "secure-ls";

function Content() {
  const server = window.$linkserver;
  const [rol_nombre, setRol_nombre] = useState("");
  const [rol_descripcion, setRol_descripcion] = useState("");

  const [isLoading, setIsLoading] = useState("invisible");
  const [dataTable, setdataTable] = useState([]);
  const [dataTableModal, setdataTableModal] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [showModalMenu, setshowModalMenu] = useState(false);
  const [rol, setRol] = useState();
  const [menu, setMenu] = useState();
  const [indexmenu, setindexmenu] = useState();

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const { data } = infoUsuario;

  const [index, setIndex] = useState();

  const [error, seterror] = useState({
    nombre: "",
    descripcion: "",
  });

  const columns = [
    {
      dataField: "rol_id",
      text: "Codigo",
      sort: true,
    },
    {
      dataField: "rol_nombre",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "rol_descripcion",
      text: "Descripción",
    },
    {
      dataField: "rol_estado",
      text: "Estado",
      formatter: (cell, row, index) => activeDesactive(row, index),
      sort: true,
    },

    {
      dataField: "ROL_EDITAR",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },

    {
      dataField: "ROL_MENU",
      formatter: (cell, row, index) => addMenu(row, index),
      text: "Agregar Menu",
      sort: true,
    },
  ];

  const columnsMenu = [
    {
      dataField: "op_codigo",
      text: "Codigo",
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "op_nombre",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "op_estado",
      text: "Estado",
      formatter: (cell, row, index) => activeDesactiveMenu(row, index),
      sort: true,
    },
  ];

  const columnsMenu1 = [
    // {

    //   dataField: "OP_CODIGO",
    //   text: "Codigo",
    //   sort: true,
    //   headerStyle: () => {
    //     return { width: "10%",visibility: 'hidden'};
    //   }
    // },
    {
      dataField: "op_nombre",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "op_estado",
      text: "Estado",
      formatter: (cell, row, index) => activeDesactiveItemMenu(row, index),
      sort: true,
    },
  ];

  const rowStyle = (row, rowIndex) => {
    row.index = rowIndex;
    const style = {};
    style.backgroundColor = "rgba(54, 163, 173, .10)";
    return style;
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <BootstrapTable
          keyField="op_id"
          wrapperClasses="table-responsive"
          data={row.list_opciones}
          columns={columnsMenu1}
        />
      </div>
    ),
    onExpand: (row, isExpand, rowIndex, e) => {
      if (isExpand) {
        setMenu(row);
        setindexmenu(rowIndex);
      }
    },
  };

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const setEstadoMenu = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const changeEstado = async (item, index) => {
    if (item.rol_estado === 1) {
      item.rol_estado = 0;
    } else if (item.rol_estado === 0) {
      item.rol_estado = 1;
    }
    update(item, index);
  };

  const changeEstadoMenuItem = async (item, index) => {
    if (item.op_estado === 1) {
      item.op_estado = 0;
    } else if (item.op_estado === 0) {
      item.op_estado = 1;
    }

    activeMenuItem(item, index);
  };

  const changeEstadoMenu = async (item, index) => {
    if (item.op_estado === 1) {
      item.op_estado = 0;
    } else if (item.op_estado === 0) {
      item.op_estado = 1;
    }
    activeMenu(item, index);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const editRol = (row, index) => {
    setRol_nombre(row.rol_nombre);
    setRol_descripcion(row.rol_descripcion);
    setRol(row);
    setIndex(index);
    setshowModal(true);
  };

  const setDataRol = () => {
    rol.rol_nombre = rol_nombre;
    rol.rol_descripcion = rol_descripcion;
    seterror((error) => {
      return { ...error, nombre: "", descripcion: "" };
    });
    setRol_nombre("");
    setRol_descripcion("");
    setshowModal(false);
    update(rol, index);
  };

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          editRol(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const listMenuForRol = async (row, inde) => {
    setRol(row);
    setIsLoading("visible");
    await axios
      .get(server + "roles/getRolesMenu/" + row.rol_id)
      .then(function (response) {
        console.log(response.data);
        setdataTableModal(response.data);
        setshowModalMenu(true);
        setIsLoading("invisible");
      })
      .catch(function (error) {})
      .then(function () {});

    // setshowModalMenu(true)
  };
  const addMenu = (row, index) => {
    return (
      <Button
        onClick={() => {
          listMenuForRol(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faPlusSquare} />
      </Button>
    );
  };

  const activeDesactiveMenu = (row, index) => {
    return (
      <input
        type="checkbox"
        name="something"
        value="asa"
        checked={setEstadoMenu(row.op_estado)}
        onChange={() => changeEstadoMenu(row, index)}
      />
    );
  };

  const activeDesactive = (row, index) => {
    return (
      <input
        type="checkbox"
        name="something"
        value="asa"
        checked={setEstado(row.rol_estado)}
        onChange={() => changeEstado(row, index)}
      />
    );
  };

  const activeDesactiveItemMenu = (row, index) => {
    return (
      <input
        type="checkbox"
        name="something"
        value="asa"
        checked={setEstado(row.op_estado)}
        onChange={() => changeEstadoMenuItem(row, index)}
      />
    );
  };

  const createRol = async () => {
    seterror((error) => {
      return { ...error, nombre: "", descripcion: "" };
    });

    if (rol_nombre !== "" && rol_descripcion !== "") {
      const dataForm = new FormData();
      dataForm.append("rol_nombre", rol_nombre);
      dataForm.append("rol_descripcion", rol_descripcion);
      dataForm.append("usuario", data.US_CODIGO);
      await axios
        .post(server + "roles/createRoles", {
          rol_nombre: rol_nombre,
          rol_descripcion: rol_descripcion,
        })

        .then(function (response) {
          if (response.status === 200) {
            showMessage("Rol creado Correctamente", "success");
            setRol_descripcion("");
            setRol_nombre("");
            getData();
          } else {
            showMessage("Error en servidor intentalo de nuevo", "error");
          }
        })
        .catch(function (error) {});
    } else {
      if (rol_nombre === "") {
        seterror((error) => {
          return { ...error, nombre: "Campo requerido" };
        });
      }

      if (rol_descripcion === "") {
        seterror((error) => {
          return { ...error, descripcion: "Campo requerido" };
        });
      }
      showMessage("Campos incompletos", "error");
    }
  };

  useEffect(() => {
    const getDataInicial = async () => {
      setIsLoading("visible");
      await axios
        .get(server + "roles/getRoles")
        .then(function (response) {
          setdataTable(response.data);
          setIsLoading("invisible");
        })
        .catch(function (error) {})
        .then(function () {});
    };
    getDataInicial();
  }, [server]);

  const getData = async () => {
    setIsLoading("visible");
    await axios
      .get(server + "roles/getRoles")
      .then(function (response) {
        setdataTable(response.data);
        setIsLoading("invisible");
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const activeMenu = (dataUpdate, index) => {
    const dataForm = new FormData();
    dataForm.append("rom_rol", rol.ROL_CODIGO);
    dataForm.append("rom_menu", dataUpdate.OP_CODIGO);
    dataForm.append("rom_estado", dataUpdate.OP_ESTADO);
    dataForm.append("usuario", data.US_CODIGO);

    axios
      .post(server + "roles/agregarRolMenu", {
        tbl_roles_rol_id: rol.rol_id,
        tbl_menu_men_id: dataUpdate.op_codigo,
        rom_estado: dataUpdate.op_estado,
      })

      .then(function (response) {
        if (response.status === 200) {
          showMessage("Menu actualizada correctamente", "success");

          let newArr = [...dataTableModal];
          newArr[index] = dataUpdate;
          setdataTableModal(newArr);
        } else {
          showMessage("Error en el server intentalo de nuevo", "error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const activeMenuItem = (dataUpdate, index) => {
    const dataForm = new FormData();
    dataForm.append("rolmo_rol", rol.ROL_CODIGO);
    dataForm.append("rolmo_menu", menu.OP_CODIGO);
    dataForm.append("rolmo_opciones", dataUpdate.OP_CODIGO);
    dataForm.append("rolmo_estado", dataUpdate.OP_ESTADO);
    dataForm.append("usuario", data.US_CODIGO);

    console.log(dataUpdate);
    console.log(menu);

    axios
      .post(server + "roles/agregarRolMenuOpciones", {
        tbl_roles_rol_id: rol.rol_id,
        tbl_menu_men_id: menu.op_codigo,
        tbl_opciones_web_op_id: dataUpdate.op_id,
        rolmo_estado: dataUpdate.op_estado,
      })

      .then(function (response) {
        if (response.status === 200) {
          showMessage("Opcion Actualizada Correctamente", "success");

          let newArr = [...dataTableModal];
          newArr[indexmenu].op_estado = 1;
          newArr[indexmenu].list_opciones[index] = dataUpdate;
          setdataTableModal(newArr);
        } else {
          showMessage("Error en el server Intentalo de Nuevo", "error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const update = (dataUpdate, index) => {
    const dataForm = new FormData();
    dataForm.append("rol_id", dataUpdate.ROL_CODIGO);
    dataForm.append("rol_nombre", dataUpdate.ROL_NOMBRE);
    dataForm.append("rol_descripcion", dataUpdate.ROL_DESCRIPCION);
    dataForm.append("rol_estado", dataUpdate.ROL_ESTADO);

    axios
      .post(server + "roles/updateRoles", {
        rol_id: dataUpdate.rol_id,
        rol_nombre: dataUpdate.rol_nombre,
        rol_descripcion: dataUpdate.rol_descripcion,
        rol_estado: dataUpdate.rol_estado,
      })

      .then(function (response) {
        if (response.status === 200) {
          showMessage("Rol se actualizo correctamente", "success");

          getData();
        } else {
          showMessage("Error en el server intetntalo de nuevo", "error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Administracion/ Configuracion"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <div>
                <div>
                  <Modal
                    show={showModalMenu}
                    onHide={!showModalMenu}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header>
                      <Modal.Title id="contained-modal-title-vcenter">
                        Selección de Menus para este Rol
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <BootstrapTable
                        keyField="op_codigo"
                        wrapperClasses="table-responsive"
                        data={dataTableModal}
                        columns={columnsMenu}
                        expandRow={expandRow}
                        rowStyle={rowStyle}
                      />
                      <div className="text-center">
                        <div className={isLoading}>
                          <Spinner animation="border" role="status"></Spinner>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        onClick={() => {
                          setshowModalMenu(false);
                        }}
                      >
                        Cerrar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>

                <div>
                  <Modal
                    show={showModal}
                    onHide={!showModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header>
                      <Modal.Title id="contained-modal-title-vcenter">
                        Editar Rol
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <FormGroup as={Row}>
                          <Form.Label column sm="2">
                            {" "}
                            Nombre:{" "}
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              maxLength={150}
                              type="text"
                              placeholder="Nombre de rol"
                              value={rol_nombre}
                              onChange={(event) =>
                                setRol_nombre(event.target.value)
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Form.Label column sm="2">
                            {" "}
                            Descripción:{" "}
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              maxLength={100}
                              type="text"
                              placeholder="Descripción de rol"
                              value={rol_descripcion}
                              onChange={(event) =>
                                setRol_descripcion(event.target.value)
                              }
                            />
                          </Col>
                        </FormGroup>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button onClick={() => setDataRol()}>Actualizar</Button>
                      <Button
                        onClick={() => {
                          setshowModal(false);
                          setRol_nombre("");
                          setRol_descripcion("");
                          seterror((error) => {
                            return { ...error, nombre: "", descripcion: "" };
                          });
                        }}
                      >
                        Cerrar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div></div>
                <h3 className="text-center">Gestión de "Roles"</h3>
                <Card>
                  <Card.Header as="h5">Creación de "rol" </Card.Header>
                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Nombre:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={150}
                            type="text"
                            placeholder="Nombre de rol"
                            value={rol_nombre}
                            onChange={(event) =>
                              setRol_nombre(event.target.value)
                            }
                          />
                          <span style={{ color: "red" }}>{error.nombre}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Descripción:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={100}
                            type="text"
                            placeholder="Descripción de rol"
                            value={rol_descripcion}
                            onChange={(event) =>
                              setRol_descripcion(event.target.value)
                            }
                          />
                          <span style={{ color: "red" }}>
                            {error.descripcion}
                          </span>
                        </Col>
                      </FormGroup>

                      <div className="text-center">
                        <Button variant="primary" onClick={() => createRol()}>
                          Guardar cambios
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>

                <br></br>

                <Card>
                  <Card.Header as="h5">Listado general de "Roles"</Card.Header>
                  <Card.Body>
                    <BootstrapTable
                      keyField="rol_idx"
                      wrapperClasses="table-responsive"
                      data={dataTable}
                      columns={columns}
                    />
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
