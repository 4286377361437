import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  // CSVExport,
} from "react-bootstrap-table2-toolkit";
import filterFactory, {
  selectFilter,
  numberFilter,
  Comparator,
  textFilter
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import optionsPagination from "../../helper/SetupComponents";

import PageTitle from "../Layout/PageTitle";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Map from "../../componentes/screens/MapList";
import * as moment from "moment";
import axios from "axios";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Swal from "sweetalert2";

import {
  // faInfoCircle,
  // faCalendarCheck,
  // faPenSquare,
  // faHandHoldingUsd,
  faInfoCircle,
  // faMapMarkedAlt,
  // faSearchLocation,
  faLocationArrow,
  faImages,
  faMoneyBillAlt,
  faMoneyCheckAlt,
  faCaretSquareDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  Row,
  Col,
  Container,
  Card,
  // DropdownButton,
  Form,
  Carousel,
  Button,
  Modal,
} from "react-bootstrap";
import * as SecureLS from "secure-ls";

function Content(props) {
  const server = window.$linkserver;
  // const [nombreBanco, setNombreBanco] = useState("Banco del Austro");
  const { SearchBar } = Search;
  // const { ExportCSVButton } = CSVExport;
  const [modalShow, setModalShow] = React.useState(false);
  // const [cartera, setCartera] = useState();
  // const [startDate, setStartDate] = useState(new Date());
  const [showModalFotos, setshowModalFotos] = useState(false);
  const [showModalMap, setshowModalMap] = useState(false);
  const [showModalDetalleCobros, setShowModalDetalleCobros] = useState(false);
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const [startDate, setStartDate] = useState(new Date(year, month, day - 1));
  const [lastDate, setLastDate] = useState(new Date());
  const [imgs, setIMGS] = useState([]);
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [getRowsPerfil, setRowsPerfil] = useState([]);
  const [horaMapa, sethoraMapa] = useState("");

  const [getRowsCiudades, setRowsCiudades] = useState([]);
  const [idCiudad, setIDCiudad] = useState(0);
  const [ltsTalleresCiudad, setltsTalleresCiudad] = useState([]);


  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  useEffect(() => {
    getCiudad()
  }, []);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });


  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const updateDate = (date) => {
    setStartDate(date);
  };

  const updateLastDate = (date) => {
    setLastDate(date);
  };

  const getCiudad = async () => {
    await axios
      .get(server + "empresas/getListCuidades")
      .then(function (response) {
        // console.log(response);
        setDataCiudad(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getTallerCiudad = async (idCiudad) => {

    await axios
      .get(server + "taller/getTallerByCiudad/" + idCiudad + "/" + data.us_empresa.emp_id)
      .then(function (response) {
        // console.log(response.data  . );
        if (response.data.length > 0) {
          let arrTalleres = []
          let promLat = 0
          let promLng = 0
          let cantidadMarcadores = 0
          response.data.map((item, index) => {

            if (item.tal_latitude != 0 || item.tal_longitude != 0) {
              console.log('insertandoooo ' + item.tal_nombre + " ---    " + item.tal_latitude);
              let newData = {
                id: item.tal_id,
                name: item.tal_nombre,
                position: { lat: item.tal_latitude, lng: item.tal_longitude }
              }
              promLat = promLat + item.tal_latitude
              promLng = promLng + item.tal_longitude
              cantidadMarcadores += 1
              arrTalleres.push(newData);
            }
          })
          console.log('SUMA LAT: ' + promLat);
          let promTotalLat = promLat / cantidadMarcadores
          let promTotalLng = promLng / cantidadMarcadores
          setLat(promTotalLat)
          setLon(promTotalLng)
          setltsTalleresCiudad(arrTalleres)
          console.log(promTotalLat);
          console.log(promTotalLng);
        }

        // setDataCiudad(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const setDataCiudad = (data) => {
    const rows = data.map((row, index) => (
      <option key={row.cui_id} value={row.cui_id}>
        {row.cui_nombre}
      </option>
    ));
    setRowsCiudades(rows);
  };


  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Mapa de talleres"
            icon="pe-7s-info icon-gradient bg-mean-fruit"
          />

          <Container fluid={true} className="content-body">
            <Row>
              <Col md="12" lg="12">

                <Modal
                  show={modalShow}
                  {...props}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Información de Cartera:
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                      Cras mattis consectetur purus sit amet fermentum. Cras
                      justo odio, dapibus ac facilisis in, egestas eget quam.
                      Morbi leo risus, porta ac consectetur ac, vestibulum at
                      eros.
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => setModalShow(false)}>Close</Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={showModalFotos}
                  {...props}
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Title id="contained-modal-title-vcenter">
                    Imagenes tomadas
                  </Modal.Title>

                  {imgs.length == 1 ? (
                    <Carousel>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={imgs[0]}
                          alt="First slide"
                        />
                      </Carousel.Item>
                    </Carousel>
                  ) : imgs.length == 2 ? (
                    <Carousel>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={imgs[0]}
                          alt="First slide"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={imgs[1]}
                          alt="Third slide"
                        />
                      </Carousel.Item>{" "}
                    </Carousel>
                  ) : imgs.length == 3 ? (
                    <Carousel>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={imgs[0]}
                          alt="First slide"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={imgs[1]}
                          alt="Third slide"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={imgs[2]}
                          alt="Third slide"
                        />
                      </Carousel.Item>{" "}
                    </Carousel>
                  ) : null}

                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={imgs.cob_img3}
                      alt="Third slide"
                    />
                  </Carousel.Item>

                  <Modal.Footer>
                    <Button onClick={() => setshowModalFotos(false)}>
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>



                <ToolkitProvider
                  keyField="id"
                  // data={products}
                  // columns={columns}
                  search
                >
                  {(props) => (
                    <div>
                      <Card
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      >
                        <Card.Header
                          as="h3"
                          style={{
                            backgroundColor: "rgba(236,236,236,0.6)",
                            color: "black",
                          }}
                        >
                          Parámetros de busqueda para talleres
                        </Card.Header>
                        <Card.Body
                          style={{
                            backgroundColor: "rgba(236,236,236,0.3)",
                          }}
                        >
                          <Row>
                            <Col>
                              <Form.Label className="title-create">
                                Seleccione la ciudad para listar los talleres:{" "}
                              </Form.Label>
                              <Form.Control
                                as="select"
                                onChange={(e) => {
                                  const value = Number(e.target.value);
                                  setIDCiudad(value);

                                }}
                              >
                                <option value="0">TODOS</option>
                                {getRowsCiudades}
                              </Form.Control>
                            </Col>

                            <Col>
                              <Button
                                onClick={() => {
                                  console.log(idCiudad);
                                  getTallerCiudad(idCiudad);

                                }}
                              >
                                Buscar talleres
                              </Button>
                            </Col>
                          </Row>
                        </Card.Body>


                      </Card>


                      <Card
                        className="mt-3"
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      >
                        <Card.Header
                          as="h3"
                          style={{
                            backgroundColor: "rgba(236,236,236,0.9)",
                            color: "black",
                          }}
                        >
                          Ubicación de Talleres
                        </Card.Header>
                        <Card.Body
                          style={{
                            backgroundColor: "rgba(236,236,236,0.6)",
                          }}
                        >
                          <Map
                            lat={lat}
                            lon={lon}
                            ltsMarcadores={ltsTalleresCiudad}
                            titulo={moment(horaMapa).format("LLLL")}
                            url="https://firebasestorage.googleapis.com/v0/b/proyecto-bajaj.appspot.com/o/Bajaj%2Fpersonamarker.png?alt=media&token=d943d61c-39fd-4983-acf4-42e8e5dde71b"
                            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyBWQwWMO0El5LZPg5eECmCQ7bh5o2Zg738"
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `400px` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                          ></Map>

                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </Container>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );

  // const infoCarteraSeleccionada = () => {
  //   return (
  //     <Modal
  //       show={modalShow}
  //       {...props}
  //       size="lg"
  //       aria-labelledby="contained-modal-title-vcenter"
  //       centered
  //     >
  //       <Modal.Header closeButton>
  //         <Modal.Title id="contained-modal-title-vcenter">
  //           Modal heading
  //         </Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body>
  //         <h4>Centered Modal</h4>
  //         <p>
  //           Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
  //           dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
  //           ac consectetur ac, vestibulum at eros.
  //         </p>
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Button onClick={props.onHide}>Close</Button>
  //       </Modal.Footer>
  //     </Modal>
  //   );
  // };
}

export default Content;
