import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
      <div className="app-footer">
        <div className="app-footer__inner">
          <div className="app-footer-right">
            <ul className="nav">
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Todos los derechos reservados
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
  );
}

export default Footer;
