import React, { Fragment, useState } from "react";
import Hamburger from "react-hamburgers";
import MobileMenu from "./MobileMenu";
import * as SecureLS from "secure-ls";
import { useHistory, Link } from "react-router-dom";

function Logo({
  enableClosedSidebar,
  SetEnableclosedsidebar,
  enableMobileMenu,
  setEnablemobilemenu,
  enableMobileMenuSmall,
  setEnablemobilemenusmall,
}) {
  const [activeHam, setActiveHam] = useState(false);
  const history = useHistory();
  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);
  const activeHamburger = () => {
    if (activeHam === true) {
      setActiveHam(false);
    } else {
      setActiveHam(true);
    }
  };

  const toggleEnableClosedSidebar = () => {
    SetEnableclosedsidebar(!enableClosedSidebar);
  };

  const getMenu = () => {
    const item = data.us_empresa.listaParametros.find(
      (element) => element.pae_tipo === 2
    );

    if (item === undefined) {
      return "https://firebasestorage.googleapis.com/v0/b/proyecto-bajaj.appspot.com/o/Predeterminados%2Flogo-inverse.png?alt=media&token=7e883494-cffe-4588-8eff-dcf81c61578f";
    } else {
      return item.pae_parametro;
    }
  };

  return (
    <Fragment>
      <div style={{ padding: 4 }} className="app-header__logo">
        <div style={{ backgroundColor: "red" }} />
        <img
          height={"90%"}
          src={getMenu()}
          alt="new"
          padding={9}
          onClick={() => history.push("/dashboard")}
        />
        <div
          style={{ backgroundColor: "red" }}
          className="header__pane ml-auto"
        >
          <div onClick={toggleEnableClosedSidebar}>
            <Hamburger
              active={enableClosedSidebar}
              type="elastic"
              onClick={activeHamburger}
            />
          </div>
        </div>
      </div>
      <MobileMenu
        enableMobileMenu={enableMobileMenu}
        setEnablemobilemenu={setEnablemobilemenu}
        enableMobileMenuSmall={enableMobileMenuSmall}
        setEnablemobilemenusmall={setEnablemobilemenusmall}
      />
    </Fragment>
  );
}

export default Logo;
