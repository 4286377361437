import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../../Layout/PageTitle";
import {
  Row,
  Col,
  Modal,
  Button,
  FormGroup,
  Form,
  Card,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import ModalSelectOpciones from "../../screens/ModalSelectOpciones";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
// import * as SecureLS from "secure-ls";
// import moment from "moment";
import "moment/locale/es";

function Content() {
  const server = window.$linkserver;
  const [perfil_nombre, setperfil_nombre] = useState("");
  const [perfil_descripcion, setperfil_descripcion] = useState("");
  // const [perfil_id, setperfil_id] = useState("");

  // const [perfil_fecha, setperfil_fecha] = useState("");

  const [dataTable, setdataTable] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [perfiles, setperfiles] = useState();
  const [index, setIndex] = useState();

  const [getRowsModal, setRowsModal] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [opciones, setOpciones] = useState([]);
  // const [isLoading, setIsLoading] = useState("invisible");

  // const ls = new SecureLS({
  //   encodingType: "des",
  //   isCompression: false,
  //   encryptionSecret: "admin-hyundai",
  // });
  // const { infoUsuario } = ls.get("ask-hy");
  // const { data } = infoUsuario;

  const columns = [
    {
      dataField: "per_id",
      text: "Código",
      sort: true,
    },
    {
      dataField: "per_nombre",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "per_descripcion",
      text: "Descripción",
    },
    // {
    //   dataField: "per_fecha_creacion",
    //   text: "Fecha de creación",
    //   formatter: (cell, row, index) => dateFormat(row, index),
    // },
    {
      dataField: "per_estado",
      text: "Estado",
      formatter: (cell, row, index) => activeDesactive(row, index),
      sort: true,
    },

    {
      dataField: "per_editar",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
    {
      dataField: "per_add_opciones",
      formatter: (cell, row, index) => addOpciones(row, index),
      text: "Agregar opciones",
      sort: true,
    },
  ];

  const [error, seterror] = useState({
    nombre: "",
    descripcion: "",
  });

  // const dateFormat = (row, index) => {
  //   if (!row) {
  //     return "";
  //   }
  //   return `${
  //     moment(row).format("dddd, DD ") +
  //     " de " +
  //     moment(row).format("MMMM") +
  //     " del " +
  //     moment(row).format("YYYY")
  //       ? moment(row).format("dddd, DD ") +
  //         " de " +
  //         moment(row).format("MMMM") +
  //         " del " +
  //         moment(row).format("YYYY")
  //       : moment(row).format("dddd, DD ") +
  //         " de " +
  //         moment(row).format("MMMM") +
  //         " del " +
  //         moment(row).format("YYYY")
  //   }`;
  // };
  //DD-MM-YYYY
  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const addOpciones = (row, index) => {
    return (
      <Button
        onClick={() => openItemsForPerfil(row)}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faPlusSquare} />
      </Button>
    );
  };

  const openItemsForPerfil = async (row) => {
    // setIsLoading("visible");
    //getOpcionesApp
    await axios
      .get(server + "gestores/getLstOpcionesbyPerfil/" + row.per_id)
      .then(function (response) {
        setperfiles(row);
        // console.log(row);,
        setRowsModal(response.data);
        setIsModalVisible(true);
        // setIsLoading("invisible");
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const changeEstado = async (item, index) => {
    if (item.per_estado === 1) {
      item.per_estado = 0;
    } else if (item.per_estado === 0) {
      item.per_estado = 1;
    }
    update(item, index);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const editperfiles = (row, index) => {
    setperfil_nombre(row.per_nombre);
    setperfil_descripcion(row.per_descripcion);
    // setperfil_id(row.per_id);
    setperfiles(row);
    setIndex(index);
    setshowModal(true);
  };

  const setDataperfiles = () => {
    perfiles.per_nombre = perfil_nombre;
    perfiles.per_descripcion = perfil_descripcion;

    seterror((error) => {
      return { ...error, nombre: "", descripcion: "" };
    });

    setperfil_nombre("");
    setperfil_descripcion("");
    setshowModal(false);
    update(perfiles, index);
  };

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          editperfiles(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const activeDesactive = (row, index) => {
    return (
      <input
        type="checkbox"
        name="something"
        value="asa"
        checked={setEstado(row.per_estado)}
        onChange={() => changeEstado(row, index)}
      />
    );
  };

  const createPerfil = async () => {
    seterror((error) => {
      return { ...error, nombre: "", descripcion: "" };
    });

    if (perfil_nombre !== "" && perfil_descripcion !== "") {
      await axios
        .post(server + "gestores/createPerfilApp", {
          per_nombre: perfil_nombre,
          per_descripcion: perfil_descripcion,
          per_estado: 1,
        })

        .then(function (response) {
          if (response.status === 200) {
            showMessage("Perfil Creado Correctamente", "success");

            setperfil_descripcion("");
            setperfil_nombre("");

            getData();
          } else {
            showMessage("Intentelo de Nuevo", "error");
          }
        })
        .catch(function (error) {});
    } else {
      if (perfil_nombre === "") {
        seterror((error) => {
          return { ...error, nombre: "Campo requerido" };
        });
      }

      if (perfil_descripcion === "") {
        seterror((error) => {
          return { ...error, descripcion: "Campo requerido" };
        });
      }
      showMessage("Campos incompletos", "error");
    }
  };

  useEffect(() => {
    const getDataIncial = async () => {
      await axios
        .get(server + "gestores/getPerfilesApp")
        .then(function (response) {
          setdataTable(response.data);
        })
        .catch(function (error) {})
        .then(function () {});
    };
    getDataIncial();
  }, [server]);

  const getData = async () => {
    await axios
      .get(server + "gestores/getPerfilesApp")
      .then(function (response) {
        setdataTable(response.data);
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const update = (dataUpdate, index) => {
    axios
      .post(server + "gestores/updatePerfilApp", {
        per_id: dataUpdate.per_id,
        per_nombre: dataUpdate.per_nombre,
        per_descripcion: dataUpdate.per_descripcion,
        per_estado: dataUpdate.per_estado,
      })

      .then(function (response) {
        if (response.status === 200) {
          showMessage("Perfil actualizado correctamente", "success");

          getData();
        } else {
          showMessage("Error en el Server Intentelo de nuevo", "error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateEstadoModalOpciones = (itemModal, indexModal) => {
    var newEstado = 0;
    // console.log(itemModal);
    if (itemModal.opc_estado === 1) {
      newEstado = 0;
    } else if (itemModal.opc_estado === 0) {
      newEstado = 1;
    }

    console.log(itemModal.opc_id + ", " + perfiles.per_id + " " + newEstado);

    axios
      .post(server + "gestores/addOpcionPerfilApp", {
        tbl_opciones_opc_id: Number(itemModal.opc_id),
        tbl_perfil_per_id: Number(perfiles.per_id),
        per_opc_estado: newEstado,
      })

      .then(function (response) {
        if (response.status === 200) {
          showMessage("Opción agregrada con exito", "success");
          let newArr = [...getRowsModal];
          newArr[indexModal].opc_estado = newEstado;
          setRowsModal(newArr);
        } else {
          showMessage("Error en el servidor intentalo de nuevo", "error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Mantenimiento/Perfiles"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <div>
                <div>
                  <Modal
                    show={showModal}
                    onHide={!showModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header>
                      <Modal.Title id="contained-modal-title-vcenter">
                        Editar perfiles
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <FormGroup as={Row}>
                          <Form.Label column sm="2">
                            {" "}
                            Nombre:{" "}
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              maxLength={150}
                              type="text"
                              placeholder="Nombre de perfiles"
                              value={perfil_nombre}
                              onChange={(event) =>
                                setperfil_nombre(event.target.value)
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Form.Label column sm="2">
                            {" "}
                            Descripción:{" "}
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              maxLength={100}
                              type="text"
                              placeholder="Descripción de perfiles"
                              value={perfil_descripcion}
                              onChange={(event) =>
                                setperfil_descripcion(event.target.value)
                              }
                            />
                          </Col>
                        </FormGroup>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button onClick={() => setDataperfiles()}>
                        Actualizar
                      </Button>
                      <Button
                        onClick={() => {
                          setshowModal(false);
                          setperfil_nombre("");
                          seterror((error) => {
                            return { ...error, nombre: "", descripcion: "" };
                          });

                          setperfil_descripcion("");
                        }}
                      >
                        Cerrar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div>
                  <ModalSelectOpciones
                    show={isModalVisible}
                    onHide={() => setIsModalVisible(false)}
                    row={1}
                    listItems={getRowsModal}
                    updateEstadoModalOpciones={updateEstadoModalOpciones}
                    // selectRow={selectRow}
                  >
                    {" "}
                  </ModalSelectOpciones>
                </div>
                <div></div>
                <h3 className="text-center">Gestión de "PERFILES"</h3>
                <Card>
                  <Card.Header as="h5">
                    Creación de "perfil" para gestores{" "}
                  </Card.Header>
                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Nombre:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={150}
                            type="text"
                            placeholder="Nombre de perfiles"
                            value={perfil_nombre}
                            onChange={(event) =>
                              setperfil_nombre(event.target.value)
                            }
                          />
                          <span style={{ color: "red" }}>{error.nombre}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Descripción:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={100}
                            type="text"
                            placeholder="Descripción de perfiles"
                            value={perfil_descripcion}
                            onChange={(event) =>
                              setperfil_descripcion(event.target.value)
                            }
                          />
                          <span style={{ color: "red" }}>
                            {error.descripcion}
                          </span>
                        </Col>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          variant="primary"
                          onClick={() => createPerfil()}
                        >
                          Guardar cambios
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>

                <br></br>

                <Card>
                  <Card.Header as="h5">
                    Listado general de "perfiles"
                  </Card.Header>
                  <Card.Body>
                    <BootstrapTable
                      keyField="MO_CODIGO"
                      data={dataTable}
                      columns={columns}
                    />
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
