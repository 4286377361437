import React, { Fragment, useEffect, useState } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Container,
  Card,
  Form,
  FormGroup,
  Button,
  Modal,
  Carousel,
  Spinner,
  Tabs,
  Tab,
} from "react-bootstrap";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import optionsPagination from "../../helper/SetupComponents";
import filterFactory, {
  textFilter,
  selectFilter,
  numberFilter,
  Comparator,
  dateFilter,
} from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import BootstrapTable, {
  ROW_SELECT_DISABLED,
} from "react-bootstrap-table-next";
import * as moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandHoldingUsd,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";

function Content(props) {
  // const { data } = this.props.location

  const [products, setproducts] = useState([]);
  const [products2, setproducts2] = useState([]);
  const [showModalFotos, setshowModalFotos] = useState(false);
  const server = window.$linkserver;
  const [LtsItemAprobacion, setLtsItemAprobacion] = useState([]);
  const [ShowModalAprobacion, setShowModalAprobacion] = useState(false);
  const [tabla1, settabla1] = useState("");
  const [tabla2, settabla2] = useState("");
  const [currentClienteCarte, setItemDataOrden] = useState([]);
  const [listaImagenes, setlistaImagenes] = useState([]);
  const [nombrePantalla, setnombrePantalla] = useState("");
  const [tipo, settipo] = useState(1);

  const columns = [
    {
      dataField: "tal_nombre",
      // text: "Nombre Taller",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "200px",
      },
      footer: "",
      filter: textFilter({
        placeholder: "Nombre Taller",
      }),
    },
    {
      dataField: "tal_cuidad",
      // text: "Ciudad",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },
      footer: "",
      filter: textFilter({
        placeholder: "Ciudad",
      }),
    },
    {
      dataField: "tal_telefono",
      text: "Teléfono",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },

      footer: "",
    },
    {
      dataField: "tre_nombre",
      text: "Trabajo",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },

      footer: "",
    },

    {
      dataField: "veh_chasis",
      text: "Chasis",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },

      footer: "",
    },

    {
      dataField: "reg_fecha_registro",
      text: "Fecha",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },
      formatter: (cell, row, index) => fechaPagoPago(row, index),
      footer: "",
    },
    {
      dataField: "Detalles",
      text: "Detalles",
      headerAlign: "center",
      headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
      formatter: (cell, row, index) => verAprobacion(row, index),
      footer: "",
    },
  ];

  const columns2 = [
    {
      dataField: "taller",
      // text: "Nombre Taller",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "200px",
      },
      footer: "",
      filter: textFilter({
        placeholder: "Nombre Taller",
      }),
    },
    {
      dataField: "ciudad",
      // text: "Ciudad",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },
      footer: "",
      filter: textFilter({
        placeholder: "Ciudad",
      }),
    },
    {
      dataField: "telefono",
      text: "Teléfono",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },

      footer: "",
    },
    {
      dataField: "trabajo_realizado",
      text: "Trabajo",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },

      footer: "",
    },

    {
      dataField: "chasis",
      text: "Chasis",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },

      footer: "",
    },

    {
      dataField: "fecha_registro",
      text: "Fecha",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },
      formatter: (cell, row, index) => fechaPagoPago(row, index),
      footer: "",
    },
  ];

  const selectOptionsAprobacion = {
    1: "Mano de Obra",
    2: "Repuesto",
  };
  const Pdf = () => {
    return (
      <>
        <Button onClick={() => setShowModalAprobacion(false)}>Cerrar</Button>
      </>
    );
  };
  const columnsAprobacion = [
    {
      dataField: "detalle_gar",
      // text: "Tipo de Registro",
      headerAlign: "center",
      footer: "",
      formatter: (cell) => selectOptionsAprobacion[cell],
      filter: selectFilter({
        options: selectOptionsAprobacion,
        placeholder: "Todos los Registros",
        withoutEmptyOption: false,
        style: {
          // backgroundColor: "pink",
        },
        className: "test-classname",
        datamycustomattr: "datamycustomattr",
      }),
      headerStyle: { backgroundColor: "rgba(255, 255, 255, 1)" },
      // formatter: (cell, row, index) => infoGestor(row, index),
    },

    {
      dataField: "detalle_nombre",
      text: "Descripción",
      headerAlign: "center",
      footer: "",
      headerStyle: { backgroundColor: "rgba(255, 255, 255, 1)" },
      // formatter: (cell, row, index) => infoGestor(row, index),
    },

    {
      dataField: "detalle_valor",
      text: "Valor",
      headerAlign: "center",
      headerStyle: { backgroundColor: "rgba(255, 255, 255, 1)" },
      footer: (columnData) =>
        "$" + columnData.reduce((acc, item) => acc + item, 0),
      // formatter: (cell, row, index) => infoGestor(row, index),
    },
  ];

  const getListaItemOrden = async (row) => {
    setItemDataOrden(row);
    setlistaImagenes(row.listaImagenes);
    // settipoLiquidar(0);
    setShowModalAprobacion(true);
    // // andres
    // setfechaordenPdfgata(fechaorden(row.reg_fecha_garantia));
    // setfechaordenPdf(fechaorden(row.ord_fecha));
    setLtsItemAprobacion(row.detalletrabajos);
  };

  const fechaorden = (fecha) => {
    return moment(fecha).format("DD-MM-YYYY");
  };
  const verAprobacion = (row, index) => {
    return (
      <Button
        onClick={() => getListaItemOrden(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon icon={faHandHoldingUsd} size="2x" />
      </Button>
    );
  };
  useEffect(() => {
    try {
      setnombrePantalla(props.props.location.data.nombre);
      if (props.props.location.data.tipo === 1) {
        settipo(3);
        getListaPDI(
          props.props.location.data.mes,
          props.props.location.data.anio
        );
      } else if (props.props.location.data.tipo === 2) {
        settipo(3);
        getListaMantenimiento(
          props.props.location.data.mes,
          props.props.location.data.anio
        );
      } else if (props.props.location.data.tipo === 3) {
        settipo(2);

        settabla1("Actuales");
        settabla2("Mes Anterior");
        getListaPDIsinMan(
          props.props.location.data.mes,
          props.props.location.data.anio
        );
      } else if (props.props.location.data.tipo === 4) {
        settipo(1);

        settabla1("Garantias Aprobadas");
        settabla2("Garantias Negadas");
        getListaGarantia(
          props.props.location.data.mes,
          props.props.location.data.anio
        );
      }
    } catch (error) {}
  }, []);

  const fechaPagoPago = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {moment(row.fecha_registro).format("DD-MM-YYYY")}
      </Form.Label>
    );
  };

  const getListaPDI = async (mes, anio) => {
    await axios

      .get(server + "api/dashboard/getCountPDIdetail/" + mes + "/" + anio)

      .then(function (response) {
        setproducts(response.data);
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const getListaMantenimiento = async (mes, anio) => {
    await axios

      .get(server + "api/dashboard/getCountMANdetail/" + mes + "/" + anio)

      .then(function (response) {
        setproducts(response.data);
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const getListaGarantia = async (mes, anio) => {
    await axios

      .get(server + "api/dashboard/getCountGARdetail/" + mes + "/" + anio)

      .then(function (response) {
        setproducts(response.data.aprobados);
        setproducts2(response.data.negados);
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const getListaPDIsinMan = async (mes, anio) => {
    await axios

      .get(
        server + "api/dashboard/getCountMotoPdiSinManDetail/" + mes + "/" + anio
      )

      .then(function (response) {
        setproducts(response.data.actual);
        setproducts2(response.data.ant);
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const tipoPantalla = (tipo) => {
    if (tipo === 1) {
      return (
        <Col md="12" lg="12">
          <div>
            <Card>
              <Card.Body>
                <ToolkitProvider
                  keyField="ci"
                  data={products}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <div>
                      <Card
                        className="mt-3"
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      >
                        <Card.Header
                          as="h3"
                          style={{
                            backgroundColor: "rgba(236,236,236,0.9)",
                            color: "black",
                          }}
                        >
                          Listado de {tabla1}
                        </Card.Header>
                        <Card.Body
                          style={{
                            backgroundColor: "rgba(236,236,236,0.6)",
                          }}
                        >
                          <Col className="mt-3"></Col>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            keyField="ci"
                            wrapperClasses="table-responsive"
                            class="align-middle"
                            filter={filterFactory()}
                            pagination={paginationFactory(optionsPagination)}
                            {...props.baseProps}
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </ToolkitProvider>
              </Card.Body>
            </Card>
          </div>

          <div style={{ marginTop: 15 }}>
            <Card>
              <Card.Body>
                <ToolkitProvider
                  keyField="ci"
                  data={products2}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <div>
                      <Card
                        className="mt-3"
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      >
                        <Card.Header
                          as="h3"
                          style={{
                            backgroundColor: "rgba(236,236,236,0.9)",
                            color: "black",
                          }}
                        >
                          Listado de {tabla2}
                        </Card.Header>
                        <Card.Body
                          style={{
                            backgroundColor: "rgba(236,236,236,0.6)",
                          }}
                        >
                          <Col className="mt-3"></Col>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            keyField="ci"
                            wrapperClasses="table-responsive"
                            class="align-middle"
                            filter={filterFactory()}
                            pagination={paginationFactory(optionsPagination)}
                            {...props.baseProps}
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </ToolkitProvider>
              </Card.Body>
            </Card>
          </div>
        </Col>
      );
    } else if (tipo === 2) {
      return (
        <Col md="12" lg="12">
          <div>
            <Card>
              <Card.Body>
                <ToolkitProvider
                  keyField="ci"
                  data={products}
                  columns={columns2}
                  search
                >
                  {(props) => (
                    <div>
                      <Card
                        className="mt-3"
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      >
                        <Card.Header
                          as="h3"
                          style={{
                            backgroundColor: "rgba(236,236,236,0.9)",
                            color: "black",
                          }}
                        >
                          Listado de {tabla1}
                        </Card.Header>
                        <Card.Body
                          style={{
                            backgroundColor: "rgba(236,236,236,0.6)",
                          }}
                        >
                          <Col className="mt-3"></Col>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            keyField="ci"
                            wrapperClasses="table-responsive"
                            class="align-middle"
                            filter={filterFactory()}
                            pagination={paginationFactory(optionsPagination)}
                            {...props.baseProps}
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </ToolkitProvider>
              </Card.Body>
            </Card>
          </div>

          <div style={{ marginTop: 15 }}>
            <Card>
              <Card.Body>
                <ToolkitProvider
                  keyField="ci"
                  data={products2}
                  columns={columns2}
                  search
                >
                  {(props) => (
                    <div>
                      <Card
                        className="mt-3"
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      >
                        <Card.Header
                          as="h3"
                          style={{
                            backgroundColor: "rgba(236,236,236,0.9)",
                            color: "black",
                          }}
                        >
                          Listado de {tabla2}
                        </Card.Header>
                        <Card.Body
                          style={{
                            backgroundColor: "rgba(236,236,236,0.6)",
                          }}
                        >
                          <Col className="mt-3"></Col>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            keyField="ci"
                            wrapperClasses="table-responsive"
                            class="align-middle"
                            filter={filterFactory()}
                            pagination={paginationFactory(optionsPagination)}
                            {...props.baseProps}
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </ToolkitProvider>
              </Card.Body>
            </Card>
          </div>
        </Col>
      );
    } else if (tipo === 3) {
      return (
        <Col md="12" lg="12">
          <Card>
            <Card.Body>
              <ToolkitProvider
                keyField="ci"
                data={products}
                columns={columns2}
                search
              >
                {(props) => (
                  <div>
                    <Card
                      className="mt-3"
                      style={{
                        borderWidth: 1,
                        borderColor: "rgba(128,128,128,0.4)",
                      }}
                    >
                      <Card.Body
                        style={{
                          backgroundColor: "rgba(236,236,236,0.6)",
                        }}
                      >
                        <Col className="mt-3"></Col>
                        <BootstrapTable
                          striped
                          hover
                          condensed
                          keyField="ci"
                          wrapperClasses="table-responsive"
                          class="align-middle"
                          filter={filterFactory()}
                          pagination={paginationFactory(optionsPagination)}
                          {...props.baseProps}
                        />
                      </Card.Body>
                    </Card>
                  </div>
                )}
              </ToolkitProvider>
            </Card.Body>
          </Card>
        </Col>
      );
    }
  };
  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading={nombrePantalla}
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          <Container fluid={true} className="content-body">
            <Modal
              show={ShowModalAprobacion}
              {...props}
              onHide={() => setShowModalAprobacion(false)}
              // dialogClassName="modal-90w"

              aria-labelledby="example-custom-modal-styling-title"
              centered
              size="xl"
              // className="modal-dialog modal-lg"
              // style = {{width : '80%'}}
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  {currentClienteCarte.reg_codigo} de fecha :{" "}
                  {fechaorden(currentClienteCarte.reg_fecha_registro)}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ToolkitProvider
                  keyField="id"
                  data={LtsItemAprobacion}
                  columns={columnsAprobacion}
                  search
                >
                  {(props) => (
                    <>
                      <Container as={Row}>
                        <Col>
                          <Row
                            style={{
                              // backgroundColor: "rgba(19, 122, 209,0.3)",
                              textAlign: "center",
                              fontSize: 18,
                              fontWeight: "bold",
                              alignSelf: "center",
                            }}
                          >
                            {" "}
                            Información Cliente
                          </Row>

                          <Container>
                            <Row>
                              <Col>Nombre:</Col>
                              <Col>{currentClienteCarte.cli_nombre}</Col>
                            </Row>
                            <Row>
                              <Col>Identificación:</Col>
                              <Col>{currentClienteCarte.cli_cedula}</Col>
                            </Row>
                            <Row>
                              <Col>Teléfono:</Col>
                              <Col>{currentClienteCarte.cli_telefono}</Col>
                            </Row>
                          </Container>
                        </Col>

                        <Col>
                          <Row
                            style={{
                              // backgroundColor: "rgba(19, 122, 209,0.3)",
                              textAlign: "center",
                              fontSize: 18,
                              fontWeight: "bold",
                              alignSelf: "center",
                            }}
                          >
                            {" "}
                            Información de Vehiculo
                          </Row>

                          <Container>
                            <Row>
                              <Col>Marca:</Col>
                              <Col>{currentClienteCarte.veh_marca}</Col>
                            </Row>

                            <Row>
                              <Col>Chasis:</Col>
                              <Col>{currentClienteCarte.veh_chasis}</Col>
                            </Row>

                            <Row>
                              <Col>Color:</Col>
                              <Col>{currentClienteCarte.veh_color}</Col>
                            </Row>
                            <Row>
                              <Col>Año:</Col>
                              <Col>{currentClienteCarte.veh_anio}</Col>
                            </Row>
                          </Container>
                        </Col>

                        <Col>
                          <Row
                            style={{
                              // backgroundColor: "rgba(19, 122, 209,0.3)",
                              textAlign: "center",
                              fontSize: 18,
                              fontWeight: "bold",
                              alignSelf: "center",
                            }}
                          >
                            {" "}
                            Información de Taller
                          </Row>

                          <Container>
                            <Row>
                              <Col>Nombre:</Col>
                              <Col>{currentClienteCarte.tal_nombre}</Col>
                            </Row>
                            <Row>
                              <Col>RUC:</Col>
                              <Col>{currentClienteCarte.tal_ruc}</Col>
                            </Row>
                            <Row>
                              <Col>Teléfono:</Col>
                              <Col>{currentClienteCarte.tal_telefono}</Col>
                            </Row>
                            <Row>
                              <Col>Ciudad:</Col>
                              <Col>{currentClienteCarte.tal_cuidad}</Col>
                            </Row>
                          </Container>
                        </Col>
                      </Container>

                      <Container as={Row}>
                        <Col
                          style={{
                            // backgroundColor: "rgba(19, 122, 209,0.3)",
                            textAlign: "center",
                            fontSize: 18,
                            fontWeight: "bold",
                            alignSelf: "center",
                            marginTop: 8,
                            marginBottom: 8,
                          }}
                        >
                          {" "}
                          Detalle
                        </Col>

                        <Col>
                          <Button
                            onClick={() => setshowModalFotos(true)}
                            color="primary"
                            variant="contained"
                            style={{
                              alignSelf: "center",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <Text style={{ fontSize: 12, marginRight: 8 }}>
                              Ver Imagenes
                            </Text>
                            <FontAwesomeIcon icon={faUserEdit} size="2x" />
                          </Button>
                        </Col>
                      </Container>

                      <Container>
                        <Col>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            wrapperClasses="table-responsive"
                            // class="react-bootstrap-table table"
                            filter={filterFactory()}
                            pagination={paginationFactory(optionsPagination)}
                            {...props.baseProps}
                          />
                        </Col>
                      </Container>
                    </>
                  )}
                </ToolkitProvider>
              </Modal.Body>

              <Modal.Footer>{Pdf()}</Modal.Footer>
            </Modal>

            <Row>{tipoPantalla(tipo)}</Row>
            <Modal
                  show={showModalFotos}
                  {...props}
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Title id="contained-modal-title-vcenter">
                    Imagenes tomadas
                  </Modal.Title>
                  <Carousel>
                    {listaImagenes.map((item) => (
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={item}
                          alt="First slide"
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <Modal.Footer>
                    <Button onClick={() => setshowModalFotos(false)}>
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              
              
          </Container>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
