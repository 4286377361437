import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Card,
  FormGroup,
  Form,
  Button,
  Spinner,
  Modal,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import axios from "axios";
import Swal from "sweetalert2";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import * as SecureLS from "secure-ls";

// import * as SecureLS from "secure-ls";

function Content() {
  const server = window.$linkserver;
  const [dataTable, setdataTable] = useState([]);
  const [dataTableUser, setdataTableUser] = useState([]);
  const [isLoadingAlmacenCreada, setisLoadingAlmacenCreada] = useState(
    "invisible"
  );
  const [isLoadingTable, setisLoadingTable] = useState("invisible");
  // const [isLoadingActualizar, setisLoadingActualizar] = useState("invisible");
  const [showModal, setshowModal] = useState(false);
  const [showModalUsuarios, setshowModalUsuarios] = useState(false);
  const [showModalCreateUsuario, setshowModalCreateUsuario] = useState(false);

  const [showModalupdateUsuario, setshowModalupdateUsuario] = useState(false);
  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  // const ls = new SecureLS({
  //   encodingType: "des",
  //   isCompression: false,
  //   encryptionSecret: "admin-hyundai",
  // });
  // const { infoUsuario } = ls.get("ask-hy");
  // const { data } = infoUsuario;

  const [Almacen, setAlmacen] = useState({
    // cli_codigo: "",
    // cli_id: "",
    cli_razon_social: "",
    cli_nombre_comercial: "",
    cli_cuidad: 0,
    cli_direccion: "",
    cli_telefono: "",
    cli_email_Almacen: "",
    cli_estado: 0,
    cli_ruc: "",
    cli_pagina_web: "",
    cli_apellido: "",
    // cli_tipo_Almacen: "",
  });

  const [usuario, setusuario] = useState({
    // cli_codigo: "",
    usr_id: 0,
    usr_usuario: "",
    usr_nombre: "",
    usr_apellido: "",
    usr_identificacion: "",
    usr_alias: "",
    usr_clave: "",
  });

  const [errorusuario, seterrorusuario] = useState({
    // cli_codigo: "",
    usr_usuario: "",
    usr_nombre: "",
    usr_apellido: "",
    usr_identificacion: "",
    usr_alias: "",
    usr_clave: "",
  });

  const [error, seterror] = useState({
    // codigo: "",
    // id: "",
    razon: "",
    nombreco: "",
    direccion: "",
    telefono: "",
    email: "",
    estado: 0,
    pagina: "",
    ruc: "",
    apellido: "",
  });

  const columns = [
    {
      dataField: "cli_id",
      text: "Codigo",
      sort: true,
    },
    {
      dataField: "cli_nombre",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "cli_apellido",
      text: "Apellido",
      sort: true,
    },

    {
      dataField: "cli_telefono",
      text: "Teléfono",
      sort: true,
    },
    {
      dataField: "cli_email",
      text: "Email",
      sort: true,
    },

    {
      dataField: "cli_cedula",
      text: "RUC",
      sort: true,
    },

    {
      dataField: "cli_editar",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const columnsUsuario = [
    {
      dataField: "usr_nombres",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "usr_apellidos",
      text: "Apellido",
      sort: true,
    },
    {
      dataField: "usr_identificacion",
      text: "Identificacion",
      sort: true,
    },
    {
      dataField: "usr_usuario",
      text: "Usuario",
      sort: true,
    },

    {
      dataField: "usr_clave",
      text: "Clave",
      sort: true,
    },

    {
      dataField: "usr_editar",
      text: "Editar",
      formatter: (cell, row, index) => editRowUser(row, index),
      sort: true,
    },
  ];

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          edit(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const editRowUser = (row, index) => {
    return (
      <Button
        onClick={() => {
          editUser(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const edit = (row, index) => {
    setAlmacen((Almacen) => {
      return {
        ...Almacen,
        cli_codigo: row.cli_codigo,
        cli_id: row.cli_id,
        cli_razon_social: row.cli_razon_social,
        cli_nombre_comercial: row.cli_nombre,
        cli_cuidad: row.cli_cuidad,
        cli_direccion: row.cli_direccion,
        cli_telefono: row.cli_telefono,
        cli_email_Almacen: row.cli_email,
        // cli_representante: row.cli_REPRESENTANTE,
        cli_ruc: row.cli_cedula,
        cli_pagina_web: row.cli_pagina_web,
        cli_estado: row.cli_estado,
        cli_apellido: row.cli_apellido,
        // cli_tipo_Almacen: row.cli_TIPO_Almacen,
      };
    });

    setshowModal(true);
  };

  const editUser = (row, index) => {
    setusuario((usuario) => {
      return {
        ...usuario,
        cli_codigo: row.cli_codigo,
        usr_id: row.usr_id,
        usr_usuario: row.usr_usuario,
        usr_nombre: row.usr_nombres,
        usr_apellido: row.usr_apellidos,
        usr_identificacion: row.usr_identificacion,
        usr_alias: row.usr_alias,
        usr_clave: row.usr_clave,
      };
    });

    setshowModalupdateUsuario(true);
  };

  const getAlmacens = async () => {
    setisLoadingTable("visible");

    //poner ide de empresa
    await axios
      .get(server + "api/almacen/getAllAlmacen/1")
      .then(function (response) {
        setisLoadingTable("invisible");
        // console.log(response);
        setdataTable(response.data);
      })
      .catch(function (error) {
        setisLoadingTable("invisible");
      })
      .then(function () {});
  };

  const getUsuarioAlmacen = async (idAlmacen) => {
    await axios
      .get(server + "Almacen/getAllUsuarioAlmacens/" + idAlmacen)
      .then(function (response) {
        // console.log(response);
        setdataTableUser(response.data);
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const createAlmacen = async () => {
    seterror((error) => {
      return {
        ...error,
        // codigo: "",
        // id: "",

        nombreco: "",
        direccion: "",
        telefono: "",
        email: "",
        // representante: "",
        pagina: "",
        ruc: "",
        apellido: "",
      };
    });

    if (
      Almacen.cli_nombre_comercial !== "" &&
      Almacen.cli_telefono !== "" &&
      Almacen.cli_apellido !== "" &&
      Almacen.cli_email_Almacen !== "" &&
      Almacen.cli_ruc !== ""
    ) {
      setisLoadingAlmacenCreada("visible");
      await axios
        .post(server + "api/almacen/createAlmacen", {
          emp_codigo: data.us_empresa.emp_codigo,
          cli_cedula: Almacen.cli_ruc,
          cli_nombre: Almacen.cli_nombre_comercial,
          cli_apellido: Almacen.cli_apellido,
          cli_telefono: Almacen.cli_telefono,
          cli_email: Almacen.cli_email_Almacen,
          cli_celular: "",
          cli_foto_path: "",
          tbl_cliente_tipo_clt_id: 1,
        })

        .then(function (response) {
          setisLoadingAlmacenCreada("invisible");

          if (response.status === 200) {
            showMessage("Almacen creada con éxito", "success");

            setAlmacen((Almacen) => {
              return {
                ...Almacen,

                cli_razon_social: "",
                cli_nombre_comercial: "",
                cli_cuidad: 0,
                cli_direccion: "",
                cli_telefono: "",
                cli_email_Almacen: "",
                cli_ruc: "",
                cli_pagina_web: "",
                cli_apellido: "",
              };
            });

            getAlmacens();

            //getData()
          } else {
            showMessage("Error el crear", "error");
          }
        })
        .catch(function (error) {
          showMessage("Error con el servidor", "error");
        });
    } else {
      if (Almacen.cli_nombre_comercial === "") {
        seterror((error) => {
          return { ...error, nombreco: "Campo requerido" };
        });
      }

      if (Almacen.cli_direccion === "") {
        seterror((error) => {
          return { ...error, direccion: "Campo requerido" };
        });
      }

      if (Almacen.cli_telefono === "") {
        seterror((error) => {
          return { ...error, telefono: "Campo requerido" };
        });
      }

      if (Almacen.cli_email_Almacen === "") {
        seterror((error) => {
          return { ...error, email: "Campo requerido" };
        });
      }

      if (Almacen.cli_ruc === "") {
        seterror((error) => {
          return { ...error, ruc: "Campo requerido" };
        });
      }

      showMessage("Campos incompletos", "error");
    }
  };

  const createusuario = async () => {
    seterrorusuario((errorusuario) => {
      return {
        ...errorusuario,
        usr_usuario: "",
        usr_nombre: "",
        usr_apellido: "",
        usr_identificacion: "",
        usr_alias: "",
        usr_clave: "",
      };
    });

    if (
      usuario.usr_usuario !== "" &&
      usuario.usr_nombre !== "" &&
      usuario.usr_apellido !== "" &&
      usuario.usr_identificacion !== "" &&
      usuario.usr_alias !== "" &&
      usuario.usr_clave !== ""
    ) {
      setisLoadingAlmacenCreada("visible");
      await axios
        .post(server + "Almacen/createusuarioAlmacen", {
          usr_nombres: usuario.usr_nombre,
          usr_apellidos: usuario.usr_apellido,
          usr_identificacion: usuario.usr_identificacion,
          usr_alias: usuario.usr_usuario,
          usr_clave: usuario.usr_clave,
          usr_usuario: usuario.usr_usuario,
          tbl_Almacen_cli_id: Almacen.cli_id,
        })

        .then(function (response) {
          setisLoadingAlmacenCreada("invisible");

          if (response.status === 200) {
            showMessage("Usuario creada con éxito", "success");

            setshowModalCreateUsuario(false);
            setusuario((usuario) => {
              return {
                ...usuario,
                usr_id: 0,
                usr_usuario: "",
                usr_nombre: "",
                usr_apellido: "",
                usr_identificacion: "",
                usr_alias: "",
                usr_clave: "",
              };
            });

            getUsuarioAlmacen(Almacen.cli_id);

            //getData()
          } else {
            showMessage("Error el crear", "error");
          }
        })
        .catch(function (error) {
          showMessage("Error con el servidor", "error");
        });
    } else {
      if (usuario.usr_usuario === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_usuario: "Campo requerido" };
        });
      }

      if (usuario.usr_nombre === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_nombre: "Campo requerido" };
        });
      }

      if (usuario.usr_apellido === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_apellido: "Campo requerido" };
        });
      }
      if (usuario.usr_identificacion === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_identificacion: "Campo requerido" };
        });
      }
      if (usuario.usr_alias === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_alias: "Campo requerido" };
        });
      }
      if (usuario.usr_clave === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_clave: "Campo requerido" };
        });
      }

      showMessage("Campos incompletos", "error");
    }
  };

  const updateusuario = async () => {
    seterrorusuario((errorusuario) => {
      return {
        ...errorusuario,
        usr_usuario: "",
        usr_nombre: "",
        usr_apellido: "",
        usr_identificacion: "",
        usr_alias: "",
        usr_clave: "",
      };
    });

    if (
      usuario.usr_usuario !== "" &&
      usuario.usr_nombre !== "" &&
      usuario.usr_apellido !== "" &&
      usuario.usr_identificacion !== "" &&
      usuario.usr_alias !== "" &&
      usuario.usr_clave !== ""
    ) {
      setisLoadingAlmacenCreada("visible");
      await axios
        .post(server + "Almacen/updateusuarioAlmacen", {
          usr_id: usuario.usr_id,
          usr_nombres: usuario.usr_nombre,
          usr_apellidos: usuario.usr_apellido,
          usr_identificacion: usuario.usr_identificacion,
          usr_alias: usuario.usr_usuario,
          usr_clave: usuario.usr_clave,
          usr_usuario: usuario.usr_usuario,
          tbl_Almacen_cli_id: Almacen.cli_id,
        })

        .then(function (response) {
          setisLoadingAlmacenCreada("invisible");

          if (response.status === 200) {
            showMessage("Usuario creada con éxito", "success");

            setshowModalupdateUsuario(false);
            setusuario((usuario) => {
              return {
                ...usuario,
                usr_id: 0,
                usr_usuario: "",
                usr_nombre: "",
                usr_apellido: "",
                usr_identificacion: "",
                usr_alias: "",
                usr_clave: "",
              };
            });

            getUsuarioAlmacen(Almacen.cli_id);

            //getData()
          } else {
            showMessage("Error el crear", "error");
          }
        })
        .catch(function (error) {
          showMessage("Error con el servidor", "error");
        });
    } else {
      if (usuario.usr_usuario === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_usuario: "Campo requerido" };
        });
      }

      if (usuario.usr_nombre === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_nombre: "Campo requerido" };
        });
      }

      if (usuario.usr_apellido === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_apellido: "Campo requerido" };
        });
      }
      if (usuario.usr_identificacion === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_identificacion: "Campo requerido" };
        });
      }
      if (usuario.usr_alias === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_alias: "Campo requerido" };
        });
      }
      if (usuario.usr_clave === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_clave: "Campo requerido" };
        });
      }

      showMessage("Campos incompletos", "error");
    }
  };

  const updateAlmacen = async () => {
    seterror((error) => {
      return {
        ...error,
        codigo: "",
        id: "",
        razon: "",
        nombreco: "",
        direccion: "",
        telefono: "",
        email: "",
        estado: "",
        pagina: "",
        ruc: "",
        apellido: "",
      };
    });

    if (
      Almacen.cli_nombre_comercial !== "" &&
      Almacen.cli_apellido !== "" &&
      Almacen.cli_telefono !== "" &&
      Almacen.cli_email_Almacen !== "" &&
      Almacen.cli_ruc !== ""
    ) {
      // setisLoadingActualizar("visible");
      await axios
        .post(server + "api/almacen/updateAlmacen", {
          cli_id: Almacen.cli_id,
          cli_cedula: Almacen.cli_ruc,
          cli_nombre: Almacen.cli_nombre_comercial,
          cli_apellido: Almacen.cli_apellido,
          cli_telefono: Almacen.cli_telefono,
          cli_email: Almacen.cli_email_Almacen,
          cli_celular: "",
          cli_foto_path: "",
        })
        .then(function (response) {
          // setisLoadingActualizar("invisible");
          if (response.status === 200) {
            showMessage("Datos actualizados con éxito", "success");

            setAlmacen((Almacen) => {
              return {
                ...Almacen,
                cli_codigo: "",
                cli_id: "",
                cli_razon_social: "",
                cli_nombre_comercial: "",
                cli_cuidad: 0,
                cli_direccion: "",
                cli_telefono: "",
                cli_email_Almacen: "",
                // cli_representante: "",s
                cli_ruc: "",
                cli_pagina_web: "",
                cli_apellido: "",
                // cli_tipo_Almacen: "0",
              };
            });
            setshowModal(false);
            getAlmacens();
            //getData()
          } else {
            showMessage("Error el actualizar datos de Almacen", "error");
            // setisLoadingActualizar("invisible");
          }
        })
        .catch(function (error) {
          showMessage("Problemas al actualizar Almacen", "error");
          // setisLoadingActualizar("invisible");
        });
    } else {
      // if (Almacen.cli_codigo === "") {
      //   seterror((error) => {
      //     return { ...error, codigo: "Campo requerido" };
      //   });
      // }

      // if (Almacen.cli_id === "") {
      //   seterror((error) => {
      //     return { ...error, id: "Campo requerido" };
      //   });
      // }

      if (Almacen.cli_razon_social === "") {
        seterror((error) => {
          return { ...error, razon: "Campo requerido" };
        });
      }

      if (Almacen.cli_nombre_comercial === "") {
        seterror((error) => {
          return { ...error, nombreco: "Campo requerido" };
        });
      }

      if (Almacen.cli_direccion === "") {
        seterror((error) => {
          return { ...error, direccion: "Campo requerido" };
        });
      }

      if (Almacen.cli_telefono === "") {
        seterror((error) => {
          return { ...error, telefono: "Campo requerido" };
        });
      }

      if (Almacen.cli_email_Almacen === "") {
        seterror((error) => {
          return { ...error, email: "Campo requerido email" };
        });
      }

      if (Almacen.cli_estado === 0) {
        seterror((error) => {
          return { ...error, estado: "Campo requerido" };
        });
      }

      if (Almacen.cli_pagina_web === "") {
        seterror((error) => {
          return { ...error, pagina: "Campo requerido" };
        });
      }

      if (Almacen.cli_ruc === "") {
        seterror((error) => {
          return { ...error, ruc: "Campo requerido" };
        });
      }

      if (Almacen.cli_apellido === "") {
        seterror((error) => {
          return { ...error, apellido: "Campo requerido" };
        });
      }

      showMessage("Campos incompletos", "error");
    }
  };

  useEffect(() => {
    const getAlmacensInicial = async () => {
      setisLoadingTable("visible");

      //poner ide de empresa
      await axios
        .get(server + "api/almacen/getAllAlmacen/1")
        .then(function (response) {
          setisLoadingTable("invisible");
          // console.log(response);
          setdataTable(response.data);
        })
        .catch(function (error) {
          setisLoadingTable("invisible");
        })
        .then(function () {});
    };
    getAlmacensInicial();
  }, [server]);

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle heading="Retail" icon="pe-7s-tools" />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <div>
                <Card>
                  <div className="text-center">
                    <div
                      className={isLoadingAlmacenCreada}
                      style={{ marginTop: 10 }}
                    >
                      <Spinner
                        animation="border"
                        role="status"
                        variant="secondary"
                      ></Spinner>
                    </div>
                  </div>

                  <Card.Header as="h5">Creación de Nuevo Retail </Card.Header>
                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Nombre :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese un Nombre "
                            id="cli_nombre_comercial"
                            value={Almacen.cli_nombre_comercial}
                            onChange={(e) => {
                              const val = e.target.value;
                              setAlmacen((Almacen) => {
                                return {
                                  ...Almacen,
                                  cli_nombre_comercial: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.nombreco}</span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Apellido:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={150}
                            placeholder="Ingrese un Apellido"
                            id="cli_apellido"
                            value={Almacen.cli_apellido}
                            onChange={(e) => {
                              const val = e.target.value;
                              setAlmacen((Almacen) => {
                                return { ...Almacen, cli_apellido: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.apellido}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            RUC o Cédula:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={13}
                            placeholder="Ingrese un RUC o Cédula"
                            id="cli_ruc"
                            value={Almacen.cli_ruc}
                            onChange={(e) => {
                              const val = e.target.value;
                              setAlmacen((Almacen) => {
                                return { ...Almacen, cli_ruc: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.ruc}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Email:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese un Email"
                            id="cli_email_Almacen"
                            value={Almacen.cli_email_Almacen}
                            onChange={(e) => {
                              const val = e.target.value;
                              setAlmacen((Almacen) => {
                                return { ...Almacen, cli_email_Almacen: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.email}</span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Teléfono:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Teléfono"
                            id="cli_telefono"
                            value={Almacen.cli_telefono}
                            onChange={(e) => {
                              const val = e.target.value;
                              setAlmacen((Almacen) => {
                                return { ...Almacen, cli_telefono: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.telefono}</span>
                        </Col>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          onClick={() => {
                            createAlmacen();
                          }}
                          variant="primary"
                        >
                          Crear Retail
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </div>
              <div>
                <Card className="mt-2">
                  <Card.Header as="h5">Listado general de "Retail"</Card.Header>
                  <Card.Body>
                    <BootstrapTable
                      keyField="cli_id"
                      data={dataTable}
                      columns={columns}
                      pagination={paginationFactory()}
                    />

                    <div className="text-center">
                      <div className={isLoadingTable} style={{ marginTop: 10 }}>
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                        ></Spinner>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>

              <div>
                <Modal
                  show={showModal}
                  // onHide={!showModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Actualización de datos de: {Almacen.cli_nombre_comercial}
                    </Modal.Title>
                  </Modal.Header>

                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Nombre :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese un Nombre "
                            id="cli_nombre_comercial"
                            value={Almacen.cli_nombre_comercial}
                            onChange={(e) => {
                              const val = e.target.value;
                              setAlmacen((Almacen) => {
                                return {
                                  ...Almacen,
                                  cli_nombre_comercial: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.nombreco}</span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Apellido:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={150}
                            placeholder="Ingrese un Apellido"
                            id="cli_apellido"
                            value={Almacen.cli_apellido}
                            onChange={(e) => {
                              const val = e.target.value;
                              setAlmacen((Almacen) => {
                                return { ...Almacen, cli_apellido: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.apellido}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            RUC o Cédula:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={13}
                            placeholder="Ingrese un RUC o Cédula"
                            id="cli_ruc"
                            value={Almacen.cli_ruc}
                            onChange={(e) => {
                              const val = e.target.value;
                              setAlmacen((Almacen) => {
                                return { ...Almacen, cli_ruc: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.ruc}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Email:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese un Email"
                            id="cli_email_Almacen"
                            value={Almacen.cli_email_Almacen}
                            onChange={(e) => {
                              const val = e.target.value;
                              setAlmacen((Almacen) => {
                                return { ...Almacen, cli_email_Almacen: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.email}</span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Teléfono:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Teléfono"
                            id="cli_telefono"
                            value={Almacen.cli_telefono}
                            onChange={(e) => {
                              const val = e.target.value;
                              setAlmacen((Almacen) => {
                                return { ...Almacen, cli_telefono: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.telefono}</span>
                        </Col>
                      </FormGroup>
                    </Form>
                  </Card.Body>

                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        updateAlmacen();
                      }}
                    >
                      Actualizar
                    </Button>
                    <Button
                      onClick={() => {
                        setshowModal(false);

                        seterror((error) => {
                          return {
                            ...error,
                            codigo: "",
                            id: "",
                            razon: "",
                            nombreco: "",
                            direccion: "",
                            telefono: "",
                            email: "",
                            representante: "",
                            pagina: "",
                            ruc: "",
                          };
                        });
                        setAlmacen((Almacen) => {
                          return {
                            ...Almacen,
                            cli_codigo: "",
                            cli_id: "",
                            cli_razon_social: "",
                            cli_nombre_comercial: "",
                            cli_cuidad: 0,
                            cli_direccion: "",
                            cli_telefono: "",
                            cli_email_Almacen: "",
                            cli_representante: "",
                            cli_ruc: "",
                            cli_pagina_web: "",
                            cli_apellido: "",
                            cli_tipo_Almacen: "0",
                          };
                        });
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>

              <div>
                <Modal
                  show={showModalUsuarios}
                  // onHide={!showModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Usuarios de: {Almacen.cli_nombre_comercial}
                    </Modal.Title>
                  </Modal.Header>

                  <Card.Body>
                    <Button
                      onClick={() => {
                        seterrorusuario((errorusuario) => {
                          return {
                            ...errorusuario,

                            usr_usuario: "",
                            usr_nombre: "",
                            usr_apellido: "",
                            usr_identificacion: "",
                            usr_alias: "",
                            usr_clave: "",
                          };
                        });
                        setusuario((usuario) => {
                          return {
                            ...usuario,
                            usr_id: 0,
                            usr_usuario: "",
                            usr_nombre: "",
                            usr_apellido: "",
                            usr_identificacion: "",
                            usr_alias: "",
                            usr_clave: "",
                          };
                        });
                        setshowModalCreateUsuario(true);
                      }}
                    >
                      Crear Retail
                    </Button>

                    <div style={{ marginTop: 8 }}>
                      <BootstrapTable
                        keyField="usr_id"
                        data={dataTableUser}
                        columns={columnsUsuario}
                        pagination={paginationFactory()}
                      />
                    </div>
                  </Card.Body>

                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        setshowModalUsuarios(false);

                        seterror((error) => {
                          return {
                            ...error,
                            codigo: "",
                            id: "",
                            razon: "",
                            nombreco: "",
                            direccion: "",
                            telefono: "",
                            email: "",
                            representante: "",
                            pagina: "",
                            ruc: "",
                          };
                        });
                        setAlmacen((Almacen) => {
                          return {
                            ...Almacen,
                            cli_codigo: "",
                            cli_id: "",
                            cli_razon_social: "",
                            cli_nombre_comercial: "",
                            cli_cuidad: 0,
                            cli_direccion: "",
                            cli_telefono: "",
                            cli_email_Almacen: "",
                            cli_representante: "",
                            cli_ruc: "",
                            cli_pagina_web: "",
                            cli_apellido: "",
                            cli_tipo_Almacen: "0",
                          };
                        });
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>

              <div>
                <Modal
                  show={showModalCreateUsuario}
                  // onHide={!showModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Crear usuario Nuevo de : {Almacen.cli_nombre_comercial}
                    </Modal.Title>
                  </Modal.Header>

                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Usuario :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Usuario"
                            id="usr_usuario"
                            value={usuario.usr_usuario}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_usuario: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_usuario}
                          </span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Clave :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Clave"
                            id="usr_clave"
                            value={usuario.usr_clave}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_clave: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_clave}
                          </span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Nombre :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Nombre"
                            id="usr_nombre"
                            value={usuario.usr_nombre}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_nombre: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_nombre}
                          </span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Apellido :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Apellido"
                            id="usr_apellido"
                            value={usuario.usr_apellido}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_apellido: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_apellido}
                          </span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Identifiación :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Identifiación"
                            id="usr_identificacion"
                            value={usuario.usr_identificacion}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_identificacion: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_identificacion}
                          </span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Alias :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Alias"
                            id="usr_alias"
                            value={usuario.usr_alias}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_alias: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_alias}
                          </span>
                        </Col>
                      </FormGroup>
                    </Form>

                    <div className="text-center">
                      <div
                        className={isLoadingAlmacenCreada}
                        style={{ marginTop: 10 }}
                      >
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                        ></Spinner>
                      </div>
                    </div>
                  </Card.Body>
                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        createusuario();
                      }}
                    >
                      Crear Usuario
                    </Button>
                    <Button
                      onClick={() => {
                        setshowModalCreateUsuario(false);

                        seterrorusuario((errorusuario) => {
                          return {
                            ...errorusuario,
                            usr_usuario: "",
                            usr_nombre: "",
                            usr_apellido: "",
                            usr_identificacion: "",
                            usr_alias: "",
                            usr_clave: "",
                          };
                        });
                        setusuario((usuario) => {
                          return {
                            ...usuario,
                            usr_id: 0,
                            usr_usuario: "",
                            usr_nombre: "",
                            usr_apellido: "",
                            usr_identificacion: "",
                            usr_alias: "",
                            usr_clave: "",
                          };
                        });
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>

              <div>
                <Modal
                  show={showModalupdateUsuario}
                  // onHide={!showModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Actualizar Usuario
                    </Modal.Title>
                  </Modal.Header>

                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Usuario :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Usuario"
                            id="usr_usuario"
                            value={usuario.usr_usuario}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_usuario: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_usuario}
                          </span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Clave :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Clave"
                            id="usr_clave"
                            value={usuario.usr_clave}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_clave: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_clave}
                          </span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Nombre :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Nombre"
                            id="usr_nombre"
                            value={usuario.usr_nombre}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_nombre: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_nombre}
                          </span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Apellido :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Apellido"
                            id="usr_apellido"
                            value={usuario.usr_apellido}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_apellido: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_apellido}
                          </span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Identifiación :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Identifiación"
                            id="usr_identificacion"
                            value={usuario.usr_identificacion}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_identificacion: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_identificacion}
                          </span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Alias :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Alias"
                            id="usr_alias"
                            value={usuario.usr_alias}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_alias: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_alias}
                          </span>
                        </Col>
                      </FormGroup>
                    </Form>

                    <div className="text-center">
                      <div
                        className={isLoadingAlmacenCreada}
                        style={{ marginTop: 10 }}
                      >
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                        ></Spinner>
                      </div>
                    </div>
                  </Card.Body>
                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        updateusuario();
                      }}
                    >
                      Actualizar Usuario
                    </Button>
                    <Button
                      onClick={() => {
                        setshowModalupdateUsuario(false);

                        seterrorusuario((errorusuario) => {
                          return {
                            ...errorusuario,
                            usr_usuario: "",
                            usr_nombre: "",
                            usr_apellido: "",
                            usr_identificacion: "",
                            usr_alias: "",
                            usr_clave: "",
                          };
                        });
                        setusuario((usuario) => {
                          return {
                            ...usuario,
                            usr_id: 0,
                            usr_usuario: "",
                            usr_nombre: "",
                            usr_apellido: "",
                            usr_identificacion: "",
                            usr_alias: "",
                            usr_clave: "",
                          };
                        });
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
