import React, { Fragment, useState } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  // CSVExport,
} from "react-bootstrap-table2-toolkit";
import filterFactory, {
  selectFilter,
  numberFilter,
  textFilter,
  Comparator,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as moment from "moment";
import Swal from "sweetalert2";
import PageTitle from "../Layout/PageTitle";
import optionsPagination from "../../helper/SetupComponents";
// import Map from "../../componentes/screens/Map";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  // faCalendarCheck,
  // faPenSquare,
  // faHandHoldingUsd,
  faInfoCircle,
  // faMapMarkedAlt,
  faIdCardAlt,
  // faLocationArrow,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  Row,
  Col,
  Container,
  Card,
  // DropdownButton,
  // Dropdown,
  Spinner,
  FormGroup,
  Form,
  Button,
  Modal,
  Image,
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
// registerLocale("es", es);
import "react-datepicker/dist/react-datepicker.css";
// import { ROW_SELECT_MULTIPLE } from "react-bootstrap-table-next";

registerLocale("es", es);
function Content(props) {
  const server = window.$linkserver;
  // const [isLoading, setIsLoading] = useState("invisible");
  // const [nombreBanco, setNombreBanco] = useState("Banco del Austro");
  const { SearchBar } = Search;
  // const { ExportCSVButton } = CSVExport;
  const [modalShow, setModalShow] = React.useState(false);
  // const [cartera, setCartera] = useState();
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const [startDate, setStartDate] = useState(new Date(year, month, day - 1));
  const [lastDate, setLastDate] = useState(new Date());
  // const [showModalMap, setshowModalMap] = useState(false);
  // const [showModalFotos, setshowModalFotos] = useState(false);
  const [showModalGestores, setShowModalGestores] = useState(false);
  const [showModalConfirmCambio, setShowModalConfirmCambio] = useState(false);
  const [showModalDetalleCartera, setShowModalDetalleCartera] = useState(false);
  const [gestores, setGestores] = useState([]);
  const [currentClienteCarte, setCurrentClienteCartera] = useState([]);

  const [currentGestor, setcurrentGestor] = useState([]);
  const [currentCartera, setcurrentCartera] = useState([]);
  const [currentVehiculo, setcurrentVehiculo] = useState([]);
  const [currentFactura, setcurrentFactura] = useState("");

  const [products, setproducts] = useState([]);
  const [search_cedula, setsearch_cedula] = useState("");
  const [showModalCargandoHistorial, setShowModalCargandoHistorial] = useState(
    false
  );
  const [showModalCargando, setShowModalCargando] = useState(false);

  const selectTipoCartera = {
    "FONDO DE GARANTIA": "FONDO DE GARANTIA",
    "CARTERA BANCO": "CARTERA BANCO",
  };

  const selectBanco = {
    SOLIDARIO: (
      <Form.Label
        style={{
          backgroundColor: "#00b5ca",
          textAlign: "center",
          width: "100%",
          borderRadius: 7,
          color: "white",
          fontWeight: "bold",
          fontStyle: "oblique",
        }}
      >
        SOLIDARIO
      </Form.Label>
    ),
    AUSTRO: (
      <Form.Label
        style={{
          backgroundColor: "#171d5c",
          textAlign: "center",
          width: "100%",
          borderRadius: 7,
          color: "white",
          fontWeight: "bold",
          fontStyle: "oblique",
        }}
      >
        AUSTRO
      </Form.Label>
    ),
  };

  const selectBanco2 = {
    SOLIDARIO: "SOLIDARIO",
    AUSTRO: "AUSTRO",
  };

  // const [carteraData, setCarteraData] = useState({
  //   // emp_codigo: "",
  //   // emp_id: "",
  //   emp_razon_social: "",
  //   emp_nombre_comercial: "",
  //   emp_cuidad: 0,
  //   emp_direccion: "",
  //   emp_telefono: "",
  //   emp_email_empresa: "",
  //   emp_estado: 0,
  //   emp_ruc: "",
  //   emp_pagina_web: "",
  //   // emp_tipo_empresa: "",
  // });
  // const selectOptions = {
  //   0: "1-30",
  //   1: "31-60",
  //   2: "61-90",
  //   3: "90+",
  // };
  // const selectOptionsGestor = {
  //   "Gestor no asignado": 'Gestor no asignado'
  // };

  // const customFilter = customFilter({
  //   type: FILTER_TYPES.TEXT,  // default is FILTER_TYPES.TEXT
  //   comparator: Comparator.EQ, // only work if type is FILTER_TYPES.SELECT
  //   caseSensitive: false, // default is true
  // })

  const columnsGestores = [
    {
      dataField: "ges_cedula",
      text: "Cédula",
      headerAlign: "center",
      headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
    },

    {
      dataField: "ges_foto",
      text: "Foto",
      formatter: (cell, row, index) => fotoGestorRow(row, index),
      sort: true,
    },
    {
      dataField: "ges_nombre",
      text: "Nombre",
      headerAlign: "center",
      headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
    },
    {
      dataField: "ges_apellido",
      text: "Apellido",
      headerAlign: "center",
      headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
    },
    {
      dataField: "ges_email",
      text: "Correo",
      headerAlign: "center",
      headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
    },
    {
      dataField: "ges_celular",
      text: "Celular",
      headerAlign: "center",
      headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
    },
    // {
    //   dataField: "ges_direccion",
    //   text: "Dirección",
    //   headerAlign: "center",
    //   headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
    // },
    // {
    //   dataField: "ges_usuario",
    //   text: "Usuario",
    //   headerAlign: "center",
    //   headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
    // },
    {
      dataField: "per_id",
      text: "Gestores",
      formatter: (cell, row, index) => asignatGestor(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
  ];

  const columns = [
    {
      dataField: "carge_dias_retraso_estado",
      text: "Estado",
      headerAlign: "center",
      footer: "",
      headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
      formatter: (cell, row, index) => semaforoEstado(row, index),
    },
    {
      dataField: "carge_nombre_banco",
      text: "Banco",
      headerAlign: "center",
      // footer: "",
      // headerStyle: (colum, colIndex) => {
      //   return {
      //     textAlign: "center",
      //     minWidth: "150px",
      //     backgroundColor: "rgba(255, 255, 255,1)",
      //   };
      // },
      formatter: (cell) => selectBanco[cell],
      filter: selectFilter({
        options: selectBanco2,
        placeholder: "TODOS",
        withoutEmptyOption: false,
        style: {
          width: "100%",
          minWidth: "120px",
          alignItems: "center",
          placeholder: "TODO",
        },
        className: "test-classname",
        datamycustomattr: "datamycustomattr",
      }),
      footer: "",
    },
    {
      dataField: "carge_tipo_cartera",
      text: "Tipo de cartera",
      headerAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "150px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      formatter: (cell) => selectTipoCartera[cell],
      filter: selectFilter({
        options: selectTipoCartera,
        withoutEmptyOption: false,
        placeholder: 'TODOS',
        style: {
          width: "100%",
          minWidth: "150px",
          alignItems: "center",
        },
        className: "test-classname",
        datamycustomattr: "datamycustomattr",
      }),
      footer: "",
    },
    {
      dataField: "carge_cedula",
      text: "Cédula",
      footer: "",
      headerAlign: "center",
      headerStyle: {
        textAlign: "center",
        minWidth: "150px",
        backgroundColor: "rgba(255, 255, 255,1)"
      },
      filter: textFilter({
        placeholder: '# cédula'
      }),
    },
    {
      dataField: "carge_nombre",
      text: "Nombres",
      headerAlign: "center",
      footer: "",
      headerStyle: {
        textAlign: "center",
        minWidth: "190px", backgroundColor: "rgba(255, 255, 255,1)"
      },
      formatter: fechaFormatter,
      filter: textFilter({
        placeholder: 'Nombres/Apellido'
      }),
    },
    // {
    //   dataField: "carge_apellido",
    //   text: "Apellidos",
    //   headerAlign: "center",
    //   footer: "",
    //   headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
    //   formatter: fechaFormatter,
    // },
    {
      dataField: "carge_total",
      text: "Crédito total",
      formatter: priceFormatter,
      headerAlign: "center",
      footer: "",
      headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
    },
    {
      dataField: "carge_saldo",
      text: "Saldo",
      formatter: priceFormatter,
      headerAlign: "center",
      headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
      footerAlign: "center",
      footer: (columnData) => "$"+columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      dataField: "detalle",
      text: "Detalle",
      formatter: (cell, row, index) => verDetalles(row, index),
      headerAlign: "center",
      footer: "",
      headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
    },
    {
      dataField: "carge_dias_retraso",
      text: "Dias de retraso",
      footer: "",
      filter: numberFilter({
        options: [10, 30, 60, 90],
        // style: { display: 'inline-grid' },  // custom the style on number filter
        // className: 'custom-numberfilter-class',  // custom the class on number filter
        placeholder: 'Todo',
        defaultValue: { number: 1, comparator: Comparator.GT },
      }),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "150px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },

      formatter: fechaFormatter,
    },

    {
      dataField: "carge_asignado",
      text: "Gestor asignado",
      footer: "",
      // filter: numberFilter({
      //   defaultValue: { number: 1, comparator: Comparator.GT },
      // }),
      formatter: (cell, row, index) => gestorAsignado(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "170px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      filter: textFilter({
        placeholder: 'Nombre gestor'
      }),
      // formatter: fechaFormatter,
    },
    {
      dataField: "per_id",
      text: "Gestores",
      footer: "",
      formatter: (cell, row, index) => getAllGestores(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
  ];

  const fotoGestorRow = (row, index) => {
    return (
      <Image
        style={{ width: 70, height: 70 }}
        src={row.ges_foto}
        roundedCircle
      />
    );
  };

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  function fechaFormatter(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "115px",
        }}
      >
        {cell}
      </Form.Label>
    );
  }

  function priceFormatter(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "100px",
        }}
      >
        $ {cell}
      </Form.Label>
    );
  }

  const semaforoEstado = (row, index) => {
    if (row.carge_dias_retraso > 10 && row.carge_dias_retraso <= 30) {
      return (
        <Button
          // onClick={() => openModalMap(row)}
          color="green"
          variant="contained"
          textAlign="center"
        >
          <FontAwesomeIcon icon={faClock} color="orange" size="2x" />
        </Button>
      );
    } else if (row.carge_dias_retraso > 30) {
      return (
        <Button
          // onClick={() => openModalMap(row)}
          color="red"
          variant="contained"
        >
          <FontAwesomeIcon icon={faClock} color="#b30000" size="2x" />
        </Button>
      );
    } else if (row.carge_dias_retraso === 0) {
      return (
        <Button
          // onClick={() => openModalMap(row)}
          color="green"
          variant="contained"
        >
          <FontAwesomeIcon icon={faClock} color="green" size="2x" />
        </Button>
      );
    }
  };

  const asignatGestor = (row, index) => {
    return (
      <Button
        onClick={() => openModalAsignarGestorCartera(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon
          icon={faUserPlus}
          size="2x"
          textAlign="center"
          style={{ alignSelf: "center", justifyContent: "center" }}
        />
      </Button>
    );
  };

  const getFechaGestorCartera = async (inicio, fin) => {
    setShowModalCargando(true);

    await axios
      .get(server + "api/cartera/getGestorCartera/" + inicio + "/" + fin)
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        // console.log(response.data);
        setproducts(response.data);
        setShowModalCargando(false);
      })
      .catch(function (error) { setShowModalCargando(false);})
      .then(function () {setShowModalCargando(false); });
  };

  const gestorAsignado = (row, index) => {
    return (
      <div>
        {row.carge_asignado !== "No existe gestor" ? (
          <Form.Label
            className="text-center align-items-center"
            style={{
              width: "100%",
              minWidth: "125px",
              backgroundColor: "rgba(0,255,0,0.3)",
            }}
          >
            {row.carge_asignado}
          </Form.Label>
        ) : (
            <Form.Label
              className="text-center align-items-center"
              style={{
                width: "100%",
                minWidth: "125px",
                backgroundColor: "rgba(255,0,0,0.3)",
              }}
            >
              Gestor no asignado
            </Form.Label>
          )}
      </div>
    );
  };

  const getAllGestores = (row, index) => {
    return (
      <Button
        onClick={() => openModalGestores(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon
          icon={faIdCardAlt}
          size="2x"
          textAlign="center"
          style={{ alignSelf: "center", justifyContent: "center" }}
        />
      </Button>
    );
  };

  // const historialUbicacion = (row, index) => {
  //   return (
  //     <Button
  //       // onClick={() => openModalMap(row)}
  //       color="primary"
  //       variant="contained"
  //       style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
  //     >
  //       <FontAwesomeIcon
  //         icon={faMapMarkedAlt}
  //         size="2x"
  //         textAlign="center"
  //         style={{ alignSelf: "center", justifyContent: "center" }}
  //       />
  //     </Button>
  //   );
  // };

  const openModalGestores = async (row) => {
    // setIsLoading("visible");
    // getOpcionesApp;
    await axios
      .get(server + "gestores/getAllGestores")
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,
        setcurrentCartera(row);
        setGestores(response.data);
        setShowModalGestores(true);
        // setIsLoading("invisible");
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const openModalAsignarGestorCartera = async (row) => {
    setShowModalConfirmCambio(true);
    setcurrentGestor(row);
  };

  const asignarGestorCartera = async () => {
    setShowModalConfirmCambio(false);
    console.log(currentCartera);
    console.log(currentGestor);
    // setIsLoading("visible");
    // // getOpcionesApp;
    await axios
      .post(server + "gestores/asignar_cartera_gestor", {
        tbl_cartera_crt_id: currentCartera.carge_id,
        tbl_gestor_ges_id: currentGestor.ges_id,
        emp_codigo: "ca55951b1242a7b2094f3033cdc6e5",
      })
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,
        // setGestoresModal(response.data);
        // setshowModalMap(true);
        // setIsLoading("invisible");
        if (response.status === 200) {
          showMessage("Gestor asignado con éxito", "success");
          getFechaGestorCartera(
            moment(startDate).format("yyyy-MM-DD"),
            moment(lastDate).format("yyyy-MM-DD")
          );
        } else {
          showMessage("Problemas al asignar gestor", "error");
        }
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const updateDate = (date) => {
    setStartDate(date);
  };

  const updateLastDate = (date) => {
    setLastDate(date);
  };

  const verDetalles = (row, index) => {
    return (
      <Button
        onClick={() => openModalCartera(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon icon={faInfoCircle} size="2x" />
      </Button>
    );
  };

  const openModalCartera = async (item) => {
    // console.log(item);

    // http://192.168.1.13:5001/api/cartera/getdetalleCartera/2

    await axios
      .get(server + "api/cartera/getdetalleCartera/" + item.carge_id)
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        if (response.status === 200) {
          // console.log(response.data.data_cartera);
          // const dataCliente = JSON.stringify(response.data.data_cartera)
          setcurrentCartera(response.data.data_cartera);
          setcurrentVehiculo(response.data.data_vehiculo);

          setCurrentClienteCartera(response.data.data_cliente);
          setcurrentFactura(response.data.numFactura);
          setShowModalDetalleCartera(true);
          // console.log(dataCliente);
        } else {
        }
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getCarterasByCIGestor = async (ci) => {
    console.log(server + "api/cartera/getCarterasByCIgestor/" + ci);
    await axios
      .get(server + "api/cartera/getGestorCICartera/" + ci)
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        console.log(response.data);
        setproducts(response.data);
        setShowModalCargandoHistorial(false);
      })
      .catch(function (error) { })
      .then(function () { });
    setShowModalCargandoHistorial(false);
  };
  // const verCobros = (row, index) => {
  //   return (
  //     <Button
  //       onClick={() => modalShow(true)}
  //       color="primary"
  //       variant="contained"
  //       style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
  //     >
  //       <FontAwesomeIcon icon={faHandHoldingUsd} size="2x" />
  //     </Button>
  //   );
  // };

  // const verVisitas = (row, index) => {
  //   return (
  //     <Button
  //       onClick={() => modalShow(true)}
  //       color="primary"
  //       variant="contained"
  //       style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
  //     >
  //       <FontAwesomeIcon icon={faPenSquare} size="2x" />
  //     </Button>
  //   );
  // };

  // const verPagos = (row, index) => {
  //   return (
  //     <Button
  //       onClick={() => modalShow(true)}
  //       color="primary"
  //       variant="contained"
  //       style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
  //     >
  //       <FontAwesomeIcon icon={faCalendarCheck} size="2x" />
  //     </Button>
  //   );
  // };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
        <Modal
            show={showModalCargando}
            onHide={() => setShowModalCargando(false)}
            centered
          >
            <Modal.Body class="text-center">
              <p></p>
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
              <p class="text-center">
                Cargando datos de cartera, espere por favor...
              </p>
            </Modal.Body>
          </Modal>


          <PageTitle
            heading="Información de Cartera"
            icon="pe-7s-info icon-gradient bg-mean-fruit"
          />
          <Container fluid={true} className="content-body">
            <Row>
              <Col md="12" lg="12">
                <Modal
                  show={showModalDetalleCartera}
                  {...props}
                  onHide={() => setShowModalDetalleCartera(false)}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Información de Cartera
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Container>
                      <Row>
                        <Col md="4">
                          <Image
                            className="img-fluid"
                            alt=""
                            src={
                              "https://apibajaj.curbe.com.ec/Resources/images/" +
                              currentVehiculo.veh_foto +
                              ".png"
                            }
                          ></Image>
                        </Col>
                        <Col md="8">
                          <Row
                            style={{
                              backgroundColor: "rgba(19, 122, 209,0.3)",
                              textAlign: "center",
                              fontSize: 24,
                              fontWeight: "bold",
                              alignSelf: "center",
                            }}
                          >
                            {" "}
                            Información de vehiculo
                          </Row>
                          <Container>
                            <Row>
                              <Col>Marca:</Col>
                              <Col>{currentVehiculo.veh_marca}</Col>
                            </Row>
                            <Row>
                              <Col>Motor:</Col>
                              <Col>{currentVehiculo.veh_motor}</Col>
                            </Row>
                            <Row>
                              <Col>Chasis:</Col>
                              <Col>{currentVehiculo.veh_chasis}</Col>
                            </Row>
                            <Row>
                              <Col>Versión:</Col>
                              <Col>{currentVehiculo.veh_version}</Col>
                            </Row>
                            <Row>
                              <Col>Modelo:</Col>
                              <Col>{currentVehiculo.veh_modelo}</Col>
                            </Row>
                            <Row>
                              <Col>Color:</Col>
                              <Col>{currentVehiculo.veh_color}</Col>
                            </Row>
                            <Row>
                              <Col>Año:</Col>
                              <Col>{currentVehiculo.veh_anio}</Col>
                            </Row>
                          </Container>
                        </Col>
                      </Row>

                      <Row
                        style={{
                          backgroundColor: "rgba(19, 122, 209,0.3)",
                          textAlign: "center",
                          fontSize: 24,
                          fontWeight: "bold",
                          alignSelf: "center",
                        }}
                      >
                        {" "}
                        Información de cliente
                      </Row>
                      <Row>
                        <Col
                          sm={2}
                          style={{
                            backgroundColor: "rgba(19, 122, 209,0.1)",
                            borderWidth: 1,
                          }}
                        >
                          Cliente:
                        </Col>
                        <Col
                          sm={7}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {currentClienteCarte.cli_nombre}{" "}
                          {currentClienteCarte.cli_apellido}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          sm={2}
                          style={{
                            backgroundColor: "rgba(19, 122, 209,0.1)",
                            borderWidth: 1,
                          }}
                        >
                          Cédula:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {currentClienteCarte.cli_cedula}
                        </Col>
                        <Col
                          sm={2}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Correo:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {currentClienteCarte.cli_email}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          sm={2}
                          style={{
                            backgroundColor: "rgba(19, 122, 209,0.1)",
                            borderWidth: 1,
                          }}
                        >
                          Teléfono:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {currentClienteCarte.cli_telefono}
                        </Col>
                        <Col
                          sm={2}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Celular:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {currentClienteCarte.cli_celular}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          sm={2}
                          style={{
                            backgroundColor: "rgba(19, 122, 209,0.1)",
                            borderWidth: 1,
                          }}
                        >
                          {" "}
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {" "}
                        </Col>
                      </Row>

                      <Row
                        style={{
                          backgroundColor: "rgba(19, 122, 209,0.3)",
                          textAlign: "center",
                          fontSize: 24,
                          fontWeight: "bold",
                          alignSelf: "center",
                          marginTop: 8,
                        }}
                      >
                        {" "}
                        Información de cartera: {currentFactura}
                      </Row>

                      <Row>
                        <Col
                          sm={4}
                          style={{
                            backgroundColor: "rgba(19, 122, 209,0.1)",
                            borderWidth: 1,
                          }}
                        >
                          Monto del crédito original:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          ${currentCartera.crt_monto_credito_original}
                        </Col>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Saldo a la fecha:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          ${currentCartera.crt_saldo_credito}
                        </Col>
                      </Row>
                      <Col className="mt-3"></Col>
                      <Row>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Valor a pagar a la fecha:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          $
                          {parseFloat(
                          currentCartera.crt_valor_pago_fecha_actual
                        ).toFixed(2)}
                        </Col>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Estado de crédito:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {currentCartera.crt_estado_credito}
                        </Col>
                      </Row>
                      <Col className="mt-3"></Col>
                      <Row>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Plazo del credito original:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {currentCartera.crt_plazo_credito_original}
                        </Col>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Fecha de inicio de crédito:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {moment(
                            currentCartera.crt_fecha_inicio_credito
                          ).format("DD-MM-yyyy")}
                        </Col>
                      </Row>
                      <Col className="mt-3"></Col>
                      <Row>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Valor de la cuota:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          $
                          {parseFloat(
                          currentCartera.crt_valor_cuota_credito
                        ).toFixed(2)}
                        </Col>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Cuotas restantes:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {currentCartera.crt_cuotas_restantes}
                        </Col>
                      </Row>
                      <Col className="mt-3"></Col>
                      <Row>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Cuotas impagas:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {currentCartera.crt_cuota_impaga_inicio}
                        </Col>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Fecha vencimiento:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {moment(currentCartera.crt_fecha_vencimiento).format(
                            "DD-MM-yyyy"
                          )}
                        </Col>
                      </Row>
                      <Col className="mt-3"></Col>
                      <Row>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Días de atraso:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {currentCartera.crt_dias_retraso}
                        </Col>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Fecha de carga de actualización:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {moment(
                            currentCartera.crt_fecha_carga_actualizacion
                          ).format("DD-MM-yyyy")}
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => setShowModalDetalleCartera(false)}>
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={modalShow}
                  {...props}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Información de Cartera:
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                      Cras mattis consectetur purus sit amet fermentum. Cras
                      justo odio, dapibus ac facilisis in, egestas eget quam.
                      Morbi leo risus, porta ac consectetur ac, vestibulum at
                      eros.
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => setModalShow(false)}>Close</Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={showModalGestores}
                  {...props}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Title id="contained-modal-title-vcenter">
                    Gestores disponibles
                  </Modal.Title>
                  <Modal
                    show={showModalConfirmCambio}
                    {...props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    style={{
                      borderWidth: 1,
                      borderColor: "rgba(128,128,128,0.8)",
                      backgroundColor: "rgba(3,108,199,0.3)",
                    }}
                  >
                    <Modal.Header>
                      <Modal.Title>Atención:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        <b>
                          {currentGestor.ges_nombre}{" "}
                          {currentGestor.ges_apellido}
                        </b>{" "}
                        será asignado a la cartera de{" "}
                        <b>
                          {currentCartera.carge_nombre}{" "}
                          {currentCartera.carge_apellido}
                        </b>
                        , estas seguro de realizar este cambio?
                      </p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        className="btn btn-warning"
                        onClick={() => setShowModalConfirmCambio(false)}
                      >
                        Cerrar
                      </Button>
                      <Button
                        className="btn btn-success"
                        onClick={() => asignarGestorCartera()}
                      >
                        Asignar gestor
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  <ToolkitProvider
                    keyField="ges_cedula"
                    data={gestores}
                    columns={columnsGestores}
                    search
                  >
                    {(props) => (
                      <div>
                        <Card
                          style={{
                            borderWidth: 1,
                            borderColor: "rgba(128,128,128,0.4)",
                          }}
                        >
                          <Card.Footer
                            style={{
                              backgroundColor: "rgba(236,236,236,0.3)",
                            }}
                          >
                            {/* <Container> */}
                            <Row>
                              <Col className="align-middle" md="auto">
                                Buscar gestor:
                              </Col>
                              <Col md="auto">
                                <SearchBar
                                  class="align-middle"
                                  placeholder="# de cédula"
                                  style={{ height: 25, width: 150 }}
                                  {...props.searchProps}
                                />
                              </Col>
                            </Row>
                            {/* </Container> */}
                          </Card.Footer>
                        </Card>
                        <Card
                          className="mt-3"
                          style={{
                            borderWidth: 1,
                            borderColor: "rgba(128,128,128,0.4)",
                          }}
                        >
                          <Card.Body
                            style={{
                              backgroundColor: "rgba(236,236,236,0.6)",
                            }}
                          >
                            <Col className="mt-3"></Col>
                            <BootstrapTable
                              striped
                              hover
                              condensed
                              keyField="ges_cedula"
                              class="align-middle"
                              wrapperClasses="table-responsive"
                              filter={filterFactory()}
                              pagination={paginationFactory(optionsPagination)}
                              {...props.baseProps}
                            />
                          </Card.Body>
                        </Card>
                      </div>
                    )}
                  </ToolkitProvider>
                  <Modal.Footer>
                    <Button onClick={() => setShowModalGestores(false)}>
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>

                <ToolkitProvider
                  keyField="id"
                  data={products}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <div>
                      <Card
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      >
                        <Card.Header
                          as="h3"
                          style={{
                            backgroundColor: "rgba(236,236,236,0.6)",
                            color: "black",
                          }}
                        >
                          Parámetros de busqueda para cartera
                        </Card.Header>
                        <Card.Body
                          style={{
                            backgroundColor: "rgba(236,236,236,0.3)",
                          }}
                        >
                          {/* <Container> */}

                          <FormGroup as={Row}>
                            <Col md="auto">
                              <Form.Label md="auto">Gestor</Form.Label>
                            </Col>
                            <Col md="auto">
                              <Form.Control
                                maxLength={13}
                                placeholder="Número de cédula"
                                id="ges_ci"
                                value={search_cedula} //{gestor.ges_rep_contrasena}
                                onChange={(e) => {
                                  const val = e.target.value;
                                  setsearch_cedula(val);
                                }}
                              />
                            </Col>

                            <Col>
                              <Button
                                onClick={() =>
                                  getCarterasByCIGestor(search_cedula)
                                }
                              >
                                Buscar por cédula de gestor
                              </Button>
                            </Col>
                          </FormGroup>

                          <Row>
                            <Col className="align-middle" md="auto">
                              Fecha inicial
                            </Col>
                            <Col md="auto">
                              <DatePicker
                                locale="es"
                                selected={startDate}
                                dateFormat="dd/MM/yyyy"
                                onChange={updateDate}
                              />
                            </Col>

                            <Col className="align-middle" md="auto">
                              Fecha final
                            </Col>
                            <Col md="auto">
                              <DatePicker
                                locale="es"
                                dateFormat="dd/MM/yyyy"
                                selected={lastDate}
                                onChange={updateLastDate}
                              />
                            </Col>
                            <Col>
                              <Button
                                onClick={() =>
                                  getFechaGestorCartera(
                                    moment(startDate).format("yyyy-MM-DD"),
                                    moment(lastDate).format("yyyy-MM-DD")
                                  )
                                }
                              >
                                Buscar por fecha
                              </Button>
                            </Col>
                          </Row>
                          {/* </Container> */}
                        </Card.Body>
                        {/* {products.length > 0 ? (
                          <Card.Footer
                            style={{
                              backgroundColor: "rgba(236,236,236,0.3)",
                            }}
                          >
                            <Col className="align-middle" md="auto">
                              Buscar cliente:
                            </Col>
                            <Col md="auto">
                              <SearchBar
                                class="align-middle"
                                placeholder="Ingrese cédula"
                                style={{ height: 25, width: 150 }}
                                {...props.searchProps}
                              />
                            </Col>
                            <Col className="align-middle" md="auto">
                              Buscar gestor:
                            </Col>
                            <Col md="auto">
                              <SearchBar
                                class="align-middle"
                                placeholder="Ingrese nombre"
                                style={{ height: 25, width: 150 }}
                                {...props.searchProps}
                              />
                            </Col>
                          </Card.Footer>
                        ) : null} */}
                      </Card>
                      <Card
                        className="mt-3"
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      >
                        <Card.Header
                          as="h3"
                          style={{
                            backgroundColor: "rgba(236,236,236,0.9)",
                            color: "black",
                          }}
                        >
                          Listado general de "Clientes"
                        </Card.Header>
                        <Card.Body
                          style={{
                            backgroundColor: "rgba(236,236,236,0.6)",
                          }}
                        >
                          <Form>
                            {/* <Col> */}
                            <Row>
                              <Form.Check>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  size="lg"
                                  color="green"
                                />
                                <Form.Check.Label
                                  column
                                  style={{ fontSize: 11 }}
                                >
                                  Sin días de retraso
                                </Form.Check.Label>
                              </Form.Check>

                              <Form.Check>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  size="lg"
                                  color="orange"
                                />
                                <Form.Check.Label
                                  column
                                  style={{ fontSize: 11 }}
                                >
                                  Retraso de entre 10 a 30 días
                                </Form.Check.Label>
                              </Form.Check>

                              <Form.Check>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  size="lg"
                                  color="#b30000"
                                />
                                <Form.Check.Label
                                  column
                                  style={{ fontSize: 11 }}
                                >
                                  Retraso de más de 30 días
                                </Form.Check.Label>
                              </Form.Check>
                            </Row>
                          </Form>
                          <Col className="mt-3"></Col>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            wrapperClasses="table-responsive"
                            // keyField="ci"
                            // class="align-middle"
                            filter={filterFactory()}
                            pagination={paginationFactory(optionsPagination)}
                            {...props.baseProps}
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </Container>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );

  // const infoCarteraSeleccionada = () => {
  //   return (
  //     <Modal
  //       show={modalShow}
  //       {...props}
  //       size="lg"
  //       aria-labelledby="contained-modal-title-vcenter"
  //       centered
  //     >
  //       <Modal.Header closeButton>
  //         <Modal.Title id="contained-modal-title-vcenter">
  //           Modal heading
  //         </Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body>
  //         <h4>Centered Modal</h4>
  //         <p>
  //           Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
  //           dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
  //           ac consectetur ac, vestibulum at eros.
  //         </p>
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Button onClick={props.onHide}>Close</Button>
  //       </Modal.Footer>
  //     </Modal>
  //   );
  // };
}

export default Content;
