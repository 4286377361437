import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../../Layout/PageTitle";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Card,
  Spinner,
  Modal,
  Image,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import ModalInfoSelection from "../../screens/ModalInfoSelection";
import * as SecureLS from "secure-ls";
import * as firebase from "firebase";
// import * as storage from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyApKubjeaTo2UWBor9AtnLJvfyxPWFEYVU",
  authDomain: "proyecto-bajaj.firebaseapp.com",
  databaseURL: "https://proyecto-bajaj.firebaseio.com",
  projectId: "proyecto-bajaj",
  storageBucket: "proyecto-bajaj.appspot.com",
  messagingSenderId: "96970549369",
  appId: "1:96970549369:web:0f9f35a63e2dd6fa318ca8",
  measurementId: "G-PZN1X2F6XV",
};

firebase.initializeApp(firebaseConfig);

function Content(props) {
  const server = window.$linkserver;

  const [ges_rep_contrasena, setges_rep_contrasena] = useState("");

  const [gestor, setGestor] = useState({
    ges_id: 0,
    ges_tipo_gestor: "",
    ges_cedula: "",
    ges_nombre: "",
    ges_apellido: "",
    ges_direccion: "",
    ges_telefono: "",
    ges_celular: "",
    ges_email: "",
    emp_codigo: "e4b82bfcf64344adf42221761cb075",
    ges_usuario: "",
    ges_contrasena: "",
    ges_foto: "",
    tbl_perfil_per_id: 0,
    tbl_empresa_emp_id: 1,
    ges_estado: 1,
  });
  const [showModalEditar, setshowModalEditar] = useState(false);

  const [fotoGestor, setFotoGestor] = useState(null);

  const [error, seterror] = useState({
    nombre: "",
    tbl_perfil_per_id: 0,
    cedula: "",
    direccion: "",
    telefonocon: "",
    apellido: "",
    correo: "",
    celular: "",
  });

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  const [gestors, setgestors] = useState();
  const [index, setIndex] = useState();

  const [dataTableUser, setdataTableUser] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [getRowsModal, setRowsModal] = useState([]);
  const [getRowsPerfil, setRowsPerfil] = useState([]);

  const [showModal, setshowModal] = useState(false);

  const [editFoto, seteditFoto] = useState(false);

  const [isLoading, setIsLoading] = useState("invisible");
  const [isLoadingGestor, setIsLoadingGestor] = useState("invisible");

  const [imgGestor] = useState("");

  const [imageG, setimageG] = useState("");
  const [currentGestor, setcurrentGestor] = useState([]);

  const columns = [
    {
      dataField: "ges_id",
      text: "Código",
      sort: true,
    },
    {
      dataField: "ges_foto",
      text: "Foto",
      formatter: (cell, row, index) => fotoGestorRow(row, index),
      sort: true,
    },
    {
      dataField: "ges_cedula",
      text: "Cédula",
      sort: true,
    },
    {
      dataField: "ges_nombre",
      text: "Nombre",
      sort: true,
    },

    {
      dataField: "ges_apellido",
      text: "Apellido",
      sort: true,
    },
    {
      dataField: "ges_direccion",
      text: "Dirección",
      formatter: fechaDireccion,
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(98, 115, 130,0.1)",
        };
      },
    },

    {
      dataField: "ges_celular",
      text: "Telefono",
      sort: true,
    },
    {
      dataField: "ges_email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "ges_usuario",
      text: "Usuario",
      sort: true,
    },
    {
      dataField: "ges_contrasena",
      text: "Contraseña",
      sort: true,
    },
    // {
    //   dataField: "ges_estado",
    //   text: "Estado",
    //   formatter: (cell, row, index) => activeDesactive(row, index),
    //   sort: true,
    // },
    {
      dataField: "ges_editar",
      text: "Editar",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "white",
          width: "100%",
          minWidth: "110px",
        };
      },
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  // const nameRef = firebase.database().ref().child("pruebas"); //.child('name')
  const storage = firebase.storage();

  // nameRef.on('value', snapshot => {
  //   console.log(snapshot.val())
  // })

  const activeDesactive = (row, index) => {
    return (
      <input
        type="checkbox"
        name="something"
        value="0"
        checked={setEstadoGestor(row.ges_estado)}
        onChange={() => changeEstadoGestor(row, index)}
      />
    );
  };

  const setEstadoGestor = (estado) => {
    console.log('estado ===== ' + estado)
    if (estado === 1) {
      console.log('estado 1')
      return true;
    } else if (estado === 0) {
      console.log('estado 0')
      return false;
    }
  };

  const changeEstadoGestor = (key, index) => {
    console.log('key' + key.ges_estado);
    if (key.ges_estado === 1) {
      console.log('111111')
      dataTableUser[index].ges_estado = 0;
      updateEstadoGestor(dataTableUser[index], index);
    } else if (key.opc_estado === 0) {
      console.log('222222')
      dataTableUser[index].ges_estado = 1;
      updateEstadoGestor(dataTableUser[index], index);
    }
  };

  const updateEstadoGestor = (itemOpcion, index) => {
    // console.log(itemOpcion); 
    // console.log(server + "gestores/updateGestor");
    axios
      .post(server + "gestores/updateGestor", {
        ges_id: itemOpcion.ges_id,
        ges_tipo_gestor: itemOpcion.ges_tipo_gestor,
        ges_cedula: itemOpcion.ges_cedula,
        ges_nombre: itemOpcion.ges_nombre,
        ges_apellido: itemOpcion.ges_apellido,
        ges_direccion: itemOpcion.ges_direccion,
        ges_telefono: itemOpcion.ges_telefono,
        ges_celular: itemOpcion.ges_celular,
        ges_email: itemOpcion.ges_email,
        ges_usuario: itemOpcion.ges_usuario,
        ges_contrasena: itemOpcion.ges_contrasena,
        tbl_perfil_per_id: itemOpcion.tbl_perfil_per_id,
        ges_estado: itemOpcion.ges_estado,
        ges_foto: itemOpcion.ges_foto,
      })
      .then(function (response) {
        if (response.status === 200) {
          setshowModal(false)
          showMessage("Gestor actualizado con éxito", "success");
          setshowModal(false)
          let newArr = [...dataTableUser];
          newArr[index] = itemOpcion;
          setdataTableUser(newArr);
          getData()
        } else {
          console.log(response.status)
          showMessage("Problemas al actualizar", "error");
        }
      })
      .catch(function (error) { });
  };


  const updateGestor = async (newFoto) => {
    // console.log(gestor);

    if (
      gestor.tbl_perfil_per_id !== 0 &&
      gestor.ges_cedula !== "" &&
      gestor.ges_nombre !== "" &&
      gestor.ges_apellido !== "" &&
      gestor.ges_direccion !== "" &&
      gestor.ges_telefono !== "" &&
      gestor.ges_celular !== "" &&
      gestor.ges_email !== "" &&
      gestor.ges_usuario !== "" &&
      gestor.ges_contrasena !== "" &&
      gestor.ges_rep_contrasena !== ""
    ) {

      if (gestor.ges_contrasena === ges_rep_contrasena) {
        // console.log(server + "gestores/updateGestor");
        // uploadFotoFBUpdate()
        await axios
          .post(server + "gestores/updateGestor", {
            ges_id: gestor.ges_id,
            ges_tipo_gestor: gestor.ges_tipo_gestor,
            ges_cedula: gestor.ges_cedula,
            ges_nombre: gestor.ges_nombre,
            ges_apellido: gestor.ges_apellido,
            ges_direccion: gestor.ges_direccion,
            ges_telefono: gestor.ges_telefono,
            ges_celular: gestor.ges_celular,
            ges_email: gestor.ges_email,
            ges_usuario: gestor.ges_usuario,
            ges_contrasena: gestor.ges_contrasena,
            tbl_perfil_per_id: gestor.tbl_perfil_per_id,
            ges_estado: gestor.ges_estado,
            ges_foto: newFoto
          })
          .then(function (response) {
            if (response.status === 200) {
              setshowModal(false)
              showMessage("Gestor actualizado con éxito", "success");
              let newArr = [...dataTableUser];
              newArr[index] = gestor;
              setdataTableUser(newArr);
              getData()
              setimageG('')
              setFotoGestor('')
              setges_rep_contrasena('')
            } else {
              console.log(response.status)
              showMessage("Problemas al actualizar", "error");
            }
          })
          .catch(function (error) { });

      } else {
        showMessage("Contraseñas no coinciden", "error");
      }
    }

    else {

      if (gestor.ges_nombre === "") {
        seterror((error) => {
          return { ...error, nombre: "Campo requerido" };
        });
      }

      if (gestor.ges_apellido === "") {
        seterror((error) => {
          return { ...error, apellido: "Campo requerido" };
        });
      }

      if (gestor.ges_usuario === "") {
        seterror((error) => {
          return { ...error, gestor: "Campo requerido" };
        });
      }

      if (gestor.ges_cedula === "") {
        seterror((error) => {
          return { ...error, cedula: "Campo requerido" };
        });
      }

      if (gestor.ges_email === "") {
        seterror((error) => {
          return { ...error, correo: "Campo requerido" };
        });
      }

      if (gestor.ges_direccion === "") {
        seterror((error) => {
          return { ...error, direccion: "Campo requerido" };
        });
      }

      if (gestor.ges_telefono === "") {
        seterror((error) => {
          return { ...error, telefonocon: "Campo requerido" };
        });
      }

      if (gestor.ges_celular === "") {
        seterror((error) => {
          return { ...error, celular: "Campo requerido" };
        });
      }

      if (gestor.tbl_perfil_per_id === 0) {
        seterror((error) => {
          return { ...error, celular: "Campo requerido" };
        });
      }
      showMessage("Campos incompletos", "error");
    }
  }




  // const updateTableRol = async (row) => {
  //   await axios
  //     .get(server + "api/gestor/getUserRol/" + row.US_CODIGO)

  //     .then(function (response) {
  //       updateRowsRol(response.data.data, row);
  //     })
  //     .catch(function (error) {})
  //     .then(function () {});
  // };

  const editRow = (row, index) => {
    return (
      <>
        <Button
          onClick={() => {
            editUsuario(row, index);
          }}
          color="primary"
          variant="contained"
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button
          onClick={() => {
            openModalEliminar(row)
            // editUsuario(row, index);
          }}
          color="primary"
          variant="contained"
        >
          <FontAwesomeIcon icon={faTrashAlt} color='#9C050C' />
        </Button>
      </>
    );
  };

  const openModalEliminar = async (row) => {
    setshowModalEditar(true);
    setcurrentGestor(row);
  };
  const fotoGestorRow = (row, index) => {
    return (
      <Image
        style={{ width: 70, height: 70 }}
        src={row.ges_foto}
        roundedCircle
      />
    );
  };

  function fechaDireccion(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "115px",
        }}
      >
        {cell}
      </Form.Label>
    );
  }

  const editUsuario = (row, index) => {
    seterror((error) => {
      return {
        ...error,
        nombre: "",
        gestor: "",
        cedula: "",
        direccion: "",
        telefonocon: "",
        apellido: "",
        celular: "",
        correo: "",
      };
    });

    setGestor((gestor) => {
      return {
        ...gestor,
        ges_id: row.ges_id,
        ges_cedula: row.ges_cedula,
        ges_nombre: row.ges_nombre,
        ges_apellido: row.ges_apellido,
        ges_direccion: row.ges_direccion,
        ges_telefono: row.ges_telefono,
        ges_celular: row.ges_celular,
        ges_email: row.ges_email,
        ges_usuario: row.ges_usuario,
        ges_contrasena: row.ges_contrasena,
        ges_foto: row.ges_foto,
        tbl_perfil_per_id: row.tbl_perfil_per_id,
      };
    });
    setimageG(row.ges_foto);
    setgestors(row);
    setIndex(index);
    setshowModal(true);
    console.log("imageG..." + row.ges_foto);
    console.log("fotoGestor..." + fotoGestor);
  };

  const setDatamodulos = () => {
    gestors.US_NOMBRE = gestor.ges_nombre;
    gestors.US_CEDULA = gestor.ges_cedula;
    gestors.US_APELLIDO = gestor.ges_apellido;
    gestors.US_DIRECCION = gestor.ges_direccion;
    gestors.US_TELEFONO = gestor.ges_telefono;
    gestors.US_CELULAR = gestor.ges_celular;
    gestors.US_EMAIL = gestor.ges_email;
    gestors.US_USUARIO = gestor.ges_gestor;
    gestors.US_CONTRASENIA = gestor.ges_contrasena;
    gestors.US_CARGO = gestor.ges_cargo;
    gestors.US_ESTADO = gestor.ges_estado;

    seterror((error) => {
      return {
        ...error,
        nombre: "",
        gestor: "",
        cedula: "",
        direccion: "",
        telefonocon: "",
        apellido: "",
        celular: "",
        correo: "",
      };
    });

    setGestor((gestor) => {
      return {
        ...gestor,
        ges_cedula: "",
        ges_nombre: "",
        ges_apellido: "",
        ges_direccion: "",
        ges_telefono: "",
        ges_celular: "",
        ges_email: "",
        ges_usuario: "",
        ges_contrasena: "",
        tbl_perfil_per_id: 0,
      };
    });

    setshowModal(false);
    update(gestors, index);
  };

  const update = (dataUpdate, index) => {
    seterror((error) => {
      return {
        ...error,
        nombre: "",
        gestor: "",
        cedula: "",
        direccion: "",
        telefonocon: "",
        apellido: "",
        celular: "",
        correo: "",
      };
    });

    if (
      gestor.ges_cedula !== "" &&
      gestor.ges_nombre !== "" &&
      gestor.ges_apellido !== "" &&
      gestor.ges_direccion !== "" &&
      gestor.ges_telefono !== "" &&
      gestor.ges_celular !== "" &&
      gestor.ges_email !== "" &&
      gestor.ges_usuario !== "" &&
      gestor.ges_contrasena !== ""
    ) {
      const dataForm = new FormData();
      dataForm.append("ges_id", dataUpdate.US_CODIGO);
      dataForm.append("ges_cedula", dataUpdate.US_CEDULA);
      dataForm.append("ges_nombre", dataUpdate.US_NOMBRE);
      dataForm.append("ges_apellido", dataUpdate.US_APELLIDO);
      dataForm.append("ges_direccion", dataUpdate.US_DIRECCION);
      dataForm.append("ges_telefono", dataUpdate.US_TELEFONO);
      dataForm.append("ges_celular", dataUpdate.US_CELULAR);
      dataForm.append("ges_email", dataUpdate.US_EMAIL);
      dataForm.append("ges_gestor", dataUpdate.US_USUARIO);
      dataForm.append("ges_cargo", dataUpdate.US_CARGO);
      dataForm.append("ges_estado", 1);
      dataForm.append("ges_contrasena", dataUpdate.US_CONTRASENIA);
      dataForm.append("gestor", data.US_CODIGO);
      dataForm.append("_method", "PUT");

      axios
        .post("http://192.168.1.13:8000/api/gestor/updateUsuario", dataForm)

        .then(function (response) {
          if (response.data.success === true) {
            showMessage(response.data.message, "success");
            seterror((error) => {
              return {
                ...error,
                nombre: "",
                gestor: "",
                cedula: "",
                direccion: "",
                telefonocon: "",
                apellido: "",
                celular: "",
                correo: "",
              };
            });

            getData();
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      if (gestor.ges_nombre === "") {
        seterror((error) => {
          return { ...error, nombre: "Campo requerido" };
        });
      }

      if (gestor.ges_apellido === "") {
        seterror((error) => {
          return { ...error, apellido: "Campo requerido" };
        });
      }

      if (gestor.ges_usuario === "") {
        seterror((error) => {
          return { ...error, gestor: "Campo requerido" };
        });
      }

      if (gestor.ges_cedula === "") {
        seterror((error) => {
          return { ...error, cedula: "Campo requerido" };
        });
      }

      if (gestor.ges_email === "") {
        seterror((error) => {
          return { ...error, correo: "Campo requerido" };
        });
      }

      if (gestor.ges_direccion === "") {
        seterror((error) => {
          return { ...error, direccion: "Campo requerido" };
        });
      }

      if (gestor.ges_telefono === "") {
        seterror((error) => {
          return { ...error, telefonocon: "Campo requerido" };
        });
      }

      if (gestor.ges_celular === "") {
        seterror((error) => {
          return { ...error, celular: "Campo requerido" };
        });
      }
      showMessage("Campos incompletos", "error");
    }
  };

  // const updateTablePermisos = async (row) => {
  //   await axios
  //     .get(
  //       "http://192.168.1.13:8000/api/gestor/getListUserPermisos/" +
  //         row.US_CODIGO
  //     )

  //     .then(function (response) {
  //       updateRowsPermisos(response.data.data, row);
  //     })
  //     .catch(function (error) {})
  //     .then(function () {});
  // };

  // const updateTableEmpresas = async (row) => {
  //   await axios
  //     .get(
  //       "http://192.168.1.13:8000/api/gestor/getListUserEmpresas/" +
  //         row.US_CODIGO
  //     )

  //     .then(function (response) {
  //       if (response.data.success) {
  //         updateRowsEmpresas(response.data.data, row);
  //       } else {
  //         showMessage(response.data.message, "error");
  //       }
  //     })
  //     .catch(function (error) {})
  //     .then(function () {});
  // };

  // const addRol = (row, index) => {
  //   return (
  //     <Button
  //       onClick={() => updateTableRol(row)}
  //       color="primary"
  //       variant="contained"
  //     >
  //       <FontAwesomeIcon icon={faPlusSquare} />
  //     </Button>
  //   );
  // };

  // const addPermisos = (row, index) => {
  //   return (
  //     <Button
  //       onClick={() => updateTablePermisos(row)}
  //       color="primary"
  //       variant="contained"
  //     >
  //       <FontAwesomeIcon icon={faPlusSquare} />
  //     </Button>
  //   );
  // };

  // const addEmpresas = (row, index) => {
  //   return (
  //     <Button
  //       onClick={() => updateTableEmpresas(row)}
  //       color="primary"
  //       variant="contained"
  //     >
  //       <FontAwesomeIcon icon={faPlusSquare} />
  //     </Button>
  //   );
  // };

  const selectRow = {
    mode: "radio",
    clickToSelect: true,

    onSelect: (row, isSelect) => { },
  };

  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  useEffect(() => {
    const getDataIncial = async () => {
      setIsLoading("visible");
      await axios
        .get(server + "gestores/getAllGestores")
        .then(function (response) {
          setdataTableUser(response.data);
          setIsLoading("invisible");
        })
        .catch(function (error) { })
        .then(function () { });
    };

    getDataIncial();

    const getPerfiles = async () => {
      await axios
        .get(server + "gestores/getPerfilesApp")
        .then(function (response) {
          setDataPerfil(response.data);
        })
        .catch(function (error) { })
        .then(function () { });
    };

    getPerfiles();
  }, [server]);



  const updateRowsRol = (data, rowUser) => {
    const rows = data.map((row, index) => (
      <Form.Check
        type="radio"
        key={row.OP_CODIGO}
        label={row.OP_NOMBRE}
        name="formHorizontalRadios"
        id="formHorizontalRadios2"
        checked={setEstado(row.OP_ESTADO)}
        onChange={() => changeEstadoRol(row, index, rowUser, data)}
      />
    ));

    setRowsModal(rows);
    setIsModalVisible(true);
  };

  const updateRowsPermisos = (data, rowUser) => {
    const rows = data.map((row, index) => (
      <Form.Check
        type="radio"
        key={row.OP_CODIGO}
        label={row.OP_NOMBRE}
        name="formHorizontalRadios"
        id="formHorizontalRadios2"
        checked={setEstado(row.OP_ESTADO)}
        onChange={() => changeEstadoPermisos(row, index, rowUser, data)}
      />
    ));

    setRowsModal(rows);
    setIsModalVisible(true);
  };

  const updateRowsEmpresas = (data, rowUser) => {
    const rows = data.map((row, index) => (
      <Form.Check
        key={row.OP_CODIGO}
        label={row.OP_NOMBRE}
        checked={setEstado(row.OP_ESTADO)}
        onChange={() => changeEstadoEmpresas(row, index, rowUser, data)}
      />
    ));

    setRowsModal(rows);
    setIsModalVisible(true);
  };

  const changeEstadoRol = async (row, index2, rowUser, data2) => {
    const dataForm = new FormData();
    dataForm.append("usr_gestor", rowUser.US_CODIGO);
    dataForm.append("usr_rol", row.OP_CODIGO);
    dataForm.append("usr_estado", 1);
    dataForm.append("gestor", data.US_CODIGO);

    axios
      .post("http://192.168.1.13:8000/api/gestor/activeRolUser", dataForm)

      .then(function (response) {
        if (response.data.success) {
          showMessage(response.data.message, "success");

          data2.forEach((row2, index) => {
            if (data2[index].OP_CODIGO === row.OP_CODIGO) {
              data2[index].OP_ESTADO = 1;
            } else {
              data2[index].OP_ESTADO = 0;
            }
          });
          updateRowsRol(data2, rowUser);
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {
        showMessage("Error de Sercidor Intentalo de Nuevo", "error");
      });
  };

  const changeEstadoPermisos = async (row, index2, rowUser, data1) => {
    if (row.OP_ESTADO === 1) {
      row.OP_ESTADO = 0;
    } else if (row.OP_ESTADO === 0) {
      row.OP_ESTADO = 1;
    }

    const dataForm = new FormData();
    dataForm.append("uspe_gestor", rowUser.US_CODIGO);
    dataForm.append("uspe_permiso", row.OP_CODIGO);
    dataForm.append("uspe_estado", row.OP_ESTADO);
    dataForm.append("gestor", data.US_CODIGO);

    axios
      .post("http://192.168.1.13:8000/api/gestor/activeUserPermisos", dataForm)

      .then(function (response) {
        if (response.data.success) {
          showMessage(response.data.message, "success");

          data1.forEach((row2, index) => {
            if (data1[index].OP_CODIGO === row.OP_CODIGO) {
              data1[index].OP_ESTADO = 1;
            } else {
              data1[index].OP_ESTADO = 0;
            }
          });

          updateRowsPermisos(data1, rowUser);
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {
        showMessage("Campos incompletos", "error");
      });
  };

  const changeEstadoEmpresas = async (row, index2, rowUser, data1) => {
    if (row.OP_ESTADO === 1) {
      row.OP_ESTADO = 0;
    } else if (row.OP_ESTADO === 0) {
      row.OP_ESTADO = 1;
    }

    const dataForm = new FormData();
    dataForm.append("use_gestor", rowUser.US_CODIGO);
    dataForm.append("use_empresa", row.OP_CODIGO);
    dataForm.append("use_estado", row.OP_ESTADO);
    dataForm.append("gestor", data.US_CODIGO);

    axios
      .post("http://192.168.1.13:8000/api/gestor/activeUserEmpresa", dataForm)

      .then(function (response) {
        if (response.data.success) {
          showMessage(response.data.message, "success");

          data1[index2].OP_ESTADO = row.OP_ESTADO;

          updateRowsEmpresas(data1, rowUser);
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {
        showMessage("Campos incompletos", "error");
      });
  };

  const setDataPerfil = (data) => {
    const rows = data.map((row, index) => (
      <option key={row.per_id} value={row.per_id}>
        {row.per_nombre}
      </option>
    ));
    setRowsPerfil(rows);
  };

  const getData = async () => {
    setIsLoading("visible");
    await axios
      .get(server + "gestores/getAllGestores")
      .then(function (response) {
        setdataTableUser(response.data);
        setIsLoading("invisible");
      })
      .catch(function (error) { })
      .then(function () { });
  };

  

  const eliminarGestor = async (idGestor) => {
    setIsLoading("visible")
    setshowModalEditar(false)
    await axios
      .get(server + "gestores/deleteGestor/"+idGestor)
      .then(function (response) {
        // setdataTableUser(response.data);
        setIsLoading("invisible");
        getData()
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const createGestor = async (url) => {
    seterror((error) => {
      return {
        ...error,
        nombre: "",
        gestor: "",
        cedula: "",
        direccion: "",
        telefonocon: "",
        apellido: "",
        celular: "",
        correo: "",
      };
    });

    // console.log(gestor)
    // console.log(url)
    // console.log(dataForm)

    if (gestor.tbl_perfil_per_id === 0) {
      showMessage("Selecciona un perfil", "error");
    } else {
      if (gestor.ges_contrasena === ges_rep_contrasena) {
        //gestor.ges_rep_contrasena) {
        if (
          gestor.ges_cedula !== "" &&
          gestor.ges_nombre !== "" &&
          gestor.ges_apellido !== "" &&
          gestor.ges_direccion !== "" &&
          gestor.ges_telefono !== "" &&
          gestor.ges_celular !== "" &&
          gestor.ges_email !== "" &&
          gestor.ges_usuario !== "" &&
          gestor.ges_contrasena !== ""
        ) {
          // console.log(gestor);
          const dataForm = new FormData();
          dataForm.append("file", imgGestor);
          // dataForm.append("idmg", gestor.ges_cedula);
          dataForm.append("gestor", gestor);

          await axios
            .post(
              server + "gestores/createGestor",

              {
                ges_nombre: gestor.ges_nombre,
                ges_apellido: gestor.ges_apellido,
                ges_usuario: gestor.ges_usuario,
                ges_contrasena: gestor.ges_contrasena,
                ges_cedula: gestor.ges_cedula,
                ges_tipo_gestor: gestor.ges_tipo_gestor,
                ges_direccion: gestor.ges_direccion,
                ges_telefono: gestor.ges_telefono,
                ges_celular: gestor.ges_celular,
                ges_email: gestor.ges_email,
                ges_foto: url,
                ges_estado: 1,
                emp_codigo: data.us_empresa.emp_codigo,
                tbl_perfil_per_id: gestor.tbl_perfil_per_id,
                tbl_empresa_emp_id: gestor.tbl_empresa_emp_id,
              }
            )
            .then(function (response) {
              if (response.status === 200) {
                showMessage("Gestor creado con éxito", "success");
                setGestor((gestor) => {
                  return {
                    ...gestor,
                    ges_cedula: "",
                    ges_nombre: "",
                    ges_apellido: "",
                    ges_direccion: "",
                    ges_telefono: "",
                    ges_celular: "",
                    ges_email: "",
                    ges_gestor: "",
                    ges_contrasena: "",
                    tbl_perfil_per_id: "",
                    ges_tipo_gestor: "",
                    // ges_rep_contrasena: "",
                  };
                });
                setges_rep_contrasena("");
                getData();
              } else {
                showMessage("Problemas al crear gestor", "error");
              }
            })
            .catch(function (error) {
              showMessage("Problemas al crear gestor", "error");
            });
        } else {
          if (gestor.ges_nombre === "") {
            seterror((error) => {
              return { ...error, nombre: "Campo requerido" };
            });
          }

          if (gestor.ges_apellido === "") {
            seterror((error) => {
              return { ...error, apellido: "Campo requerido" };
            });
          }

          if (gestor.tbl_perfil_per_id === "") {
            seterror((error) => {
              return { ...error, tbl_perfil_per_id: "Campo requerido" };
            });
          }

          if (gestor.ges_cedula === "") {
            seterror((error) => {
              return { ...error, cedula: "Campo requerido" };
            });
          }

          if (gestor.ges_email === "") {
            seterror((error) => {
              return { ...error, correo: "Campo requerido" };
            });
          }
          if (gestor.ges_foto === "") {
            showMessage("Debes agregar una foto", "error");
          }

          if (gestor.ges_direccion === "") {
            seterror((error) => {
              return { ...error, direccion: "Campo requerido" };
            });
          }

          if (gestor.ges_telefono === "") {
            seterror((error) => {
              return { ...error, telefonocon: "Campo requerido" };
            });
          }

          if (gestor.ges_celular === "") {
            seterror((error) => {
              return { ...error, celular: "Campo requerido" };
            });
          }
          showMessage("Campos incompletos de gestor", "error");
        }
      } else {
        showMessage("Contraseña no coincide", "error");
      }
    }
  };

  // const updateEstadoModal = (itemModal, indexModal) => {
  //   var newEstado = 0;
  //   if (itemModal.op_estado === 1) {
  //     newEstado = 0;
  //   } else if (itemModal.op_estado === 0) {
  //     newEstado = 1;
  //   }

  //   const dataForm = new FormData();
  //   dataForm.append("mop_menu", menu.MEN_CODIGO);
  //   dataForm.append("mop_opcion", itemModal.OP_CODIGO);
  //   dataForm.append("mop_estado", newEstado);
  //   dataForm.append("gestor", data.US_CODIGO);

  //   axios
  //     .post("http://192.168.1.13:5001/menu/agregarMenuOpcion", {
  //       tbl_menu_men_id: Number(menu.men_id),
  //       tbl_opciones_web_op_id: Number(itemModal.op_id),
  //       mop_estado: newEstado,
  //     })

  //     .then(function (response) {
  //       if (response.status === 200) {
  //         showMessage("Opción agregrada con exito", "success");
  //         let newArr = [...getRowsModal];
  //         newArr[indexModal].op_estado = newEstado;
  //         setRowsModal(newArr);
  //       } else {
  //         showMessage("Error en el servidor intentalo de nuevo", "error");
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // const onHandleTelephoneChange = (e) => {
  //   // let telephone = e.target.value;
  //   // if (!Number(telephone)) {
  //   //   return;
  //   // }
  //   // const re = "/^[0-9\b]+$/";
  //   // if (e.target.value === "" || re.test(e.target.value)) {
  //   //   setGestor((gestor) => {
  //   //     return { ...gestor, ges_celular: e.target.value };
  //   //   });
  //   // }
  //   // this.setState({
  //   //     [e.target.name]: telephone
  //   // });
  // };

  const selectFile = async (event) => {
    if (event.target.files[0]) {
      // uploadFotoFB(event.target.files[0])
      // setGestor((gestor) => {
      //   return { ...gestor, ges_foto: event.target.files[0] };
      // });
      setimageG(URL.createObjectURL(event.target.files[0]));
      setFotoGestor(event.target.files[0]);
    }
    // setDocumentPath(URL.createObjectURL(event.target.files[0]));
    // setDocument(event.target.files[0]);
  };

  const selectFileGestor = async (event) => {
    if (event.target.files[0]) {
      // uploadFotoFB(event.target.files[0])
      // setGestor((gestor) => {
      //   return { ...gestor, ges_foto: URL.createObjectURL(event.target.files[0]) };
      // });
      seteditFoto(true)
      setimageG(URL.createObjectURL(event.target.files[0]));
      // selectFileGestor(URL.createObjectURL(event.target.files[0]));
      setFotoGestor(event.target.files[0]);
    }
    // setDocumentPath(URL.createObjectURL(event.target.files[0]));
    // setDocument(event.target.files[0]);
  };

  const uploadFotoFBUpdate = async () => {
    // console.log("imageG..." + imageG);
    // console.log("fotoGestor..." + fotoGestor);

    if (editFoto === true) {
      // updateGestor(url)
      if (imageG !== "") {
        // console.log("cargando foto...");
        setIsLoadingGestor("visible");
        const uploadTask = storage
          .ref(`Bajaj/gestores/${gestor.ges_cedula}`)
          .put(fotoGestor);

        await uploadTask.on(
          "state_changed",
          (snapshot) => { },
          (error) => {
            console.log("erorrL L" + error);
          },
          () => {
            storage
              .ref("Bajaj/gestores/")
              .child(gestor.ges_cedula)
              .getDownloadURL()
              .then((url) => {
                console.log(url)
                // setImgGestor(url)
                // setGestor((gestor) => {
                //   return { ...gestor, ges_foto: url };
                // });
                // setGestor((gestor) => {
                //   return { ...gestor, ges_foto: url };
                // });
                seteditFoto(true)
                console.log("foto cargada...");
                setIsLoadingGestor("invisible");
                updateGestor(url)
                // createGestor(url);
              });
          }
        );
      } else {
        showMessage("Debes agregar el todos los campos", "error");
      }
    } else {
      updateGestor(imageG)
    }
  };



  const uploadFotoFB = async () => {
    if (imageG !== "") {
      // console.log("cargando foto...");
      setIsLoadingGestor("visible");
      const uploadTask = storage
        .ref(`Bajaj/gestores/${gestor.ges_cedula}`)
        .put(fotoGestor);

      await uploadTask.on(
        "state_changed",
        (snapshot) => { },
        (error) => {
          console.log("erorrL L" + error);
        },
        () => {
          storage
            .ref("Bajaj/gestores/")
            .child(gestor.ges_cedula)
            .getDownloadURL()
            .then((url) => {
              // console.log(url)
              // setImgGestor(url)
              // setGestor((gestor) => {
              //   return { ...gestor, ges_foto: url };
              // });
              console.log("foto cargada...");
              setIsLoadingGestor("invisible");
              createGestor(url);
            });
        }
      );
    } else {
      showMessage("Debes agregar el todos los campos", "error");
    }
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Administracion/ Configuracion"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <div>
                <div>
                  <Modal
                    show={showModal}
                    onHide={!showModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header>
                      <Modal.Title id="contained-modal-title-vcenter">
                        Editar Usuario
                      </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <Form.Label className="title-create" md="auto">
                        Foto de perfil:{" "}
                      </Form.Label>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Col md="auto">
                          <img
                            style={{ width: 200, height: 200, borderColor: 'lightgray' }}
                            src={imageG}
                            alt=""
                          />
                          <FormGroup as={Col} md="auto">
                            <label className="btn btn-default" md="auto">
                              <input
                                md="auto"
                                type="file"
                                onChange={selectFileGestor}
                                onClick={(e) => (e.target.value = null)}
                              />
                            </label>
                          </FormGroup>
                        </Col>
                      </div>
                      <FormGroup as={Row}>


                        <Col>
                          <Form.Label className="title-create">
                            Nombre:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Ingrese nombre"
                            id="ges_nombre"
                            value={gestor.ges_nombre}
                            onChange={(e) => {
                              const val = e.target.value;
                              setGestor((gestor) => {
                                return { ...gestor, ges_nombre: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.nombre}</span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Apellido:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Ingrese apellido"
                            id="ges_apellido"
                            value={gestor.ges_apellido}
                            onChange={(e) => {
                              const val = e.target.value;
                              setGestor((gestor) => {
                                return { ...gestor, ges_apellido: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.apellido}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Usuario:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Ingrese un nombre de gestor"
                            id="ges_usuario"
                            value={gestor.ges_usuario}
                            onChange={(e) => {
                              const val = e.target.value;
                              setGestor((gestor) => {
                                return { ...gestor, ges_usuario: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.gestor}</span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Perfil de gestor (Para accesos de la App móvil):{" "}
                          </Form.Label>

                          <Form.Control
                            as="select"
                            onChange={(e) => {
                              const val = Number(e.target.value);
                              setGestor((gestor) => {
                                return { ...gestor, tbl_perfil_per_id: val };
                              });
                              // console.log(val)
                            }}
                          >
                            <option value="0">N/A</option>
                            {getRowsPerfil}
                          </Form.Control>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Cédula:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={13}
                            placeholder="Cédula"
                            id="ges_cedula"
                            value={gestor.ges_cedula}
                            onChange={(e) => {
                              const valcedula = e.target.value;
                              setGestor((gestor) => {
                                return { ...gestor, ges_cedula: valcedula };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.cedula}</span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Correo electrónico:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Correo electrónico"
                            id="ges_email"
                            type="email"
                            value={gestor.ges_email}
                            onChange={(e) => {
                              const val = e.target.value;
                              setGestor((gestor) => {
                                return { ...gestor, ges_email: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.correo}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Contraseña:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={250}
                            placeholder="Ingrese una contraseña"
                            id="ges_contrasena"
                            value={gestor.ges_contrasena}
                            onChange={(e) => {
                              const val = e.target.value;
                              setGestor((gestor) => {
                                return { ...gestor, ges_contrasena: val };
                              });
                            }}
                          />
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Repita Contraseña:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={250}
                            placeholder="Repita la contraseña"
                            id="ges_rep_contrasena"
                            value={ges_rep_contrasena} //{gestor.ges_rep_contrasena}
                            onChange={(e) => {
                              const val = e.target.value;
                              setges_rep_contrasena(val);
                              // setGestor((gestor) => {
                              //   return { ...gestor, ges_rep_contrasena: val };
                              // });
                            }}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Dirección:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={200}
                            placeholder="Ingrese una dirección"
                            id="ges_direccion"
                            value={gestor.ges_direccion}
                            onChange={(e) => {
                              const val = e.target.value;
                              setGestor((gestor) => {
                                return { ...gestor, ges_direccion: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {error.direccion}
                          </span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Teléfono convencional:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={13}
                            placeholder="Ingrese teléfono"
                            id="ges_telefono"
                            value={gestor.ges_telefono}
                            onChange={(e) => {
                              const val = e.target.value;
                              setGestor((gestor) => {
                                return { ...gestor, ges_telefono: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {error.telefonocon}
                          </span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Celular:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={15}
                            placeholder="Ingrese celular"
                            id="ges_celular"
                            value={gestor.ges_celular}
                            onChange={(e) => {
                              const val = e.target.value;
                              setGestor((gestor) => {
                                return { ...gestor, ges_celular: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.celular}</span>
                        </Col>
                      </FormGroup>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button onClick={() => uploadFotoFBUpdate()}>
                        Actualizar
                      </Button>
                      <Button
                        onClick={() => {
                          setshowModal(false);
                          setGestor((gestor) => {
                            return {
                              ...gestor,
                              ges_cedula: "",
                              ges_nombre: "",
                              ges_apellido: "",
                              ges_direccion: "",
                              ges_telefono: "",
                              ges_celular: "",
                              ges_email: "",
                              ges_usuario: "",
                              ges_contrasena: "",
                              ges_cargo: "",
                            };
                          });
                        }}
                      >
                        Cerrar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div>
                  <ModalInfoSelection
                    show={isModalVisible}
                    onHide={() => setIsModalVisible(false)}
                    row={1}
                    listItems={getRowsModal}
                    // updateEstadoModal={updateEstadoModal}
                    selectRow={selectRow}
                  >
                    {" "}
                  </ModalInfoSelection>
                </div>
                <div></div>
                <h3 className="text-center">Gestión de "Gestores"</h3>
                <Card>
                  <Card.Header as="h5">Creción de NUEVO Gestor </Card.Header>
                  <Card.Body>
                    <Form>
                      {/* <FormGroup as={Row}> */}
                      <Form.Label className="title-create" md="auto">
                        Foto de perfil:{" "}
                      </Form.Label>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Col md="auto">
                          <img
                            style={{ width: 200, height: 200 }}
                            src={imageG}
                            alt=""
                          />
                          <FormGroup as={Col} md="auto">
                            <label className="btn btn-default" md="auto">
                              <input
                                md="auto"
                                type="file"
                                onChange={selectFile}
                                onClick={(e) => (e.target.value = null)}
                              />
                            </label>
                          </FormGroup>
                          {/* <Button
                            variant="primary"
                            onClick={() => uploadFotoFB()}
                          >
                            subir
                        </Button> */}
                        </Col>
                      </div>
                      {/* </FormGroup> */}

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Nombre:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Ingrese nombre"
                            id="ges_nombre"
                            value={gestor.ges_nombre}
                            onChange={(e) => {
                              const val = e.target.value;
                              setGestor((gestor) => {
                                return { ...gestor, ges_nombre: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.nombre}</span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Apellido:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Ingrese apellido"
                            id="ges_apellido"
                            value={gestor.ges_apellido}
                            onChange={(e) => {
                              const val = e.target.value;
                              setGestor((gestor) => {
                                return { ...gestor, ges_apellido: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.apellido}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Usuario:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Ingrese un nombre de gestor"
                            id="ges_usuario"
                            value={gestor.ges_usuario}
                            onChange={(e) => {
                              const val = e.target.value;
                              setGestor((gestor) => {
                                return { ...gestor, ges_usuario: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.gestor}</span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Perfil de gestor (Para accesos de la App móvil):{" "}
                          </Form.Label>

                          <Form.Control
                            as="select"
                            onChange={(e) => {
                              const val = Number(e.target.value);
                              setGestor((gestor) => {
                                return { ...gestor, tbl_perfil_per_id: val };
                              });
                              // console.log(val)
                            }}
                          >
                            <option value="0">N/A</option>
                            {getRowsPerfil}
                          </Form.Control>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Cédula:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={13}
                            placeholder="Cédula"
                            id="ges_cedula"
                            value={gestor.ges_cedula}
                            onChange={(e) => {
                              const valcedula = e.target.value;
                              setGestor((gestor) => {
                                return { ...gestor, ges_cedula: valcedula };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.cedula}</span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Correo electrónico:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Correo electrónico"
                            id="ges_email"
                            type="email"
                            value={gestor.ges_email}
                            onChange={(e) => {
                              const val = e.target.value;
                              setGestor((gestor) => {
                                return { ...gestor, ges_email: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.correo}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Contraseña:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={250}
                            placeholder="Ingrese una contraseña"
                            id="ges_contrasena"
                            value={gestor.ges_contrasena}
                            onChange={(e) => {
                              const val = e.target.value;
                              setGestor((gestor) => {
                                return { ...gestor, ges_contrasena: val };
                              });
                            }}
                          />
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Repita Contraseña:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={250}
                            placeholder="Repita la contraseña"
                            id="ges_rep_contrasena"
                            value={ges_rep_contrasena} //{gestor.ges_rep_contrasena}
                            onChange={(e) => {
                              const val = e.target.value;
                              setges_rep_contrasena(val);
                              // setGestor((gestor) => {
                              //   return { ...gestor, ges_rep_contrasena: val };
                              // });
                            }}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Dirección:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Ingrese una dirección"
                            id="ges_direccion"
                            value={gestor.ges_direccion}
                            onChange={(e) => {
                              const val = e.target.value;
                              setGestor((gestor) => {
                                return { ...gestor, ges_direccion: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {error.direccion}
                          </span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Tipo de Gestor:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese un tipo de gestor"
                            id="ges_tipo_gestor"
                            value={gestor.ges_tipo_gestor}
                            onChange={(e) => {
                              const val = e.target.value;
                              setGestor((gestor) => {
                                return { ...gestor, ges_tipo_gestor: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {error.direccion}
                          </span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Teléfono convencional:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={13}
                            placeholder="Ingrese teléfono"
                            id="ges_telefono"
                            value={gestor.ges_telefono}
                            onChange={(e) => {
                              const val = e.target.value;
                              setGestor((gestor) => {
                                return { ...gestor, ges_telefono: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {error.telefonocon}
                          </span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Celular:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={15}
                            inputMode="numeric"
                            // type="number"
                            pattern="[0-9]"
                            placeholder="Ingrese celular"
                            id="ges_celular"
                            value={gestor.ges_celular}
                            onChange={(e) => {
                              const val = e.target.value;
                              // onHandleTelephoneChange(e);
                              setGestor((gestor) => {
                                return { ...gestor, ges_celular: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.celular}</span>
                        </Col>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          variant="primary"
                          // onClick={() => createGestor()}
                          onClick={() => uploadFotoFB()}
                        >
                          Guardar cambios
                        </Button>
                      </div>

                      <div className="text-center">
                        <div className={isLoadingGestor}>
                          <Spinner animation="border" role="status"></Spinner>
                        </div>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>

                <br></br>

                <Card>
                  <Card.Header as="h5">
                    Listado general de "Gestores"
                  </Card.Header>
                  <Card.Body>
                    <BootstrapTable
                      keyField="US_CODIGO"
                      data={dataTableUser}
                      columns={columns}
                    />

                    <div className="text-center">
                      <div className={isLoading}>
                        <Spinner animation="border" role="status"></Spinner>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
          {/* </Container> */}


          <Modal
            show={showModalEditar}
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{
              borderWidth: 1,
              borderColor: "rgba(128,128,128,0.8)",
              backgroundColor: "rgba(3,108,199,0.3)",
            }}
          >
            <Modal.Header>
              <Modal.Title>Atención:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
              El gestor {" "}
                <b>
                  {currentGestor.ges_nombre}{" "}
                  {currentGestor.ges_apellido}
                </b>{", va a ser "}
                <b>
                 ELIMINADO!
                 </b>
                 , estas seguro de realizar este cambio?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                // className="btn btn-warning"
                variant="secondary"
                onClick={() => setshowModalEditar(false)}
              >
                CERRAR
              </Button>
              <Button
                // className="btn btn-success"
                variant="danger"
                onClick={() =>
                  {
                    eliminarGestor(currentGestor.ges_id)
                  }
                }
              >
                ELIMINAR GESTOR
              </Button>
            </Modal.Footer>
          </Modal>

        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
