import React, { Fragment, useState } from "react";
import cx from "classnames";
import Navbarmenu from "./Navbarmenu";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PerfectScrollbar from "react-perfect-scrollbar";
import Logo from "./Logo";
import * as SecureLS from "secure-ls";

function AppSidebar() {
  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  const getColor = () => {
    const item = data.us_empresa.listaParametros.find(
      (element) => element.pae_tipo === 6
    );

    if (item === undefined) {
      return "#137ad1";
    } else {
      return "#" + item.pae_parametro;
    }
  };
  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        className={cx("app-sidebar", "bg-royal sidebar-text-light", {
          "sidebar-shadow": true,
        })}
        transitionName="SidebarAnimation"
        transitionAppear={true}
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Logo />
        <PerfectScrollbar style={{ backgroundColor: getColor() }}>
          <div className="app-sidebar__inner">
            <Navbarmenu />
          </div>
        </PerfectScrollbar>
        <div className={cx("app-sidebar-bg", "opacity-06")}></div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default AppSidebar;
