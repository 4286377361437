import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup, {
  contextTypes,
} from "react-addons-css-transition-group";
import BootstrapTable, {
  ROW_SELECT_DISABLED,
} from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import filterFactory, {
  textFilter,
  selectFilter,
  numberFilter,
  Comparator,
  dateFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import PageTitle from "../Layout/PageTitle";
import optionsPagination from "../../helper/SetupComponents";
import Map from "../../componentes/screens/Map";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow,
  Polyline,
} from "react-google-maps";
import * as moment from "moment";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faCalendarCheck,
  faPenSquare,
  faHandHoldingUsd,
  faInfoCircle,
  faMapMarkedAlt,
  faHistory,
  faLocationArrow,
  faImages,
  faUserEdit,
  faDirections,
} from "@fortawesome/free-solid-svg-icons";
import {
  Row,
  Col,
  Container,
  Card,
  Image,
  Form,
  FormGroup,
  Button,
  Modal,
  Carousel,
  Spinner,
  Tabs,
  Tab,
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
// registerLocale("es", es);
import "react-datepicker/dist/react-datepicker.css";
import { findByDisplayValue } from "@testing-library/react";
import { scryRenderedDOMComponentsWithClass } from "react-dom/test-utils";
import Swal from "sweetalert2";
import * as firebase from "firebase";
// const firebaseConfig = {
//   apiKey: "AIzaSyApKubjeaTo2UWBor9AtnLJvfyxPWFEYVU",
//   authDomain: "proyecto-bajaj.firebaseapp.com",
//   databaseURL: "https://proyecto-bajaj.firebaseio.com",
//   projectId: "proyecto-bajaj",
//   storageBucket: "proyecto-bajaj.appspot.com",
//   messagingSenderId: "96970549369",
//   appId: "1:96970549369:web:0f9f35a63e2dd6fa318ca8",
//   measurementId: "G-PZN1X2F6XV",
// };

// firebase.initializeApp(firebaseConfig);

registerLocale("es", es);

function Content(props) {
  const storage = firebase.storage();
  const server = window.$linkserver;
  const [nombreBanco, setNombreBanco] = useState("Banco del Austro");
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const [modalShow, setModalShow] = React.useState(false);
  const [cartera, setCartera] = useState();
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const [startDate, setStartDate] = useState(
    new Date(year, month, day - day + 1)
  );
  const [lastDate, setLastDate] = useState(new Date());
  const [horaInicio, sethoraInicio] = useState(new Date());
  const [horaFin, sethoraFin] = useState(new Date());

  const [showModalMap, setshowModalMap] = useState(false);
  const [showModalFotos, setshowModalFotos] = useState(false);
  const [showModalPagos, setShowModalPagos] = useState(false);
  const [showModalVisitas, setShowModalVisitas] = useState(false);
  const [showModalHistorial, setShowModalHistorial] = useState(false);
  const [showModalMapQlink, setShowModalMapQlink] = useState(false);
  const [showModalMapHistorial, setShowModalMapHistorial] = useState(false);
  const [showModalFotosCliente, setShowModalFotosCliente] = useState(false);
  const [showModalLoadingData, setShowModalLoadingData] = useState(false);
  const [showModalCargando, setShowModalCargando] = useState(false);
  const [startDateHistorial, setStartDateHistorial] = useState(new Date());
  const [search_cedula, setsearch_cedula] = useState("");
  const [showModalDirecciones, setShowModalDirecciones] = useState(false);

  const [showInfoMarker, setShowInfoMarker] = useState();
  const [showModalCobros, setShowModalCobros] = useState(false);
  const [showModalDetalleCartera, setShowModalDetalleCartera] = useState(false);
  const [currentClienteCarte, setCurrentClienteCartera] = useState([]);
  const [products, setproducts] = useState([]);
  const [productsVenteConsig, setproductsVenteConsig] = useState([])
  const [dataCobros, setdataCobros] = useState([]);
  const [currentCartera, setcurrentCartera] = useState([]);
  const [currentFactura, setcurrentFactura] = useState("");

  const [currentCarge, setcurrentCarge] = useState("");
  const [horaMapa, sethoraMapa] = useState("");
  const [showModalCargandoHistorial, setShowModalCargandoHistorial] = useState(
    false
  );

  const [currentVehiculo, setcurrentVehiculo] = useState([]);

  const [imgVisita1, setIMGVisita1] = useState([]);
  const [imgVisita2, setIMGVisita2] = useState([]);
  const [imgVisita3, setIMGVisita3] = useState([]);
  const [latVisita, setLatVisita] = useState(0);
  const [lonVisita, setLonVisita] = useState(0);
  const [direccionCli, setdireccionCli] = useState("");
  const [fechaCli, setfechaCli] = useState("");
  const [velocidadCli, setvelocidadCli] = useState("");
  const [motorCli, setmotorCli] = useState("");
  const [voltajeCli, setvoltajeCli] = useState("");
  const [key, setKey] = useState("Mapa");
  // const [fechaCli, setfechaCli] = useState('');

  const defaultProps = {
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyBWQwWMO0El5LZPg5eECmCQ7bh5o2Zg738",
  };

  const columnsCobro = [
    {
      dataField: "cob_fecha_pago",
      text: "Fecha",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },

      formatter: (cell, row, index) => fechaPagoPago(row, index),
      footer: "",
    },
    {
      dataField: "tip_nombre",
      text: "Tipo Pago",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },

      footer: "",
    },
    {
      dataField: "dtc_valor_cobro",
      text: "Valor",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },

      footer: (columnData) =>
        "$" + columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      dataField: "cob_detalle_pago",
      text: "Detalle Pago",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },

      footer: "",
    },
    {
      dataField: "cli_nombre",
      // text: "Nombre Taller",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "200px",
      },
      footer: "",
      filter: textFilter({
        placeholder: "Cliente",
      }),
    },
    {
      dataField: "cli_cedula",
      // text: "Ciudad",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },
      footer: "",
      filter: textFilter({
        placeholder: "Cédula",
      }),
    },

    {
      dataField: "Gestor",
      // text: "Ciudad",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },
      formatter: (cell, row, index) => gestorCobros(row, index),
      footer: "",
      filter: textFilter({
        placeholder: "Gestor",
      }),
    },

    {
      dataField: "veh_chasis",
      text: "Chasis",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },

      footer: "",
    },
  ];

  const selectOptions = {
    1: "Éxitosa",
    0: "Fallida",
  };

  const [tipoLista, settipoLista] = useState("Listado");
  const [ltsPagos, setLtsPagos] = useState([]);
  const [ltsVisitas, setLtsVisitas] = useState([]);
  const [ltsCobros, setLtsCobros] = useState([]);
  const [ltsUbicaciones, setLtsUbicaciones] = useState([]);
  const [ltsUbicacionesreal, setltsUbicacionesreal] = useState([]);
  const [ltsDirecciones, setLtsDirecciones] = useState([]);
  const [nombrePantalla, setnombrePantalla] = useState("");
  const [ltsHistorial, setLtsHistorial] = useState([]);
  const [ltsLocationHistorial, setLtsLocationHistorial] = useState([]);

  const [urlimageG, setURLimageG] = useState("");
  const [tipo, settipo] = useState(1);
  const [urlCliCedula, setURLCliCedula] = useState("");
  const [urlCliCroquis, setURLCliCroquis] = useState("");
  const [urlCliMatricula, setURLCliMatricula] = useState("");
  const [urlCliActaEntrega, setURLCliActaEntrega] = useState("");

  const [imageG, setimageG] = useState("");
  const [imgCliCedula, setCliCedula] = useState("");
  const [imgCliCroquis, setCliCroquis] = useState("");
  const [imgCliMatricula, setCliMatricula] = useState("");
  const [imgCliActaEntrega, setCliActaEntrega] = useState("");

  const [ltsImgCliente, setltsImgCliente] = useState({
    cedula: "",
    croquis: "",
    matricula: "",
    acta: "",
    cliente: "",
  });

  const selectTipoCartera = {
    "FONDO DE GARANTIA": (
      <Form.Label
        style={{ textAlign: "center", width: "100%", fontWeight: "bold" }}
      >
        FONDO DE GARANTIA
      </Form.Label>
    ),
    "CARTERA BANCO": (
      <Form.Label
        style={{ textAlign: "center", width: "100%", fontWeight: "bold" }}
      >
        CARTERA BANCO
      </Form.Label>
    ),
  };

  const selectTipoCartera2 = {
    "FONDO DE GARANTIA": "FONDO DE GARANTIA",
    "CARTERA BANCO": "CARTERA BANCO",
  };

  const selectBanco = {
    SOLIDARIO: (
      <Form.Label
        style={{
          backgroundColor: "#00b5ca",
          textAlign: "center",
          width: "100%",
          borderRadius: 7,
          color: "white",
          fontWeight: "bold",
          fontStyle: "oblique",
        }}
      >
        SOLIDARIO
      </Form.Label>
    ),
    AUSTRO: (
      <Form.Label
        style={{
          backgroundColor: "#171d5c",
          textAlign: "center",
          width: "100%",
          borderRadius: 7,
          color: "white",
          fontWeight: "bold",
          fontStyle: "oblique",
        }}
      >
        AUSTRO
      </Form.Label>
    ),
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

  const selectBanco2 = {
    SOLIDARIO: "SOLIDARIO",
    AUSTRO: "AUSTRO",
  };

  const selectGPS = {
    1: "GPS Activado",
    0: "GPS Desactivado",
  };

  const columnsVisitas = [
    {
      dataField: "gestor",
      text: "Gestor",
      headerAlign: "center",
      headerStyle: { backgroundColor: "rgba(255, 255, 255, 1)" },
      formatter: (cell, row, index) => infoGestor(row, index),
    },
    {
      dataField: "vis_fecha_visita",
      text: "Fecha de visita",
      formatter: (cell, row, index) => fechaVisita(row, index),
    },
    {
      dataField: "vis_tipo_visita",
      text: "Estado",
      formatter: (cell) => selectOptions[cell],
      filter: selectFilter({
        options: selectOptions,
        withoutEmptyOption: false,
        style: {
          // backgroundColor: "pink",
        },
        className: "test-classname",
        datamycustomattr: "datamycustomattr",
      }),
    },
    {
      dataField: "vis_observaciones",
      text: "Observaciones",
    },
    {
      dataField: "fotos",
      text: "Fotos",
      formatter: (cell, row, index) => showFotos(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "cdp_ubicacion",
      text: "Ubicación",
      formatter: (cell, row, index) => locationClienteVisita(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
  ];

  const columnsCobros = [
    {
      dataField: "gestor",
      text: "Gestor",
      headerAlign: "center",
      headerStyle: { backgroundColor: "rgba(255, 255, 255, 1)" },
      formatter: (cell, row, index) => infoGestor(row, index),
    },
    {
      dataField: "cob_fecha_pago",
      text: "Fecha de cobro",
      formatter: (cell, row, index) => fechaCobro(row, index),
    },
    // {
    //   dataField: "vis_tipo_visita",
    //   text: "Estado",
    //   formatter: (cell) => selectOptions[cell],
    //   filter: selectFilter({
    //     options: selectOptions,
    //     withoutEmptyOption: false,
    //     style: {
    //       // backgroundColor: "pink",
    //     },
    //     className: "test-classname",
    //     datamycustomattr: "datamycustomattr",
    //   }),
    // },
    {
      dataField: "cob_detalle_pago",
      text: "Detalle",
    },
    {
      dataField: "fotos",
      text: "Fotos",
      formatter: (cell, row, index) => showFotosCobros(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "cdp_ubicacion",
      text: "Ubicación",
      formatter: (cell, row, index) => locationClienteCobro(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
  ];

  const columnsPagos = [
    // {
    //   dataField: "ci",
    //   text: "Cédula",
    //   headerAlign: "center",
    //   headerStyle: { backgroundColor: "rgba(255, 255, 255,0.1)" },
    // },
    // {
    //   dataField: "name",
    //   text: "Nombres",
    //   headerAlign: "center",
    //   headerStyle: { backgroundColor: "rgba(255, 255, 255,0.1)" },
    //   formatter: fechaFormatter,
    // },
    {
      dataField: "gestor",
      text: "Gestor",
      headerAlign: "center",
      headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
      formatter: (cell, row, index) => infoGestor(row, index),
    },
    {
      dataField: "cdp_fecha_actual_compromiso",
      text: "Fecha de visita",
      headerAlign: "center",
      formatter: (cell, row, index) => fechaPagoVisita(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      // formatter: fechaFormatter,
    },
    {
      dataField: "cdp_fecha_pago",
      text: "Fecha de compromiso",
      headerAlign: "center",
      formatter: (cell, row, index) => fechaPagoPago(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          // minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      // formatter: fechaFormatter,
    },

    {
      dataField: "cdp_valor_pago",
      text: "Valor",
      textAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      formatter: priceFormatter,
    },
    // {
    //   dataField: "cdp_observacion",
    //   text: "Observaciones",
    //   headerAlign: "center",
    //   headerStyle: (colum, colIndex) => {
    //     return {
    //       textAlign: "center",
    //       minWidth: "200px",
    //       backgroundColor: "rgba(255, 255, 255,0.1)",
    //     };
    //   },

    //   formatter: observacionesFormatter,
    // },
    // {
    //   dataField: "cdp_estado_pago",
    //   text: "Estado",
    //   headerAlign: "center",
    //   formatter: (cell, row, index) => semaforoEstado(row, index),
    //   headerStyle: (colum, colIndex) => {
    //     return {
    //       textAlign: "center",
    //       backgroundColor: "rgba(255, 255, 255,0.1)",
    //     };
    //   },
    // },
    // {
    //   dataField: "cdp_estado_pago1",
    //   text: "Observación de estado",
    //   headerStyle: (colum, colIndex) => {
    //     return {
    //       textAlign: "center",
    //       alignItems: "center",
    //       minWidth: "200px",
    //       backgroundColor: "rgba(255, 255, 255,0.1)",
    //     };
    //   },
    //   formatter: observacionesFormatter,
    // },
    {
      dataField: "cdp_ubicacion",
      text: "Ubicación",
      formatter: (cell, row, index) => locationPago(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    // {
    //   dataField: "fotos",
    //   text: "Fotos",
    //   formatter: (cell, row, index) => showFotosPagos(row, index),
    //   headerStyle: (colum, colIndex) => {
    //     return {
    //       textAlign: "center",
    //       alignItems: "center",
    //       backgroundColor: "rgba(255, 255, 255,1)",
    //     };
    //   },
    // },
  ];

  const columnsHistorial = [
    // {
    //   dataField: "ci",
    //   text: "Cédula",
    //   headerAlign: "center",
    //   headerStyle: { backgroundColor: "rgba(255, 255, 255,0.1)" },
    // },
    // {
    //   dataField: "name",
    //   text: "Nombres",
    //   headerAlign: "center",
    //   headerStyle: { backgroundColor: "rgba(255, 255, 255,0.1)" },
    //   formatter: fechaFormatter,
    // },
    {
      dataField: "crt_estado_credito",
      text: "Estado",
      headerAlign: "center",
      headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
      // formatter: (cell, row, index) => infoGestor(row, index),
    },
    {
      dataField: "crt_fecha_carga_actualizacion",
      text: "Fecha de carga",
      headerAlign: "center",
      formatter: (cell, row, index) => fechaHistorialCarga(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "crt_fecha_inicio_credito",
      text: "Fecha de inicio",
      headerAlign: "center",
      formatter: (cell, row, index) => fechaHistorial(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "crt_fecha_vencimiento",
      text: "Fecha vencimiento",
      headerAlign: "center",
      formatter: (cell, row, index) => fechaHistorialFin(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          // minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      // formatter: fechaFormatter,
    },

    {
      dataField: "crt_saldo_credito",
      text: "Saldo",
      textAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      formatter: priceFormatter,
    },
    {
      dataField: "crt_monto_credito_original",
      text: "Monto crédito",
      textAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      formatter: priceFormatter,
    },
    {
      dataField: "crt_valor_cuota_credito",
      text: "Cuota",
      textAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      formatter: priceFormatter,
    },
    {
      dataField: "crt_plazo_credito_original",
      text: "Plazo",
      textAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "crt_dias_retraso",
      text: "Días retraso",
      textAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "crt_cuotas_restantes",
      text: "Cuotas restantes",
      textAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },

    // {
    //   dataField: "cdp_observacion",
    //   text: "Observaciones",
    //   headerAlign: "center",
    //   headerStyle: (colum, colIndex) => {
    //     return {
    //       textAlign: "center",
    //       minWidth: "200px",
    //       backgroundColor: "rgba(255, 255, 255,0.1)",
    //     };
    //   },

    //   formatter: observacionesFormatter,
    // },
    // {
    //   dataField: "cdp_estado_pago",
    //   text: "Estado",
    //   headerAlign: "center",
    //   formatter: (cell, row, index) => semaforoEstado(row, index),
    //   headerStyle: (colum, colIndex) => {
    //     return {
    //       textAlign: "center",
    //       backgroundColor: "rgba(255, 255, 255,0.1)",
    //     };
    //   },
    // },
    // {
    //   dataField: "cdp_estado_pago1",
    //   text: "Observación de estado",
    //   headerStyle: (colum, colIndex) => {
    //     return {
    //       textAlign: "center",
    //       alignItems: "center",
    //       minWidth: "200px",
    //       backgroundColor: "rgba(255, 255, 255,0.1)",
    //     };
    //   },
    //   formatter: observacionesFormatter,
    // },

    // {
    //   dataField: "fotos",
    //   text: "Fotos",
    //   formatter: (cell, row, index) => showFotosPagos(row, index),
    //   headerStyle: (colum, colIndex) => {
    //     return {
    //       textAlign: "center",
    //       alignItems: "center",
    //       backgroundColor: "rgba(255, 255, 255,1)",
    //     };
    //   },
    // },
  ];

  const columnsDirecciones = [
    {
      dataField: "gestor",
      text: "Gestor",
      headerAlign: "center",
      headerStyle: { backgroundColor: "rgba(255, 255, 255, 1)" },
      formatter: (cell, row, index) => infoGestor(row, index),
    },
    {
      dataField: "dir_calle_principal",
      text: "Calle principal",
      // formatter: (cell, row, index) => fechaVisita(row, index),
    },
    {
      dataField: "dir_calle_secundaria",
      text: "Calle secundaria",
    },
    {
      dataField: "cdp_ubicacion",
      text: "Ubicación",
      formatter: (cell, row, index) => locationDirecciones(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
  ];

  const columns = [

    {
      dataField: "dde_nombre",
      // text: "Distribuidor",
      headerAlign: "center",
      filter: textFilter({
        placeholder: "Distribuidor",
      }),
      // footer: "",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "350px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      // formatter: (cell) => selectBanco[cell],
      // filter: selectFilter({
      //   options: selectBanco2,
      //   placeholder: "TODOS",
      //   withoutEmptyOption: false,
      //   style: {
      //     width: "100%",
      //     minWidth: "120px",
      //     alignItems: "center",
      //     placeholder: "TODO",
      //   },
      //   className: "test-classname",
      //   datamycustomattr: "datamycustomattr",
      // }),
      footer: "",
    },
    {
      dataField: "dde_venta",
      text: "Venta",
      headerAlign: "center",
      // footer: "",
      // headerStyle: (colum, colIndex) => {
      //   return {
      //     textAlign: "center",
      //     minWidth: "150px",
      //     backgroundColor: "rgba(255, 255, 255,1)",
      //   };
      // },
      // formatter: (cell) => selectTipoCartera[cell],
      // filter: selectFilter({
      //   placeholder: "TODOS",
      //   options: selectTipoCartera2,
      //   withoutEmptyOption: false,
      //   style: {
      //     width: "100%",
      //     minWidth: "150px",
      //     alignItems: "center",
      //     placeholder: "TODO",
      //   },
      //   className: "test-classname",
      //   datamycustomattr: "datamycustomattr",
      // }),
      footer: "",
    },

    {
      dataField: "dde_consignacion",
      text: "Consignación",
      headerAlign: "center",
      // headerStyle: {
      //   backgroundColor: "rgba(255, 255, 255,1)",
      //   width: "100%",
      //   minWidth: "150px",
      // },
      footer: "",
      // filter: textFilter({
      //   placeholder: "# cédula",
      // }),
      // formatter: cedulaFormatter,
    },
    {
      dataField: "dde_total",
      text: "Total",
      headerAlign: "center",
      // formatter: clienteFormatter,
      // footer: "",
      // filter: textFilter({
      //   placeholder: "Nombre o apellido",
      // }),
    }
  ];

  const columnsVenteConsig = [

    {
      dataField: "cli_nombres",
      // text: "Distribuidor",
      headerAlign: "center",
      filter: textFilter({
        placeholder: "Distribuidor",
      }),
      // footer: "",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "350px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      // formatter: (cell) => selectBanco[cell],
      // filter: selectFilter({
      //   options: selectBanco2,
      //   placeholder: "TODOS",
      //   withoutEmptyOption: false,
      //   style: {
      //     width: "100%",
      //     minWidth: "120px",
      //     alignItems: "center",
      //     placeholder: "TODO",
      //   },
      //   className: "test-classname",
      //   datamycustomattr: "datamycustomattr",
      // }),
      footer: "",
    },
    {
      dataField: "veh_modelo",
      text: "Modelo",
      headerAlign: "center",
      // footer: "",
      // headerStyle: (colum, colIndex) => {
      //   return {
      //     textAlign: "center",
      //     minWidth: "150px",
      //     backgroundColor: "rgba(255, 255, 255,1)",
      //   };
      // },
      // formatter: (cell) => selectTipoCartera[cell],
      // filter: selectFilter({
      //   placeholder: "TODOS",
      //   options: selectTipoCartera2,
      //   withoutEmptyOption: false,
      //   style: {
      //     width: "100%",
      //     minWidth: "150px",
      //     alignItems: "center",
      //     placeholder: "TODO",
      //   },
      //   className: "test-classname",
      //   datamycustomattr: "datamycustomattr",
      // }),
      footer: "",
    },

    {
      dataField: "veh_anio",
      text: "Año",
      headerAlign: "center",
      // headerStyle: {
      //   backgroundColor: "rgba(255, 255, 255,1)",
      //   width: "100%",
      //   minWidth: "150px",
      // },
      footer: "",
      // filter: textFilter({
      //   placeholder: "# cédula",
      // }),
      // formatter: cedulaFormatter,
    },
    {
      dataField: "veh_color",
      text: "Color",
      headerAlign: "center",
      footer: "",
    },
    {
      dataField: "veh_chasis",
      text: "Chasis",
      headerAlign: "center",
      footer: "",
    },
    {
      dataField: "venta",
      text: "Venta",
      headerAlign: "center",
      footer: "",
    },
    {
      dataField: "consignacion",
      text: "Consignación",
      headerAlign: "center",
      footer: "",
    },
    {
      dataField: "total",
      text: "Total",
      headerAlign: "center",
      footer: "",
    }
  ];

  const columnsHistorialMapa = [
    {
      dataField: "fechastr",
      text: "Fecha y Hora",
      sort: true,
      fechaHisFormatter,
    },
    {
      dataField: "direccion",
      text: "Dirección",
      sort: true,
    },
    {
      dataField: "velocidad",
      text: "Velocidad",
      sort: true,
    },
    {
      dataField: "cdp_ubicacion",
      text: "Ubicación",
      footer: "",
      formatter: (cell, row, index) => locationClienteHistorial(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
  ];

  useEffect(() => {
    try {
      setnombrePantalla(props.props.location.data.nombre);

      console.log(props.props.location.data.tipo);
      if (
        // props.props.location.data.tipo === 1 ||
        props.props.location.data.tipo === 2
        || props.props.location.data.tipo === 3
      ) {
        settipo(2);
        getFechaGestorCartera(
          props.props.location.data.idCliente
        );
      } else if (props.props.location.data.tipo === 1) {
        settipo(1);
        getMotosVentaConsig(
          props.props.location.data.idCliente
        );
        // console.log("%%%%%%%%%%%%%%%%%%");
        // console.log(props.props.location.data.mes);
        // getCobros(
        //   props.props.location.data.mes,
        //   props.props.location.data.anio,
        //   props.props.location.data.banco
        // );
      }
    } catch (error) { }
  }, []);

  const getCobros = async (mes, anio, banco) => {
    await axios

      .get(
        server +
        "api/cartera/getreporteCobrosMes/" +
        mes +
        "/" +
        anio +
        "/" +
        banco
      )

      .then(function (response) {
        setdataCobros(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const gestorAsignado = (row, index) => {
    return (
      <div>
        {row.carge_asignado !== "No existe gestor" ? (
          <Form.Label
            className="text-center align-items-center"
            style={{
              fontWeight: "bold",
              width: "100%",
              minWidth: "150px",
              backgroundColor: "rgba(0,255,0,0.15)",
              borderRadius: 10,
              alignSelf: "center",
              justifyContent: "center",
              msAlignSelf: "center",
              align: "middle",
            }}
          >
            {row.carge_asignado}
          </Form.Label>
        ) : (
            <Form.Label
              className="text-center align-items-center"
              style={{
                fontWeight: "bold",
                width: "100%",
                minWidth: "125px",
                backgroundColor: "rgba(255,0,0,0.15)",
                borderRadius: 10,
                align: "middle",
              }}
            >
              Gestor no asignado
            </Form.Label>
          )}
      </div>
    );
  };

  const getFechaGestorCartera = async (tipo) => {
    setShowModalCargando(true);

    await axios
      .get(
        server +
        "api/dashboard/getTotalDistribuidoresDetalle/" + tipo
      )
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        console.log(response.data);
        setproducts(response.data);
        setShowModalCargandoHistorial(false);
        setShowModalCargando(false);
      })
      .catch(function (error) {
        setShowModalCargando(false);
      })
      .then(function () { });
    setShowModalCargandoHistorial(false);
    setShowModalCargando(false);
  };

  const getMotosVentaConsig = async (tipo) => {
    setShowModalCargando(true);


    if (tipo == 0) {
      settipoLista("Listado de venta")
    } else if (tipo == 2) {
      settipoLista("Listado de consignación")
    }
    await axios
      .get(
        server +
        "api/dashboard/getMotosVentaConsig/" + tipo
      )
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        console.log(response.data);
        setproductsVenteConsig(response.data);
        setShowModalCargandoHistorial(false);
        setShowModalCargando(false);
      })
      .catch(function (error) {
        setShowModalCargando(false);
      })
      .then(function () { });
    setShowModalCargandoHistorial(false);
    setShowModalCargando(false);
  };

  const getCarterasByCIGestor = async (ci) => {
    console.log(server + "api/cartera/getCarterasByCIgestor/" + ci);
    await axios
      .get(server + "api/cartera/getGestorCICartera/" + ci)
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        console.log(response.data);
        setproducts(response.data);
        setShowModalCargandoHistorial(false);
      })
      .catch(function (error) { })
      .then(function () { });
    setShowModalCargandoHistorial(false);
  };

  const getHistorialUbicaciones = async (inicio, fin) => {
    setShowModalCargandoHistorial(true);
    // setCurrentClienteCartera(row)
    console.log("-----" + inicio);
    // console.log("-----" + currentCarge.carge_cedula);

    await axios
      .post("https://apibajaj.curbe.com.ec/api/qlink/history", {
        id: currentCarge.carge_cedula,
        vin: currentCarge.carge_chasis,
        desde: inicio,
        hasta: fin,
      })

      .then(function (response) {
        if (response.status === 200) {
          // console.log(response.data)
          if (response.data != "Access denied") {
            setltsUbicacionesreal(response.data);
            setLtsUbicaciones(response.data);
            let coord = response.data.map((item, key) => {
              return {
                lat: item.latitud,
                lng: item.longitud,
              };
            });
            let lat = "";
            let long = "";
            coord.map((item, key) => {
              lat = item.lat;
              long = item.lng;
            });
            // console.log(coord)
            setLtsLocationHistorial(coord);
            setLatVisita(lat);
            setLonVisita(long);
            setShowModalCargandoHistorial(false);
          } else {
            showMessage("Este vehículo no tiene activado el GPS", "error");
            setShowModalCargandoHistorial(true);
            setShowModalMapHistorial(false);
          }
        } else {
          // showMessage("Intentelo de Nuevo", "error");
        }
      })
      .catch(function (error) { });
    setShowModalCargandoHistorial(false);
  };

  const infoGestor = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {row.gestor.ges_nombre} {row.gestor.ges_apellido}
      </Form.Label>
    );
  };
  const fechaPagoPago = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {moment(row.cob_fecha_pago).format("DD-MM-YYYY")}
      </Form.Label>
    );
  };

  const gestorCobros = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {row.ges_nombre} {row.ges_apellido}
      </Form.Label>
    );
  };
  const fechaPagoVisita = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {moment(row.cdp_fecha_actual_compromiso).format("DD-MM-YYYY | HH:mm a")}
      </Form.Label>
    );
  };
  const fechaVisita = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {moment(row.vis_fecha_visita).format("DD-MM-YYYY | HH:mm a")}
      </Form.Label>
    );
  };
  const fechaHistorialCarga = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {moment(row.crt_fecha_carga_actualizacion).format(
          "DD-MM-YYYY | HH:mm:ss a"
        )}
      </Form.Label>
    );
  };
  const fechaHistorial = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {moment(row.crt_fecha_inicio_credito).format("DD-MM-YYYY")}
      </Form.Label>
    );
  };
  const fechaHistorialFin = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {moment(row.crt_fecha_vencimiento).format("DD-MM-YYYY")}
      </Form.Label>
    );
  };
  const fechaCobro = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {moment(row.cob_fecha_pago).format("DD-MM-YYYY HH:mm a")}
      </Form.Label>
    );
  };
  const locationPago = (row, index) => {
    return (
      <Button
        onClick={() => openModalMap(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center" }}
      >
        <FontAwesomeIcon
          icon={faMapMarkedAlt}
          size="2x"
          textAlign="center"
          style={{ alignSelf: "center", justifyContent: "center" }}
        />
      </Button>
    );
  };
  const getVisitasByCartera = async () => {
    // http://192.168.1.13:5001/api/asignacionapp/getLstVisitasbyAsignacion/38

    await axios
      .get(server + "api/asignacionapp/getLstVisitasbyAsignacion/")
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        // console.log(response.data);
        setproducts(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const showFotos = (row, index) => {
    return (
      <Button
        onClick={() => openModalFotos(row)}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faImages} size="2x" />
      </Button>
    );
  };

  const showFotosPagos = (row, index) => {
    return (
      <Button
        onClick={() => openModalFotos(row)}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faImages} size="2x" />
      </Button>
    );
  };

  function openModalFotos(item) {
    setshowModalFotos(true);
    setIMGVisita1(item.vis_img1);
    setIMGVisita2(item.vis_img2);
    setIMGVisita3(item.vis_img3);
  }

  function openModalFotosCobros(item) {
    setshowModalFotos(true);
    setIMGVisita1(item.cob_img1);
    setIMGVisita2(item.cob_img2);
    setIMGVisita3(item.cob_img3);
  }

  const showFotosCobros = (row, index) => {
    return (
      <Button
        onClick={() => openModalFotosCobros(row)}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faImages} size="2x" />
      </Button>
    );
  };

  function observacionesFormatter(cell, row) {
    return (
      <Form.Label
        className="text-justify"
        style={{ width: "100%", fontSize: 11 }}
      >
        {cell}
      </Form.Label>
    );
  }
  function diasFormatter(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "65px",
        }}
      >
        {cell}
      </Form.Label>
    );
  }

  function fechaDesembolso(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "115px",
        }}
      >
        {moment(cell.carge_fecha_desembolso).format("DD-MM-YYYY")}
      </Form.Label>
    );
  }

  function fechaFormatter(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "115px",
        }}
      >
        {cell}
      </Form.Label>
    );
  }

  function clienteFormatter(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "200px",
        }}
      >
        {cell}
      </Form.Label>
    );
  }

  function cedulaFormatter(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "115px",
        }}
      >
        {cell}
      </Form.Label>
    );
  }

  function priceFormatter(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "100px",
        }}
      >
        $ {parseFloat(cell).toFixed(2)}
      </Form.Label>
    );
  }

  function fechaHisFormatter(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          fontSize: 50,
        }}
      ></Form.Label>
    );
  }

  const semaforoEstado = (row, index) => {
    if (row.carge_dias_retraso > 10 && row.carge_dias_retraso <= 30) {
      return (
        <Button
          // onClick={() => openModalMap(row)}
          color="green"
          variant="contained"
          textAlign="center"
        >
          <FontAwesomeIcon icon={faClock} color="orange" size="2x" />
        </Button>
      );
    } else if (row.carge_dias_retraso > 30) {
      return (
        <Button
          // onClick={() => openModalMap(row)}
          color="red"
          variant="contained"
        >
          <FontAwesomeIcon icon={faClock} color="#b30000" size="2x" />
        </Button>
      );
    } else if (row.carge_dias_retraso <= 10) {
      return (
        <Button
          // onClick={() => openModalMap(row)}
          color="green"
          variant="contained"
        >
          <FontAwesomeIcon icon={faClock} color="green" size="2x" />
        </Button>
      );
    }
  };

  const locationCliente = (row, index) => {
    return (
      <Button
        onClick={() => openModalMap2(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        {row.carge_gps_activado == 1 ? (
          <FontAwesomeIcon
            icon={faLocationArrow}
            size="2x"
            textAlign="center"
            style={{
              alignSelf: "center",
              justifyContent: "center",
              color: "green",
            }}
          />
        ) : (
            <FontAwesomeIcon
              icon={faLocationArrow}
              size="2x"
              textAlign="center"
              style={{ alignSelf: "center", justifyContent: "center" }}
            />
          )}
      </Button>
    );
  };

  const locationClienteHistorial = (row, index) => {
    return (
      <Button
        onClick={() => openModalMap3(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon
          icon={faLocationArrow}
          size="2x"
          textAlign="center"
          style={{ alignSelf: "center", justifyContent: "center" }}
        />
      </Button>
    );
  };

  const historialUbicacion = (row, index) => {
    return (
      <Button
        onClick={() => openModalMapHistorial(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon
          icon={faMapMarkedAlt}
          size="2x"
          textAlign="center"
          style={{ alignSelf: "center", justifyContent: "center" }}
        />
      </Button>
    );
  };

  const locationDirecciones = (row, index) => {
    return (
      <Button
        onClick={() => openModalDireccionesUbicacion(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon
          icon={faLocationArrow}
          size="2x"
          textAlign="center"
          style={{ alignSelf: "center", justifyContent: "center" }}
        />
      </Button>
    );
  };

  const locationClienteVisita = (row, index) => {
    return (
      <Button
        onClick={() => openModalVisitaUbicacion(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon
          icon={faLocationArrow}
          size="2x"
          textAlign="center"
          style={{ alignSelf: "center", justifyContent: "center" }}
        />
      </Button>
    );
  };

  const locationClienteCobro = (row, index) => {
    return (
      <Button
        onClick={() => openModalCobroUbicacion(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon
          icon={faLocationArrow}
          size="2x"
          textAlign="center"
          style={{ alignSelf: "center", justifyContent: "center" }}
        />
      </Button>
    );
  };

  const openModalVisitaUbicacion = (row) => {
    setshowModalMap(true);
    setLatVisita(row.vis_ubicacion_actual_lat);
    setLonVisita(row.vis_ubicacion_actual_lon);
    sethoraMapa(row.vis_fecha_visita);
  };

  const openModalDireccionesUbicacion = (row) => {
    setshowModalMap(true);
    setLatVisita(row.dir_lat);
    setLonVisita(row.dir_lon);
  };

  const openModalCobroUbicacion = (row) => {
    setshowModalMap(true);
    setLatVisita(row.cob_lat);
    setLonVisita(row.cob_lon);
    sethoraMapa(row.cob_fecha_pago);
  };

  const openModalMap = async (row) => {
    setshowModalMap(true);

    setLatVisita(row.cdp_lat);
    setLonVisita(row.cdp_lon);
    sethoraMapa(row.cdp_fecha_actual_compromiso);
  };

  const openModalMapHistorial = async (row) => {
    setShowModalMapHistorial(true);
    setcurrentCarge(row);
    // await axios
    //   .post("https://apibajaj.curbe.com.ec/api/qlink/history", {
    //     id: row.carge_cedula,
    //     vin: row.carge_chasis,
    //     desde: "2020-11-05T07:00:52.486Z",
    //     hasta: "2020-11-05T07:00:52.486Z"
    //   })

    //   .then(function (response) {
    //     if (response.status === 200) {
    //       // console.log(response.data)
    //       setLtsUbicaciones(response.data)
    //       let coord = response.data.map((item, key) => {
    //         return {
    //           latitude: item.latitud,
    //           longitude: item.longitud,
    //         };
    //       });
    //       let lat = '';
    //       let long = '';
    //       coord.map((item, key) => {
    //         lat = item.latitude;
    //         long = item.longitude;
    //       });
    //       setLatVisita(lat)
    //       setLonVisita(long)

    //     } else {
    //       // showMessage("Intentelo de Nuevo", "error");
    //     }
    //   })
    //   .catch(function (error) { });
  };

  const openUbicacionCliente = (data) => {
    setShowModalMapQlink(true);

    setLatVisita(data.latitud);
    setLonVisita(data.longitud);
    setdireccionCli(data.direccion);
    setfechaCli(data.fecha);
    setmotorCli(data.motor);
    setvoltajeCli(data.voltage);
    setvelocidadCli(data.velocidad);
    setShowModalCargandoHistorial(false);
    console.log(latVisita);
  };

  const openModalMap2 = async (row) => {
    // setShowModalMapQlink(true);
    // console.log("https://apibajaj.curbe.com.ec/api/qlink/status/" + row.carge_cedula + "/" + row.carge_chasis)
    // var cedula = '13123'
    // var chasis = '123123'
    // setShowModalCargandoHistorial(true)

    await axios
      .get(
        "https://apibajaj.curbe.com.ec/api/qlink/status/" +
        row.carge_cedula +
        "/" +
        row.carge_chasis
      )
      .then(function (response) {
        // setperfiles(row);
        // console.log(response)
        if (response.status === 200) {
          // console.log(response);

          if (response.data != "Access denied") {
            console.log(response.data);
            // const dataCliente = JSON.stringify(response.data.data_cartera)
            // let coord = response.data.map((item, key) => {
            //   return {
            //     latitude: item.latitud,
            //     longitude: item.longitud,
            //   };
            // });
            // let lat = '';
            // let long = '';
            // coord.map((item, key) => {
            //   lat = item.latitude;
            //   long = item.longitude;
            // });
            openUbicacionCliente(response.data);

            // setLtsLocationHistorial(coord)
            // setLatVisita(response.data.latitud)
            // setLonVisita(response.data.longitud)

            console.log(latVisita);
          } else {
            showMessage("Este vehículo no tiene activado el GPS", "error");
            setShowModalMapQlink(false);
          }
          // console.log(dataCliente);
        } else {
        }
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const openModalMap3 = async (row) => {
    // setShowModalMapQlink(true);
    // console.log("https://apibajaj.curbe.com.ec/api/qlink/status/" + row.carge_cedula + "/" + row.carge_chasis)
    // var cedula = '13123'
    // var chasis = '123123'
    // setShowModalCargandoHistorial(true)

    openUbicacionCliente(row);
  };

  const updateDate = (date) => {
    setStartDate(date);
  };
  const updateDateHistorial = (date) => {
    setStartDateHistorial(date);
  };

  const updateLastDate = (date) => {
    setLastDate(date);
  };

  const openModalDirecciones = async (item) => {
    console.log(server + "api/cliente/getDirecionByCliCI/" + item.carge_cedula);

    // http://192.168.1.13:5001/api/cartera/getdetalleCartera/2
    setShowModalDirecciones(true);

    await axios
      .get(server + "api/cliente/getDirecionByCliCI/" + item.carge_cedula)
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        if (response.status === 200) {
          setLtsDirecciones(response.data);
          console.log(response.data);
          // const dataCliente = JSON.stringify(response.data.data_cartera)
          // setcurrentCartera(response.data.data_cartera);
          // setcurrentVehiculo(response.data.data_vehiculo);
          // setCurrentClienteCartera(response.data.data_cliente);
          // setcurrentFactura(response.data.numFactura);
          // setShowModalDetalleCartera(true);
          // console.log(dataCliente);
        } else {
        }
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const openModalCartera = async (item) => {
    // console.log(item);

    // http://192.168.1.13:5001/api/cartera/getdetalleCartera/2

    await axios
      .get(server + "api/cartera/getdetalleCartera/" + item.carge_id)
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        if (response.status === 200) {
          // console.log(response.data.data_cartera);
          // const dataCliente = JSON.stringify(response.data.data_cartera)
          setcurrentCartera(response.data.data_cartera);
          setcurrentVehiculo(response.data.data_vehiculo);
          setCurrentClienteCartera(response.data.data_cliente);
          setcurrentFactura(response.data.numFactura);
          setShowModalDetalleCartera(true);
          // console.log(dataCliente);
        } else {
        }
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const verFotosCliente = (row, index) => {
    return (
      <Button
        onClick={() => openModalFotosCliente(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon icon={faUserEdit} size="2x" />
      </Button>
    );
  };

  const verDetalles = (row, index) => {
    return (
      <Button
        onClick={() => openModalCartera(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon icon={faInfoCircle} size="2x" />
      </Button>
    );
  };

  const verDirecciones = (row, index) => {
    return (
      <Button
        onClick={() => openModalDirecciones(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon icon={faDirections} size="2x" />
      </Button>
    );
  };

  const verHistorialCartera = (row, index) => {
    return (
      <Button
        onClick={() => getHistorialCartera(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon icon={faHistory} size="2x" />
      </Button>
    );
  };

  const verCobros = (row, index) => {
    return (
      <Button
        onClick={() => getCobrosbyCliente(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon icon={faHandHoldingUsd} size="2x" />
      </Button>
    );
  };

  const verVisitas = (row, index) => {
    return (
      <Button
        onClick={() => getVisitasbyCliente(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon icon={faPenSquare} size="2x" />
      </Button>
    );
  };

  const verPagos = (row, index) => {
    return (
      <Button
        onClick={() => getPagosbyCliente(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon icon={faCalendarCheck} size="2x" />
      </Button>
    );
  };

  const getPagosbyCliente = async (row) => {
    setCurrentClienteCartera(row);
    setShowModalPagos(true);
    // console.log(server + "api/asignacionapp/getLstCompromisosbyAsignacion/" + row.carge_id)
    await axios
      .get(
        server +
        "api/asignacionapp/getLstCompromisosbyAsignacion/" +
        row.carge_id
      )
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        // console.log(response.data);
        setLtsPagos(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const openModalFotosCliente = async (row) => {
    // console.log(row)
    getFotosClienteServer(row.carge_cedula);
    setCurrentClienteCartera(row);
    setShowModalFotosCliente(true);
  };

  const getVisitasbyCliente = async (row) => {
    setCurrentClienteCartera(row);
    setShowModalVisitas(true);
    // console.log(row)
    await axios
      .get(
        server + "api/asignacionapp/getLstVisitasbyAsignacion/" + row.carge_id
      )
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        // console.log(response.data);
        setLtsVisitas(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getHistorialCartera = async (row) => {
    // setCurrentClienteCartera(row)
    setShowModalHistorial(true);
    setShowModalCargandoHistorial(true);

    await axios
      .get(server + "api/cartera/getHistorialCartera/" + row.carge_id)
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        console.log(response.data);
        setLtsHistorial(response.data);
        setShowModalCargandoHistorial(false);
      })
      .catch(function (error) { })
      .then(function () { });
    setShowModalCargandoHistorial(false);
  };

  const getCobrosbyCliente = async (row) => {
    setCurrentClienteCartera(row);
    setShowModalCobros(true);
    sethoraMapa(row.cob_fecha_pago);
    await axios
      .get(server + "api/asignacionapp/getLstCobrosbyCartera/" + row.carge_id)
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        console.log(response.data);
        setLtsCobros(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const CMap = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={16}
        defaultCenter={{ lat: latVisita, lng: lonVisita }}
      >
        {props.children}
      </GoogleMap>
    ))
  );

  const selectFileCedula = async (event) => {
    if (event.target.files[0]) {
      setURLCliCedula(URL.createObjectURL(event.target.files[0]));
      // setCliCedula(event.target.files[0]);
      const val = event.target.files[0];

      setltsImgCliente((ltsImgCliente) => {
        return { ...ltsImgCliente, cedula: val };
      });

      uploadFotosFB("cedula", val);
    }
  };

  const selectFileCliente = async (event) => {
    if (event.target.files[0]) {
      setURLimageG(URL.createObjectURL(event.target.files[0]));
      // setimageG(event.target.files[0]);
      // setltsImgCliente({ cliente: imageG })
      const val = event.target.files[0];
      setltsImgCliente((ltsImgCliente) => {
        return { ...ltsImgCliente, cliente: val };
      });
      uploadFotosFB("cliente", val);
    }
  };

  const selectFileMatricula = async (event) => {
    if (event.target.files[0]) {
      setURLCliMatricula(URL.createObjectURL(event.target.files[0]));
      // setCliMatricula(event.target.files[0]);
      // setltsImgCliente({ matricula: imgCliMatricula })
      const val = event.target.files[0];
      setltsImgCliente((ltsImgCliente) => {
        return { ...ltsImgCliente, matricula: val };
      });
      uploadFotosFB("matricula", val);
    }
  };

  const selectFileActa = async (event) => {
    if (event.target.files[0]) {
      setURLCliActaEntrega(URL.createObjectURL(event.target.files[0]));
      // setCliActaEntrega(event.target.files[0]);
      // setltsImgCliente({ acta: imgCliActaEntrega })
      const val = event.target.files[0];
      setltsImgCliente((ltsImgCliente) => {
        return { ...ltsImgCliente, acta: val };
      });
      uploadFotosFB("actaentrega", val);
    }
  };

  const selectFileCroquis = async (event) => {
    if (event.target.files[0]) {
      setURLCliCroquis(URL.createObjectURL(event.target.files[0]));
      // setCliCroquis(event.target.files[0]);
      // setltsImgCliente({ croquis: imgCliCroquis })
      const val = event.target.files[0];
      setltsImgCliente((ltsImgCliente) => {
        return { ...ltsImgCliente, croquis: val };
      });
      uploadFotosFB("croquis", val);
    }
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  //
  const getFotosClienteServer = async (row) => {
    await axios
      .get(server + "api/cliente/getClienteByCI/" + row)
      .then(function (response) {
        // console.log(response.data)
        if (
          response.data.cli_foto_cedula != "" ||
          response.data.cli_foto_cedula != null
        ) {
          // console.log('response.data.cli_foto_cedula')
          setURLCliCedula(response.data.cli_foto_cedula);
        }
        if (
          response.data.cli_foto_croquis != "" ||
          response.data.cli_foto_croquis != null
        ) {
          // console.log('response.data.cli_foto_matricula')
          setURLCliCroquis(response.data.cli_foto_croquis);
        }
        if (
          response.data.cli_foto_matricula != "" ||
          response.data.cli_foto_matricula != null
        ) {
          // console.log('response.data.cli_foto_matricula')
          setURLCliMatricula(response.data.cli_foto_matricula);
        }
        if (
          response.data.cli_foto_actaentrega != "" ||
          response.data.cli_foto_actaentrega != null
        ) {
          // console.log('response.data.cli_foto_actaentrega')
          setURLCliActaEntrega(response.data.cli_foto_actaentrega);
        }
        if (
          response.data.cli_foto_path != "" ||
          response.data.cli_foto_path != null
        ) {
          // console.log('response.data.cli_foto_path')
          setURLimageG(response.data.cli_foto_path);
        }

        // else if (tipoFoto == "cliente") {
        //   setimageG(url)
        // } else if (tipoFoto == "matricula") {
        //   setCliMatricula(url)
        // } else if (tipoFoto == "actaentrega") {
        //   setCliActaEntrega(url)
        // } else if (tipoFoto == "croquis") {
        //   setCliCroquis(url)
        // }
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const setFotosClienteServer = async () => {
    // console.log(ltsImgCliente)
    // for (var i = 0; i < 5; i++) {
    //   console.log(ltsImgCliente)
    // }
    // console.log(imgCliCedula)
    // console.log(imageG)

    if (
      urlimageG !== "" &&
      urlCliCedula != "" &&
      urlCliActaEntrega != "" &&
      urlCliCroquis != "" &&
      urlCliMatricula != ""
    ) {
      setShowModalLoadingData(true);
      await axios
        .post(server + "api/cliente/uploadFotos", {
          cli_foto_path: imageG,
          cli_foto_cedula: imgCliCedula,
          cli_foto_croquis: imgCliCroquis,
          cli_foto_matricula: imgCliMatricula,
          cli_foto_actaentrega: imgCliActaEntrega,
          cli_cedula: currentClienteCarte.carge_cedula,
        })
        .then(function (response) {
          if (response.status === 200) {
            setShowModalLoadingData(false);

            showMessage("Fotos subidas con éxito", "success");
            setltsImgCliente((ltsImgCliente) => {
              return {
                ...ltsImgCliente,
                cedula: "",
                croquis: "",
                matricula: "",
                acta: "",
                cliente: "",
              };
            });
            setCliCedula("");
            setCliCroquis("");
            setCliMatricula("");
            setCliActaEntrega("");
            setimageG("");
            setShowModalFotosCliente(false);
          } else {
            showMessage("Problemas al crear gestor", "error");
          }
        })
        .catch(function (error) {
          showMessage("Problemas al subir fotos", "error");
        });
    } else {
      showMessage("Debes agregar el todos los campos", "error");
    }
  };

  const filtrarporHoras = () => {
    let dateFiltrado = ltsUbicacionesreal.filter(
      (dato) =>
        Date.parse(dato.fecha) >= horaInicio && Date.parse(dato.fecha) < horaFin
    );

    getHistorialHoras(dateFiltrado);

    // ltsUbicaciones
    // fechastr
  };

  const getHistorialHoras = async (vector) => {
    setLtsUbicaciones(vector);
    let coord = vector.map((item, key) => {
      return {
        lat: item.latitud,
        lng: item.longitud,
      };
    });
    let lat = "";
    let long = "";
    coord.map((item, key) => {
      lat = item.lat;
      long = item.lng;
    });
    // console.log(coord)
    setLtsLocationHistorial(coord);
    setLatVisita(lat);
    setLonVisita(long);
  };

  const uploadFotosFB = async (tipoFoto, foto) => {
    //const ref = storage().ref().child('Bajaj/cartera/' + cartera.cliente.cli_cedula + '/cobros/' + idDate + '/').child(uid);
    console.log(currentClienteCarte.cli_cedula);
    if (foto !== "") {
      console.log("cargando foto...");
      // setIsLoadingGestor("visible");
      const uploadTask = storage
        .ref(`Bajaj/cartera/${currentClienteCarte.carge_cedula}/${tipoFoto}`)
        .put(foto);

      await uploadTask.on(
        "state_changed",
        (snapshot) => { },
        (error) => {
          console.log("erorrL L" + error);
        },
        () => {
          storage
            .ref("Bajaj/cartera/")
            .child(currentClienteCarte.carge_cedula + "/" + tipoFoto)
            .getDownloadURL()
            .then((url) => {
              console.log(url);
              // setImgGestor(url)
              // setGestor((gestor) => {
              //   return { ...gestor, ges_foto: url };
              // });
              console.log("foto cargada...");
              // setIsLoadingGestor("invisible");
              // createGestor(url);

              if (tipoFoto == "cedula") {
                setCliCedula(url);
              } else if (tipoFoto == "cliente") {
                setimageG(url);
              } else if (tipoFoto == "matricula") {
                setCliMatricula(url);
              } else if (tipoFoto == "actaentrega") {
                setCliActaEntrega(url);
              } else if (tipoFoto == "croquis") {
                setCliCroquis(url);
              }
            });
        }
      );
    } else {
      showMessage("Debes agregar el todos los campos", "error");
    }
  };

  const tipoPantalla = (tipo) => {
    if (tipo === 1) {
      return (
        <Col md="12" lg="12">
          <div>
            <Card>

              <Row>
                <Form.Label style={{ marginLeft: 32, marginTop: 8 }}>  FILTRAR POR: </Form.Label>
                <Col>
                  <Button style={{ marginLeft: 16, marginTop: 8 }} onClick={() => getMotosVentaConsig(0)} > Ventas </Button>
                  <Button style={{ marginLeft: 16, marginTop: 8 }} onClick={() => getMotosVentaConsig(2)} > Consignaciones </Button>
                </Col>

                <Col>

                </Col>
              </Row>


              <Card.Body>
                <ToolkitProvider
                  keyField="ci"
                  data={productsVenteConsig}
                  columns={columnsVenteConsig}
                  search
                >
                  {(props) => (
                    <div>
                      <Card
                        className="mt-3"
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      >
                        <Card.Header
                          as="h3"
                          style={{
                            backgroundColor: "rgba(236,236,236,0.9)",
                            color: "black",
                          }}
                        >
                          {tipoLista}
                        </Card.Header>
                        <Card.Body
                          style={{
                            backgroundColor: "rgba(236,236,236,0.6)",
                          }}
                        >
                          <Col className="mt-3"></Col>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            keyField="ci"
                            wrapperClasses="table-responsive"
                            class="align-middle"
                            filter={filterFactory()}
                            pagination={paginationFactory(optionsPagination)}
                            {...props.baseProps}
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </ToolkitProvider>
              </Card.Body>
            </Card>
          </div>
        </Col>
      );
    }


    else if (tipo === 2) {
      return (
        <Col md="12" lg="12">
          <div>
            <Card>
              <Card.Body>
                <ToolkitProvider
                  keyField="ci"
                  data={products}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <div>
                      <Card
                        className="mt-3"
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      >
                        <Card.Header
                          as="h3"
                          style={{
                            backgroundColor: "rgba(236,236,236,0.9)",
                            color: "black",
                          }}
                        >
                          Listado
                        </Card.Header>
                        <Card.Body
                          style={{
                            backgroundColor: "rgba(236,236,236,0.6)",
                          }}
                        >
                          <Col className="mt-3"></Col>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            keyField="ci"
                            wrapperClasses="table-responsive"
                            class="align-middle"
                            filter={filterFactory()}
                            pagination={paginationFactory(optionsPagination)}
                            {...props.baseProps}
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </ToolkitProvider>
              </Card.Body>
            </Card>
          </div>
        </Col>
      );
    }
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        {tipoPantalla(tipo)}
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
