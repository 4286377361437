import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Card,
  FormGroup,
  Form,
  Button,
  Spinner,
  Modal,
  Image
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import axios from "axios";
import Swal from "sweetalert2";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import * as firebase from "firebase";
// import * as SecureLS from "secure-ls";

function Content() {
  const storage = firebase.storage();
  const server = window.$linkserver;
  const [dataTable, setdataTable] = useState([]);
  // const [getRowsCiudades, setRowsCiudades] = useState([]);
  const [
    isLoadingtiporegistroCreada,
    setisLoadingtiporegistroCreada,
  ] = useState("invisible");
  const [isLoadingTable, setisLoadingTable] = useState("invisible");
  const [isLoadingActualizar] = useState("invisible");
  const [showModal, setshowModal] = useState(false);
  const [imageG, setimageG] = useState("");
  // const ls = new SecureLS({
  //   encodingType: "des",
  //   isCompression: false,
  //   encryptionSecret: "admin-hyundai",
  // });
  // const { infoUsuario } = ls.get("ask-hy");
  // const { data } = infoUsuario;

  const [tiporegistro, settiporegistro] = useState({
    tre_id: 0,
    tre_nombre: "",
    tre_descripcion: "",
    tre_valor: "",
    tre_titulo: "",
    tre_foto: "",
    tre_estado: 0
  });

  const [error, seterror] = useState({
    tre_nombre: "",
    tre_descripcion: "",
    tre_valor: "",
    tre_titulo: "",
    tre_foto: "",
  });

  const columns = [
    {
      dataField: "tre_id",
      text: "Código",
      sort: true,
      headerAlign: "center",
      formatter: txtFormatter,
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "75px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "tre_nombre",
      text: "Nombre",
      sort: true,
      headerAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "150px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "tre_titulo",
      text: "Título",
      sort: true,
      headerAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "150px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "tre_descripcion",
      text: "Descripción",
      sort: true,
      headerAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "250px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "tre_foto",
      text: "Foto",
      sort: true,
      headerAlign: "center",
      formatter: (cell, row, index) => fotoRegistroRow(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "200px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "tre_valor",
      text: "Valor",
      sort: true,
      headerAlign: "center",
      formatter: priceFormatter,
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "70px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "tre_editar",
      text: "Editar",
      headerAlign: "center",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  function priceFormatter(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "75px",
        }}
      >
        $ {cell}
      </Form.Label>
    );
  }

  function txtFormatter(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "75px",
        }}
      >
        {cell}
      </Form.Label>
    );
  }

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          edit(row, index);
        }}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon icon={faEdit} size="2x" />
      </Button>
    );
  };

  const edit = (row, index) => {
    settiporegistro((tiporegistro) => {
      return {
        ...tiporegistro,
        tre_id: row.tre_id,
        tre_nombre: row.tre_nombre,
        tre_descripcion: row.tre_descripcion,
        tre_titulo: row.tre_titulo,
        tre_valor: row.tre_valor,
        tre_foto: row.tre_foto,
        tre_estado: row.tre_estado
      };
    });

    setshowModal(true);
  };

  // const setDataCiudad = (data) => {
  //   const rows = data.map((row, index) => (
  //     <option key={row.cui_id} value={row.cui_id}>
  //       {row.cui_nombre}
  //     </option>
  //   ));
  //   setRowsCiudades(rows);
  // };

  const fotoRegistroRow = (row, index) => {
    return (
      <Image
        style={{ width: "250px", alignSelf: "center", justifyContent: "center", }}
        src={row.tre_foto ? row.tre_foto : "https://firebasestorage.googleapis.com/v0/b/proyecto-bajaj.appspot.com/o/Bajaj%2Fnoimg.jpg?alt=media&token=49b045a5-b8cf-46e7-b1a6-34521beb79df"}
      // roundedCircle
      />
    );
  };

  const selectFile = async (event) => {
    if (event.target.files[0]) {
      // uploadFotoFB(event.target.files[0])
      // setGestor((gestor) => {
      //   return { ...gestor, ges_foto: event.target.files[0] };
      // });
      setimageG(URL.createObjectURL(event.target.files[0]));
      // setFotoGestor(event.target.files[0]);
    }
    // setDocumentPath(URL.createObjectURL(event.target.files[0]));
    // setDocument(event.target.files[0]);
  };


  const gettiporegistros = async () => {
    setisLoadingTable("visible");

    await axios
      .get(server + "taller/getTipoRegistro")
      .then(function (response) {
        setisLoadingTable("invisible");
        setdataTable(response.data);
      })
      .catch(function (error) {
        setisLoadingTable("invisible");
      })
      .then(function () { });
  };

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });


  const selectFileRegistro = (event) => {
    // setbanderaMenu(true);
    setimageG(URL.createObjectURL(event.target.files[0]));
    const uploadTask = storage
      .ref(`Talleres/TipoRegistro/${tiporegistro.tre_nombre}`)
      .put(event.target.files[0]);
    uploadTask.on(
      "state_changed",
      (snapshot) => { },
      (error) => {
        process = false;
      },
      () => {
        storage
          .ref(`Talleres/TipoRegistro/${tiporegistro.tre_nombre}`)
          .getDownloadURL()
          .then((url) => {
            settiporegistro((tiporegistro) => {
              return { ...tiporegistro, tre_foto: url };
            });

            // setbanderaMenu(false);
          });
      }
    );
  };

  const createtiporegistro = async () => {
    seterror((error) => {
      return {
        ...error,
        tre_nombre: "",
        tre_descripcion: "",
        tre_valor: "",
        tre_titulo: "",
        tre_foto: "",
        
      };
    });

    console.log(tiporegistro);
    if (tiporegistro.tre_nombre !== "" && tiporegistro.tre_valor !== ""
      && tiporegistro.tre_titulo !== "" && tiporegistro.tre_descripcion !== "") {
      setisLoadingtiporegistroCreada("visible");
      await axios
        .post(server + "taller/createtiporegistro", {
          tre_nombre: tiporegistro.tre_nombre,
          tre_titulo: tiporegistro.tre_titulo,
          tre_descripcion: tiporegistro.tre_descripcion,
          tre_foto: tiporegistro.tre_foto,
          tre_valor: parseFloat(tiporegistro.tre_valor),
          tre_estado: 1
        })

        .then(function (response) {
          setisLoadingtiporegistroCreada("invisible");

          if (response.status === 200) {
            showMessage("Tipo Registro creada con éxito", "success");

            settiporegistro((tiporegistro) => {
              return {
                ...tiporegistro,
                tre_id: 0,
                tre_nombre: "",
                tre_descripcion: "",
                tre_valor: "",
                tre_titulo: "",
                tre_foto: "",
                tre_estado: 0
              };
            });

            gettiporegistros();

            //getData()
          } else {
            showMessage("Error el crear", "error");
          }
        })
        .catch(function (error) {
          console.log(error);
          showMessage("Error con el servidor", "error");
        });
    } else {
      if (tiporegistro.tre_nombre === "") {
        seterror((error) => {
          return { ...error, tre_nombre: "Campo requerido" };
        });
      }

      if (tiporegistro.tre_valor === "") {
        seterror((error) => {
          return { ...error, tre_valor: "Campo requerido" };
        });
      }

      if (tiporegistro.tre_titulo === "") {
        seterror((error) => {
          return { ...error, tre_titulo: "Campo requerido" };
        });
      }

      if (tiporegistro.tre_descripcion === "") {
        seterror((error) => {
          return { ...error, tre_descripcion: "Campo requerido" };
        });
      }

      // showMessage("Campos incompletos", "error");
    }
  };

  const updatetiporegistro = async () => {
    seterror((error) => {
      return {
        ...error,
        tre_nombre: "",
        tre_descripcion: "",
        tre_valor: "",
        tre_titulo: "",
        tre_foto: "",
      };
    });

    // console.log(tiporegistro.tre_id + " " +
    //   + " " + parseFloat(tiporegistro.tre_valor) + " " + tiporegistro.tre_titulo + " " +
    //   tiporegistro.tre_descripcion + " " + tiporegistro.tre_foto);

    if (tiporegistro.tre_valor !== "" && tiporegistro.tre_titulo !== "" && tiporegistro.tre_descripcion !== "") {
      setisLoadingtiporegistroCreada("visible");
      await axios

        .post(server + "taller/updateTipoRegistro", {
          tre_id: tiporegistro.tre_id,
          tre_valor: parseFloat(tiporegistro.tre_valor),
          tre_titulo: tiporegistro.tre_titulo,
          tre_descripcion: tiporegistro.tre_descripcion,
          tre_foto: tiporegistro.tre_foto,
          tre_nombre: tiporegistro.tre_nombre,
          tre_estado: tiporegistro.tre_estado
        })

        .then(function (response) {
          setisLoadingtiporegistroCreada("invisible");

          if (response.status === 200) {
            showMessage("Tipo Registro actualizada con éxito", "success");
            setimageG("")
            settiporegistro((tiporegistro) => {
              return {
                ...tiporegistro,
                tre_id: 0,
                tre_nombre: "",
                tre_valor: "",
                tre_descripcion: "",
                tre_titulo: "",
                tre_foto: "",
              };
            });

            setshowModal(false);

            gettiporegistros();
          } else {
            showMessage("Error el crear", "error");
          }
        })
        .catch(function (error) {
          showMessage("Campos incompletos", "error");
        });
    } else {
      if (tiporegistro.tre_descripcion === "") {
        seterror((error) => {
          return { ...error, tre_descripcion: "Campo requerido" };
        });
      }

      if (tiporegistro.tre_valor === "") {
        seterror((error) => {
          return { ...error, tre_valor: "Campo requerido" };
        });
      }

      if (tiporegistro.tre_titulo === "") {
        seterror((error) => {
          return { ...error, tre_titulo: "Campo requerido" };
        });
      }
      showMessage("Campos incompletos", "error");
    }
  };

  useEffect(() => {
    // console.log(server + "taller/getTipoRegistro");
    const gettiporegistrosInicial = async () => {
      setisLoadingTable("visible");
      await axios
        .get(server + "taller/getTipoRegistro")
        .then(function (response) {
          setisLoadingTable("invisible");
          setdataTable(response.data);
        })
        .catch(function (error) {
          setisLoadingTable("invisible");
        })
        .then(function () { });
    };

    gettiporegistrosInicial();
  }, [server]);

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle heading="Tipo Registro" icon="pe-7s-like2" />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              
              <div>

                <Card>
                  <div className="text-center">
                    <div
                      className={isLoadingtiporegistroCreada}
                      style={{ marginTop: 10 }}
                    >
                      <Spinner
                        animation="border"
                        role="status"
                        variant="secondary"
                      ></Spinner>
                    </div>
                  </div>

                  <Card.Header as="h5">
                    Creación de Nueva Tipo Registro{" "}
                  </Card.Header>
                 
                  <Card.Body>
                    <Form>
                      {/* <FormGroup as={Row}> */}

                      <FormGroup as={Row}>
                        <Col xs="auto">
                          <Form.Label className="title-create">
                            Foto de Registro:{" "}
                          </Form.Label>

                          {/* <div
                            style={{ display: "flex", justifyContent: "center" }}
                          > */}
                          <Col >
                            <img
                              style={{ width: 350, height: 200 }}
                              src={imageG}
                              alt=""
                            />
                            <FormGroup as={Col} >
                              <label className="btn btn-default" >
                                <input
                                  // md="auto"
                                  type="file"
                                  onChange={selectFileRegistro}
                                  onClick={(e) => (e.target.value = null)}
                                />
                              </label>
                            </FormGroup>
                          </Col>
                          {/* </div> */}

                        </Col>

                        {/* <FormGroup as={Row}> */}
                        <Form xs="lg">
                          <Col >
                            <Form.Label className="title-create">
                              Nombre:{" "}
                            </Form.Label>
                            <Form.Control

                              maxLength={50}
                              placeholder="Ingrese un Nombre"
                              id="tre_nombre"
                              value={tiporegistro.tre_nombre}
                              onChange={(e) => {
                                const val = e.target.value;
                                settiporegistro((tiporegistro) => {
                                  return { ...tiporegistro, tre_nombre: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {error.tre_nombre}
                            </span>
                          </Col>

                          <Col>
                            <Form.Label className="title-create">
                              Título:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              placeholder="Ingrese un título"
                              id="tre_titulo"
                              value={tiporegistro.tre_titulo}
                              onChange={(e) => {
                                const val = e.target.value;
                                settiporegistro((tiporegistro) => {
                                  return { ...tiporegistro, tre_titulo: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {error.tre_titulo}
                            </span>
                          </Col>

                          <Col>
                            <Form.Label className="title-create">
                              Valor:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              placeholder="Ingrese un valor"
                              id="tre_valor"
                              value={tiporegistro.tre_valor}
                              onChange={(e) => {
                                const val = e.target.value;
                                settiporegistro((tiporegistro) => {
                                  return { ...tiporegistro, tre_valor: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {error.tre_valor}
                            </span>
                          </Col>
                        </Form >
                        <Col >
                          <Form.Label className="title-create">
                            Descripción:{" "}
                          </Form.Label>
                          <Form.Control
                            as="textarea" rows={3}
                            style={{ height: 130 }}
                            maxLength={500}
                            placeholder="Ingrese una descripción"
                            id="tre_descripcion"
                            value={tiporegistro.tre_descripcion}
                            onChange={(e) => {
                              const val = e.target.value;
                              settiporegistro((tiporegistro) => {
                                return { ...tiporegistro, tre_descripcion: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {error.tre_descripcion}
                          </span>
                        </Col>
                      </FormGroup>



                      <div className="text-center">
                        <Button
                          onClick={() => {
                            createtiporegistro();
                          }}
                          variant="primary"
                        >
                          Crear un Tipo Registro
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </div>
              <div>
                <Card className="mt-2">
                  <Card.Header as="h5">
                    Listado general de "Tipo Registro"
                  </Card.Header>
                  <Card.Body>
                    <BootstrapTable
                      keyField="tre_id"
                      wrapperClasses="table-responsive"
                      data={dataTable}
                      columns={columns}
                      pagination={paginationFactory()}
                    />

                    <div className="text-center">
                      <div className={isLoadingTable} style={{ marginTop: 10 }}>
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                        ></Spinner>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>

              <div>
                <Modal
                  show={showModal}
                  // onHide={!showModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Actualización de datos de: {tiporegistro.tre_nombre}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="text-center">
                      <div className={isLoadingActualizar}>
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                        ></Spinner>
                      </div>
                    </div>

                    <div style={{ marginTop: 0 }}>

                      <Form>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Col xs="auto">
                            <Form.Label className="title-create">
                              Foto de Registro:{" "}
                            </Form.Label>

                            {/* <div
                            style={{ display: "flex", justifyContent: "center" }}
                          > */}
                            <Col >
                              <img
                                style={{ width: 350, height: 200 }}
                                src={tiporegistro.tre_foto ? tiporegistro.tre_foto : "https://firebasestorage.googleapis.com/v0/b/proyecto-bajaj.appspot.com/o/Bajaj%2Fnoimg.jpg?alt=media&token=49b045a5-b8cf-46e7-b1a6-34521beb79df"}
                                alt=""
                              />
                              <FormGroup as={Col} >
                                <label className="btn btn-default" >
                                  <input
                                    // md="auto"
                                    type="file"
                                    onChange={selectFileRegistro}
                                    onClick={(e) => (e.target.value = null)}
                                  />
                                </label>
                              </FormGroup>
                            </Col>


                          </Col>
                        </div>
                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Valor:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              placeholder="Ingrese un valor"
                              id="tre_valor"
                              value={tiporegistro.tre_valor}
                              onChange={(e) => {
                                const val = e.target.value;
                                settiporegistro((tiporegistro) => {
                                  return { ...tiporegistro, tre_valor: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {error.tre_valor}
                            </span>
                          </Col>
                          {/* </FormGroup>
                        <FormGroup as={Row}> */}
                          <Col>
                            <Form.Label className="title-create">
                              Título:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={500}
                              placeholder="Ingrese un título"
                              id="tre_titulo"
                              value={tiporegistro.tre_titulo}
                              onChange={(e) => {
                                const val = e.target.value;
                                settiporegistro((tiporegistro) => {
                                  return { ...tiporegistro, tre_titulo: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {error.tre_titulo}
                            </span>
                          </Col>
                        </FormGroup>
                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Descripción:{" "}
                            </Form.Label>
                            <Form.Control
                              as="textarea" rows={3}
                              style={{ height: 130 }}
                              maxLength={500}
                              placeholder="Ingrese una descripción"
                              id="tre_descripcion"
                              value={tiporegistro.tre_descripcion}
                              onChange={(e) => {
                                const val = e.target.value;
                                settiporegistro((tiporegistro) => {
                                  return { ...tiporegistro, tre_descripcion: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {error.tre_descripcion}
                            </span>
                          </Col>
                        </FormGroup>
                      </Form>
                    </div>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        updatetiporegistro();
                      }}
                    >
                      Actualizar
                    </Button>
                    <Button
                      onClick={() => {
                        setshowModal(false);

                        seterror((error) => {
                          return {
                            ...error,
                            tre_nombre: "",
                            tre_descripcion: "",
                            tre_valor: "",
                          };
                        });

                        settiporegistro((tiporegistro) => {
                          return {
                            ...tiporegistro,
                            tre_id: 0,
                            tre_nombre: "",
                            tre_descripcion: "",
                            tre_valor: "",
                          };
                        });
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
