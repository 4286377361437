import React, { Fragment, useState } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  // CSVExport,
} from "react-bootstrap-table2-toolkit";
import filterFactory, {
  selectFilter,
  numberFilter,
  textFilter,
  Comparator,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as moment from "moment";
import Swal from "sweetalert2";
import PageTitle from "../Layout/PageTitle";
import optionsPagination from "../../helper/SetupComponents";
// import Map from "../../componentes/screens/Map";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  // faCalendarCheck,
  // faPenSquare,
  // faHandHoldingUsd,
  faInfoCircle,
  // faMapMarkedAlt,
  faIdCardAlt,
  // faLocationArrow,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  Row,
  Col,
  Container,
  Card,
  // DropdownButton,
  // Dropdown,
  FormGroup,
  Form,
  Button,
  Modal,
  Image,
  Spinner
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
// registerLocale("es", es);
import "react-datepicker/dist/react-datepicker.css";
// import { ROW_SELECT_MULTIPLE } from "react-bootstrap-table-next";

registerLocale("es", es);

function Content() {

  const [products, setproducts] = useState([]);
  const [showModalCargando, setShowModalCargando] = useState(false);

  const server = window.$linkserver;
  // const [isLoading, setIsLoading] = useState("invisible");
  // const [nombreBanco, setNombreBanco] = useState("Banco del Austro");
  // const { SearchBar } = Search;
  const { SearchBar, ClearSearchButton } = Search;
  // const { ExportCSVButton } = CSVExport;
  const [modalShow, setModalShow] = React.useState(false);
  // const [cartera, setCartera] = useState();
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const [startDate, setStartDate] = useState(new Date(year, month, day - 1));
  const [lastDate, setLastDate] = useState(new Date());
  const columns = [
    // {
    //   dataField: "std_fecha_factura",
    //   text: "Fecha de factura",
    //   headerAlign: "center",
    //   footer: "",
    //   headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
    //   formatter: (cell, row, index) => fechaDesembolso(row, index),
    //   // formatter: (cell, row, index) => semaforoEstado(row, index),
    // },

    {
      dataField: "std_cliente",
      text: "Cliente",
      headerAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "250px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      filter: textFilter({
        placeholder: 'Nombre distribuidor'
      }),

      // formatter: (cell) => selectTipoCartera[cell],
      // filter: selectFilter({
      //   options: selectTipoCartera,
      //   withoutEmptyOption: false,
      //   placeholder: 'TODOS',
      //   style: {
      //     width: "100%",
      //     minWidth: "150px",
      //     alignItems: "center",
      //   },
      //   className: "test-classname",
      //   datamycustomattr: "datamycustomattr",
      // }),
      footer: "",
    },
    {
      dataField: "std_marca",
      text: "Marca",
      footer: "",
      headerAlign: "center",
      headerStyle: {
        textAlign: "center",
        minWidth: "150px",
        backgroundColor: "rgba(255, 255, 255,1)"
      },
      filter: textFilter({
        placeholder: 'Marca'
      }),
      formatter: fechaFormatter,
      // filter: textFilter({
      //   placeholder: '# cédula'
      // }),
      footer: ""
    },
    {
      dataField: "std_modelo",
      text: "Modelo",
      headerAlign: "center",
      footer: "",
      headerStyle: {
        textAlign: "center",
        minWidth: "190px", backgroundColor: "rgba(255, 255, 255,1)"
      },
      filter: textFilter({
        placeholder: 'Modelo'
      }),

      formatter: fechaFormatter,
      // filter: textFilter({
      //   placeholder: 'Nombres/Apellido'
      // }),
      footer: ""
    },
    {
      dataField: "std_anio",
      text: "Año",
      headerAlign: "center",
      footer: "",
      headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
      formatter: fechaFormatter,
      filter: textFilter({
        placeholder: 'Año'
      }),
      footer: ""
    },
    {
      dataField: "std_color",
      text: "Color",
      // formatter: priceFormatter,
      headerAlign: "center",
      footer: "",
      headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
      filter: textFilter({
        placeholder: 'Color'
      }),
      formatter: fechaFormatter,
      footer: ""
    },

    // {
    //   dataField: "detalle",
    //   text: "Cantidad",
    //   // formatter: (cell, row, index) => verDetalles(row, index),
    //   headerAlign: "center",
    //   footer: "",
    //   headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
    // },
    {
      dataField: "std_cantidad_activado",
      text: "Activados",
      footer: "",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "150px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      formatter: fechaFormatter,
      footer: (columnData) => "Activos: " + columnData.reduce((acc, item) => acc + item, 0),
    },

    {
      dataField: "std_cantidad_disponible",
      text: "Disponibles",
      footer: "",

      // filter: numberFilter({
      //   options: [30, 60, 90],
      //   // style: { display: 'inline-grid' },  // custom the style on number filter
      //   // className: 'custom-numberfilter-class',  // custom the class on number filter
      //   placeholder: 'Todo',
      //   defaultValue: { number: 1, comparator: Comparator.GT },
      // }),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "150px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      formatter: fechaFormatter,
      footer: (columnData) => "Disponibles: " + columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      dataField: "std_cantidad",
      text: "Total",
      footer: "",
      // filter: numberFilter({
      //   options: [30, 60, 90],
      //   // style: { display: 'inline-grid' },  // custom the style on number filter
      //   // className: 'custom-numberfilter-class',  // custom the class on number filter
      //   placeholder: 'Todo',
      //   defaultValue: { number: 1, comparator: Comparator.GT },
      // }),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "150px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      formatter: fechaFormatter,
      footer: (columnData) => "Total: " + columnData.reduce((acc, item) => acc + item, 0),
    }
  ];


  const getAllGestores = (row, index) => {
    return (
      <Button
        // onClick={() => openModalGestores(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon
          icon={faIdCardAlt}
          size="2x"
          textAlign="center"
          style={{ alignSelf: "center", justifyContent: "center" }}
        />
      </Button>
    );
  };


  const getFechaGestorCartera = async (inicio, fin) => {
    // console.log(server + "api/inventario/getListStockConsolidado/" + inicio + "/" + fin);
    setShowModalCargando(true)
    await axios
      .get(server + "api/inventario/getListStockConsolidado/" + inicio + "/" + fin)
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        // console.log(response.data.size);
        setproducts(response.data);
        setShowModalCargando(false)
      })
      .catch(function (error) { })
      .then(function () { });
  };

  function fechaFormatter(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "100px",
        }}
      >
        {cell}
      </Form.Label>
    );
  }

  function fechaDesembolso(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        { moment(cell.std_fecha_factura).format("DD-MM-YYYY")}
      </Form.Label>
    );
  }

  const updateDate = (date) => {
    setStartDate(date);
  };

  const updateLastDate = (date) => {
    setLastDate(date);
  };

  function priceFormatter(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "100px",
        }}
      >
        $ {cell}
      </Form.Label>
    );
  }

  const getAll = async () => {
    // console.log(server + "api/inventario/getListStockDistribuidor/" + inicio + "/" + fin);
    setShowModalCargando(true)
    await axios
      .get(server + "api/inventario/getAllListStockConsolidado")
      .then(function (response) {
        setShowModalCargando(false)
        setproducts(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };



  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>

          <Modal
            show={showModalCargando}
            onHide={() => setShowModalCargando(false)}
            centered
          >
            <Modal.Body class="text-center">
              <p></p>
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
              <p class="text-center">
                Cargando datos, espere por favor...
            </p>
            </Modal.Body>
          </Modal>


          <PageTitle
            heading="Inventario/ Consultas"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          <Container fluid={true} className="content-body">
            <Row>
              <Col md="12" lg="12">
                <ToolkitProvider
                  keyField="id"
                  data={products}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <div>
                      <Card
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      >
                        <Card.Header
                          as="h3"
                          style={{
                            backgroundColor: "rgba(236,236,236,0.6)",
                            color: "black",
                          }}
                        >
                          Parámetros de busqueda para stock
                        </Card.Header>
                        <Card.Body
                          style={{
                            backgroundColor: "rgba(236,236,236,0.3)",
                          }}
                        >
                          {/* <Container> */}


                          <Row>
                            <Col className="align-middle" md="auto">
                              Fecha inicial
                            </Col>
                            <Col md="auto">
                              <DatePicker
                                locale="es"
                                selected={startDate}
                                dateFormat="dd/MM/yyyy"
                                onChange={updateDate}
                              />
                            </Col>

                            <Col className="align-middle" md="auto">
                              Fecha final
                            </Col>
                            <Col md="auto">
                              <DatePicker
                                locale="es"
                                dateFormat="dd/MM/yyyy"
                                selected={lastDate}
                                onChange={updateLastDate}
                              />
                            </Col>
                            <Col>
                              <Button
                                onClick={() =>
                                  getFechaGestorCartera(
                                    moment(startDate).format("yyyy-MM-DD"),
                                    moment(lastDate).format("yyyy-MM-DD")
                                  )
                                }
                              >
                                Buscar por fecha
                              </Button>

                              <Button
                                style={{ marginLeft: 16 }}
                                onClick={() =>
                                  getAll()
                                }
                              >
                                Buscar todo
                              </Button>
                            </Col>
                          </Row>
                          {/* </Container> */}
                        </Card.Body>
                        {/* {products.length > 0 ? (
                          <Card.Footer
                            style={{
                              backgroundColor: "rgba(236,236,236,0.3)",
                            }}
                          >
                            <Col className="align-middle" md="auto">
                              Buscar cliente:
                            </Col>
                            <Col md="auto">
                              <SearchBar
                                class="align-middle"
                                placeholder="Ingrese cédula"
                                style={{ height: 25, width: 150 }}
                                {...props.searchProps}
                              />
                            </Col>
                            <Col className="align-middle" md="auto">
                              Buscar gestor:
                            </Col>
                            <Col md="auto">
                              <SearchBar
                                class="align-middle"
                                placeholder="Ingrese nombre"
                                style={{ height: 25, width: 150 }}
                                {...props.searchProps}
                              />
                            </Col>
                          </Card.Footer>
                        ) : null} */}
                      </Card>
                      <Card
                        className="mt-3"
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      >
                        <Card.Header
                          as="h3"
                          style={{
                            backgroundColor: "rgba(236,236,236,0.9)",
                            color: "black",
                          }}
                        >
                          Listado general
                        </Card.Header>
                        <Card.Body
                          style={{
                            backgroundColor: "rgba(236,236,236,0.6)",
                          }}
                        >
                          <Form>
                            {/* <Col> */}

                          </Form>
                          <Col className="mt-3"></Col>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            wrapperClasses="table-responsive"
                            // keyField="ci"
                            // class="align-middle"
                            filter={filterFactory()}
                            pagination={paginationFactory(optionsPagination)}
                            {...props.baseProps}
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </Container>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
