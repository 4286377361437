import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import ToolkitProvider, {
  Search,
  // CSVExport,
} from "react-bootstrap-table2-toolkit";
import {
  Row,
  Col,
  Modal,
  FormGroup,
  Form,
  Card,
  Tabs,
  Tab,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { Tooltip } from "primereact/tooltip";
import filterFactory, {
  selectFilter,
  numberFilter,
  Comparator,
  textFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import optionsPagination from "../../helper/SetupComponents";
import axios from "axios";
import Swal from "sweetalert2";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faScrewdriver,
  faSearch,
  faHandshake,
  faMoneyBill,
  faThumbsUp,
  faWallet,
  faExclamationTriangle,
  faPiggyBank,
  faHandHoldingUsd,
  faMotorcycle,
  faNetworkWired,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";

import * as SecureLS from "secure-ls";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Button } from "primereact/button";
import { Spin } from "react-loading-io";
import { Chart } from "primereact/chart";
import DatePicker, { registerLocale } from "react-datepicker";
import { locale, addLocale } from "primereact/api";

import * as moment from "moment";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { useHistory, Link } from "react-router-dom";
import ReactExport from "react-export-excel";
import { Calendar } from "primereact/calendar";

function Content(props) {
  const server = window.$linkserver;
  const { SearchBar } = Search;
  const [dataTable, setdataTable] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [postventa, setpostventa] = useState({});
  const [showtallerRegistro, setshowtallerRegistro] = useState(false);
  const [showtallerModelos, setshowtallerModelos] = useState(false);
  
  const [datosLinea, setdatosLinea] = useState({});
  const [datosModeloGarantia, setdatosModeloGarantia] = useState({});
  const [datosCarteraCobro, setdatosCarteraCobro] = useState({});
  const [datosModeloInventario, setdatosModeloInventario] = useState({});
  const [
    datosModeloInventarioTienda,
    setdatosModeloInventarioTienda,
  ] = useState({});
  const [tipoDas, settipoDas] = useState(1);
  const [datosTallerRegistro, setdatosTallerRegistro] = useState({});
  const [datosVisitasCobros, setdatosVisitasCobros] = useState({});
  const [datosDetalleGrupo, setdatosDetalleGrupo] = useState({});
  const [datosDetalleGrupoTienda, setdatosDetalleGrupoTienda] = useState({});

  const [datosCartera, setdatosCartera] = useState({});
  const [index, setIndex] = useState();
  const [lastDate, setLastDate] = useState(new Date());
  const history = useHistory();
  const [date, setdate] = useState(new Date());
  const [dateCobro, setdateCobro] = useState(new Date());
  const [dateCobroA, setdateCobroA] = useState(new Date());
  const [date2, setdate2] = useState(new Date());
  const [key, setKey] = useState("Inventario");
  const [showModalUsoRepuestos, setShowModalUsoRepuestos] = useState(false);
  const [showModalGestorAsignacion, setShowModalGestorAsignacion] = useState(
    false
  );
  const [showModalMotosPiso, setShowModalMotosPiso] = useState(false);
  const [ltsUsoRepuestos, setltsUsoRepuestos] = useState([]);
  const [ltsGestorAsignacion, setltsGestorAsignacion] = useState([]);
  const [ltsMotosPiso, setltsMotosPiso] = useState([]);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const month2 = date2.getMonth();
  const basicData = {
    labels: ["FONDO DE GARANTIA", "CARTERA BANCO"],
    datasets: [
      {
        label: "SOLIDARIO",
        backgroundColor: "#00b5ca",
        data: [1159, 2477],
      },
      {
        label: "AUSTRO",
        backgroundColor: "#171d5c",
        data: [7, 203],
      },
    ],
  };

  const basicData2 = {
    labels: ["Cancelado", "Vigente", "NORMAL DIFERIDA", "Vencido", "ORIGINAL"],
    datasets: [
      {
        label: "SOLIDARIO",
        backgroundColor: "#00b5ca",
        data: [95, 1677, 0, 1864, 0],
      },
      {
        label: "AUSTRO",
        backgroundColor: "#171d5c",
        data: [0, 0, 82, 0, 128],
      },
    ],
  };

  const columnsUsoRepuestos = [
    // {
    //   dataField: "gestor",
    //   text: "Gestor",
    //   headerAlign: "center",
    //   headerStyle: { backgroundColor: "rgba(255, 255, 255, 1)" },
    //   formatter: (cell, row, index) => infoGestor(row, index),
    // },
    // {
    //   dataField: "ccli_cedula",
    //   text: "Cédula",
    // },
    {
      dataField: "taller",
      // text: "Repuestos",
      filter: textFilter({
        placeholder: "Nombre del Taller",
      }),
      footer: "",
      // formatter: (cell, row, index) => valorcobro(row, index),
    },
    {
      dataField: "nombre",
      // text: "Repuestos",
      filter: textFilter({
        placeholder: "Nombre del Repuesto",
      }),
      footer: "",
      // formatter: (cell, row, index) => valorcobro(row, index),
    },
    {
      dataField: "cantidad",
      text: "Cantidad",
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
      // formatter: (cell, row, index) => tipoCobro(row, index),
    },
    {
      dataField: "precio",
      text: "Precio",
      footer: (columnData) =>
        "$" + columnData.reduce((acc, item) => acc + item, 0),
      // formatter: (cell, row, index) => fechaCobroDetalle(row, index),
    },
    {
      dataField: "total",
      text: "Total",
      footer: (columnData) =>
        "$" + columnData.reduce((acc, item) => acc + item, 0),
      // formatter: (cell, row, index) => fechaCobroDetalle(row, index),
    },
  ];

  const columnsGestorAsignacion = [
    // {
    //   dataField: "gestor",
    //   text: "Gestor",
    //   headerAlign: "center",
    //   headerStyle: { backgroundColor: "rgba(255, 255, 255, 1)" },
    //   formatter: (cell, row, index) => infoGestor(row, index),
    // },
    // {
    //   dataField: "ccli_cedula",
    //   text: "Cédula",
    // },
    {
      dataField: "svg_gestor",
      // text: "Repuestos",
      filter: textFilter({
        placeholder: "Nombre del Gestor",
      }),
      // formatter: (cell, row, index) => valorcobro(row, index),
    },
    {
      dataField: "svg_num_clientes",
      text: "Número de clientes",
      // formatter: (cell, row, index) => tipoCobro(row, index),
    },
    {
      dataField: "svg_saldo_vencido",
      text: "Saldo vencido",
      formatter: (cell, row, index) => saldo(cell, index),
      // formatter: (cell, row, index) => fechaCobroDetalle(row, index),
    },
    {
      dataField: "svg_rango_90",
      text: "90-120",
      formatter: (cell, row, index) => saldo(cell, index),
    },
    {
      dataField: "svg_rango_120",
      text: "120-180",
      formatter: (cell, row, index) => saldo(cell, index),
    },
    {
      dataField: "svg_rango_180",
      text: "180-360",
      formatter: (cell, row, index) => saldo(cell, index),
    },
    {
      dataField: "svg_rango_360",
      text: "360-1080",
      formatter: (cell, row, index) => saldo(cell, index),
    },
    {
      dataField: "svg_rango_1080",
      text: "Más de 1080",
      formatter: (cell, row, index) => saldo(cell, index),
    },
  ];

  const columnsMotosPiso = [
    // {
    //   dataField: "gestor",
    //   text: "Gestor",
    //   headerAlign: "center",
    //   headerStyle: { backgroundColor: "rgba(255, 255, 255, 1)" },
    //   formatter: (cell, row, index) => infoGestor(row, index),
    // },
    // {
    //   dataField: "ccli_cedula",
    //   text: "Cédula",
    // },
    {
      dataField: "cli_nombres",
      // text: "Repuestos",
      filter: textFilter({
        placeholder: "Nombre del Distribuidor",
      }),
      // formatter: (cell, row, index) => valorcobro(row, index),
    },
    {
      dataField: "veh_modelo",
      // text: "Modelo",
      filter: textFilter({
        placeholder: "Modelo",
      }),
      // formatter: (cell, row, index) => tipoCobro(row, index),
    },
    {
      dataField: "total",
      text: "Total",
      // formatter: (cell, row, index) => saldo(cell, index),
      // formatter: (cell, row, index) => fechaCobroDetalle(row, index),
    },
    {
      dataField: "dias",
      text: "Promedio días piso",
      // formatter: (cell, row, index) => saldo(cell, index),
    },
  ];

  const saldo = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "100px",
        }}
      >
        {formatter.format(row)}
      </Form.Label>
    );
  };
  const valorsaldo = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "100px",
        }}
      >
        ${row.svg_saldo_vencido}
      </Form.Label>
    );
  };

  const [startDate, setStartDate] = useState(
    new Date(year, month, day - day + 1)
  );
  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  const [lstPedidos, setlstPedidos] = useState([
    {
      name: "Compromiso de Pago",
      estado: 0,
      img: faHandshake,
      color: "#5bc88d",
      colorIcono: "white",
      data: 0,
    },
    {
      name: "Pagos Efectuados",
      estado: 0,
      img: faMoneyBill,
      color: "#d9a54a",
      colorIcono: "white",
      data: 0,
    },
  ]);

  const [ltsDashBoarSolidario, setltsDashBoarSolidario] = useState([
    {
      name: "CBS",
      estado: 0,
      img: faWallet,
      colortext: "black",
      color: "#00b5ca",
      colorIcono: "white",
      data: 0,
      tipo: 2,
      click: () => clickCartera(1, new Date(), "Cartera Solidario", "solidario", "CARTERA BANCO"),// clickCartera(1, new Date()),
      titulo: "Cartera Banco - Banco Solidario",
      descripcion: "",
      tipoBanco: "solidario"
    },
    {
      name: "Cartera Vencida",
      estado: 0,
      img: faExclamationTriangle,
      colortext: "black",
      color: "#00b5ca",
      colorIcono: "white",
      data: 0,
      tipo: 2,
      click: () => clickCartera(2, new Date(), "Cartera VENCIDA", "solidario", "CARTERA VENCIDA"),//clickCartera(2, new Date()),
      titulo: "Cartera VENCIDA - Banco Solidario",
      descripcion: "",
      tipoBanco: "solidario",
    },
    {
      name: "Cartera por Vencer",
      estado: 0,
      img: faExclamationTriangle,
      colortext: "black",
      color: "#00b5ca",
      colorIcono: "white",
      data: 0,
      tipo: 2,
      click: () => clickCartera(5, new Date(), "Cartera Solidario", "solidario", "CARTERA"),//clickCartera(2, new Date()),
      titulo: "Cartera por Vencer- Banco Solidario",
      descripcion: "",
      tipoBanco: "solidario",
    },

    {
      name: "FGS",
      estado: 0,
      colortext: "black",
      img: faPiggyBank,
      color: "#00b5ca",
      colorIcono: "white",
      data: 0,
      tipo: 2,
      click: () => clickCartera(3, new Date(), "Cartera Solidario", "solidario", "FONDO DE GARANTIA"),//clickCartera(1, new Date()),
      titulo: "Fondo de Garantia - Banco Solidario",
      descripcion: "",
      tipoBanco: "solidario",
    },
    {
      name: "Cobros",
      estado: 0,
      colortext: "black",
      img: faHandHoldingUsd,
      color: "#00b5ca",
      colorIcono: "white",
      tipo: 2,
      data: 0,
      click: () => clickCartera(4, dateCobro, "Cobros Solidario", "SOLIDARIO"),
      titulo: "",
      descripcion: "",
      tipoBanco: "solidario",
    },
  ]);

  const [ltsDashBoarAustro, setltsDashBoarAustro] = useState([
    {
      name: "CBA",
      estado: 0,
      colortext: "white",
      img: faWallet,
      color: "#1d2473",
      colorIcono: "white",
      data: 0,
      tipo: 2,
      click: () => clickCartera(1, new Date(), "Cartera Austro", "austro", "CARTERA BANCO"),//clickCartera(1, new Date()),
      titulo: "Cartera Banco - Banco del Austro",
      descripcion: "",
      tipoBanco: "austro",
    },
    {
      name: "Cartera Vencida",
      estado: 0,
      colortext: "white",
      img: faExclamationTriangle,
      color: "#1d2473",
      colorIcono: "white",
      data: 0,
      tipo: 2,
      click: () => clickCartera(2, new Date(), "Cartera VENCIDA", "austro", "CARTERA VENCIDA"),//clickCartera(2, new Date()),
      titulo: "Cartera Vencida - Banco de Austro",
      descripcion: "",
      tipoBanco: "austro"
    },
    {
      name: "Cartera por Vencer",
      estado: 0,
      img: faExclamationTriangle,
      colortext: "white",
      color: "#1d2473",
      colorIcono: "white",
      data: 0,
      tipo: 2,
      click: () => clickCartera(5, new Date(), "Cartera Austro", "austro", "CARTERA"),//clickCartera(2, new Date()),
      titulo: "Cartera - Banco Austro",
      descripcion: "",
      tipoBanco: "austro",
    },
    {
      name: "FGA",
      estado: 0,
      colortext: "white",
      img: faPiggyBank,
      color: "#1d2473",
      colorIcono: "white",
      data: 0,
      tipo: 2,
      click: () => clickCartera(3, new Date(), "Cartera Austro", "austro", "FONDO DE GARANTIA"),//clickCartera(1, new Date()),
      titulo: "Fondo de Garantia - Banco Austro",
      descripcion: "",
      tipoBanco: "solidario"
    },
    {
      name: "Cobros",
      estado: 0,
      colortext: "white",
      img: faHandHoldingUsd,
      color: "#1d2473",
      colorIcono: "white",
      data: 0,
      tipo: 2,
      click: () => clickCartera(4, dateCobroA, "Cobros Austro", "AUSTRO"),
      titulo: "",
      descripcion: "",
      tipoBanco: "austro",
    },
  ]);

  const clickPostVenta = (tipo, fecha, nombre) => {
    const datos = {
      tipo: tipo,
      mes: fecha.getMonth() + 1,
      anio: fecha.getFullYear(),
      nombre: nombre,
    };

    history.push({
      pathname: "/postventa",
      data: datos,
    });
  };

  const clickCartera = (tipo, fecha, nombre, banco, tipoCartera) => {
    // console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - -');
    // console.log(fecha);
    // console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - -');
    // console.log(dateCobro);
    // console.log('- - - - - - - - - - - - - - - - - - - - - - - - - - - -');
    // console.log(dateCobroA);
    const datos = {
      tipo: tipo,
      mes: fecha.getMonth() + 1,
      anio: fecha.getFullYear(),
      nombre: nombre,
      banco: banco,
      tipoCartera: tipoCartera
    };

    history.push({
      pathname: "/cartera",
      data: datos,
    });
  };

  const clickInventario = (tipo, idCliente) => {
    const datos = {
      tipo: tipo,
      // mes: fecha.getMonth() + 1,
      // anio: fecha.getFullYear(),
      // nombre: nombre,
      idCliente: idCliente,
    };

    history.push({
      pathname: "/inventario",
      data: datos,
    });
  };

  const [ltsDashTaller, setltsDashTaller] = useState([
    {
      name: "PDI",
      estado: 0,
      img: faSearch,
      color: "#B3D1EA",
      colorIcono: "white",
      data: 0,
      data1: 0,
      click: () => clickPostVenta(1, new Date()),
      titulo: "PDI",
      descripcion: "PDI realizados en los talleres en el mes en curso",
    },
    {
      name: "MAN: 500KM",
      estado: 0,
      img: faScrewdriver,
      color: "#FFD565",
      colorIcono: "white",
      data: 0,
      data1: 0,
      click: () => clickPostVenta(2, new Date()),
      titulo: "Mantenimiento de 500KM",
      descripcion:
        "Mantnimientos realizados en los talleres en el mes en curso",
    },
    {
      name: "SIN MAN",
      estado: 0,
      img: faScrewdriver,
      color: "#A5A0A0",
      colorIcono: "white",
      data: 0,
      data1: 0,
      click: () => clickPostVenta(3, new Date()),
      titulo: "Sin Mantenimiento",
      descripcion:
        "Todas las motos que se realizaron PDI pero no tubieron un mantenimiento de Mes Anterior (Ant) y Mes Actual(Act)",
    },
    {
      name: "GARANTIAS",
      estado: 0,
      img: faThumbsUp,
      color: "#BBDCAD",
      colorIcono: "white",
      data: 0,
      data1: 0,
      click: () => clickPostVenta(4, new Date()),
      titulo: "Garantias",
      descripcion:
        "Garantias realizadas en el mes en curso aprobadas y rechazadas ",
    },
  ]);

  const [ltsDashInvetario, setltsDashInvetario] = useState([
    {
      name: "Total Motos Distr",
      estado: 0,
      img: faMotorcycle,
      colortext: "black",
      color: "#B3D1EA",
      colorIcono: "white",
      tipo: 1,
      data: 0,
      data1: 0,
      click: () => clickInventario(1, 0),
      titulo: "Total Motos en los Distribuidores",
      descripcion:
        "El total de todas las motos en venta y en consignación de nuestros distribuidores",
    },
    {
      name: "Total Distribuidores",
      estado: 0,
      img: faNetworkWired,
      colortext: "black",
      color: "#FFD565",
      colorIcono: "white",
      tipo: 1,
      data: 0,
      data1: 0,
      click: () => clickInventario(2, 2),
      titulo: "Total de nuestros distribuidores",
      descripcion:
        "La cantidad de distribuidores que tiene nuestras motos (CM) y distribuidores que no tienen motos (SM)",
    },
    {
      name: "Total Motos Tienda",
      estado: 0,
      img: faMotorcycle,
      colortext: "black",
      color: "#FFD565",
      colorIcono: "white",
      tipo: 1,
      data: 0,
      data1: 0,
      click: () => clickInventario(2, 5),
      titulo: "",
      descripcion: "",
    },
    {
      name: "Días Piso",
      estado: 0,
      colortext: "black",
      img: faWarehouse,
      color: "#BBDCAD",
      colorIcono: "white",
      tipo: 1,
      data: 0,
      data1: 0,
      click: () => console.log(""),
      titulo: "",
      descripcion: "",
    },
  ]);

  const [error, seterror] = useState({
    nombre: "",
    descripcion: "",
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  useEffect(() => {
    addLocale("es", {
      firstDayOfWeek: 1,
      dayNames: [
        "domingo",
        "lunes",
        "martes",
        "miércoles",
        "jueves",
        "viernes",
        "sábado",
      ],
      dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
      dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
      monthNames: [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ],
      monthNamesShort: [
        "ene",
        "feb",
        "mar",
        "abr",
        "may",
        "jun",
        "jul",
        "ago",
        "sep",
        "oct",
        "nov",
        "dic",
      ],
      today: "Hoy",
      clear: "Claro",
    });

    locale("es");

    if (data.rol_id === 1) {
      settipoDas(1);
      metodoCartera(month, year, date);
      metodosTaller(month, year, date);

      metodoInventario();
    } else if (data.rol_id === 2) {
      settipoDas(3);
      metodoCartera(month, year, date);
    } else if (data.rol_id === 3) {
      settipoDas(2);

      metodosTaller(month, year, date);
    } else if (data.rol_id === 4) {
      settipoDas(4);

      metodoInventario();
    }
  }, []);

  const getCobrosByFecha = (mes, anio, tipo) => {
    if (tipo == "austro") {
      getCobrosAustroCartera(mes + 1, anio);
    } else if (tipo == 'solidario') {
      getCobrosSolidarioCartera(mes + 1, anio)
    }


  }

  const cambioFecha = (mes, anio, fecha) => {
    setdate(fecha);

    if (data.rol_id === 1) {
      metodosTaller(mes, anio, fecha);
    } else if (data.rol_id === 3) {
      metodosTaller(mes, anio);
    }
  };

  const metodosTaller = (mes, anio, fecha) => {
    let newArr = [...ltsDashTaller];
    newArr[0].click = () => clickPostVenta(1, fecha, "Registro PDI");
    newArr[1].click = () =>
      clickPostVenta(2, fecha, "Registro Mantenimiento 500 kM");
    newArr[2].click = () => clickPostVenta(3, fecha, "Sin Mantenimiento");
    newArr[3].click = () => clickPostVenta(4, fecha, "Garantias");
    setltsDashTaller(newArr);

    getMotosGrantiasTaller(mes + 1, anio);
    getTalleRegistroTaller(mes + 1, anio);
    getPDITaller(mes + 1, anio);
    getManKilometrajeTaller(mes + 1, anio);
    getGarantiasTaller(mes + 1, anio);
    getSinMantenimientoTaller(mes + 1, anio);
    getDetalleUsoRepuestos(mes + 1, anio);
  };

  const metodoCartera = (mes, anio, fecha) => {
    console.log("metodo carteraaaaa");
    let newArr = [...ltsDashBoarSolidario];
    newArr[0].click = () => clickCartera(1, new Date(), "Cartera Banco Solidario", "solidario", "CARTERA BANCO")
    newArr[1].click = () => clickCartera(3, new Date(), "Cartera Solidario", "solidario", "FONDO DE GARANTIA")
    newArr[2].click = () => clickCartera(2, new Date(), "Cartera VENCIDA", "solidario", "CARTERA VENCIDA")
    newArr[3].click = () => clickCartera(5, new Date(), "Cartera Solidario", "solidario", "CARTERA")
    newArr[4].click = () => clickCartera(4, dateCobro, "Cobros Solidario", "SOLIDARIO");
    setltsDashBoarSolidario(newArr);

    let newArr2 = [...ltsDashBoarAustro];
    newArr2[0].click = () => clickCartera(1, fecha, "Cartera Banco Austro", "austro", "CARTERA BANCO");
    newArr2[1].click = () => clickCartera(3, fecha, "Fondo Garantia Austro", "austro", "FONDO DE GARANTIA");
    newArr2[2].click = () => clickCartera(2, fecha, "Cartera Vencida", "austro", "CARTERA VENCIDA");
    newArr2[3].click = () => clickCartera(5, new Date(), "Cartera Austro", "austro", "CARTERA");
    newArr2[4].click = () => clickCartera(4, dateCobroA, "Cobros Austro", "AUSTRO");
    setltsDashBoarAustro(newArr2);

    geSumaTipoCarteraByBanco('solidario', 'cartera', mes + 1, anio)
    geSumaTipoCarteraByBanco('solidario', 'fondo', mes + 1, anio)
    getSCSSolidarioCartera(mes + 1, anio);
    getCarteraVencidaSolidarioCartera(mes + 1, anio);
    getCobrosSolidarioCartera(mes + 1, anio);
    // geSumaTipoCarteraByBanco('austro', 'cartera' mes + 1, anio)

    // getSCSSolidarioCartera(mes + 1, anio);

    // getSFGSSolidarioCartera(mes + 1, anio);

    geSumaTipoCarteraByBanco('austro', 'cartera', mes + 1, anio)
    geSumaTipoCarteraByBanco('austro', 'fondo', mes + 1, anio)

    getSCSAustroCartera(mes + 1, anio);
    getCarteraVencidaAustroCartera(mes + 1, anio);
    // getSFGSAustroCartera(mes + 1, anio);
    getCobrosAustroCartera(mes + 1, anio);
    getAnalisiVisitasCobrosCartera(mes + 1, anio);
    getDetallesCobros(mes + 1, anio);

    if (month2 === mes) {
      getReporteVistasDias(
        moment(startDate).format("yyyy-MM-DD"),
        moment(lastDate).format("yyyy-MM-DD")
      );
    } else {
      var inicioFecha = new Date();
      inicioFecha.setFullYear(anio, mes, 1);
      inicioFecha.setHours(0);
      inicioFecha.setMinutes(0);
      inicioFecha.setSeconds(0);

      var finFecha = new Date();
      finFecha.setFullYear(anio, mes + 1, 0);
      finFecha.setHours(23);
      finFecha.setMinutes(59);
      finFecha.setSeconds(59);

      getReporteVistasDias(
        moment(inicioFecha).format("yyyy-MM-DD"),
        moment(finFecha).format("yyyy-MM-DD")
      );
    }
  };

  const metodoInventario = () => {
    getMotosInventario();
    getDistribuidoresInventario();
    getMotosTienda();
    getDiasPisoInventario();
    getMotosBarrasInventario();
    getMotosBarrasInventarioTienda();
    getGrupoInventarios();
    getGrupoInventariosTienda();
    getRangoSaldoVencidoByGestor();
    getMotosPiso();
  };

  const getRangoSaldoVencidoByGestor = async () => {
    await axios

      .get(server + "api/dashboard/getRangoSaldoVencidoByGestor")
      // .get(server + "api/dashboard/getMotosGarantias/" + mes + "/" + anio)
      .then(function (response) {
        // console.log("getRangoSaldoVencidoByGestor");
        // console.log(response.data);
        setltsGestorAsignacion(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getDetalleUsoRepuestos = async (mes, anio) => {
    await axios

      .get(server + "api/dashboard/getDetalleUsoRepuestos/" + mes + "/" + anio)
      // .get(server + "api/dashboard/getMotosGarantias/" + mes + "/" + anio)
      .then(function (response) {
        setltsUsoRepuestos(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getMotosPiso = async (mes, anio) => {
    await axios

      .get(server + "api/dashboard/getMotosDiasPiso")
      // .get(server + "api/dashboard/getMotosGarantias/" + mes + "/" + anio)
      .then(function (response) {
        setltsMotosPiso(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getMotosGrantiasTaller = async (mes, anio) => {
    await axios

      .get(server + "api/dashboard/getMotosGarantias/" + mes + "/" + anio)
      // .get(server + "api/dashboard/getMotosGarantias/" + mes + "/" + anio)
      .then(function (response) {
        const labes = [];
        const datas = [];
        const listaDas = [];
        response.data.forEach((row, index) => {
          labes.push(row.veh_modelo);
          datas.push(row.total);
        });

        const item = {
          label: "",
          data: datas,
          backgroundColor: "#689BD0",
        };

        listaDas.push(item);

        const lista = {
          labels: labes,
          datasets: listaDas,
        };

        setdatosModeloGarantia(lista);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getDetallesCobros = async (mes, anio) => {
    await axios

      .get(server + "api/dashboard/getCountCobrosRango/" + mes + "/" + anio)

      .then(function (response) {
        const labes = [];
        const datas = [];
        const listaDas = [];
        response.data.forEach((row, index) => {
          labes.push(row.nombre_rango);
          datas.push(row.valor);
        });

        const item = {
          label: "",
          data: datas,
          backgroundColor: "#689BD0",
        };

        listaDas.push(item);

        const lista = {
          labels: labes,
          datasets: listaDas,
        };
        setdatosCarteraCobro(lista);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getMotosBarrasInventario = async () => {
    await axios
      .get(server + "api/inventario/getModelosInventario/2")
      .then(function (response) {
        const labes = [];
        const datas = [];
        const listaDas = [];
        response.data.forEach((row, index) => {
          labes.push(row.min_modelo);
          datas.push(row.min_cantidad);
        });

        const item = {
          label: "",
          data: datas,
          backgroundColor: "#689BD0",
        };

        listaDas.push(item);

        const lista = {
          labels: labes,
          datasets: listaDas,
        };

        setdatosModeloInventario(lista);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getMotosBarrasInventarioTienda = async () => {
    await axios
      .get(server + "api/inventario/getModelosInventario/5")
      .then(function (response) {
        const labes = [];
        const datas = [];
        const listaDas = [];
        response.data.forEach((row, index) => {
          labes.push(row.min_modelo);
          datas.push(row.min_cantidad);
        });

        const item = {
          label: "",
          data: datas,
          backgroundColor: "#689BD0",
        };

        listaDas.push(item);

        const lista = {
          labels: labes,
          datasets: listaDas,
        };

        setdatosModeloInventarioTienda(lista);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getTalleRegistroTaller = async (mes, anio) => {
    await axios
      .get(server + "api/dashboard/getTallerPDIMAN/" + mes + "/" + anio)
      .then(function (response) {
        const labes = [];
        const datas = [];
        const datas2 = [];
        const listaDas = [];

        response.data.sort((a, b) => {
          return b.man - a.man;
      });
        response.data.forEach((row, index) => {
          labes.push(row.tal_nombre);
          datas.push(row.pdi);
          datas2.push(row.man);
        });


        console.log()
        console.log(datas)

       

        const item = {
          label: "PDI",
          data: datas,
          yAxisID: "y-axis-1",
          backgroundColor: "#689BD0",
        };

        const item2 = {
          label: "Man",
          data: datas2,
          yAxisID: "y-axis-2",
          backgroundColor: "#E08244",
        };


        
        listaDas.push(item);
        listaDas.push(item2);

        const lista = {
          labels: labes,
          datasets: listaDas,
        };

       

        setdatosTallerRegistro(lista);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getPDITaller = async (mes, anio) => {
    let newArrT = [...ltsDashTaller];
    newArrT[0].estado = 1;
    setltsDashTaller(newArrT);

    await axios

      .get(server + "api/dashboard/getCountPDI/" + mes + "/" + anio)
      // .get(server + "api/dashboard/getCountPDI")
      .then(function (response) {
        let newArr = [...ltsDashTaller];

        console.log(response);

        newArr[0].data = "NRO : " + response.data.cantidad;
        newArr[0].data1 = "$ : " + response.data.valor;
        newArr[0].estado = 2;
        // if (response.data.length > 0) {
        //   newArr[0].data = response.data;
        //   newArr[0].estado = 2;
        // } else {
        //   newArr[0].estado = 3;
        // }

        setltsDashTaller(newArr);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getManKilometrajeTaller = async (mes, anio) => {
    let newArrT = [...ltsDashTaller];
    newArrT[1].estado = 1;
    setltsDashTaller(newArrT);

    await axios
      .get(server + "api/dashboard/getCountMAN/" + mes + "/" + anio)
      .then(function (response) {
        let newArr = [...ltsDashTaller];

        newArr[1].data = "NRO : " + response.data.cantidad;
        newArr[1].data1 = "$ : " + response.data.valor;
        newArr[1].estado = 2;
        // if (response.data.length > 0) {
        //   newArr[0].data = response.data;
        //   newArr[0].estado = 2;
        // } else {
        //   newArr[0].estado = 3;
        // }

        setltsDashTaller(newArr);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getSinMantenimientoTaller = async (mes, anio) => {
    let newArrT = [...ltsDashTaller];
    newArrT[2].estado = 1;
    setltsDashTaller(newArrT);

    await axios
      .get(server + "api/dashboard/getCountMotoPdiSinMan/" + mes + "/" + anio)
      .then(function (response) {
        let newArr = [...ltsDashTaller];

        newArr[2].data = "Ant : " + response.data.ant;
        newArr[2].data1 = "Act : " + response.data.actual;
        newArr[2].estado = 2;

        setltsDashTaller(newArr);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getGarantiasTaller = async (mes, anio) => {
    let newArrT = [...ltsDashTaller];
    newArrT[3].estado = 1;
    setltsDashTaller(newArrT);

    await axios
      .get(server + "api/dashboard/getCountGAR/" + mes + "/" + anio)
      .then(function (response) {
        let newArr = [...ltsDashTaller];

        console.log(response);

        newArr[3].data = "Apro : " + response.data.aprobados;
        newArr[3].data1 = "Neg : " + response.data.negados;
        newArr[3].estado = 2;
        // if (response.data.length > 0) {
        //   newArr[0].data = response.data;
        //   newArr[0].estado = 2;
        // } else {
        //   newArr[0].estado = 3;
        // }

        setltsDashTaller(newArr);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getNumeroCompromisos = async (inicio, fin) => {
    let newArrT = [...lstPedidos];
    newArrT[0].estado = 1;
    setlstPedidos(newArrT);

    await axios
      .get(
        server + "api/asignacionapp/getTodosCompromiso/" + inicio + "/" + fin
      )
      .then(function (response) {
        let newArr = [...lstPedidos];

        newArr[0].data = response.data;
        newArr[0].estado = 2;
        // if (response.data.length > 0) {
        //   newArr[0].data = response.data;
        //   newArr[0].estado = 2;
        // } else {
        //   newArr[0].estado = 3;
        // }

        setlstPedidos(newArr);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const geSumaTipoCarteraByBanco = async (banco, tipoCartera, mes, anio) => {
    let newArrT = [...ltsDashBoarSolidario];
    newArrT[0].estado = 1;
    setltsDashBoarSolidario(newArrT);

    let newArrT2 = [...ltsDashBoarAustro];
    newArrT2[0].estado = 1;
    setltsDashBoarAustro(newArrT2);

    await axios
      .get(server + "api/dashboard/geSumaTipoCarteraByBanco/" + mes + "/" + anio + "/" + banco + "/" + tipoCartera)
      .then(function (response) {
        if (banco == 'solidario') {
          let newArr = [...ltsDashBoarSolidario];
          if (tipoCartera == 'cartera') {
            newArr[0].data = (response.data * 1.0).toFixed(2);
            newArr[0].estado = 2;
            setltsDashBoarSolidario(newArr);
          } else {
            newArr[3].data = (response.data * 1.0).toFixed(2);
            newArr[3].estado = 2;
            setltsDashBoarSolidario(newArr);
          }

        } else {
          let newArr2 = [...ltsDashBoarAustro];
          if (tipoCartera == 'cartera') {
            newArr2[0].data = (response.data * 1.0).toFixed(2);
            newArr2[0].estado = 2;
            setltsDashBoarAustro(newArr2);
          } else {
            newArr2[3].data = (response.data * 1.0).toFixed(2);
            newArr2[3].estado = 2;
            setltsDashBoarAustro(newArr2);
          }
        }


      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getSCSSolidarioCartera = async (mes, anio) => {
    let newArrT = [...ltsDashBoarSolidario];
    newArrT[2].estado = 1;
    setltsDashBoarSolidario(newArrT);

    await axios
      .get(server + "api/dashboard/getSCS/" + mes + "/" + anio)
      .then(function (response) {
        let newArr = [...ltsDashBoarSolidario];

        newArr[2].data = (response.data * 1.0).toFixed(2);
        newArr[2].estado = 2;

        setltsDashBoarSolidario(newArr);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getCarteraVencidaSolidarioCartera = async (mes, anio) => {
    let newArrT = [...ltsDashBoarSolidario];
    newArrT[2].estado = 1;
    setltsDashBoarSolidario(newArrT);

    await axios
      .get(server + "api/dashboard/getCarteraVencidaSol/" + mes + "/" + anio)
      .then(function (response) {
        let newArr = [...ltsDashBoarSolidario];

        newArr[1].data = (response.data * 1.0).toFixed(2);
        newArr[1].estado = 2;

        setltsDashBoarSolidario(newArr);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getSFGSSolidarioCartera = async (mes, anio) => {
    let newArrT = [...ltsDashBoarSolidario];
    newArrT[2].estado = 1;
    setltsDashBoarSolidario(newArrT);

    await axios
      .get(server + "api/dashboard/getSFGS/" + mes + "/" + anio)
      .then(function (response) {
        let newArr = [...ltsDashBoarSolidario];

        // newArr[2].data = (response.data * 1.0).toFixed(2);
        // newArr[2].estado = 2;

        setltsDashBoarSolidario(newArr);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getCobrosSolidarioCartera = async (mes, anio) => {
    let newArrT = [...ltsDashBoarSolidario];
    newArrT[4].estado = 1;
    setltsDashBoarSolidario(newArrT);
    console.log(server + "api/dashboard/getCountCobroSol/" + mes + "/" + anio);
    await axios
      .get(server + "api/dashboard/getCountCobroSol/" + mes + "/" + anio)
      .then(function (response) {
        let newArr = [...ltsDashBoarSolidario];

        newArr[4].data = (response.data * 1.0).toFixed(2);
        newArr[4].estado = 2;

        setltsDashBoarSolidario(newArr);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getSCSAustroCartera = async (mes, anio) => {
    let newArrT = [...ltsDashBoarAustro];
    newArrT[0].estado = 1;
    setltsDashBoarAustro(newArrT);

    await axios
      .get(server + "api/dashboard/getSCA/" + mes + "/" + anio)
      .then(function (response) {
        let newArr = [...ltsDashBoarAustro];

        newArr[2].data = (response.data * 1.0).toFixed(2);
        newArr[2].estado = 2;

        setltsDashBoarAustro(newArr);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getCarteraVencidaAustroCartera = async (mes, anio) => {
    let newArrT = [...ltsDashBoarAustro];
    newArrT[1].estado = 1;
    setltsDashBoarAustro(newArrT);

    await axios
      .get(server + "api/dashboard/getCarteraVencidaAus/" + mes + "/" + anio)
      .then(function (response) {
        let newArr = [...ltsDashBoarAustro];

        newArr[1].data = (response.data * 1.0).toFixed(2);
        newArr[1].estado = 2;

        setltsDashBoarAustro(newArr);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getSFGSAustroCartera = async (mes, anio) => {
    let newArrT = [...ltsDashBoarAustro];
    newArrT[2].estado = 1;
    setltsDashBoarAustro(newArrT);

    await axios
      .get(server + "api/dashboard/getSFGA/" + mes + "/" + anio)
      .then(function (response) {
        let newArr = [...ltsDashBoarAustro];

        newArr[2].data = (response.data * 1.0).toFixed(2);
        newArr[2].estado = 2;

        setltsDashBoarAustro(newArr);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getCobrosAustroCartera = async (mes, anio) => {
    let newArrT = [...ltsDashBoarAustro];
    newArrT[4].estado = 1;
    setltsDashBoarAustro(newArrT);
    console.log(server + "api/dashboard/getCountCobroAus/" + mes + "/" + anio);
    await axios
      .get(server + "api/dashboard/getCountCobroAus/" + mes + "/" + anio)
      .then(function (response) {
        let newArr = [...ltsDashBoarAustro];

        newArr[4].data = (response.data * 1.0).toFixed(2);
        newArr[4].estado = 2;

        setltsDashBoarAustro(newArr);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getMotosInventario = async () => {
    let newArrT = [...ltsDashInvetario];
    newArrT[0].estado = 1;
    setltsDashInvetario(newArrT);

    await axios
      .get(server + "api/dashboard/getMotosDistribuidor")
      .then(function (response) {
        let newArr = [...ltsDashInvetario];

        newArr[0].data = "VENTA: " + response.data.venta;
        newArr[0].data1 = "CONSI: " + response.data.consi;
        newArr[0].estado = 2;

        setltsDashInvetario(newArr);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getMotosTienda = async () => {
    let newArrT = [...ltsDashInvetario];
    newArrT[2].estado = 1;
    setltsDashInvetario(newArrT);

    await axios
      .get(server + "api/dashboard/getTotalMotosTienda")
      .then(function (response) {
        let newArr = [...ltsDashInvetario];

        newArr[2].data = "CANTIDAD";
        newArr[2].data1 = response.data.total;
        newArr[2].estado = 2;

        setltsDashInvetario(newArr);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getDiasPisoInventario = async () => {
    let newArrT = [...ltsDashInvetario];
    newArrT[3].estado = 1;
    setltsDashInvetario(newArrT);

    await axios
      .get(server + "api/inventario/getAllDiasPiso")
      .then(function (response) {
        let newArr = [...ltsDashInvetario];

        newArr[3].data = "CANTIDAD";
        newArr[3].data1 = response.data;
        newArr[3].estado = 2;

        setltsDashInvetario(newArr);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getDistribuidoresInventario = async () => {
    let newArrT = [...ltsDashInvetario];
    newArrT[1].estado = 1;
    setltsDashInvetario(newArrT);

    await axios
      .get(server + "api/dashboard/getTotalDistribuidores")
      .then(function (response) {
        let newArr = [...ltsDashInvetario];

        newArr[1].data = "CM: " + response.data.cm;
        newArr[1].data1 = "SM: " + response.data.sm;
        newArr[1].estado = 2;

        setltsDashInvetario(newArr);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getAnalisiVisitasCobrosCartera = async (mes, anio) => {
    await axios
      .get(server + "api/dashboard/getCountGestorCobro/" + mes + "/" + anio)
      .then(function (response) {
        const labes = [];
        const datas = [];
        const datas2 = [];
        const listaDas = [];
        response.data.forEach((row, index) => {
          labes.push(row.ges_nombre + " " + row.ges_apellido);
          datas.push(row.total_visita);
          datas2.push(row.total_cobro);
        });

        const item = {
          label: "Visitas",
          data: datas,
          yAxisID: "y-axis-1",
          backgroundColor: "#689BD0",
        };

        const item2 = {
          label: "Cobros",
          data: datas2,
          yAxisID: "y-axis-2",
          backgroundColor: "#E08244",
        };

        listaDas.push(item);
        listaDas.push(item2);

        const lista = {
          labels: labes,
          datasets: listaDas,
        };

        setdatosVisitasCobros(lista);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getGrupoInventarios = async () => {
    await axios
      .get(server + "api/inventario/getInventarioGrupos/2")
      .then(function (response) {
        const labes = [];
        const datas = [];
        const datas2 = [];
        const listaDas = [];
        response.data.forEach((row, index) => {
          labes.push(row.inv_label);
          datas.push(row.inv_num_clie);
          datas2.push(row.inv_cantidad);
        });

        const item = {
          label: "Nro Clientes",
          data: datas,
          yAxisID: "y-axis-1",
          backgroundColor: "#689BD0",
        };

        const item2 = {
          label: "Inventario",
          data: datas2,
          yAxisID: "y-axis-2",
          backgroundColor: "#E08244",
        };

        listaDas.push(item);
        listaDas.push(item2);

        const lista = {
          labels: labes,
          datasets: listaDas,
        };

        setdatosDetalleGrupo(lista);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getGrupoInventariosTienda = async () => {
    await axios
      .get(server + "api/inventario/getInventarioGrupos/5")
      .then(function (response) {
        const labes = [];
        const datas = [];
        const datas2 = [];
        const listaDas = [];
        response.data.forEach((row, index) => {
          labes.push(row.inv_label);
          datas.push(row.inv_num_clie);
          datas2.push(row.inv_cantidad);
        });

        const item = {
          label: "Nro Clientes",
          data: datas,
          yAxisID: "y-axis-1",
          backgroundColor: "#689BD0",
        };

        const item2 = {
          label: "Inventario",
          data: datas2,
          yAxisID: "y-axis-2",
          backgroundColor: "#E08244",
        };

        listaDas.push(item);
        listaDas.push(item2);

        const lista = {
          labels: labes,
          datasets: listaDas,
        };

        setdatosDetalleGrupoTienda(lista);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getPagoEfectuados = async () => {
    let newArrT = [...lstPedidos];
    newArrT[1].estado = 1;
    setlstPedidos(newArrT);

    await axios
      .get(server + "api/cartera/getPagosEfectuados")
      .then(function (response) {
        let newArr = [...lstPedidos];

        newArr[1].data = response.data.cantidad;
        newArr[1].estado = 2;
        setlstPedidos(newArr);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getReporteVistasDias = async (inicio, fin) => {
    await axios
      .get(server + "api/asignacionapp/getGestorDias/" + inicio + "/" + fin)
      .then(function (response) {
        const listaGestor = [];

        response.data.gestores.forEach((row, index) => {
          const item = {
            label: row.nombre,
            data: row.dias,
            fill: false,
            borderColor: row.color,
          };
          listaGestor.push(item);
        });

        const lista = {
          labels: response.data.diasmes,
          datasets: listaGestor,
        };

        setdatosLinea(lista);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getReporteCarteraEstado = async () => {
    await axios
      .get(server + "api/cartera/getCarteraEstado")
      .then(function (response) {
        const listaGestor = [];
        // console.log("getCarteraEstado")
        // console.log(response)
        response.data.forEach((row, index) => {
          // console.log(data)
          const item = {
            label: row.crt_tipo_cartera,
            data: row.crt_count_tipo,
            // borderColor: row.color,
          };
          listaGestor.push(item);
        });
        // console.log(listaGestor);

        const lista = {
          labels: ["AUSTRO", "SOLIDARIO"],
          datasets: listaGestor,
        };

        // setdatosCartera(lista);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getReporteCarteraInfo = async () => {
    await axios
      .get(server + "api/cartera/getCarteraInfo")
      .then(function (response) {
        const listaGestor = [];
        const labels = [];
        console.log("getCarteraInfo");
        console.log(response);
        response.data.forEach((row, index) => {
          console.log(row);
          labels.push(row.crt_nombre);
        });
        console.log(labels);

        const lista = {
          labels: labels,
          datasets: listaGestor,
        };

        setdatosCartera(lista);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getReportes = (inicio, fin) => {
    getReporteVistasDias(inicio, fin);

    getNumeroCompromisos(inicio, fin);
  };

  const getData = async () => {
    await axios
      .get(server + "api/cargo/getallcargos")
      .then(function (response) {
        setdataTable(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getColor = () => {
    const item = data.us_empresa.listaParametros.find(
      (element) => element.pae_tipo === 4
    );

    if (item === undefined) {
      return "#137ad1";
    } else {
      return "#" + item.pae_parametro;
    }
  };

  const update = (dataUpdate, index) => {
    seterror((error) => {
      return { ...error, nombre: "", descripcion: "" };
    });

    const dataForm = new FormData();
    dataForm.append("car_id", dataUpdate.CAR_CODIGO);
    dataForm.append("car_nombre", dataUpdate.CAR_NOMBRE);
    dataForm.append("car_descripcion", dataUpdate.CAR_DESCRIPCION);
    dataForm.append("car_estado", dataUpdate.CAR_ESTADO);
    dataForm.append("usuario", data.US_CODIGO);
    dataForm.append("_method", "PUT");

    axios
      .post(server + "api/cargo/updateCargo", dataForm)

      .then(function (response) {
        if (response.data.success === true) {
          showMessage(response.data.message, "success");

          let newArr = [...dataTable];
          newArr[index] = dataUpdate;
          setdataTable(newArr);
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const popover = (data) => {
    return (
      <Popover id="popover-basic">
        <Popover.Title as="h3">{data.titulo}</Popover.Title>
        <Popover.Content>{data.descripcion}</Popover.Content>
      </Popover>
    );
  };

  const renderGridItem = (data) => {
    if (data.titulo === "") {
      return (
        <div className="p-col-12 p-md-3">
          {data.name == "Cobros" ?

            <FormGroup as={Row} style={{ marginTop: -0, marginBottom: -0 }}>
              <Col>
                <span
                  className="product-category"
                  style={{ fontSize: 12, color: 'black' }}
                >FILTRAR:</span>
              </Col>
              <Col>
                {data.tipoBanco == 'solidario' ?
                  <Calendar
                    style={{ height: 30 }}
                    value={dateCobro}
                    onChange={(e) => {
                      console.log(e.value)
                      // metodoCartera(month,year,e.value)
                      setdateCobro(e.value)
                      getCobrosByFecha(e.value.getMonth(),
                        e.value.getFullYear(), data.tipoBanco)
                    }
                    }
                    view="month"
                    dateFormat="mm/yy"
                    yearNavigator
                    yearRange="2010:2030"
                  />
                  : <Calendar
                    style={{ height: 30 }}
                    value={dateCobroA}
                    onChange={(e) => {
                      console.log(e.value);
                      // metodoCartera(month,year,e.value)
                      setdateCobroA(e.value)
                      getCobrosByFecha(e.value.getMonth(),
                        e.value.getFullYear(), data.tipoBanco)
                    }
                    }
                    view="month"
                    dateFormat="mm/yy"
                    yearNavigator
                    yearRange="2010:2030"
                  />}
              </Col>
            </FormGroup>
            : <Col style={{ height: 30 }}></Col>}

          <div
            onClick={data.click}
            className="product-grid-item card"
            style={{ backgroundColor: data.color }}
          >
            <div className="product-grid-item-content">
              <div className="product-grid-item-content">
                <i className="pi pi-tag product-category-icon"></i>
                <span
                  className="product-category"
                  style={{ fontSize: 16, color: data.colortext }}
                >
                  {data.name}
                </span>
              </div>
            </div>
            <div className="product-grid-item-content">
              <FontAwesomeIcon
                style={{ marginTop: 8, marginBottom: 10 }}
                icon={data.img}
                color={data.colorIcono}
                size="3x"
              />

              {tipo(
                data.estado,
                data.data,
                data.name,
                data.colortext,
                data.tipo,
                data.data1
              )}
            </div>
          </div>
          <Col style={{ height: 30 }}></Col>
        </div>
      );
    } else {
      return (
        <div className="p-col-12 p-md-3">

          {data.name == "Cobros" ?

            <FormGroup as={Row} style={{ marginTop: -0, marginBottom: -0 }}>
              <Col>
                <span
                  className="product-category"
                  style={{ fontSize: 12, color: 'black' }}
                >FILTRAR:</span>
              </Col>
              <Col>
                {data.tipoBanco == 'solidario' ?
                  <Calendar
                    style={{ height: 30 }}
                    value={dateCobro}
                    onChange={(e) => {
                      console.log(e.value);
                      // metodoCartera(month,year,e.value)
                      setdateCobro(e.value)
                      getCobrosByFecha(e.value.getMonth(),
                        e.value.getFullYear(), data.tipoBanco)
                    }
                    }
                    view="month"
                    dateFormat="mm/yy"
                    yearNavigator
                    yearRange="2010:2030"
                  />
                  : <Calendar
                    style={{ height: 30 }}
                    value={dateCobroA}
                    onChange={(e) => {
                      console.log(e.value);
                      // metodoCartera(month,year,e.value)
                      setdateCobroA(e.value)
                      getCobrosByFecha(e.value.getMonth(),
                        e.value.getFullYear(), data.tipoBanco)
                    }
                    }
                    view="month"
                    dateFormat="mm/yy"
                    yearNavigator
                    yearRange="2010:2030"
                  />}
              </Col>
            </FormGroup>
            : <Col style={{ height: 30 }}></Col>}

          <OverlayTrigger
            trigger="hover"
            placement="top"
            overlay={popover(data)}
          >
            <div
              onClick={data.click}
              className="product-grid-item card"
              style={{ backgroundColor: data.color }}
            >
              <div className="product-grid-item-content">
                <div className="product-grid-item-content">
                  <i className="pi pi-tag product-category-icon"></i>
                  <span
                    className="product-category"
                    style={{ fontSize: 16, color: data.colortext }}
                  >
                    {data.name}
                  </span>
                </div>
              </div>
              <div className="product-grid-item-content">
                <FontAwesomeIcon
                  style={{ marginTop: 8, marginBottom: 10 }}
                  icon={data.img}
                  color={data.colorIcono}
                  size="3x"
                />

                {tipo(
                  data.estado,
                  data.data,
                  data.name,
                  data.colortext,
                  data.tipo,
                  data.data1
                )}
              </div>
            </div>
          </OverlayTrigger>
        </div>
      );
    }
  };

  const renderGridItemTaller = (data) => {
    if (data.titulo === "") {
      return (
        <div className="p-col-12 p-md-3">
          <div
            onClick={data.click}
            className="product-grid-item card"
            style={{ backgroundColor: data.color }}
          >
            <div className="product-grid-item-content">
              <div className="product-grid-item-content">
                <i className="pi pi-tag product-category-icon"></i>
                <span
                  className="product-category"
                  style={{ fontSize: 16, color: getColor() }}
                >
                  {data.name}
                </span>
              </div>
            </div>
            <div className="product-grid-item-content">
              <FontAwesomeIcon
                style={{ marginTop: 8, marginBottom: 10 }}
                icon={data.img}
                color={data.colorIcono}
                size="3x"
              />

              {tipoTaller(data.estado, data.data, data.name, data.data1)}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="p-col-12 p-md-3">
          <OverlayTrigger
            trigger="hover"
            placement="top"
            overlay={popover(data)}
          >
            <div
              onClick={data.click}
              className="product-grid-item card"
              style={{ backgroundColor: data.color }}
            >
              <div className="product-grid-item-content">
                <div className="product-grid-item-content">
                  <i className="pi pi-tag product-category-icon"></i>
                  <span
                    className="product-category"
                    style={{ fontSize: 16, color: getColor() }}
                  >
                    {data.name}
                  </span>
                </div>
              </div>
              <div className="product-grid-item-content">
                <FontAwesomeIcon
                  style={{ marginTop: 8, marginBottom: 10 }}
                  icon={data.img}
                  color={data.colorIcono}
                  size="3x"
                />

                {tipoTaller(data.estado, data.data, data.name, data.data1)}
              </div>
            </div>
          </OverlayTrigger>
        </div>
      );
    }
  };

  const tipo = (newTipo, datos, nombres, colortext, tipo, datos1) => {
    if (newTipo === 0) {
      return (
        <div
          className="product-name"
          style={{ fontSize: 20, color: colortext }}
        >
          0
        </div>
      );
    } else if (newTipo === 1) {
      return (
        <div className="product-grid-item-content">
          <Spin size={50} color={getColor()} />
        </div>
      );
    } else if (newTipo === 2) {
      if (tipo == 1) {
        return (
          <>
            <div
              className="product-name"
              style={{ fontSize: 25, color: colortext }}
            >
              {datos}
            </div>

            <div
              className="product-name"
              style={{ fontSize: 25, color: colortext }}
            >
              {datos1}
            </div>
          </>
        );
      } else if (tipo == 2) {
        return (
          <div
            className="product-name"
            style={{ fontSize: 25, color: colortext }}
          >
            {formatter.format(datos)}
          </div>
        );
      }
    } else if (newTipo === 3) {
      return (
        <div>
          <span style={{ color: "red" }} className="product-description">
            No existe datos
          </span>
        </div>
      );
    }
  };

  const tipoCartera = (newTipo, datos, nombres, colortext) => {
    if (newTipo === 0) {
      return (
        <div
          className="product-name"
          style={{ fontSize: 20, color: colortext }}
        >
          0
        </div>
      );
    } else if (newTipo === 1) {
      return (
        <div className="product-grid-item-content">
          <Spin size={50} color={getColor()} />
        </div>
      );
    } else if (newTipo === 2) {
      return (
        <div
          className="product-name"
          style={{ fontSize: 25, color: colortext }}
        >
          {formatter.format(datos)}
        </div>
      );
    } else if (newTipo === 3) {
      return (
        <div>
          <span style={{ color: "red" }} className="product-description">
            No existe datos
          </span>
        </div>
      );
    }
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  const tipoTaller = (newTipo, datos, nombres, datos1) => {
    if (newTipo === 0) {
      return (
        <div className="product-name" style={{ fontSize: 20 }}>
          0
        </div>
      );
    } else if (newTipo === 1) {
      return (
        <div className="product-grid-item-content">
          <Spin size={50} color={getColor()} />
        </div>
      );
    } else if (newTipo === 2) {
      return (
        <>
          <div className="product-name" style={{ fontSize: 25 }}>
            {datos}
          </div>
          <div className="product-name" style={{ fontSize: 25 }}>
            {datos1}
          </div>
        </>
      );
    } else if (newTipo === 3) {
      return (
        <div>
          <span style={{ color: "red" }} className="product-description">
            No existe datos
          </span>
        </div>
      );
    }
  };

  const getLightTheme = () => {
    let basicOptions = {
      legend: {
        labels: {
          fontColor: "#495057",
        },
      },
      scales: {
        xAxes: [
          {
            ticks: {
              fontColor: "#495057",
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              fontColor: "#495057",
            },
          },
        ],
      },
    };

    let multiAxisOptions = {
      responsive: true,
      hoverMode: "index",
      stacked: false,
      scales: {
        xAxes: [
          {
            ticks: {
              fontColor: "#495057",
            },
            gridLines: {
              color: "#ebedef",
            },
          },
        ],
        yAxes: [
          {
            type: "linear",
            display: true,
            position: "left",
            id: "y-axis-1",
            ticks: {
              fontColor: "#495057",
            },
            gridLines: {
              color: "#ebedef",
            },
          },
          {
            type: "linear",
            display: true,
            position: "right",
            id: "y-axis-2",
            ticks: {
              fontColor: "#495057",
            },
            gridLines: {
              drawOnChartArea: false,
              color: "#ebedef",
            },
          },
        ],
      },
      legend: {
        labels: {
          fontColor: "#495057",
        },
      },
    };

    return {
      basicOptions,
      multiAxisOptions,
    };
  };

  const { basicOptions, multiAxisOptions } = getLightTheme();
  const lineStylesData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "First Dataset",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: "#42A5F5",
      },
      {
        label: "Second Dataset",
        data: [28, 48, 40, 19, 86, 27, 90],
        fill: false,
        borderDash: [5, 5],
        borderColor: "#66BB6A",
      },
      {
        label: "Third Dataset",
        data: [12, 51, 62, 33, 21, 62, 45],
        fill: true,
        borderColor: "#FFA726",
        backgroundColor: "rgba(255,167,38,0.2)",
      },
    ],
  };

  const tipoDashBoard = (tipo) => {
    if (tipo === 1) {
      return (
        <div>
          <Modal
            show={showModalUsoRepuestos}
            {...props}
            onHide={() => setShowModalUsoRepuestos(false)}
            // dialogClassName="modal-90w"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            centered
          // className="modal-dialog modal-lg"
          // style = {{width : '80%'}}
          >
            <Modal.Header closeButton>
              {/* <p>Cliente: <b>{currentClienteCarte.cli_nombre} {" "} {currentClienteCarte.cli_apellido}</b> <br></br>Celular:<b>{currentClienteCarte.cli_celular}</b></p> */}
            </Modal.Header>
            <Modal.Body
              style={{
                maxHeight: "calc(100vh - 210px)",
                overflowY: "auto",
              }}
            >
              <ToolkitProvider
                keyField="id"
                data={ltsUsoRepuestos}
                columns={columnsUsoRepuestos}
                search
              >
                {(props) => (
                  <div>
                    <Col className="mt-3"></Col>
                    <Form>
                      <Row></Row>
                    </Form>
                    <Col className="mt-3"></Col>

                    <BootstrapTable
                      striped
                      hover
                      condensed
                      wrapperClasses="table-responsive"
                      // class="react-bootstrap-table table"
                      filter={filterFactory()}
                      pagination={paginationFactory(optionsPagination)}
                      {...props.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Modal.Body>
          </Modal>



          <Modal
            show={showModalGestorAsignacion}
            {...props}
            onHide={() => setShowModalGestorAsignacion(false)}
            // dialogClassName="modal-90w"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            centered
          // className="modal-dialog modal-lg"
          // style = {{width : '80%'}}
          >
            <Modal.Header closeButton>
              {/* <p>Cliente: <b>{currentClienteCarte.cli_nombre} {" "} {currentClienteCarte.cli_apellido}</b> <br></br>Celular:<b>{currentClienteCarte.cli_celular}</b></p> */}
            </Modal.Header>
            <Modal.Body
              style={{
                maxHeight: "calc(100vh - 210px)",
                overflowY: "auto",
              }}
            >
              <ToolkitProvider
                keyField="id"
                data={ltsGestorAsignacion}
                columns={columnsGestorAsignacion}
                search
              >
                {(props) => (
                  <div>
                    <Col className="mt-3"></Col>
                    <Form>
                      <Row></Row>
                    </Form>
                    <Col className="mt-3"></Col>

                    <BootstrapTable
                      striped
                      hover
                      condensed
                      wrapperClasses="table-responsive"
                      // class="react-bootstrap-table table"
                      filter={filterFactory()}
                      pagination={paginationFactory(optionsPagination)}
                      {...props.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Modal.Body>
          </Modal>

          <Modal
            show={showModalMotosPiso}
            {...props}
            onHide={() => setShowModalMotosPiso(false)}
            // dialogClassName="modal-90w"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            centered
          // className="modal-dialog modal-lg"
          // style = {{width : '80%'}}
          >
            <Modal.Header closeButton>
              {/* <p>Cliente: <b>{currentClienteCarte.cli_nombre} {" "} {currentClienteCarte.cli_apellido}</b> <br></br>Celular:<b>{currentClienteCarte.cli_celular}</b></p> */}
            </Modal.Header>
            <Modal.Body
              style={{
                maxHeight: "calc(100vh - 210px)",
                overflowY: "auto",
              }}
            >
              <ToolkitProvider
                keyField="id"
                data={ltsMotosPiso}
                columns={columnsMotosPiso}
                search
              >
                {(props) => (
                  <div>
                    <Col className="mt-3"></Col>
                    <Form>
                      <Row></Row>
                    </Form>
                    <Col className="mt-3"></Col>

                    <BootstrapTable
                      striped
                      hover
                      condensed
                      wrapperClasses="table-responsive"
                      // class="react-bootstrap-table table"
                      filter={filterFactory()}
                      pagination={paginationFactory(optionsPagination)}
                      {...props.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Modal.Body>
          </Modal>

          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="Inventario" title="Inventario">
              <div className="card">
                <Col md="12" lg="12">
                  <div className="dataview-demo">
                    <div className="card">
                      <Card
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      ></Card>

                      <DataView
                        value={ltsDashInvetario}
                        layout={"grid"}
                        itemTemplate={renderGridItem}
                      />
                    </div>
                  </div>
                </Col>

                <FormGroup as={Row}>
                  <Col>
                    <div
                      className="card"
                      style={{ marginTop: 24, marginLeft: 16, marginRight: 16 }}
                    >
                      <h5>Detalle Modelos por Inventario</h5>
                      <Chart
                        type="bar"
                        data={datosModeloInventario}
                        options={basicOptions}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div
                      className="card"
                      style={{ marginTop: 24, marginLeft: 16, marginRight: 16 }}
                    >
                      <h5>Detalle de Inventario por Grupos</h5>
                      <Chart
                        type="bar"
                        data={datosDetalleGrupo}
                        options={multiAxisOptions}
                      />
                    </div>
                  </Col>
                </FormGroup>

                <FormGroup as={Row}>
                  <Col>
                    <div
                      className="card"
                      style={{ marginTop: 24, marginLeft: 16, marginRight: 16 }}
                    >
                      <h5>Detalle Modelos por Inventario en Tiendas</h5>
                      <Chart
                        type="bar"
                        data={datosModeloInventarioTienda}
                        options={basicOptions}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div
                      className="card"
                      style={{ marginTop: 24, marginLeft: 16, marginRight: 16 }}
                    >
                      <h5>Detalle de Inventario por Grupos de Tienda</h5>
                      <Chart
                        type="bar"
                        data={datosDetalleGrupoTienda}
                        options={multiAxisOptions}
                      />
                    </div>
                  </Col>
                </FormGroup>

                <Row>
                  <Col className="align-middle" md="auto">
                    <div
                      className="card"
                      style={{ marginLeft: 32, marginRight: 16 }}
                    >
                      <h5>Ver Modelos con promedio de días de piso</h5>
                    </div>
                  </Col>
                  <Col>
                    <Button onClick={() => setShowModalMotosPiso(true)}>
                      {" "}
                      Ver detalles{" "}
                    </Button>
                  </Col>
                </Row>



              </div>
            </Tab>
            <Tab eventKey="Cartera" title="Cartera">
              <div className="card">
                
              <Col md="12" lg="12">
                  <div className="dataview-demo">
                    <div className="card">
                      <Card
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      ></Card>

                      <DataView
                        value={ltsDashBoarAustro}
                        layout={"grid"}
                        itemTemplate={renderGridItem}
                      />
                    </div>
                  </div>
                </Col>

                <Col md="12" lg="12">
                  <div className="dataview-demo">
                    <div className="card">
                      <Card
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      ></Card>

                      <DataView
                        value={ltsDashBoarSolidario}
                        layout={"grid"}
                        itemTemplate={renderGridItem}
                      />
                    </div>
                  </div>
                </Col>

                

                <FormGroup as={Row}>
                  <Col>
                    <div
                      className="card"
                      style={{ marginTop: 24, marginLeft: 16, marginRight: 16 }}
                    >
                      <h5>Análisis Visita Cobros</h5>
                      <Chart
                        type="bar"
                        data={datosVisitasCobros}
                        options={multiAxisOptions}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div
                      className="card"
                      style={{ marginTop: 24, marginLeft: 16, marginRight: 16 }}
                    >
                      <h5>Detalle de cartera vencida</h5>
                      <Chart
                        type="bar"
                        data={datosCarteraCobro}
                        options={basicOptions}
                      />
                    </div>
                  </Col>
                </FormGroup>

                <div
                  style={{
                    marginLeft: 16,
                    marginRight: 16,
                    marginBottom: 400,
                  }}
                >
                  <h5>Reporte de Visitas del Mes</h5>
                  <Chart
                    type="line"
                    data={datosLinea}
                    height={100}
                    options={basicOptions}
                  />
                </div>

                <Row>
                  <Col className="align-middle" md="auto">
                    <div
                      className="card"
                      style={{ marginLeft: 32, marginRight: 16 }}
                    >
                      <h5>Gestores con asignación por Cartera Vencida</h5>
                    </div>
                  </Col>
                  <Col>
                    <Button onClick={() => setShowModalGestorAsignacion(true)}>
                      {" "}
                      Ver detalles{" "}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Tab>

            <Tab eventKey="PostVenta" title="PostVenta">
              <div style={{ marginBottom: 5 }}>
                <FormGroup as={Row}>
                  <Col>
                    <h5>Parametos de Busqueda</h5>
                  </Col>
                  <Col>
                    <Calendar
                      value={date}
                      onChange={(e) =>
                        cambioFecha(
                          e.value.getMonth(),
                          e.value.getFullYear(),
                          e.value
                        )
                      }
                      view="month"
                      dateFormat="mm/yy"
                      yearNavigator
                      yearRange="2010:2030"
                    />
                  </Col>
                  <Col></Col>
                  <Col></Col>
                  <Col></Col>
                </FormGroup>
              </div>
              <div className="card">
                <Col md="12" lg="12">
                  <div className="dataview-demo">
                    <div className="card">
                      <Card
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      ></Card>

                      <DataView
                        value={ltsDashTaller}
                        layout={"grid"}
                        itemTemplate={renderGridItemTaller}
                      />
                    </div>
                  </div>
                </Col>

                <FormGroup as={Row}>
                  <Col

                    onClick={() => {

                      setshowtallerModelos(true)

                    }}
                  
                  
                  >
                    <div
                      className="card"
                      style={{ marginTop: 24, marginLeft: 16, marginRight: 16 }}
                    >
                      <h5>Detalle Modelos</h5>
                      <Chart
                        type="bar"
                        data={datosModeloGarantia}
                        options={basicOptions}
                      />
                    </div>
                  </Col>
                  <Col
                  
                  onClick={() => {

                    setshowtallerRegistro(true)
             
                  }}
                  >
                    <div
                      className="card"
                      style={{ marginTop: 24, marginLeft: 16, marginRight: 16 }}
                    >
                      <h5>Detalle Taller Registro</h5>
                      <Chart
                        type="bar"
                        data={datosTallerRegistro}
                        options={multiAxisOptions}
                      />
                    </div>
                  </Col>
                </FormGroup>
                <Row>
                  <Col className="align-middle" md="auto">
                    <div
                      className="card"
                      style={{ marginLeft: 32, marginRight: 16 }}
                    >
                      <h5>Repuestos que se han usado</h5>
                    </div>
                  </Col>
                  <Col>
                    <Button onClick={() => setShowModalUsoRepuestos(true)}>
                      {" "}
                      Ver detalles{" "}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Tab>
          </Tabs>
        </div>
      );
    }
    //Taller
    else if (tipo === 2) {
      return (
        <div className="card">

<Modal
            show={showModalUsoRepuestos}
            {...props}
            onHide={() => setShowModalUsoRepuestos(false)}
            // dialogClassName="modal-90w"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            centered
            // className="modal-dialog modal-lg"
            // style = {{width : '80%'}}
          >
            <Modal.Header closeButton>
              {/* <p>Cliente: <b>{currentClienteCarte.cli_nombre} {" "} {currentClienteCarte.cli_apellido}</b> <br></br>Celular:<b>{currentClienteCarte.cli_celular}</b></p> */}
            </Modal.Header>
            <Modal.Body
              style={{
                maxHeight: "calc(100vh - 210px)",
                overflowY: "auto",
              }}
            >
              <ToolkitProvider
                keyField="id"
                data={ltsUsoRepuestos}
                columns={columnsUsoRepuestos}
                search
              >
                {(props) => (
                  <div>
                    <Col className="mt-3"></Col>
                    <Form>
                      <Row></Row>
                    </Form>
                    <Col className="mt-3"></Col>

                    <BootstrapTable
                      striped
                      hover
                      condensed
                      wrapperClasses="table-responsive"
                      // class="react-bootstrap-table table"
                      filter={filterFactory()}
                      pagination={paginationFactory(optionsPagination)}
                      {...props.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Modal.Body>
          </Modal>

          
          <Col md="12" lg="12">
            <div className="dataview-demo">
              <div className="card">
                <Card
                  style={{
                    borderWidth: 1,
                    borderColor: "rgba(128,128,128,0.4)",
                  }}
                ></Card>

                <div style={{ marginTop: 5 }}>
                  <FormGroup as={Row}>
                    <Col>
                      <h5>Parametos de Busqueda</h5>
                    </Col>
                    <Col>
                      <Calendar
                        value={date}
                        onChange={(e) =>
                          cambioFecha(
                            e.value.getMonth(),
                            e.value.getFullYear(),
                            e.value
                          )
                        }
                        view="month"
                        dateFormat="mm/yy"
                        yearNavigator
                        yearRange="2010:2030"
                      />
                    </Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                  </FormGroup>
                </div>

                <DataView
                  value={ltsDashTaller}
                  layout={"grid"}
                  itemTemplate={renderGridItemTaller}
                />
              </div>
            </div>
          </Col>

          <FormGroup as={Row}>
            <Col
             onClick={() => {

              setshowtallerModelos(true)

            }}
            >
              <div
                className="card"
                style={{ marginTop: 24, marginLeft: 16, marginRight: 16 }}
              >
                <h5>Detalle Modelos </h5>
                <Chart
                  type="bar"
                  data={datosModeloGarantia}
                  options={basicOptions}
                />
              </div>
            </Col>
            <Col
            onClick={() => {

              setshowtallerRegistro(true)
       
            }}
            >
              <div
                className="card"
                style={{ marginTop: 24, marginLeft: 16, marginRight: 16 }}
              >
                <h5>Detalle Taller Registro</h5>
                <Chart
                  type="bar"
                  data={datosTallerRegistro}
                  options={multiAxisOptions}
                />
              </div>
            </Col>
          </FormGroup>

          <Row>
            <Col className="align-middle" md="auto">
              <div
                className="card"
                style={{ marginLeft: 32, marginRight: 16 }}
              >
                <h5>Repuestos que se han usado</h5>
              </div>
            </Col>
            <Col>
         
  
            <Button onClick={() => {
          
              setShowModalUsoRepuestos(true)}}>
                      {" "}
                      Ver detalles{" "}
                    </Button>


          
            </Col>
          </Row>
        </div>
      );
    }
    //Cartera
    else if (tipo === 3) {
      return (
        <div className="card">
          <Col md="12" lg="12">
            <div className="dataview-demo">
              <div className="card">
                <Card
                  style={{
                    borderWidth: 1,
                    borderColor: "rgba(128,128,128,0.4)",
                  }}
                ></Card>

                <DataView
                  value={ltsDashBoarAustro}
                  layout={"grid"}
                  itemTemplate={renderGridItem}
                />
              </div>
            </div>
          </Col>


          <Col md="12" lg="12">
            <div className="dataview-demo">
              <div className="card">
                <Card
                  style={{
                    borderWidth: 1,
                    borderColor: "rgba(128,128,128,0.4)",
                  }}
                ></Card>

                <DataView
                  value={ltsDashBoarSolidario}
                  layout={"grid"}
                  itemTemplate={renderGridItem}
                />
              </div>
            </div>
          </Col>



          <FormGroup as={Row}>
            <Col>
              <div
                className="card"
                style={{ marginTop: 24, marginLeft: 16, marginRight: 16 }}
              >
                <h5>Análisis Visita Cobros</h5>
                <Chart
                  type="bar"
                  data={datosVisitasCobros}
                  options={multiAxisOptions}
                />
              </div>
            </Col>
            <Col>
              <div
                className="card"
                style={{ marginTop: 24, marginLeft: 16, marginRight: 16 }}
              >
                <h5>Detalle de cartera vencida</h5>
                <Chart
                  type="bar"
                  data={datosCarteraCobro}
                  options={basicOptions}
                />
              </div>
            </Col>
          </FormGroup>

          <div
            className="card"
            style={{
              marginTop: 24,
              marginLeft: 16,
              marginRight: 16,
            }}
          >
            <h5>Reporte de Visitas del Mes</h5>
            <Chart type="line" data={datosLinea} options={basicOptions} />
          </div>

          <Row>
            <Col className="align-middle" md="auto">
              <div
                className="card"
                style={{ marginLeft: 32, marginRight: 16 }}
              >
                <h5>Gestores con asignación</h5>
              </div>
            </Col>
            <Col>
              <Button onClick={() => setShowModalGestorAsignacion(true)}>
                {" "}
                Ver detalles{" "}
              </Button>
            </Col>
          </Row>
        </div>
      );
    }

    //Inventario
    else if (tipo === 4) {
      return (
        <div className="card">
          <Col md="12" lg="12">
            <div className="dataview-demo">
              <div className="card">
                <Card
                  style={{
                    borderWidth: 1,
                    borderColor: "rgba(128,128,128,0.4)",
                  }}
                ></Card>

                <DataView
                  value={ltsDashInvetario}
                  layout={"grid"}
                  itemTemplate={renderGridItem}
                />
              </div>
            </div>
          </Col>

          <FormGroup as={Row}>
            <Col>
              <div
                className="card"
                style={{ marginTop: 24, marginLeft: 16, marginRight: 16 }}
              >
                <h5>Detalle Modelos por Inventario</h5>
                <Chart
                  type="bar"
                  data={datosModeloInventario}
                  options={multiAxisOptions}
                />
              </div>
            </Col>
            <Col>
              <div
                className="card"
                style={{ marginTop: 24, marginLeft: 16, marginRight: 16 }}
              >
                <h5>Detalle de Inventario por Grupos</h5>
                <Chart
                  type="bar"
                  data={datosDetalleGrupo}
                  options={multiAxisOptions}
                />
              </div>
            </Col>
          </FormGroup>


          <Row>
            <Col className="align-middle" md="auto">
              <div
                className="card"
                style={{ marginLeft: 32, marginRight: 16 }}
              >
                <h5>Ver Modelos con promedio de días de piso</h5>
              </div>
            </Col>
            <Col>
              <Button onClick={() => setShowModalMotosPiso(true)}>
                {" "}
                Ver detalles{" "}
              </Button>
            </Col>
          </Row>
        </div>
      );
    }
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        
<Modal
                  show={showtallerRegistro}
                  {...props}
                  dialogClassName="modal-90w"
                  aria-labelledby="example-custom-modal-styling-title"
                  centered
                >
                  <Modal.Title id="contained-modal-title-vcenter">
                  Detalle Taller Registro
                  </Modal.Title>
                  <Modal.Body>
                  <Chart
                        type="bar"
                        data={datosTallerRegistro}
                        options={multiAxisOptions}
                      />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => setshowtallerRegistro(false)}>
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>


                <Modal
                  show={showtallerModelos}
                  {...props}
                  dialogClassName="modal-90w"
                  aria-labelledby="example-custom-modal-styling-title"
                  centered
                >
                  <Modal.Title id="contained-modal-title-vcenter">
                  Detalle Modelos
                  </Modal.Title>
                  <Modal.Body>
                      <Chart
                        type="bar"
                        data={datosModeloGarantia}
                        options={basicOptions}
                      />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => setshowtallerModelos(false)}>
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>


        {tipoDashBoard(tipoDas)}
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
