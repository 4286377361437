import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Card,
  FormGroup,
  Form,
  Button,
  Spinner,
  Modal,
  Carousel
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import axios from "axios";
import Swal from "sweetalert2";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faUsers,faWindowClose } from "@fortawesome/free-solid-svg-icons";
import * as SecureLS from "secure-ls";
import Map from "../../componentes/screens/Map";
import * as moment from "moment";
import {
  // faInfoCircle,
  // faCalendarCheck,
  // faPenSquare,
  // faHandHoldingUsd,
  faInfoCircle,
  // faMapMarkedAlt,
  // faSearchLocation,
  faLocationArrow,
  faImages,
  faMoneyBillAlt,
  faMoneyCheckAlt,
  faCaretSquareDown,
} from "@fortawesome/free-solid-svg-icons";
import filterFactory, {
  textFilter,
  selectFilter,
  numberFilter,
  Comparator,
  dateFilter
} from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import optionsPagination from "../../helper/SetupComponents";
function Content(props) {
  const server = window.$linkserver;
  const [dataTable, setdataTable] = useState([]);
  const [dataTableUser, setdataTableUser] = useState([]);
  const [getRowsCiudades, setRowsCiudades] = useState([]);
  const [showModalConfirmCambio, setShowModalConfirmCambio] = useState(false);
  const [isLoadingtallerCreada, setisLoadingtallerCreada] = useState(
    "invisible"
  );
  const [showModalMap, setshowModalMap] = useState(false);
  const [showModalFotos, setshowModalFotos] = useState(false);
  const [imgs, setIMGS] = useState([]);

  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [horaMapa, sethoraMapa] = useState("");

  const [isLoadingTable, setisLoadingTable] = useState("invisible");
  // const [isLoadingActualizar, setisLoadingActualizar] = useState("invisible");
  const [showModal, setshowModal] = useState(false);
  const [showModalUsuarios, setshowModalUsuarios] = useState(false);
  const [showModalCreateUsuario, setshowModalCreateUsuario] = useState(false);

  const [showModalupdateUsuario, setshowModalupdateUsuario] = useState(false);

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  const [taller, settaller] = useState({
    // tal_codigo: "",
    // tal_id: "",
    tal_razon_social: "",
    tal_nombre_comercial: "",
    tal_cuidad: 0,
    tal_direccion: "",
    tal_telefono: "",
    tal_email_taller: "",
    tal_estado: 0,
    tal_ruc: "",
    tal_pagina_web: "",
    // tal_tipo_taller: "",
  });

  const [usuario, setusuario] = useState({
    // tal_codigo: "",
    usr_id: 0,
    usr_usuario: "",
    usr_nombre: "",
    usr_apellido: "",
    usr_identificacion: "",
    usr_alias: "",
    usr_clave: "",
  });

  const [errorusuario, seterrorusuario] = useState({
    // tal_codigo: "",
    usr_usuario: "",
    usr_nombre: "",
    usr_apellido: "",
    usr_identificacion: "",
    usr_alias: "",
    usr_clave: "",
  });

  const [error, seterror] = useState({
    // codigo: "",
    // id: "",
    razon: "",
    nombreco: "",
    direccion: "",
    telefono: "",
    email: "",
    estado: 0,
    pagina: "",
    ruc: "",
  });

  const columns = [
    {
      dataField: "tal_id",
      text: "Codigo",
      sort: true,
    },
    {
      dataField: "nombre_ciudad",
      text: "Ciudad",
      sort: true,
      filter: textFilter({
        placeholder: "Ciudad"
      }),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "150px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "tal_nombre",
      text: "Nombre",
      sort: true,
      filter: textFilter({
        placeholder: "Taller"
      }),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "160px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "tal_direccion",
      text: "Dirección",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "180px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "tal_telefono",
      text: "Teléfono",
      sort: true,
    },
    {
      dataField: "tal_email",
      text: "Email",
      sort: true,
    },

    {
      dataField: "tal_ruc",
      text: "RUC",
      sort: true,
    },
    {
      dataField: "fotos",
      text: "Fotos",
      formatter: (cell, row, index) => showFotos(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 1)",
        };
      },
    },
    {
      dataField: "tal_ubicacion",
      text: "Ubicación",
      formatter: (cell, row, index) => locationCliente(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 1)",
        };
      },
    },
    {
      dataField: "tal_editar",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },

    {
      dataField: "tal_eliminar",
      text: "Eliminar",
      formatter: (cell, row, index) => deleteRow(row, index),
      sort: true,
    },

    {
      dataField: "tal_usuarios",
      text: "Usuarios",
      formatter: (cell, row, index) => addUser(row, index),
      sort: true,
    },
  ];

  const columnsUsuario = [
    {
      dataField: "usr_nombres",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "usr_apellidos",
      text: "Apellido",
      sort: true,
    },
    {
      dataField: "usr_identificacion",
      text: "Identificacion",
      sort: true,
    },
    {
      dataField: "usr_usuario",
      text: "Usuario",
      sort: true,
    },

    {
      dataField: "usr_clave",
      text: "Clave",
      sort: true,
    },

    {
      dataField: "usr_editar",
      text: "Editar",
      formatter: (cell, row, index) => editRowUser(row, index),
      sort: true,
    },
  ];

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          edit(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} color={'#3BC9EC'} />
      </Button>
    );
  };

  const showFotos = (row, index) => {
    return (
      <Button
        onClick={() => {

          if (row.tal_foto_1 !== "" ){
            openModalFotos(row)
          } else {
            showMessage(`${row.tal_nombre} no tiene fotos del local`, "error");
          }
      
        
        }}
        color="primary"
        variant="contained"
      >
        {row.tal_foto_1 !== "" ?
        <FontAwesomeIcon icon={faImages} size="2x"  color="darkgreen" />
        :
        <FontAwesomeIcon icon={faImages} size="2x" color="darkorange" />
      }
      </Button>
    );
  };

  const locationCliente = (row, index) => {
    return (
      <Button
        onClick={() => {
          if (row.tal_latitude !== 0 ){
            openModalMap(row)
          } else {
            showMessage(`${row.tal_nombre} no esta georeferenciado`, "error");
          }
         
        }}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        {row.tal_latitude !== 0 ?
        <FontAwesomeIcon
          icon={faLocationArrow}
          size="2x"
          color="darkgreen"
          textAlign="center"
          style={{ alignSelf: "center", justifyContent: "center" }}
        />
        : 
        <FontAwesomeIcon
          icon={faLocationArrow}
          size="2x"
          color="darkred"
          textAlign="center"
          style={{ alignSelf: "center", justifyContent: "center" }}
        />
        
        }
      </Button>
    );
  };

  const deleteRow = (row, index) => {
    return (
      <Button
        onClick={() => {

          deleteTaller(row,index)
          
          // edit(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faWindowClose} color={'red'} />
      </Button>
    );
  };

  

  const editRowUser = (row, index) => {
    return (
      <Button
        onClick={() => {
          editUser(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const addUser = (row, index) => {
    return (
      <Button
        onClick={() => {
          addUserClick(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faUsers} color={'#7C4AF9'} />
      </Button>
    );
  };

  function openModalFotos(item) {
    // console.log(item);
  
    // setIMGS(item.ccli_cobro);
    // itemsCarousel(item)

    if (item.tal_foto_1 != "") {
      imgs.push(item.tal_foto_1);
     
    } else if ( item.tal_foto_1 != "" && item.tal_foto_2 != "" ) {
      imgs.push(item.tal_foto_1);
      imgs.push(item.tal_foto_2);
    } else if ( item.tal_foto_1 != "" && item.tal_foto_2 != "" && item.tal_foto_3 != "") {
      imgs.push(item.tal_foto_1);
      imgs.push(item.tal_foto_2);
      imgs.push(item.tal_foto_3);
      
    } 
    setshowModalFotos(true);
  }

  const openModalMap = async (row) => {
    console.log(row);
    setshowModalMap(true);
    setLat(row.tal_latitude);
    setLon(row.tal_longitude);
    sethoraMapa(row.tal_nombre);
  };

  const edit = (row, index) => {
    settaller((taller) => {
      return {
        ...taller,
        tal_codigo: row.tal_codigo,
        tal_id: row.tal_id,
        tal_razon_social: row.tal_razon_social,
        tal_nombre_comercial: row.tal_nombre,
        tal_cuidad: row.tal_cuidad,
        tal_direccion: row.tal_direccion,
        tal_telefono: row.tal_telefono,
        tal_email_taller: row.tal_email,
        // tal_representante: row.tal_REPRESENTANTE,
        tal_ruc: row.tal_ruc,
        tal_pagina_web: row.tal_pagina_web,
        tal_estado: row.tal_estado,
        // tal_tipo_taller: row.tal_TIPO_taller,
      };
    });

    setshowModal(true);
  };


  const deleteTaller = (row, index) => {
    settaller((taller) => {
      return {
        ...taller,
        tal_codigo: row.tal_codigo,
        tal_id: row.tal_id,
        tal_razon_social: row.tal_razon_social,
        tal_nombre_comercial: row.tal_nombre,
        tal_cuidad: row.tal_cuidad,
        tal_direccion: row.tal_direccion,
        tal_telefono: row.tal_telefono,
        tal_email_taller: row.tal_email,
        // tal_representante: row.tal_REPRESENTANTE,
        tal_ruc: row.tal_ruc,
        tal_pagina_web: row.tal_pagina_web,
        tal_estado: row.tal_estado,
        // tal_tipo_taller: row.tal_TIPO_taller,
      };
    });

    setShowModalConfirmCambio(true)
  
  };

  const editUser = (row, index) => {
    setusuario((usuario) => {
      return {
        ...usuario,
        tal_codigo: row.tal_codigo,
        usr_id: row.usr_id,
        usr_usuario: row.usr_usuario,
        usr_nombre: row.usr_nombres,
        usr_apellido: row.usr_apellidos,
        usr_identificacion: row.usr_identificacion,
        usr_alias: row.usr_alias,
        usr_clave: row.usr_clave,
      };
    });

    setshowModalupdateUsuario(true);
  };

  const addUserClick = (row, index) => {
    settaller((taller) => {
      return {
        ...taller,
        tal_codigo: row.tal_codigo,
        tal_id: row.tal_id,
        tal_razon_social: row.tal_razon_social,
        tal_nombre_comercial: row.tal_nombre,
        tal_cuidad: row.tal_cuidad,
        tal_direccion: row.tal_direccion,
        tal_telefono: row.tal_telefono,
        tal_email_taller: row.tal_email,
        // tal_representante: row.tal_REPRESENTANTE,
        tal_ruc: row.tal_ruc,
        tal_pagina_web: row.tal_pagina_web,
        tal_estado: row.tal_estado,
        // tal_tipo_taller: row.tal_TIPO_taller,
      };
    });

    getUsuarioTaller(row.tal_id);

    setshowModalUsuarios(true);
  };

  const setDataCiudad = (data) => {
    const rows = data.map((row, index) => (
      <option key={row.cui_id} value={row.cui_id}>
        {row.cui_nombre}
      </option>
    ));
    setRowsCiudades(rows);
  };

  const gettallers = async () => {
    setisLoadingTable("visible");
    console.log(server + "taller/getListtallers/" + data.us_empresa.emp_id);
    //poner ide de empresa
    await axios
      .get(server + "taller/getListtallers/" + data.us_empresa.emp_id)
      .then(function (response) {
        setisLoadingTable("invisible");
        // console.log(response);
        setdataTable(response.data);
      })
      .catch(function (error) {
        setisLoadingTable("invisible");
      })
      .then(function () { });
  };

  const getUsuarioTaller = async (idTaller) => {
    await axios
      .get(server + "taller/getAllUsuariotallers/" + idTaller)
      .then(function (response) {
        // console.log(response);
        setdataTableUser(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const createtaller = async () => {
    seterror((error) => {
      return {
        ...error,
        // codigo: "",
        // id: "",

        nombreco: "",
        direccion: "",
        telefono: "",
        email: "",
        // representante: "",
        pagina: "",
        ruc: "",
      };
    });

    if (taller.tal_cuidad === 0) {
      showMessage("Selecciona una Ciudad", "error");
    } else {
      if (
        taller.tal_nombre_comercial !== "" &&
        taller.tal_direccion !== "" &&
        taller.tal_telefono !== "" &&
        taller.tal_email_taller !== "" &&
        taller.tal_ruc !== ""
      ) {
        setisLoadingtallerCreada("visible");
        await axios
          .post(server + "taller/createtaller", {
            tal_ruc: taller.tal_ruc,
            tal_nombre: taller.tal_nombre_comercial,
            tal_cuidad: taller.tal_cuidad,
            tal_direccion: taller.tal_direccion,
            tbl_empresa_emp_id: 1,
            tal_telefono: taller.tal_telefono,
            tal_email: taller.tal_email_taller,
          })

          .then(function (response) {
            setisLoadingtallerCreada("invisible");

            if (response.status === 200) {
              showMessage("taller creada con éxito", "success");

              settaller((taller) => {
                return {
                  ...taller,

                  tal_razon_social: "",
                  tal_nombre_comercial: "",
                  tal_cuidad: 0,
                  tal_direccion: "",
                  tal_telefono: "",
                  tal_email_taller: "",
                  tal_ruc: "",
                  tal_pagina_web: "",
                };
              });

              gettallers();
            } else {
              showMessage("Error el crear", "error");
            }
          })
          .catch(function (error) {
            showMessage("Error con el servidor", "error");
          });
      } else {
        if (taller.tal_nombre_comercial === "") {
          seterror((error) => {
            return { ...error, nombreco: "Campo requerido" };
          });
        }

        if (taller.tal_direccion === "") {
          seterror((error) => {
            return { ...error, direccion: "Campo requerido" };
          });
        }

        if (taller.tal_telefono === "") {
          seterror((error) => {
            return { ...error, telefono: "Campo requerido" };
          });
        }

        if (taller.tal_email_taller === "") {
          seterror((error) => {
            return { ...error, email: "Campo requerido" };
          });
        }

        if (taller.tal_ruc === "") {
          seterror((error) => {
            return { ...error, ruc: "Campo requerido" };
          });
        }

        showMessage("Campos incompletos", "error");
      }
    }
  };

  const createusuario = async () => {
    seterrorusuario((errorusuario) => {
      return {
        ...errorusuario,
        usr_usuario: "",
        usr_nombre: "",
        usr_apellido: "",
        usr_identificacion: "",
        usr_alias: "",
        usr_clave: "",
      };
    });

    if (
      usuario.usr_usuario !== "" &&
      usuario.usr_nombre !== "" &&
      usuario.usr_apellido !== "" &&
      usuario.usr_identificacion !== "" &&
      usuario.usr_alias !== "" &&
      usuario.usr_clave !== ""
    ) {
      setisLoadingtallerCreada("visible");
      await axios
        .post(server + "taller/createusuariotaller", {
          usr_nombres: usuario.usr_nombre,
          usr_apellidos: usuario.usr_apellido,
          usr_identificacion: usuario.usr_identificacion,
          usr_alias: usuario.usr_usuario,
          usr_clave: usuario.usr_clave,
          usr_usuario: usuario.usr_usuario,
          tbl_taller_tal_id: taller.tal_id,
        })

        .then(function (response) {
          setisLoadingtallerCreada("invisible");

          if (response.status === 200) {
            showMessage("Usuario creada con éxito", "success");

            setshowModalCreateUsuario(false);
            setusuario((usuario) => {
              return {
                ...usuario,
                usr_id: 0,
                usr_usuario: "",
                usr_nombre: "",
                usr_apellido: "",
                usr_identificacion: "",
                usr_alias: "",
                usr_clave: "",
              };
            });

            getUsuarioTaller(taller.tal_id);

            //getData()
          } else {
            showMessage("Error el crear", "error");
          }
        })
        .catch(function (error) {
          showMessage("Error con el servidor", "error");
        });
    } else {
      if (usuario.usr_usuario === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_usuario: "Campo requerido" };
        });
      }

      if (usuario.usr_nombre === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_nombre: "Campo requerido" };
        });
      }

      if (usuario.usr_apellido === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_apellido: "Campo requerido" };
        });
      }
      if (usuario.usr_identificacion === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_identificacion: "Campo requerido" };
        });
      }
      if (usuario.usr_alias === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_alias: "Campo requerido" };
        });
      }
      if (usuario.usr_clave === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_clave: "Campo requerido" };
        });
      }

      showMessage("Campos incompletos", "error");
    }
  };

  const updateusuario = async () => {
    seterrorusuario((errorusuario) => {
      return {
        ...errorusuario,
        usr_usuario: "",
        usr_nombre: "",
        usr_apellido: "",
        usr_identificacion: "",
        usr_alias: "",
        usr_clave: "",
      };
    });

    if (
      usuario.usr_usuario !== "" &&
      usuario.usr_nombre !== "" &&
      usuario.usr_apellido !== "" &&
      usuario.usr_identificacion !== "" &&
      usuario.usr_alias !== "" &&
      usuario.usr_clave !== ""
    ) {
      setisLoadingtallerCreada("visible");
      await axios
        .post(server + "taller/updateusuariotaller", {
          usr_id: usuario.usr_id,
          usr_nombres: usuario.usr_nombre,
          usr_apellidos: usuario.usr_apellido,
          usr_identificacion: usuario.usr_identificacion,
          usr_alias: usuario.usr_usuario,
          usr_clave: usuario.usr_clave,
          usr_usuario: usuario.usr_usuario,
          tbl_taller_tal_id: taller.tal_id,
        })

        .then(function (response) {
          setisLoadingtallerCreada("invisible");

          if (response.status === 200) {
            showMessage("Usuario creada con éxito", "success");

            setshowModalupdateUsuario(false);
            setusuario((usuario) => {
              return {
                ...usuario,
                usr_id: 0,
                usr_usuario: "",
                usr_nombre: "",
                usr_apellido: "",
                usr_identificacion: "",
                usr_alias: "",
                usr_clave: "",
              };
            });

            getUsuarioTaller(taller.tal_id);

            //getData()
          } else {
            showMessage("Error el crear", "error");
          }
        })
        .catch(function (error) {
          showMessage("Error con el servidor", "error");
        });
    } else {
      if (usuario.usr_usuario === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_usuario: "Campo requerido" };
        });
      }

      if (usuario.usr_nombre === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_nombre: "Campo requerido" };
        });
      }

      if (usuario.usr_apellido === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_apellido: "Campo requerido" };
        });
      }
      if (usuario.usr_identificacion === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_identificacion: "Campo requerido" };
        });
      }
      if (usuario.usr_alias === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_alias: "Campo requerido" };
        });
      }
      if (usuario.usr_clave === "") {
        seterrorusuario((errorusuario) => {
          return { ...errorusuario, usr_clave: "Campo requerido" };
        });
      }

      showMessage("Campos incompletos", "error");
    }
  };

  const updatetaller = async () => {
    seterror((error) => {
      return {
        ...error,
        codigo: "",
        id: "",
        razon: "",
        nombreco: "",
        direccion: "",
        telefono: "",
        email: "",
        estado: "",
        pagina: "",
        ruc: "",
      };
    });

    if (taller.tal_cuidad === 0) {
      showMessage("Selecciona una Ciudad", "error");
    } else {
      if (
        taller.tal_nombre_comercial !== "" &&
        taller.tal_direccion !== "" &&
        taller.tal_telefono !== "" &&
        taller.tal_email_taller !== "" &&
        taller.tal_ruc !== ""
      ) {
        // setisLoadingActualizar("visible");
        await axios
          .post(server + "taller/updatetaller", {
            tal_id: taller.tal_id,
            tal_ruc: taller.tal_ruc,
            tal_nombre: taller.tal_nombre_comercial,
            tal_cuidad: taller.tal_cuidad,
            tal_direccion: taller.tal_direccion,
            tal_telefono: taller.tal_telefono,
            tal_email: taller.tal_email_taller,
            tal_estado: 1
          })
          .then(function (response) {
            // setisLoadingActualizar("invisible");
            if (response.status === 200) {
              showMessage("Datos actualizados con éxito", "success");

              settaller((taller) => {
                return {
                  ...taller,
                  tal_codigo: "",
                  tal_id: "",
                  tal_razon_social: "",
                  tal_nombre_comercial: "",
                  tal_cuidad: 0,
                  tal_direccion: "",
                  tal_telefono: "",
                  tal_email_taller: "",
                  // tal_representante: "",
                  tal_ruc: "",
                  tal_pagina_web: "",
                  // tal_tipo_taller: "0",
                };
              });
              setshowModal(false);
              gettallers();
              //getData()
            } else {
              showMessage("Error el actualizar datos de taller", "error");
              // setisLoadingActualizar("invisible");
            }
          })
          .catch(function (error) {
            showMessage("Problemas al actualizar taller", "error");
            // setisLoadingActualizar("invisible");
          });
      } else {
        // if (taller.tal_codigo === "") {
        //   seterror((error) => {
        //     return { ...error, codigo: "Campo requerido" };
        //   });
        // }

        // if (taller.tal_id === "") {
        //   seterror((error) => {
        //     return { ...error, id: "Campo requerido" };
        //   });
        // }

        if (taller.tal_razon_social === "") {
          seterror((error) => {
            return { ...error, razon: "Campo requerido" };
          });
        }

        if (taller.tal_nombre_comercial === "") {
          seterror((error) => {
            return { ...error, nombreco: "Campo requerido" };
          });
        }

        if (taller.tal_direccion === "") {
          seterror((error) => {
            return { ...error, direccion: "Campo requerido" };
          });
        }

        if (taller.tal_telefono === "") {
          seterror((error) => {
            return { ...error, telefono: "Campo requerido" };
          });
        }

        if (taller.tal_email_taller === "") {
          seterror((error) => {
            return { ...error, email: "Campo requerido email" };
          });
        }

        if (taller.tal_estado === 0) {
          seterror((error) => {
            return { ...error, estado: "Campo requerido" };
          });
        }

        if (taller.tal_pagina_web === "") {
          seterror((error) => {
            return { ...error, pagina: "Campo requerido" };
          });
        }

        if (taller.tal_ruc === "") {
          seterror((error) => {
            return { ...error, ruc: "Campo requerido" };
          });
        }

        showMessage("Campos incompletos", "error");
      }
    }
  };


  const deletetaller = async () => {

    
        await axios
          .post(server + "taller/desactivartaller", {
            tal_id: taller.tal_id,
            tal_ruc: taller.tal_ruc,
            tal_nombre: taller.tal_nombre_comercial,
            tal_cuidad: taller.tal_cuidad,
            tal_direccion: taller.tal_direccion,
            tal_telefono: taller.tal_telefono,
            tal_email: taller.tal_email_taller,
          })
          .then(function (response) {
            // setisLoadingActualizar("invisible");
            if (response.status === 200) {
              showMessage("Taller Eliminado con éxito", "success");

              settaller((taller) => {
                return {
                  ...taller,
                  tal_codigo: "",
                  tal_id: "",
                  tal_razon_social: "",
                  tal_nombre_comercial: "",
                  tal_cuidad: 0,
                  tal_direccion: "",
                  tal_telefono: "",
                  tal_email_taller: "",
                  // tal_representante: "",
                  tal_ruc: "",
                  tal_pagina_web: "",
                  // tal_tipo_taller: "0",
                };
              });

              setShowModalConfirmCambio(false)
              gettallers();
              //getData()
            } else {
              showMessage("Error el actualizar datos de taller", "error");
              // setisLoadingActualizar("invisible");
            }
          })
          .catch(function (error) {
            showMessage("Problemas al actualizar taller", "error");
            // setisLoadingActualizar("invisible");
          });
       
      
      
    
  };

  useEffect(() => {
    
    getCiudad();
    gettallersInicial();
  }, [server, data]);

 
  const getCiudad = async () => {
    await axios
      .get(server + "empresas/getListCuidades")
      .then(function (response) {
        // console.log(response);
        setDataCiudad(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };
  const gettallersInicial = async () => {
    setisLoadingTable("visible");
    //poner ide de empresa
    await axios
      .get(server + "taller/getListtallers/" + data.us_empresa.emp_id)
      .then(function (response) {
        setisLoadingTable("invisible");
        console.log(response.data.length);
        setdataTable(response.data);
      })
      .catch(function (error) {
        setisLoadingTable("invisible");
      })
      .then(function () { });
  };
  

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle heading="Taller" icon="pe-7s-tools" />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <div>
                <Card>
                  <div className="text-center">
                    <div
                      className={isLoadingtallerCreada}
                      style={{ marginTop: 10 }}
                    >
                      <Spinner
                        animation="border"
                        role="status"
                        variant="secondary"
                      ></Spinner>
                    </div>
                  </div>

                  <Card.Header as="h5">Creación de Nuevo taller </Card.Header>
                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Nombre :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese un Nombre Comercial"
                            id="tal_nombre_comercial"
                            value={taller.tal_nombre_comercial}
                            onChange={(e) => {
                              const val = e.target.value;
                              settaller((taller) => {
                                return {
                                  ...taller,
                                  tal_nombre_comercial: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.nombreco}</span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            RUC:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={13}
                            placeholder="Ingrese un RUC"
                            id="tal_ruc"
                            value={taller.tal_ruc}
                            onChange={(e) => {
                              const val = e.target.value;
                              settaller((taller) => {
                                return { ...taller, tal_ruc: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.ruc}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Dirección:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Dirección"
                            id="tal_direccion"
                            value={taller.tal_direccion}
                            onChange={(e) => {
                              const val = e.target.value;
                              settaller((taller) => {
                                return { ...taller, tal_direccion: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {error.direccion}
                          </span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Ciudad:{" "}
                          </Form.Label>
                          <Form.Control
                            as="select"
                            onChange={(e) => {
                              const val = Number(e.target.value);
                              settaller((taller) => {
                                return {
                                  ...taller,
                                  tal_cuidad: val,
                                };
                              });
                            }}
                          >
                            <option value="0">N/A</option>
                            {getRowsCiudades}
                          </Form.Control>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Email:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese un Email"
                            id="tal_email_taller"
                            value={taller.tal_email_taller}
                            onChange={(e) => {
                              const val = e.target.value;
                              settaller((taller) => {
                                return { ...taller, tal_email_taller: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.email}</span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Teléfono:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Teléfono"
                            id="tal_telefono"
                            value={taller.tal_telefono}
                            onChange={(e) => {
                              const val = e.target.value;
                              settaller((taller) => {
                                return { ...taller, tal_telefono: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.telefono}</span>
                        </Col>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          onClick={() => {
                            createtaller();
                          }}
                          variant="primary"
                        >
                          Crear taller
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </div>
              <div>
                <Card className="mt-2">
                  <Card.Header as="h5">
                    Listado general de "tallers"
                  </Card.Header>
                  <Card.Body>
                    <ToolkitProvider
                      keyField="id"
                      data={dataTable}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <BootstrapTable
                            // keyField="tal_id"
                            // wrapperClasses="table-responsive"
                            // data={dataTable}
                            // columns={columns}
                            striped
                            hover
                            condensed
                            wrapperClasses="table-responsive"
                            // class="react-bootstrap-table table"

                            filter={filterFactory()}
                            pagination={paginationFactory(optionsPagination)}
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                    <div className="text-center">
                      <div className={isLoadingTable} style={{ marginTop: 10 }}>
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                        ></Spinner>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>

              <div>
                <Modal
                  show={showModal}
                  // onHide={!showModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Actualización de datos de: {taller.tal_nombre_comercial}
                    </Modal.Title>
                  </Modal.Header>

                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Nombre :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese un Nombre Comercial"
                            id="tal_nombre_comercial"
                            value={taller.tal_nombre_comercial}
                            onChange={(e) => {
                              const val = e.target.value;
                              settaller((taller) => {
                                return {
                                  ...taller,
                                  tal_nombre_comercial: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.nombreco}</span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            RUC:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={13}
                            placeholder="Ingrese un RUC"
                            id="tal_ruc"
                            value={taller.tal_ruc}
                            onChange={(e) => {
                              const val = e.target.value;
                              settaller((taller) => {
                                return { ...taller, tal_ruc: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.ruc}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Dirección:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Dirección"
                            id="tal_direccion"
                            value={taller.tal_direccion}
                            onChange={(e) => {
                              const val = e.target.value;
                              settaller((taller) => {
                                return { ...taller, tal_direccion: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {error.direccion}
                          </span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Ciudad:{" "}
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={taller.tal_cuidad}
                            onChange={(e) => {
                              const val = Number(e.target.value);
                              settaller((taller) => {
                                return {
                                  ...taller,
                                  tal_cuidad: val,
                                };
                              });
                            }}
                          >
                            <option value="0">N/A</option>
                            {getRowsCiudades}
                          </Form.Control>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Email:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese un Email"
                            id="tal_email_taller"
                            value={taller.tal_email_taller}
                            onChange={(e) => {
                              const val = e.target.value;
                              settaller((taller) => {
                                return { ...taller, tal_email_taller: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.email}</span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Teléfono:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Teléfono"
                            id="tal_telefono"
                            value={taller.tal_telefono}
                            onChange={(e) => {
                              const val = e.target.value;
                              settaller((taller) => {
                                return { ...taller, tal_telefono: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.telefono}</span>
                        </Col>
                      </FormGroup>
                    </Form>
                  </Card.Body>

                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        updatetaller();
                      }}
                    >
                      Actualizar
                    </Button>
                    <Button
                      onClick={() => {
                        setshowModal(false);

                        seterror((error) => {
                          return {
                            ...error,
                            codigo: "",
                            id: "",
                            razon: "",
                            nombreco: "",
                            direccion: "",
                            telefono: "",
                            email: "",
                            representante: "",
                            pagina: "",
                            ruc: "",
                          };
                        });
                        settaller((taller) => {
                          return {
                            ...taller,
                            tal_codigo: "",
                            tal_id: "",
                            tal_razon_social: "",
                            tal_nombre_comercial: "",
                            tal_cuidad: 0,
                            tal_direccion: "",
                            tal_telefono: "",
                            tal_email_taller: "",
                            tal_representante: "",
                            tal_ruc: "",
                            tal_pagina_web: "",
                            tal_tipo_taller: "0",
                          };
                        });
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>

              <Modal
                  show={showModalMap}
                  onHide={() => setshowModalMap(false)}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <Map
                      lat={lat}
                      lon={lon}
                      titulo={horaMapa}
                      url="https://firebasestorage.googleapis.com/v0/b/proyecto-bajaj.appspot.com/o/Bajaj%2Fpersonamarker.png?alt=media&token=d943d61c-39fd-4983-acf4-42e8e5dde71b"
                      googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyBWQwWMO0El5LZPg5eECmCQ7bh5o2Zg738"
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `400px` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                    ></Map>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        setshowModalMap(false);
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>


                <Modal
                  show={showModalFotos}
                  {...props}
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Title id="contained-modal-title-vcenter">
                    Imagenes tomadas
                  </Modal.Title>

                  {imgs.length == 1 ? (
                    <Carousel>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={imgs[0]}
                          alt="First slide"
                        />
                      </Carousel.Item>
                    </Carousel>
                  ) : imgs.length == 2 ? (
                    <Carousel>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={imgs[0]}
                          alt="First slide"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={imgs[1]}
                          alt="Third slide"
                        />
                      </Carousel.Item>{" "}
                    </Carousel>
                  ) : imgs.length == 3 ? (
                    <Carousel>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={imgs[0]}
                          alt="First slide"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={imgs[1]}
                          alt="Third slide"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={imgs[2]}
                          alt="Third slide"
                        />
                      </Carousel.Item>{" "}
                    </Carousel>
                  ) : null}

                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={imgs.cob_img3}
                      alt="Third slide"
                    />
                  </Carousel.Item>

                  <Modal.Footer>
                    <Button onClick={() => {
                      setshowModalFotos(false)
                      setIMGS([])
                      }}>
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>

              <Modal
                  show={showModalConfirmCambio}
        
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  style={{
                    borderWidth: 1,
                    borderColor: "rgba(128,128,128,0.8)",
                    backgroundColor: "rgba(3,108,199,0.3)",
                  }}
                >
                  <Modal.Header>
                    <Modal.Title>Atención:</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Estas seguro de eliminar el taller : {taller.tal_nombre_comercial} ?</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-success"
                      onClick={() => setShowModalConfirmCambio(false)}
                    >
                      Cerrar
                    </Button>

                    <Button
                      className="btn btn-warning"
                      onClick={() => deletetaller()}
                    >
                      Eliminar
                    </Button>
                  </Modal.Footer>
                </Modal>


              <div>
                <Modal
                  show={showModalUsuarios}
                  // onHide={!showModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Usuarios de: {taller.tal_nombre_comercial}
                    </Modal.Title>
                  </Modal.Header>

                  <Card.Body>
                    <Button
                      onClick={() => {
                        seterrorusuario((errorusuario) => {
                          return {
                            ...errorusuario,

                            usr_usuario: "",
                            usr_nombre: "",
                            usr_apellido: "",
                            usr_identificacion: "",
                            usr_alias: "",
                            usr_clave: "",
                          };
                        });
                        setusuario((usuario) => {
                          return {
                            ...usuario,
                            usr_id: 0,
                            usr_usuario: "",
                            usr_nombre: "",
                            usr_apellido: "",
                            usr_identificacion: "",
                            usr_alias: "",
                            usr_clave: "",
                          };
                        });
                        setshowModalCreateUsuario(true);
                      }}
                    >
                      Crear Usuario
                    </Button>

                    <div style={{ marginTop: 8 }}>
                      <BootstrapTable
                        keyField="usr_id"
                        data={dataTableUser}
                        columns={columnsUsuario}
                        pagination={paginationFactory()}
                      />
                    </div>
                  </Card.Body>

                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        setshowModalUsuarios(false);

                        seterror((error) => {
                          return {
                            ...error,
                            codigo: "",
                            id: "",
                            razon: "",
                            nombreco: "",
                            direccion: "",
                            telefono: "",
                            email: "",
                            representante: "",
                            pagina: "",
                            ruc: "",
                          };
                        });
                        settaller((taller) => {
                          return {
                            ...taller,
                            tal_codigo: "",
                            tal_id: "",
                            tal_razon_social: "",
                            tal_nombre_comercial: "",
                            tal_cuidad: 0,
                            tal_direccion: "",
                            tal_telefono: "",
                            tal_email_taller: "",
                            tal_representante: "",
                            tal_ruc: "",
                            tal_pagina_web: "",
                            tal_tipo_taller: "0",
                          };
                        });
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>

              <div>
                <Modal
                  show={showModalCreateUsuario}
                  // onHide={!showModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Crear usuario Nuevo de : {taller.tal_nombre_comercial}
                    </Modal.Title>
                  </Modal.Header>

                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Usuario :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Usuario"
                            id="usr_usuario"
                            value={usuario.usr_usuario}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_usuario: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_usuario}
                          </span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Clave :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Clave"
                            id="usr_clave"
                            value={usuario.usr_clave}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_clave: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_clave}
                          </span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Nombre :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Nombre"
                            id="usr_nombre"
                            value={usuario.usr_nombre}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_nombre: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_nombre}
                          </span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Apellido :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Apellido"
                            id="usr_apellido"
                            value={usuario.usr_apellido}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_apellido: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_apellido}
                          </span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Identifiación :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Identifiación"
                            id="usr_identificacion"
                            value={usuario.usr_identificacion}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_identificacion: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_identificacion}
                          </span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Alias :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Alias"
                            id="usr_alias"
                            value={usuario.usr_alias}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_alias: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_alias}
                          </span>
                        </Col>
                      </FormGroup>
                    </Form>

                    <div className="text-center">
                      <div
                        className={isLoadingtallerCreada}
                        style={{ marginTop: 10 }}
                      >
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                        ></Spinner>
                      </div>
                    </div>
                  </Card.Body>
                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        createusuario();
                      }}
                    >
                      Crear Usuario
                    </Button>
                    <Button
                      onClick={() => {
                        setshowModalCreateUsuario(false);

                        seterrorusuario((errorusuario) => {
                          return {
                            ...errorusuario,
                            usr_usuario: "",
                            usr_nombre: "",
                            usr_apellido: "",
                            usr_identificacion: "",
                            usr_alias: "",
                            usr_clave: "",
                          };
                        });
                        setusuario((usuario) => {
                          return {
                            ...usuario,
                            usr_id: 0,
                            usr_usuario: "",
                            usr_nombre: "",
                            usr_apellido: "",
                            usr_identificacion: "",
                            usr_alias: "",
                            usr_clave: "",
                          };
                        });
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>

              <div>
                <Modal
                  show={showModalupdateUsuario}
                  // onHide={!showModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Actualizar Usuario
                    </Modal.Title>
                  </Modal.Header>

                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Usuario :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Usuario"
                            id="usr_usuario"
                            value={usuario.usr_usuario}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_usuario: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_usuario}
                          </span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Clave :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Clave"
                            id="usr_clave"
                            value={usuario.usr_clave}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_clave: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_clave}
                          </span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Nombre :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Nombre"
                            id="usr_nombre"
                            value={usuario.usr_nombre}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_nombre: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_nombre}
                          </span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Apellido :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Apellido"
                            id="usr_apellido"
                            value={usuario.usr_apellido}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_apellido: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_apellido}
                          </span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Identifiación :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Identifiación"
                            id="usr_identificacion"
                            value={usuario.usr_identificacion}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_identificacion: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_identificacion}
                          </span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Alias :{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese Alias"
                            id="usr_alias"
                            value={usuario.usr_alias}
                            onChange={(e) => {
                              const val = e.target.value;
                              setusuario((usuario) => {
                                return {
                                  ...usuario,
                                  usr_alias: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {errorusuario.usr_alias}
                          </span>
                        </Col>
                      </FormGroup>
                    </Form>

                    <div className="text-center">
                      <div
                        className={isLoadingtallerCreada}
                        style={{ marginTop: 10 }}
                      >
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                        ></Spinner>
                      </div>
                    </div>
                  </Card.Body>
                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        updateusuario();
                      }}
                    >
                      Actualizar Usuario
                    </Button>
                    <Button
                      onClick={() => {
                        setshowModalupdateUsuario(false);

                        seterrorusuario((errorusuario) => {
                          return {
                            ...errorusuario,
                            usr_usuario: "",
                            usr_nombre: "",
                            usr_apellido: "",
                            usr_identificacion: "",
                            usr_alias: "",
                            usr_clave: "",
                          };
                        });
                        setusuario((usuario) => {
                          return {
                            ...usuario,
                            usr_id: 0,
                            usr_usuario: "",
                            usr_nombre: "",
                            usr_apellido: "",
                            usr_identificacion: "",
                            usr_alias: "",
                            usr_clave: "",
                          };
                        });
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
