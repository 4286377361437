import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import "./css/login.css";
import { Modal, Button, Col, Row, Form, Spinner } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Swal from "sweetalert2";
// import axios from 'axios'
import Image from "react-bootstrap/Image";
import Logobajaj from "../../images/assets/logo_desarrollo1.png";
import * as SecureLS from "secure-ls";
import axios from "axios";

function Index() {
  const history = useHistory();
  const [usuario, setUsuario] = useState("");
  const [password, setPassword] = useState("");
  const server = window.$linkserver;

  // const [user, setUser] = useState("");
  // const [pass, setPass] = useState("");
  // const [getDataMenu, setDataMenu] = useState(
  //   '[{"id":296,"nama":"Appetizer"},{"id":295,"nama":"Bahan"}]'
  // );
  // const [dataa, setdata] = useState();
  const [showModal, setshowModal] = useState(false);
  const [email, setemail] = useState();

  const [isLoading, setIsLoading] = useState("invisible");

  const [imagenBacground, setimagenBacground] = useState("");
  const [logo, setlogo] = useState("");

  useEffect(() => {
    document.documentElement.style.setProperty("--tipoS", "hidden");
    const getDataDominio = async (dominio) => {
      await axios
        .get(server + "empresas/getParametrosEmpresaDominio/" + dominio)

        .then(function (response) {
          response.data.map((item, key) => {
            if (item.pae_tipo == 1) {
              setimagenBacground(item.pae_parametro);
            }

            if (item.pae_tipo == 3) {
              setlogo(item.pae_parametro);
            }

            if (item.pae_tipo == 4) {
              document.documentElement.style.setProperty(
                "--primary",
                "#" + item.pae_parametro
              );
            }
          });
        })
        .catch(function (error) {})
        .then(function () {});
    };
    // Get Empresa
    const url = window.location.hostname;
    const sub = url.split(".");
    getDataDominio(sub[0]);
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    let infoUsuario = [];
    const ls = new SecureLS({
      encodingType: "des",
      isCompression: false,
      encryptionSecret: "admin-hyundai",
    });

    setIsLoading("visible");

    await axios
      .get(server + "usuario/logeoUser/" + usuario + "/" + password)
      .then(function (response) {
        setIsLoading("invisible");

        if (response.status === 200) {
          showMessage("Logeo", "success");

          infoUsuario = response.data;
          ls.set("ask-hy", { infoUsuario });

          history.push("/dashboard");
        } else {
          showMessage(response.data.message, "error");
        }

        //setDataCargo(response.data)
      })
      .catch(function (error) {
        setIsLoading("invisible");

        showMessage("Ocurrio un error intentalo de nuevo", "error");
      });
  };

  //   // if (usuario === "admin@ad-a.com.ec" && password === "admin") {
  //   //   infoUsuario = {
  //   //     status: true,
  //   //     success: true,
  //   //     autenticado: true,
  //   //     data: {
  //   //       US_CODIGO: 8,
  //   //       US_CEDULA: "0987654321",
  //   //       US_NOMBRE: "Carlos",
  //   //       US_APELLIDO: "Fernandez",
  //   //       US_DIRECCION: "Uncovia",
  //   //       US_TELEFONO: "2900000",
  //   //       US_CELULAR: "0987654321",
  //   //       US_EMAIL: "demo@demo.com",
  //   //       US_CARGO: "Cargo 4",
  //   //       US_ROL: "Carga 2",
  //   //       US_AUTENTICADO: true,
  //   //       US_MODULO: [
  //   //         {
  //   //           mo_codigo: 1,
  //   //           mo_nombre: "Principal",
  //   //           mo_descripcion: "ss",
  //   //           mo_menu: [
  //   //             {
  //   //               MEN_CODIGO: 2,
  //   //               MEN_NOMBRE: "Home",
  //   //               men_descripcion: "Descripción deHome",
  //   //               men_icono: "icono 1",
  //   //               men_opciones: [
  //   //                 {
  //   //                   op_codigo: 11,
  //   //                   OP_NOMBRE: "nuevaOp4",
  //   //                   OP_URL: "/.op4",
  //   //                 },
  //   //                 {
  //   //                   op_codigo: 7,
  //   //                   OP_NOMBRE: "opcion",
  //   //                   OP_URL: "/url/opcion",
  //   //                 },
  //   //                 {
  //   //                   op_codigo: 15,
  //   //                   OP_NOMBRE: "op8",
  //   //                   OP_URL: "/op8",
  //   //                 },
  //   //               ],
  //   //             },
  //   //           ],
  //   //         },
  //   //         {
  //   //           mo_codigo: 2,
  //   //           mo_nombre: "Vehículo",
  //   //           mo_descripcion: "Todo en Vehículo",
  //   //           mo_menu: [
  //   //             {
  //   //               MEN_CODIGO: 2,
  //   //               MEN_NOMBRE: "Home",
  //   //               men_descripcion: "Descripción deHome",
  //   //               men_icono: "icono 1",
  //   //               men_opciones: [
  //   //                 {
  //   //                   MEN_CODIGO: 4,
  //   //                   MEN_NOMBRE: "Catalogo",
  //   //                   men_descripcion: "Menu 3",
  //   //                   men_icono: "icono 3",
  //   //                   men_opciones: [
  //   //                     {
  //   //                       op_codigo: 14,
  //   //                       OP_NOMBRE: "op7",
  //   //                       OP_URL: "/op7",
  //   //                     },
  //   //                   ],
  //   //                 },
  //   //               ],
  //   //             },
  //   //           ],
  //   //         },
  //   //       ],
  //   //       US_PERMISOS: [
  //   //         {
  //   //           PER_CODIGO: 2,
  //   //           PER_NOMBRE: "Empresa",
  //   //           PER_DESCRIPCION: "Empresa",
  //   //         },
  //   //       ],
  //   //       US_EMPRESAS: [
  //   //         {
  //   //           EMP_CODIGO: 36,
  //   //           EMP_ID: "036",
  //   //           EMP_RAZON_SOCIAL: "GALARMOBIL S.A.",
  //   //           EMP_NOMBRE_COMERCIAL: "GALARMOBIL",
  //   //           EMP_DIRECCION: "KM 10 1/2 VIA SAMBRORONDON\n",
  //   //           EMP_TELEFONO: "042145581\n",
  //   //           EMP_EMAIL_EMPRESA: "rindaburo@galarmobil.com.ec\n",
  //   //           EMP_REPRESENTANTE: "ROLANDO INDABURO PALACIOS\n",
  //   //           EMP_RUC: "0992535776001",
  //   //           EMP_PAGINA_WEB: "www.galarmobil.com.ec",
  //   //         },
  //   //         {
  //   //           EMP_CODIGO: 2,
  //   //           EMP_ID: "002",
  //   //           EMP_RAZON_SOCIAL: "NEOHYUNDAI",
  //   //           EMP_NOMBRE_COMERCIAL: "NEOHYUNDAI",
  //   //           EMP_DIRECCION: "Av. Galo Plaza Lasso",
  //   //           EMP_TELEFONO: "(02) 603-0740",
  //   //           EMP_EMAIL_EMPRESA: "",
  //   //           EMP_REPRESENTANTE: "",
  //   //           EMP_RUC: "0190310647001",
  //   //           EMP_PAGINA_WEB: "",
  //   //         },
  //   //         {
  //   //           EMP_CODIGO: 18,
  //   //           EMP_ID: "018",
  //   //           EMP_RAZON_SOCIAL: "ECSYAUTO S.A.",
  //   //           EMP_NOMBRE_COMERCIAL: "ECSYAUTO",
  //   //           EMP_DIRECCION:
  //   //             "Av. Remigio Tamariz y Av. Solano, Edificio Office, 3er piso, oficina 1",
  //   //           EMP_TELEFONO: "072838771",
  //   //           EMP_EMAIL_EMPRESA: "ecsyauto@ecsyauto.com.ec",
  //   //           EMP_REPRESENTANTE: "Ivan Garcia",
  //   //           EMP_RUC: "0992249102001",
  //   //           EMP_PAGINA_WEB: "www.ecsyauto.com.ec",
  //   //         },
  //   //       ],
  //   //       US_EMPRESA_DEFAULT: null,
  //   //     },
  //   //     message: "Correcto",
  //   //   };
  //   //   ls.set("ask-hy", { infoUsuario });
  //   //   // console.log(infoUsuario.data.US_PERMISOS[0].PER_CODIGO)
  //   //   if (infoUsuario.data.US_PERMISOS[0].PER_CODIGO === 2) {
  //   //     history.push("/listadoempresa");
  //   //   } else {
  //   //     history.push("/dashboard");
  //   //   }
  //   // }

  //   // else {
  //   //   setUsuario("");
  //   //   setPassword("");
  //   //   infoUsuario = [];
  //   //   ls.removeAll();
  //   //   alert("Usuario Incorrecto");
  //   //   history.push("/");
  //   // }
  // };

  // const handleLogin = (e) => {
  //   e.preventDefault();
  //   let infoUsuario = [];
  //   const ls = new SecureLS({
  //     encodingType: "des",
  //     isCompression: false,
  //     encryptionSecret: "admin-hyundai",
  //   });
  //   if (usuario === "admin@admin.com" && password === "admin") {
  //     infoUsuario = {
  //       status: true,
  //       success: true,
  //       autenticado: true,
  //       data: {
  //         US_CODIGO: 8,
  //         US_CEDULA: "0987654321",
  //         US_NOMBRE: "Jhonny",
  //         US_APELLIDO: "Pauta",
  //         US_DIRECCION: "Uncovia",
  //         US_TELEFONO: "2900000",
  //         US_CELULAR: "0987654321",
  //         US_EMAIL: "demo@demo.com",
  //         US_CARGO: "Cargo 4",
  //         US_ROL: "Carga 2",
  //         us_modulo: [
  //           {
  //             mo_codigo: 1,
  //             mo_nombre: "Administración",
  //             mo_menu: [
  //               {
  //                 men_codigo: 2,
  //                 men_nombre: "Cargado de datos",
  //                 men_icono: "pe-7s-home",
  //                 men_opciones: [
  //                   {
  //                     op_codigo: 1,
  //                     label: "Subir datos",
  //                     to: "/subirdatos",
  //                   },
  //                 ],
  //               },
  //               {
  //                 men_codigo: 3,
  //                 men_nombre: "Cartera",
  //                 men_icono: "pe-7s-home",
  //                 men_opciones: [
  //                   {
  //                     op_codigo: 1,
  //                     label: "Visualización de cartera",
  //                     to: "/listacartera",
  //                   },
  //                   {
  //                     op_codigo: 3,
  //                     label: "Compromisos de pago",
  //                     to: "/pagos",
  //                   },
  //                   {
  //                     op_codigo: 4,
  //                     label: "Revisión de visitas",
  //                     to: "/visitas",
  //                   },
  //                   {
  //                     op_codigo: 5,
  //                     label: "Revisión de cobros",
  //                     to: "/cobros",
  //                   },
  //                 ],
  //               },
  //               {
  //                 men_codigo: 4,
  //                 men_nombre: "Mantenimiento",
  //                 men_icono: "pe-7s-home",
  //                 men_opciones: [
  //                   {
  //                     op_codigo: 1,
  //                     label: "Administrar gestor",
  //                     to: "/gestores",
  //                   },
  //                   {
  //                     op_codigo: 2,
  //                     label: "Crear perfil de gestor",
  //                     to: "/perfilgestor",
  //                   },
  //                   {
  //                     op_codigo: 3,
  //                     label: "Crear opciones de gestor",
  //                     to: "/opcionesgestor",
  //                   },
  //                 ],
  //               },
  //               {
  //                 men_codigo: 5,
  //                 men_nombre: "Asignación de cartera",
  //                 men_icono: "pe-7s-home",
  //                 men_opciones: [
  //                   {
  //                     op_codigo: 1,
  //                     label: "Asignar gestor a cartera",
  //                     to: "/asignarcartera",
  //                   },
  //                   // {
  //                   //   op_codigo: 2,
  //                   //   label: "---",
  //                   //   to: "/opcion2",
  //                   // },
  //                 ],
  //               },
  //             ],
  //           },
  //           {
  //             mo_codigo: 2,
  //             mo_nombre: "Reportes",
  //             mo_menu: [
  //               {
  //                 men_codigo: 1,
  //                 men_nombre: "Catalogo",
  //                 men_icono: "pe-7s-news-paper",
  //                 men_opciones: [
  //                   {
  //                     label: "Opcion 4",
  //                     to: "/opcion4",
  //                   },
  //                   {
  //                     label: "Opcion 5",
  //                     to: "/opcion5",
  //                   },
  //                   {
  //                     label: "Opcion 6",
  //                     to: "/opcion6",
  //                   },
  //                 ],
  //               },
  //             ],
  //           },

  //           {
  //             mo_codigo: 3,
  //             mo_nombre: "Talleres",
  //             mo_menu: [
  //               {
  //                 men_codigo: 1,
  //                 men_nombre: "Talleres",
  //                 men_icono: "pe-7s-tools",
  //                 men_opciones: [
  //                   {
  //                     label: "Lista de Talleres",
  //                     to: "/listatalleres",
  //                   },
  //                 ],
  //               },
  //               {
  //                 men_codigo: 2,
  //                 men_nombre: "Repuestos",
  //                 men_icono: "pe-7s-settings",
  //                 men_opciones: [
  //                   {
  //                     label: "Lista de Repuestos",
  //                     to: "/listaderepuestos",
  //                   },
  //                 ],
  //               },
  //               {
  //                 men_codigo: 3,
  //                 men_nombre: "Mano de Obra",
  //                 men_icono: "pe-7s-like2",
  //                 men_opciones: [
  //                   {
  //                     label: "Lista de Mano de Obra",
  //                     to: "/listaobra",
  //                   },
  //                 ],
  //               },
  //               {
  //                 men_codigo: 4,
  //                 men_nombre: "Orden de Pago",
  //                 men_icono: "pe-7s-print",
  //                 men_opciones: [
  //                   {
  //                     label: "Ordenes de Pago",
  //                     to: "/ordenpago",
  //                   },
  //                 ],
  //               },

  //               {
  //                 men_codigo: 5,
  //                 men_nombre: "Registros",
  //                 men_icono: "pe-7s-portfolio",
  //                 men_opciones: [
  //                   {
  //                     label: "Tipo de Registros",
  //                     to: "/tiporegistros",
  //                   },
  //                   {
  //                     label: "Kilometraje",
  //                     to: "/kilometraje",
  //                   },
  //                 ],
  //               },
  //             ],
  //           },

  //           {
  //             mo_codigo: 5,
  //             mo_nombre: "Almacenes",
  //             mo_menu: [
  //               {
  //                 men_codigo: 1,
  //                 men_nombre: "Almacenes",
  //                 men_icono: "pe-7s-culture",
  //                 men_opciones: [
  //                   {
  //                     label: "Gestionar Almacenes",
  //                     to: "/gestionaralmaccenes",
  //                   },
  //                 ],
  //               },
  //             ],
  //           },

  //           {
  //             mo_codigo: 4,
  //             mo_nombre: "ADMINISTRACION",
  //             mo_descripcion: "Administración",
  //             mo_menu: [
  //               {
  //                 men_codigo: 8,
  //                 men_nombre: "Configuración",
  //                 men_descripcion: "Configuración",
  //                 men_icono: "pe-7s-config",
  //                 men_opciones: [
  //                   {
  //                     op_codigo: 12,
  //                     label: "Empresas",
  //                     to: "/empresas",
  //                   },
  //                   {
  //                     op_codigo: 12,
  //                     label: "Usuarios",
  //                     to: "/usuarios",
  //                   },
  //                   {
  //                     op_codigo: 13,
  //                     label: "Cargo",
  //                     to: "/cargo",
  //                   },
  //                   {
  //                     op_codigo: 14,
  //                     label: "Roles",
  //                     to: "/roles",
  //                   },

  //                   {
  //                     op_codigo: 16,
  //                     label: "Permisos",
  //                     to: "/permisos",
  //                   },
  //                   {
  //                     op_codigo: 17,
  //                     label: "Modulos",
  //                     to: "/modulos",
  //                   },
  //                   {
  //                     op_codigo: 18,
  //                     label: "Menu",
  //                     to: "/menu",
  //                   },
  //                   {
  //                     op_codigo: 19,
  //                     label: "Opciones",
  //                     to: "/opciones",
  //                   },
  //                 ],
  //               },
  //             ],
  //           },
  //         ],
  //         US_PERMISOS: [
  //           {
  //             PER_CODIGO: 1,
  //             PER_NOMBRE: "Marca",
  //           },
  //         ],
  //         US_EMPRESAS: [
  //           {
  //             EMP_CODIGO: 36,
  //             EMP_NOMBRE_COMERCIAL: "GALARMOBIL",
  //           },
  //           {
  //             EMP_CODIGO: 2,
  //             EMP_NOMBRE_COMERCIAL: "NEOHYUNDAI",
  //           },
  //           {
  //             EMP_CODIGO: 18,
  //             EMP_NOMBRE_COMERCIAL: "ECSYAUTO",
  //           },
  //         ],
  //         US_EMPRESA_DEFAULT: null,
  //       },
  //       message: "Correcto",
  //     };
  //     ls.set("ask-hy", { infoUsuario });
  //     console.log(infoUsuario.data.US_PERMISOS[0].PER_CODIGO);
  //     if (infoUsuario.data.US_PERMISOS[0].PER_CODIGO === 2) {
  //       history.push("/listadoempresa");
  //     } else {
  //       history.push("/dashboard");
  //     }
  //   } else {
  //     setUsuario("");
  //     setPassword("");
  //     infoUsuario = [];
  //     ls.removeAll();
  //     alert("Usuario Incorrecto");
  //     history.push("/");
  //   }
  // };

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });
  // const setEmailPass = async() =>{

  //       setIsLoading("visible")
  //       await axios.get('http://192.168.1.13:8000/api/usuario/recuperarCuenta/'+email)
  //           .then(function (response) {

  //             setIsLoading("invisible")
  //               if (response.data.success === true) {
  //                 showMessage(response.data.message, 'success')

  //               } else {
  //                 showMessage(response.data.message, 'error')
  //               }

  //               setemail("")
  //               setshowModal(false)

  //           })
  //           .catch(function (error) {

  //           })
  //           .then(function () {

  //           });

  // }

  return (
    <div
      style={{
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      <div
        style={{
          backgroundImage: "url(" + imagenBacground + ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="login-cont"
      >
        <div>
          <Modal
            show={showModal}
            onHide={!showModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Recupera tu contraseña
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Col>
                <Form.Label className="title-create">Correo: </Form.Label>
                <Form.Control
                  maxLength={255}
                  placeholder="Ingrese nombre"
                  id="us_correo"
                  value={email}
                  onChange={(e) => {
                    setemail(e.target.value);
                  }}
                />
              </Col>

              <div className="text-center">
                <div className={isLoading}>
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button>Enviar</Button>
              <Button
                onClick={() => {
                  setshowModal(false);
                  setemail("");
                }}
              >
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <Container>
          <Row className="login-box">
            <Col
              style={{ backgroundColor: "transparent" }}
              md={4}
              className="login-form mx-auto my-auto p-md-3"
            >
              <Row>
                <Col xs={15} md={15} className="mx-auto text-center p-3">
                  <Image
                    src={
                      logo != ""
                        ? logo
                        : "https://firebasestorage.googleapis.com/v0/b/proyecto-bajaj.appspot.com/o/Predeterminados%2Flogo_bajaj.jpeg?alt=media&token=e4b90edf-f176-41e2-b684-948efcd0faf6"
                    }
                    alt="logo hyundai"
                    fluid
                    className="text-center p-3"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mx-auto">
                  <Form onSubmit={handleLogin}>
                    <Form.Group>
                      <Form.Control
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Ingrese su email"
                        value={usuario}
                        onChange={(event) => setUsuario(event.target.value)}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        id="password"
                        name="password"
                        type="password"
                        placeholder="Ingrese su contraseña"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Button type="submit" block>
                        Iniciar
                      </Button>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Link onClick={() => setshowModal(true)} to="/">
                    <span
                      style={{ color: "white", fontSize: 16 }}
                      className="login-recover"
                    >
                      ¿Olvidaste tu contraseña?
                    </span>
                  </Link>
                </Col>
              </Row>

              <Row style={{ marginTop: 50 }}>
                <Col
                  md={40}
                  onClick={() =>
                    window.open(
                      "http://www.tecnocyclo.com/division-industrial.php",
                      "_blank"
                    )
                  }
                >
                  <Col xs={10} md={10}>
                    <Image src={Logobajaj} alt="logo hyundai" fluid />
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <div className="text-center">
          <div className={isLoading}>
            <Spinner animation="border" role="status"></Spinner>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
