import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Modal,
  Card,
  Spinner,
  Image
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
// import * as SecureLS from "secure-ls";
import * as firebase from "firebase";
import paginationFactory from "react-bootstrap-table2-paginator";
import DatePicker, { registerLocale } from "react-datepicker";
import * as moment from "moment";
import * as SecureLS from "secure-ls";

function Content() {
  const storage = firebase.storage();
  const server = window.$linkserver;
  const [op_nombre, setOp_nombre] = useState("");
  const [op_url, setOp_url] = useState("");
  const [isLoadingTable, setisLoadingTable] = useState("invisible");

  const [dataOpciones, setDataOpciones] = useState([]);
  const [dataTable, setdataTable] = useState([]);
  const [isLoading, setIsLoading] = useState("invisible");
  const [opciones, setopciones] = useState();
  const [index, setIndex] = useState();
  const [showModal, setshowModal] = useState(false);
  const [ isLoadingdatoNoticiaCreada, setisLoadingdatoNoticiaCreada] = useState("invisible");
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const [startDate, setStartDate] = useState(
    new Date(year, month, day)
  );

  const [lastDate, setLastDate] = useState(new Date(year, month, day));

  // const ls = new SecureLS({
  //   encodingType: "des",
  //   isCompression: false,
  //   encryptionSecret: "admin-hyundai",
  // });
  // const { infoUsuario } = ls.get("ask-hy");
  // const { data } = infoUsuario;
  const [imageG, setimageG] = useState("");
  const [imageDataNoticia, setimageDataNoticia] = useState(null);

  const [datoNoticia, setdatoNoticia] = useState({
    not_id: 0,
    not_descripcion: "",
    not_titulo: "",
    not_foto: "",
    not_fecha_inicio: new Date(),
    not_fecha_final: new Date(),
  });

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  const columns = [
    {
      dataField: "not_fecha_creacion",
      text: "Fecha creación",
      headerStyle: { backgroundColor: "rgba(255, 255, 255, 1)", minWidth: '150px', alignSelf:'center' },
      formatter: (cell, row, index) => fechaNoticia(row, index),
    },
    {
      dataField: "not_titulo",
      text: "Título",
      sort: true,
    },
    {
      dataField: "not_descripcion",
      text: "Descripción",
      formatter: (cell, row, index) => observacionesFormatter(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          maxWidth: "200px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "not_fecha_inicio",
      text: "Fecha inicial de publicación",
      headerStyle: { backgroundColor: "rgba(255, 255, 255, 1)", minWidth: '150px' },
      formatter: (cell, row, index) => fechaNoticiaIni(row, index),
    },
    {
      dataField: "not_fecha_final",
      text: "Fecha final de publicación",
      headerStyle: { backgroundColor: "rgba(255, 255, 255, 1)", minWidth: '150px' },
      formatter: (cell, row, index) => fechaNoticiaFin(row, index),
    },
    {
      dataField: "not_foto",
      text: "Foto",
      sort: true,
      headerAlign: "center",
      formatter: (cell, row, index) => fotoRegistroRow(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "200px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "not_estado",
      text: "Estado",
      formatter: (cell, row, index) => activeDesactive(row, index),
      sort: true,
    },
  ];

  const [error, seterror] = useState({
    not_nombre: "",
    not_descripcion: "",
    not_valor: "",
    not_titulo: "",
    not_foto: "",
  });

  const fechaNoticia = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "150px",
          alignItems: "center",
        }}
      >
        {moment(row.not_fecha_creacion).format(`DD-MM-YYYY | HH:mm a`)}
      </Form.Label>
    );
  };
  const fechaNoticiaIni = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "150px",
          alignItems: "center",
        }}
      >
        {moment(row.not_fecha_inicio).format(`LL`)}
      </Form.Label>
    );
  };
  const fechaNoticiaFin = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "150px",
        }}
      >
        {moment(row.not_fecha_final).format(`LL`)}
      </Form.Label>
    );
  };

  function observacionesFormatter(cell, row) {
    return (
      <Form.Label
        className="text-justify"
        style={{ width: "100%", fontSize: 12 }}
      >
        {cell.not_descripcion}
      </Form.Label>
    );
  }

  const fotoRegistroRow = (row, index) => {
    return (
      <Image
        style={{ width: "200px", alignSelf: "center", justifyContent: "center", }}
        src={row.not_foto ? row.not_foto : "https://firebasestorage.googleapis.com/v0/b/proyecto-bajaj.appspot.com/o/Bajaj%2Fnoimg.jpg?alt=media&token=49b045a5-b8cf-46e7-b1a6-34521beb79df"}
      // roundedCircle
      />
    );
  };

  const editpermisos = (row, index) => {
    setOp_nombre(row.op_nombre);
    setOp_url(row.op_url);
    setopciones(row);
    setIndex(index);
    setshowModal(true);
  };

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          editpermisos(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const activeDesactive = (row, index) => {
    return (
      <input
        type="checkbox"
        name="something"
        value="asa"
        checked={setEstado(row.not_estado)}
        onChange={() => changeEstado(row, index)}
      />
    );
  };

  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const updateDate = (date) => {
    setStartDate(date);
    setdatoNoticia((datoNoticia) => {
      return { ...datoNoticia, not_fecha_inicio: date };
    });
  };

  const updateLastDate = (date) => {
    setLastDate(date);
    setdatoNoticia((datoNoticia) => {
      return { ...datoNoticia, not_fecha_final: date };
    });
  };

  const setDataOpcionesModal = () => {
    opciones.op_nombre = op_nombre;
    opciones.op_url = op_url;
    seterror((error) => {
      return { ...error, nombre: "", ruta: "" };
    });

    setOp_nombre("");
    setOp_url("");
    setshowModal(false);
    updateEstadoOp(opciones, index);
  };

  const changeEstado = async (key, index) => {
    console.log(key);
    if (key.not_estado === 1) {
      // dataOpciones.data[index].not_estado = 0;
      updateEstadoOp(key, 0);
    } else if (key.not_estado === 0) {
      // dataOpciones.data[index].not_estado = 1;
      updateEstadoOp(key, 1);
    }
  };

  const selectFileNoticia = (event) => {
    // setbanderaMenu(true);

    console.log(URL.createObjectURL(event.target.files[0]));
    setimageG(URL.createObjectURL(event.target.files[0]));
    setimageDataNoticia(event.target.files[0])

    // const uploadTask = storage.ref(`Talleres/Noticias/${datoNoticia.not_nombre}`).put(event.target.files[0]);

    // uploadTask.on("state_changed", (snapshot) => { }, (error) => { process = false;}, () => {
      
    //   storage.ref(`Talleres/Noticias/${datoNoticia.not_nombre}`).getDownloadURL().then((url) => {
    //     setdatoNoticia((datoNoticia) => {
    //       return { ...datoNoticia, not_foto: url };
    //     });
    //   });
    
    // });

  };

 

  const updateEstadoOp = (itemOpcion, index) => {
    // const dataForm = new FormData();
    // dataForm.append("op_id", itemOpcion.OP_CODIGO);
    // dataForm.append("op_nombre", itemOpcion.OP_NOMBRE);
    // dataForm.append("op_url", itemOpcion.OP_URL);
    // dataForm.append("op_estado", itemOpcion.OP_ESTADO);
    // dataForm.append("usuario", data.US_CODIGO);
    console.log('itemOpcion.not_id: ' + itemOpcion.not_id);
    console.log('index' + index);
    axios
      .post(server + "taller/updateEstadoNoticia", {
        not_id: itemOpcion.not_id,
        not_estado: index,
      })

      .then(function (response) {
        if (response.status === 200) {
          showMessage("Estado de Noticia Actualizada Correctamente", "success");
          //   let newArr = [...dataTable];
          //   newArr[index] = itemOpcion;
          //   setdataTable(newArr);

          // getData();
          getDataIncial();
        } else {
          showMessage("Error en el Servidor Intentalo de Nuevo", "error");
        }
      })
      .catch(function (error) { });


  };

  const getData = async () => {
    setIsLoading("visible");
    await axios
      .get(server + "opciones/getOpciones")
      .then(function (response) {
        setDataOpciones(response);

        setdataTable(response.data);

        setIsLoading("invisible");
      })
      .catch(function (error) { })
      .then(function () {
        // always executed
      });
  };


  useEffect(() => {
    // Make a request for a user with a given ID
   
   

    getDataIncial();
  }, [server]);

  const getDataIncial = async () => {
    var fechaIni = moment(startDate).format("yyyy-MM-DD")
    var fechaFin = moment(lastDate).format("yyyy-MM-DD")

    setIsLoading("visible");
    await axios
      // .get(server + "taller/getNoticias/" + fechaIni + "/" + fechaFin)
      .get(server + "taller/getAllNoticiasActivas")
      .then(function (response) {
        setDataOpciones(response);

        setdataTable(response.data);

        setIsLoading("invisible");
      })
      .catch(function (error) { 
        setIsLoading("invisible");
      })
      .then(function () {
        // always executed
      });
  };

  const createNuevaNoticia = async () => {
    console.log(datoNoticia)
    seterror((error) => {
      return {
        ...error,
        not_nombre: "",
        not_descripcion: "",
        not_valor: "",
        not_titulo: "",
        not_foto: "",
      };
    });

    setisLoadingdatoNoticiaCreada('visible')


   

    if (imageG !== "" && datoNoticia.not_titulo !== "" && datoNoticia.not_descripcion !== "") {

      console.log(datoNoticia);
     var fechaN = moment(new Date()).format('DD-MM-YYYY-HH:mm:ss');
      const uploadTask = storage.ref(`Talleres/Noticias/${fechaN}-${data.us_id}`).put(imageDataNoticia);
      uploadTask.on("state_changed", (snapshot) => { }, (error) => { process = false;}, () => {
        storage.ref(`Talleres/Noticias/${fechaN}-${data.us_id}`).getDownloadURL().then(async (url) => {
          setdatoNoticia((datoNoticia) => {
            return { ...datoNoticia, not_foto: url };
          });

          await axios.post(server + "taller/createNoticia", {
            not_usr_crea:  data.us_id,
            not_fecha_inicio: startDate,
            not_fecha_final: lastDate,
            not_foto: url,///
            not_titulo: datoNoticia.not_titulo,
            not_descripcion: datoNoticia.not_descripcion
          }).then(function (response) {
            // setisLoadingtiporegistroCreada("invisible");
  
            if (response.status === 200) {
              showMessage("La noticia fue creada con éxito", "success");
              setdatoNoticia((datoNoticia) => {
                return {
                  ...datoNoticia,
                  not_id: 0,
                  not_descripcion: "",
                  not_titulo: "",
                  not_foto: "",
                  not_fecha_inicio: new Date(),
                  not_fecha_final: new Date(),
                };
              });
              setimageG('')
              setimageDataNoticia(null)
              getDataIncial();
              setIsLoading("invisible");
              setisLoadingdatoNoticiaCreada('invisible')

            } else {
              setisLoadingdatoNoticiaCreada('invisible')
              showMessage("Error el crear", "error");
            }
          }).catch(function (error) {
            showMessage("Campos incompletos", "error");
            setIsLoading("invisible");
            setisLoadingdatoNoticiaCreada('invisible')
          });
  
        });
      
      });

     
    } else {
      if (datoNoticia.not_titulo === "") {
        seterror((error) => {
          return { ...error, not_titulo: "Campo requerido" };
        });
        setIsLoading("invisible");
      }

      if (datoNoticia.not_descripcion === "") {
        seterror((error) => {
          return { ...error, not_descripcion: "Campo requerido" };
        });
        setIsLoading("invisible");
      }

      if (datoNoticia.not_foto === "") {
        seterror((error) => {
          return { ...error, not_foto: "Campo requerido" };
        });
        setIsLoading("invisible");
      }
      setIsLoading("invisible");
      setisLoadingdatoNoticiaCreada('invisible')
      showMessage("Campos incompletos", "error");
    }
  };

  const createOpciones = async () => {
    
    seterror((error) => {
      return { ...error, nombre: "", ruta: "" };
    });

    if (op_nombre !== "" && op_url !== "") {
      const dataForm = new FormData();
      dataForm.append("opt_nombre", op_nombre);
      dataForm.append("opt_url", op_url);

      await axios
        .post(server + "opciones/createOpcion", {
          op_nombre: op_nombre,
          op_url: op_url,
        })
        .then(function (response) {
          console.log("sdffsdfdsfds");
          console.log(response.status);
          if (response.status === 200) {
            showMessage("Ingreso de Opción Correcta", "success");
            setOp_url("");
            setOp_nombre("");
            getData();
            // updateItems()
          } else {
            showMessage("Error en el server Intentelo de Nuevo", "error");
          }
        })
        .catch(function (error) { });
    } else {
      if (op_nombre === "") {
        seterror((error) => {
          return { ...error, nombre: "Campo requerido" };
        });
      }

      if (op_url === "") {
        seterror((error) => {
          return { ...error, ruta: "Campo requerido" };
        });
      }
      showMessage("Campos incompletos", "error");
    }

  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Talleres / Creación de noticias"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <div>
                <Modal
                  show={showModal}
                  onHide={!showModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Editar Opciones
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Nombre:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={150}
                            type="text"
                            placeholder="Nombre de Permisos"
                            value={op_nombre}
                            onChange={(event) =>
                              setOp_nombre(event.target.value)
                            }
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Url:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={100}
                            type="text"
                            placeholder="Descripción de Permisos"
                            value={op_url}
                            onChange={(event) => setOp_url(event.target.value)}
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => setDataOpcionesModal()}>
                      Actualizar
                    </Button>
                    <Button
                      onClick={() => {
                        setshowModal(false);
                        setOp_nombre("");
                        setOp_url("");
                        seterror((error) => {
                          return { ...error, nombre: "", ruta: "" };
                        });
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>

              <div>

                <Card>
                  <div className="text-center">
                    <div
                      className={isLoadingdatoNoticiaCreada}
                      style={{ marginTop: 10 }}
                    >
                      <Spinner
                        animation="border"
                        role="status"
                        variant="secondary"
                      ></Spinner>
                    </div>
                  </div>

                  <Card.Header as="h5">
                    Creación de Nueva Tipo Noticia{" "}
                  </Card.Header>

                  <Card.Body>
                    <Form>
                      {/* <FormGroup as={Row}> */}

                      <FormGroup as={Row}>
                        <Col xs="auto">
                          <Form.Label className="title-create">
                            Foto de Noticia/Información:{" "}
                          </Form.Label>

                          <Col >
                            <img
                              style={{ width: 450, height: 250 }}
                              src={imageG}
                              alt=""
                            />
                            <FormGroup as={Col} >
                              <label className="btn btn-default" >
                                <input
                                  // md="auto"
                                  type="file"
                                  onChange={selectFileNoticia}
                                  onClick={(e) => (e.target.value = null)}
                                />
                              </label>
                            </FormGroup>
                          </Col>
                          {/* </div> */}
                          <span style={{ color: "red" }}>
                                {error.not_foto}
                              </span>
                        </Col>

                        {/* <FormGroup as={Row}> */}
                        <Col xs="lg" style={{ justifyContent: 'center', alignItems: 'center' }}>

                          <Row style={{ border: '1px solid rgba(169, 96, 54,0.5)', background: 'rgba(242, 228, 207,0.2)', borderRadius: 10 }}>
                            <Col>

                              <Form.Label className="title-create" style={{ marginTop: 16 }}>
                                La Noticia/Información va ha estar disponible en un rango de fechas comprendidos entre:{" "}
                              </Form.Label>
                            </Col>
                            <Col>
                              <Form.Label className="title-create" style={{ marginTop: 4, marginRight: 8 }}>
                                Fecha inicial de publicación:  {"\t"}
                              </Form.Label>
                              <DatePicker
                                locale="es"
                                dateFormat="dd/MM/yyyy"
                                selected={startDate}
                                // startDate={startDate}
                                // endDate={lastDate}
                                // minDate={startDate}
                                // selectsStart
                                onChange={updateDate}
                              />
                              <span style={{ color: "red" }}>
                                {error.not_nombre}
                              </span>
                            </Col>

                            <Col>
                              <Form.Label className="title-create" style={{ marginTop: 4, marginRight: 8 }}>
                                Fecha final de publicación:  {"\t"}
                              </Form.Label>
                              <DatePicker
                                locale="es"
                                dateFormat="dd/MM/yyyy"
                                selected={lastDate}
                                // selectsEnd
                                // startDate={startDate}
                                // endDate={lastDate}
                                // minDate={startDate}
                                onChange={updateLastDate}
                              />
                              <span style={{ color: "red" }}>
                                {error.not_nombre}
                              </span>
                            </Col>
                          </Row>


                          <Row>
                            {/* <Col> */}
                            <Form.Label className="title-create" style={{ marginTop: 8 }}>
                              Título:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              placeholder="Ingrese un título"
                              id="not_titulo"
                              value={datoNoticia.not_titulo}
                              onChange={(e) => {
                                const val = e.target.value;
                                setdatoNoticia((datoNoticia) => {
                                  return { ...datoNoticia, not_titulo: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {error.not_titulo}
                            </span>
                            {/* </Col> */}
                            {/* <Col>
                            </Col> */}
                          </Row>




                          <Row >
                            <Form.Label className="title-create" style={{ marginTop: 8 }}>
                              Descripción:{" "}
                            </Form.Label>
                            <Form.Control
                              as="textarea" rows={3}
                              style={{ height: 100 }}
                              maxLength={500}
                              placeholder="Ingrese una descripción"
                              id="not_descripcion"
                              value={datoNoticia.not_descripcion}
                              onChange={(e) => {
                                const val = e.target.value;
                                setdatoNoticia((datoNoticia) => {
                                  return { ...datoNoticia, not_descripcion: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {error.not_descripcion}
                            </span>
                          </Row>

                        </Col >


                      </FormGroup>



                      <div className="text-center">
                        <Button
                          onClick={() => {
                            createNuevaNoticia();
                          }}
                          variant="primary"
                        >
                          Crear una Noticia/Información
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </div>


              <div>
                <Card className="mt-2">
                  <Card.Header as="h5">
                    Listado general de "Tipo Registro"
                  </Card.Header>
                  <Card.Body>
                    <BootstrapTable
                      keyField="not_id"
                      wrapperClasses="table-responsive"
                      data={dataTable}
                      columns={columns}
                      pagination={paginationFactory()}
                    />

                    <div className="text-center">
                      <div className={isLoadingTable} style={{ marginTop: 10 }}>
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                        ></Spinner>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>ƒ

            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
