import React from "react";
import { useState, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import cx from "classnames";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import Footer from "../../Layout/Footer";
import Content from "./Content";

function Gestores({ width, height }) {
  const [enableClosedSidebar, SetEnableclosedsidebar] = useState(true);
  const [enableMobileMenu, setEnablemobilemenu] = useState(false);
  const [enableMobileMenuSmall, setEnablemobilemenusmall] = useState(false);
  const [closedSmallerSidebar, setClosedsmallersidebar] = useState(true);

  let colorScheme = "white";

  let enableFixedHeader = true;
  let enableFixedSidebar = true;
  let enableFixedFooter = true;
  // let enablePageTabsAlt = null;

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div
          className={cx(
            "app-container app-theme-" + colorScheme,
            { "fixed-header": enableFixedHeader },
            { "fixed-sidebar": enableFixedSidebar || width < 1250 },
            { "fixed-footer": enableFixedFooter },
            { "closed-sidebar": enableClosedSidebar || width < 1250 },
            { "closed-sidebar-mobile": closedSmallerSidebar || width < 1250 },
            { "sidebar-mobile-open": enableMobileMenu }
          )}
        >
          <Header
            enableClosedSidebar={enableClosedSidebar}
            SetEnableclosedsidebar={SetEnableclosedsidebar}
            enableMobileMenu={enableMobileMenu}
            setEnablemobilemenu={setEnablemobilemenu}
            enableMobileMenuSmall={enableMobileMenuSmall}
            setEnablemobilemenusmall={setEnablemobilemenusmall}
            closedSmallerSidebar={closedSmallerSidebar}
            setClosedsmallersidebar={setClosedsmallersidebar}
          />
          <div className="app-main">
            <Sidebar />
            <div className="app-main__outer">
              <div className="app-main__inner">
                <Content />
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Gestores;
