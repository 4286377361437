import React, { Fragment, useState } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import Logo from "./Logo";
import cx from "classnames";
import UserBox from "./UserBox";
import * as SecureLS from "secure-ls";

function Header({
  enableClosedSidebar,
  SetEnableclosedsidebar,
  enableMobileMenu,
  setEnablemobilemenu,
  enableMobileMenuSmall,
  setEnablemobilemenusmall,
  closedSmallerSidebar,
  setClosedsmallersidebar,
}) {
  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  const getColor = () => {
    document.documentElement.style.setProperty("--tipoS", "scroll");
    const item = data.us_empresa.listaParametros.find(
      (element) => element.pae_tipo === 4
    );

    const itemBarra = data.us_empresa.listaParametros.find(
      (element) => element.pae_tipo === 5
    );

    if (item === undefined) {
    } else {
      document.documentElement.style.setProperty(
        "--primary",
        "#" + item.pae_parametro
      );
    }

    if (itemBarra === undefined) {
      return "#137ad1";
    } else {
      return "#" + itemBarra.pae_parametro;
    }
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        className={cx("app-header", "header-text-light", {
          "header-shadow": false,
        })}
        transitionName="HeaderAnimation"
        transitionAppear={true}
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
        style={{ backgroundColor: getColor() }}
      >
        <Logo
          enableClosedSidebar={enableClosedSidebar}
          SetEnableclosedsidebar={SetEnableclosedsidebar}
          enableMobileMenu={enableMobileMenu}
          setEnablemobilemenu={setEnablemobilemenu}
          enableMobileMenuSmall={enableMobileMenuSmall}
          setEnablemobilemenusmall={setEnablemobilemenusmall}
          closedSmallerSidebar={closedSmallerSidebar}
          setClosedsmallersidebar={setClosedsmallersidebar}
        />

        <div
          className={cx("app-header__content", {
            "header-mobile-open": enableMobileMenuSmall,
          })}
        >
          <div className="app-header-left"></div>
          <div className="app-header-right">
            <UserBox />
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Header;
