import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Card,
  FormGroup,
  Form,
  Button,
  Spinner,
  Modal,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import axios from "axios";
import Swal from "sweetalert2";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import filterFactory, {
  textFilter,
  selectFilter,
  numberFilter,
  Comparator,
  dateFilter
} from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import optionsPagination from "../../helper/SetupComponents";
// import * as SecureLS from "secure-ls";

function Content() {
  const server = window.$linkserver;
  const [dataTable, setdataTable] = useState([]);

  const [isLoadingrepuestosCreada, setisLoadingrepuestosCreada] = useState(
    "invisible"
  );
  const [isLoadingTable, setisLoadingTable] = useState("invisible");
  // const [isLoadingActualizar, setisLoadingActualizar] = useState("invisible");
  const [showModal, setshowModal] = useState(false);

  // const [first, setfirst] = useState(true);

  // const ls = new SecureLS({
  //   encodingType: "des",
  //   isCompression: false,
  //   encryptionSecret: "admin-hyundai",
  // });
  // const { infoUsuario } = ls.get("ask-hy");
  // const { data } = infoUsuario;

  const [repuestos, setrepuestos] = useState({
    rep_id: 0,
    rep_nombre: "",
    rep_descripcion: "",
  });

  const [error, seterror] = useState({
    rep_nombre: "",
    rep_descripcion: "",
  });

  const columns = [
    {
      dataField: "rep_codigo",
      text: "Codigo",
      sort: true,
      headerAlign: "center",
      filter: textFilter({
        placeholder: "# código"
      }),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "160px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "rep_nombre",
      text: "Nombre del repuesto",
      sort: true,
      headerAlign: "center",
      filter: textFilter({
        placeholder: "Repuesto"
      }),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "200px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "rep_descripcion",
      text: "Descripción",
      sort: true,
    },
    {
      dataField: "rep_editar",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          edit(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const edit = (row, index) => {
    setrepuestos((repuestos) => {
      return {
        ...repuestos,
        rep_id: row.rep_id,
        rep_nombre: row.rep_nombre,
        rep_descripcion: row.rep_descripcion,
      };
    });

    setshowModal(true);
  };

  const getrepuestoss = async () => {
    setisLoadingTable("visible");
    await axios
      .get(server + "taller/getRepuestos")
      .then(function (response) {
        setisLoadingTable("invisible");
        setdataTable(response.data);
      })
      .catch(function (error) {
        setisLoadingTable("invisible");
      })
      .then(function () { });
  };

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const createrepuestos = async () => {
    seterror((error) => {
      return {
        ...error,
        rep_nombre: "",
        rep_descripcion: "",
      };
    });

    if (repuestos.rep_nombre !== "" && repuestos.rep_descripcion !== "") {
      setisLoadingrepuestosCreada("visible");
      await axios

        .post(server + "taller/creteRepuesto", {
          rep_nombre: repuestos.rep_nombre,
          rep_descripcion: repuestos.rep_descripcion,
        })

        .then(function (response) {
          setisLoadingrepuestosCreada("invisible");

          if (response.status === 200) {
            showMessage("Repuestos creada con éxito", "success");

            setrepuestos((repuestos) => {
              return {
                ...repuestos,
                rep_id: 0,
                rep_nombre: "",
                rep_descripcion: "",
              };
            });

            getrepuestoss();

            //getData()
          } else {
            showMessage("Error el crear", "error");
          }
        })
        .catch(function (error) {
          showMessage("Campos incompletos", "error");
        });
    } else {
      if (repuestos.rep_nombre === "") {
        seterror((error) => {
          return { ...error, rep_nombre: "Campo requerido" };
        });
      }

      if (repuestos.rep_descripcion === "") {
        seterror((error) => {
          return { ...error, rep_descripcion: "Campo requerido" };
        });
      }

      showMessage("Campos incompletos", "error");
    }
  };

  const updaterepuestos = async () => {
    seterror((error) => {
      return {
        ...error,
        rep_nombre: "",
        rep_descripcion: "",
      };
    });

    if (repuestos.rep_nombre !== "" && repuestos.rep_descripcion !== "") {
      setisLoadingrepuestosCreada("visible");
      await axios

        .post(server + "taller/updateRepuestos", {
          rep_id: repuestos.rep_id,
          rep_nombre: repuestos.rep_nombre,
          rep_descripcion: repuestos.rep_descripcion,
        })

        .then(function (response) {
          setisLoadingrepuestosCreada("invisible");

          if (response.status === 200) {
            showMessage("Repuestos actualizada con éxito", "success");

            setrepuestos((repuestos) => {
              return {
                ...repuestos,
                rep_id: 0,
                rep_nombre: "",
                rep_descripcion: "",
              };
            });

            setshowModal(false);

            getrepuestoss();
          } else {
            showMessage("Error el crear", "error");
          }
        })
        .catch(function (error) {
          showMessage("Campos incompletos", "error");
        });
    } else {
      if (repuestos.rep_nombre === "") {
        seterror((error) => {
          return { ...error, rep_nombre: "Campo requerido" };
        });
      }

      if (repuestos.rep_descripcion === "") {
        seterror((error) => {
          return { ...error, rep_descripcion: "Campo requerido" };
        });
      }

      showMessage("Campos incompletos", "error");
    }
  };

  useEffect(() => {
    const getrepuestossInicial = async () => {
      setisLoadingTable("visible");
      await axios
        .get(server + "taller/getRepuestos")
        .then(function (response) {
          setisLoadingTable("invisible");
          setdataTable(response.data);
        })
        .catch(function (error) {
          setisLoadingTable("invisible");
        })
        .then(function () { });
    };

    getrepuestossInicial();
  }, [server]);

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle heading="Repuestos" icon="pe-7s-settings" />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <div>
                <Card>
                  <div className="text-center">
                    <div
                      className={isLoadingrepuestosCreada}
                      style={{ marginTop: 10 }}
                    >
                      <Spinner
                        animation="border"
                        role="status"
                        variant="secondary"
                      ></Spinner>
                    </div>
                  </div>

                  <Card.Header as="h5">
                    Creación de Nuevo Repuestos{" "}
                  </Card.Header>
                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Nombre:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese un Nombre"
                            id="rep_nombre"
                            value={repuestos.rep_nombre}
                            onChange={(e) => {
                              const val = e.target.value;
                              setrepuestos((repuestos) => {
                                return { ...repuestos, rep_nombre: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {error.rep_nombre}
                          </span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Descripción:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese una Descripción"
                            id="rep_descripcion"
                            value={repuestos.rep_descripcion}
                            onChange={(e) => {
                              const val = e.target.value;
                              setrepuestos((repuestos) => {
                                return { ...repuestos, rep_descripcion: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {error.rep_descripcion}
                          </span>
                        </Col>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          onClick={() => {
                            createrepuestos();
                          }}
                          variant="primary"
                        >
                          Crear un Repuestos
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </div>
              <div>
                <Card className="mt-2">
                  <Card.Header as="h5">
                    Listado general de "Repuestos"
                  </Card.Header>
                  <Card.Body>
                    <ToolkitProvider
                      keyField="id"
                      data={dataTable}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            wrapperClasses="table-responsive"
                            // class="react-bootstrap-table table"

                            filter={filterFactory()}
                            pagination={paginationFactory(optionsPagination)}
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                    <div className="text-center">
                      <div className={isLoadingTable} style={{ marginTop: 10 }}>
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                        ></Spinner>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>

              <div>
                <Modal
                  show={showModal}
                  // onHide={!showModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Actualización de datos de: {repuestos.rep_nombre}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {/* <div className="text-center">
                      <div className={isLoadingActualizar}>
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                        ></Spinner>
                      </div>
                    </div> */}

                    <div style={{ marginTop: 10 }}>
                      <Form>
                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Nombre:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              placeholder="Ingrese un Nombre"
                              id="rep_nombre"
                              value={repuestos.rep_nombre}
                              onChange={(e) => {
                                const val = e.target.value;
                                setrepuestos((repuestos) => {
                                  return { ...repuestos, rep_nombre: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {error.rep_nombre}
                            </span>
                          </Col>

                          <Col>
                            <Form.Label className="title-create">
                              Descripción:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              placeholder="Ingrese una Descripción"
                              id="rep_descripcion"
                              value={repuestos.rep_descripcion}
                              onChange={(e) => {
                                const val = e.target.value;
                                setrepuestos((repuestos) => {
                                  return { ...repuestos, rep_descripcion: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {error.rep_descripcion}
                            </span>
                          </Col>
                        </FormGroup>
                      </Form>
                    </div>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        updaterepuestos();
                      }}
                    >
                      Actualizar
                    </Button>
                    <Button
                      onClick={() => {
                        setshowModal(false);

                        seterror((error) => {
                          return {
                            ...error,
                            rep_nombre: "",
                            rep_descripcion: "",
                          };
                        });

                        setrepuestos((repuestos) => {
                          return {
                            ...repuestos,
                            rep_id: 0,
                            rep_nombre: "",
                            rep_descripcion: "",
                          };
                        });
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
