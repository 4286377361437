import React, { Fragment, useState } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  // CSVExport,
} from "react-bootstrap-table2-toolkit";
import filterFactory, {
  selectFilter,
  numberFilter,
  Comparator,
  textFilter
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import optionsPagination from "../../helper/SetupComponents";

import PageTitle from "../Layout/PageTitle";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Map from "../../componentes/screens/Map";
import * as moment from "moment";
import axios from "axios";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Swal from "sweetalert2";

import {
  // faInfoCircle,
  // faCalendarCheck,
  // faPenSquare,
  // faHandHoldingUsd,
  faInfoCircle,
  // faMapMarkedAlt,
  // faSearchLocation,
  faLocationArrow,
  faImages,
  faMoneyBillAlt,
  faMoneyCheckAlt,
  faCaretSquareDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  Row,
  Col,
  Container,
  Card,
  // DropdownButton,
  Form,
  Carousel,
  Button,
  Modal,
} from "react-bootstrap";

function Content(props) {
  const server = window.$linkserver;
  // const [nombreBanco, setNombreBanco] = useState("Banco del Austro");
  const { SearchBar } = Search;
  // const { ExportCSVButton } = CSVExport;
  const [modalShow, setModalShow] = React.useState(false);
  // const [cartera, setCartera] = useState();
  // const [startDate, setStartDate] = useState(new Date());
  const [showModalFotos, setshowModalFotos] = useState(false);
  const [showModalMap, setshowModalMap] = useState(false);
  const [showModalDetalleCobros, setShowModalDetalleCobros] = useState(false);
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const [startDate, setStartDate] = useState(new Date(year, month, day - 1));
  const [lastDate, setLastDate] = useState(new Date());
  const [imgs, setIMGS] = useState([]);
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [getRowsPerfil, setRowsPerfil] = useState([]);
  const [horaMapa, sethoraMapa] = useState("");

  // const selectOptions = {
  //   0: "1-30",
  //   1: "31-60",
  //   2: "61-90",
  //   3: "90+",
  // };

  const selectBanco = {
    SOLIDARIO: (
      <Form.Label
        style={{
          backgroundColor: "#00b5ca",
          textAlign: "center",
          width: "100%",
          borderRadius: 7,
          color: "white",
          fontWeight: "bold",
          fontStyle: "oblique",
        }}
      >
        SOLIDARIO
      </Form.Label>
    ),
    AUSTRO: (
      <Form.Label
        style={{
          backgroundColor: "#171d5c",
          textAlign: "center",
          width: "100%",
          borderRadius: 7,
          color: "white",
          fontWeight: "bold",
          fontStyle: "oblique",
        }}
      >
        AUSTRO
      </Form.Label>
    ),
  };

  const selectBanco2 = {
    SOLIDARIO: "SOLIDARIO",
    AUSTRO: "AUSTRO",
  };

  const selectTipoCartera = {
    "FONDO DE GARANTIA": (
      <Form.Label
        style={{ textAlign: "center", width: "100%", fontWeight: "bold" }}
      >
        FONDO DE GARANTIA
      </Form.Label>
    ),
    "CARTERA BANCO": (
      <Form.Label
        style={{ textAlign: "center", width: "100%", fontWeight: "bold" }}
      >
        CARTERA BANCO
      </Form.Label>
    ),
  };

  const selectTipoCartera2 = {
    "FONDO DE GARANTIA": "FONDO DE GARANTIA",
    "CARTERA BANCO": "CARTERA BANCO",
  };

  const columns = [
    {
      dataField: "ccli_nombre_banco",
      text: "Banco",
      headerAlign: "center",
      // footer: "",
      // headerStyle: (colum, colIndex) => {
      //   return {
      //     textAlign: "center",
      //     minWidth: "150px",
      //     backgroundColor: "rgba(255, 255, 255,1)",
      //   };
      // },
      formatter: (cell) => selectBanco[cell],
      filter: selectFilter({
        options: selectBanco2,
        placeholder: "TODOS",
        withoutEmptyOption: false,
        style: {
          width: "100%",
          minWidth: "120px",
          alignItems: "center",
          placeholder: "TODO",
        },
        className: "test-classname",
        datamycustomattr: "datamycustomattr",
      }),
      footer: "",
    },
    {
      dataField: "ccli_tipo_cartera",
      text: "Tipo de cartera",
      headerAlign: "center",
      // footer: "",
      // headerStyle: (colum, colIndex) => {
      //   return {
      //     textAlign: "center",
      //     minWidth: "150px",
      //     backgroundColor: "rgba(255, 255, 255,1)",
      //   };
      // },
      formatter: (cell) => selectTipoCartera[cell],
      filter: selectFilter({
        placeholder: "TODOS",
        options: selectTipoCartera2,
        withoutEmptyOption: false,
        style: {
          width: "100%",
          minWidth: "150px",
          alignItems: "center",
          placeholder: "TODO",
        },
        className: "test-classname",
        datamycustomattr: "datamycustomattr",
      }),
      footer: "",
    },
    {
      dataField: "gestor",
      text: "Gestor",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255, 1)",
        textAlign: "center",
        minWidth: "160px",
      },
      formatter: (cell, row, index) => infoGestor(row, index),
      filter: textFilter({
        placeholder: 'Nombre gestor'
      }),
    },
    {
      dataField: "ccli_cedula",
      text: "Cédula",
      headerStyle: {
        textAlign: "center",
        minWidth: "160px",
        backgroundColor: "rgba(255, 255, 255, 1)"
      },
      filter: textFilter({
        placeholder: '# cédula'
      }),
    },
    {
      dataField: "ccli_nombre",
      text: "Nombres",
      headerStyle: {
        textAlign: "center",
        minWidth: "190px",
        backgroundColor: "rgba(255, 255, 255, 1)"
      },
      filter: textFilter({
        placeholder: 'Nombre/Apellido'
      }),
    },
    // {
    //   dataField: "ccli_apellido",
    //   text: "Apellidos",
    // },
    {
      dataField: "ccli_fecha",
      text: "Fecha de cobro",
      formatter: (cell, row, index) => fechaCobro(row, index),
    },
    {
      dataField: "ccli_saldo_cartera",
      text: "Saldo",
      textAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255, 1)",
        };
      },

      formatter: (cell, row, index) => valorsaldo(row, index),
    },
    {
      dataField: "cdp_observacion",
      text: "Observaciones",
      headerAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "200px",
          backgroundColor: "rgba(255, 255, 255, 1)",
        };
      },

      formatter: (cell, row, index) => observaciones(row, index),
    },
    {
      dataField: "fotos",
      text: "Fotos",
      formatter: (cell, row, index) => showFotos(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 1)",
        };
      },
    },
    {
      dataField: "cdp_images",
      text: "Detalles",
      formatter: (cell, row, index) => detalles(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 1)",
        };
      },
    },
    {
      dataField: "cdp_ubicacion",
      text: "Ubicación",
      formatter: (cell, row, index) => locationCliente(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 1)",
        };
      },
    },
    {
      dataField: "cdp_estado_pago",
      text: "Estado de cobro",
      headerAlign: "center",
      formatter: (cell, row, index) => estadoCobro(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          minWidth: "140px",
          textAlign: "center",

          backgroundColor: "rgba(255, 255, 255, 1)",
        };
      },
    }


  ];

  const columnsDetalleCobros = [
    // {
    //   dataField: "gestor",
    //   text: "Gestor",
    //   headerAlign: "center",
    //   headerStyle: { backgroundColor: "rgba(255, 255, 255, 1)" },
    //   formatter: (cell, row, index) => infoGestor(row, index),
    // },
    // {
    //   dataField: "ccli_cedula",
    //   text: "Cédula",
    // },
    {
      dataField: "dtc_valor_cobro",
      text: "Valor",
      formatter: (cell, row, index) => valorcobro(row, index),
    },
    {
      dataField: "tbl_cobro_tipo_tip_id",
      text: "Tipo",
      formatter: (cell, row, index) => tipoCobro(row, index),
    },
    {
      dataField: "dtc_fecha_cobro",
      text: "Fecha de cobro",
      formatter: (cell, row, index) => fechaCobroDetalle(row, index),
    },
  ];

  const [products, setProducts] = useState([]);

  const [ltsDetalleCobros, setltsDetalleCobros] = useState([]);


  const handleSwitchChange = async (row, checked) => {
    // setisUserAdmin(true)
    // console.log(row.ccli_compromiso.cdp_id)
    var estado = 0
    if (checked === true) {
      estado = 1
    } else if (checked === false) {
      estado = 0
    }
    // console.log(server + "api/asignacionapp/updateCobrosEstadoCobro/" + row.ccli_cobro.cob_id + "/" + estado)

    await axios
      .get(
        server + "api/asignacionapp/updateCobrosEstadoCobro/" + row.ccli_cobro.cob_id + "/" + estado
      )
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,
        // console.log(response.data);
        if (response.status == 200) {
          showMessage("Cobro actualizado", "success");
          getFechaCobros(
            moment(startDate).format("yyyy-MM-DD"),
            moment(lastDate).format("yyyy-MM-DD")
          )
        } else {
          showMessage("Error al actualizar cobro", "error");
          getFechaCobros(
            moment(startDate).format("yyyy-MM-DD"),
            moment(lastDate).format("yyyy-MM-DD")
          )
        }

      })
      .catch(function (error) { })
      .then(function () { });

  }

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });


  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const estadoCobro = (row, index) => {

    if (row.ccli_cobro.cob_estado_cobro == 1) {
      // setisUserAdmin(true)
      return (
        <BootstrapSwitchButton
          checked={true}
          onlabel='COBRADO'
          onstyle='success'
          offlabel='SIN COBRO'
          offstyle='danger'
          style='w-100'
          onChange={(checked) => handleSwitchChange(row, checked)}
        />
      )

    } else if (row.ccli_cobro.cob_estado_cobro == 0) {
      // setisUserAdmin(false)
      return (
        <BootstrapSwitchButton
          checked={false}
          onlabel='COBRADO'
          onstyle='success'
          offlabel='SIN COBRO'
          offstyle='danger'
          style='w-100'
          onChange={(checked) => handleSwitchChange(row, checked)}
        />
      )
    }

  };

  const observaciones = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          fontSize: 11
        }}
      >
        {row.ccli_observacion}
      </Form.Label>
    );
  };

  const infoGestor = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {row.ccli_cobro.gestor.ges_nombre} {row.ccli_cobro.gestor.ges_apellido}
      </Form.Label>
    );
  };
  const showFotos = (row, index) => {
    return (
      <Button
        onClick={() => openModalFotos(row)}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faImages} size="2x" />
      </Button>
    );
  };

  const detalles = (row, index) => {
    return (
      <Button
        onClick={() => openModalDetalleCobros(row)}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faInfoCircle} size="2x" />
      </Button>
    );
  };

  const locationCliente = (row, index) => {
    return (
      <Button
        onClick={() => openModalMap(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon
          icon={faLocationArrow}
          size="2x"
          textAlign="center"
          style={{ alignSelf: "center", justifyContent: "center" }}
        />
      </Button>
    );
  };

  const fechaCobro = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "145px",
        }}
      >
        {moment(row.ccli_fecha).format("DD-MM-YYYY | HH:mm a")}
      </Form.Label>
    );
  };

  const fechaCobroDetalle = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {moment(row.dtc_fecha_cobro).format("DD-MM-YYYY | HH:mm a")}
      </Form.Label>
    );
  };

  const valorcobro = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        ${parseFloat(row.dtc_valor_cobro).toFixed(2)}
      </Form.Label>
    );
  };

  const valorsaldo = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "100px",
        }}
      >
        ${row.ccli_saldo_cartera}
      </Form.Label>
    );
  };

  const tipoCobro = (row, index) => {
    // console.log(Difference_In_Days)
    if (row.tbl_cobro_tipo_tip_id === 1) {
      return (
        <Form.Label
          className="text-center align-items-center"
          style={{
            width: "100%",
            minWidth: "125px",
          }}
        >
          {/* <FontAwesomeIcon icon={faMoneyBillAlt} color="green" size="1x" /> */}
          Efectivo
        </Form.Label>
      );
    } else if (row.tbl_cobro_tipo_tip_id === 2) {
      return (
        <Form.Label
          className="text-center align-items-center"
          style={{
            width: "100%",
            minWidth: "125px",
          }}
        >
          {/* <FontAwesomeIcon icon={faCaretSquareDown} color="green" size="1x" /> */}
          Depósito/Transferencia
        </Form.Label>
      );
    } else if (row.tbl_cobro_tipo_tip_id === 3) {
      return (
        <Form.Label
          className="text-center align-items-center"
          style={{
            width: "100%",
            minWidth: "125px",
          }}
        >
          {/* <FontAwesomeIcon icon={faMoneyCheckAlt} color="green" size="1x" /> */}
          Cheque
        </Form.Label>
      );
    }
  };

  const openModalDetalleCobros = (row) => {
    setShowModalDetalleCobros(true);
    getDetalleCobros(row.ccli_cobro.cob_id);
    // console.log(row)
  };

  const openModalMap = async (row) => {
    setshowModalMap(true);
    setLat(row.ccli_lat);
    setLon(row.ccli_lon);
    sethoraMapa(row.ccli_fecha);
    // setIsLoading("visible");
    //getOpcionesApp
    // await axios
    //   .get(
    //     "http://192.168.1.13:5001/gestores/getLstOpcionesbyPerfil/" + row.per_id
    //   )
    //   .then(function (response) {
    //     setperfiles(row);
    //     // console.log(row);,
    //     setRowsModal(response.data);
    //     setIsModalVisible(true);
    //     setIsLoading("invisible");
    //   })
    //   .catch(function (error) {})
    //   .then(function () {});
  };

  const itemsCarousel = (item) => {
    // if (item.ccli_cobro.cob_img1 != "") {
    //   const rows = () => {
    //     <Carousel.Item>
    //       <img
    //         className="d-block w-100"
    //         src={item.ccli_cobro.cob_img1}
    //         alt="First slide"
    //       />
    //     </Carousel.Item>
    //   }
    //   setRowsPerfil(rows)
    // } else if (item.ccli_cobro.cob_img1 != "" && item.ccli_cobro.cob_img2 != "") {
    //   getRowsPerfil.push(item.ccli_cobro.cob_img1)
    //   getRowsPerfil.push(item.ccli_cobro.cob_img2)
    // } else if (item.ccli_cobro.cob_img1 != "" && item.ccli_cobro.cob_img2 != "" && item.ccli_cobro.cob_img3 != "") {
    //   getRowsPerfil.push(item.ccli_cobro.cob_img1)
    //   getRowsPerfil.push(item.ccli_cobro.cob_img2)
    //   getRowsPerfil.push(item.ccli_cobro.cob_img3)
    // }
  };

  function openModalFotos(item) {
    setshowModalFotos(true);
    // setIMGS(item.ccli_cobro);
    // itemsCarousel(item)

    if (item.ccli_cobro.cob_img1 != "") {
      imgs.push(item.ccli_cobro.cob_img1);
    } else if (
      item.ccli_cobro.cob_img1 != "" &&
      item.ccli_cobro.cob_img2 != ""
    ) {
      imgs.push(item.ccli_cobro.cob_img1);
      imgs.push(item.ccli_cobro.cob_img2);
    } else if (
      item.ccli_cobro.cob_img1 != "" &&
      item.ccli_cobro.cob_img2 != "" &&
      item.ccli_cobro.cob_img3 != ""
    ) {
      imgs.push(item.ccli_cobro.cob_img1);
      imgs.push(item.ccli_cobro.cob_img2);
      imgs.push(item.ccli_cobro.cob_img3);
    }
  }

  // function openModalCartera(item) {
  //   console.log(item);
  //   setModalShow(true);
  // }

  const updateDate = (date) => {
    setStartDate(date);
  };

  const updateLastDate = (date) => {
    setLastDate(date);
  };

  const getFechaCobros = async (inicio, fin) => {
    // console.log(server + "api/asignacionapp/getVisitasByFecha/" +
    //   inicio +
    //   "/" +
    //   fin)
    await axios
      .get(server + "api/asignacionapp/getCobrosByFecha/" + inicio + "/" + fin)
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        // console.log(response.data);
        setProducts(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getDetalleCobros = async (crt_id) => {
    // console.log(server + "api/asignacionapp/getLstCobrosbyCartera/" + crt_id)
    await axios
      .get(server + "api/asignacionapp/getDetalleCobro/" + crt_id)
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        console.log(response.data);
        setltsDetalleCobros(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const setDataPerfil = (data) => {
    const rows = data.map((row, index) => (
      // <option key={row.per_id} value={row.per_id}>
      //   {row.per_nombre}
      // </option>

      <Carousel.Item>
        <img className="d-block w-100" src={row} alt="First slide" />
      </Carousel.Item>
    ));
    setRowsPerfil(rows);
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Cobros"
            icon="pe-7s-info icon-gradient bg-mean-fruit"
          />

          <Container fluid={true} className="content-body">
            <Row>
              <Col md="12" lg="12">
                <Modal
                  show={modalShow}
                  {...props}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Información de Cartera:
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <h4>Centered Modal</h4>
                    <p>
                      Cras mattis consectetur purus sit amet fermentum. Cras
                      justo odio, dapibus ac facilisis in, egestas eget quam.
                      Morbi leo risus, porta ac consectetur ac, vestibulum at
                      eros.
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => setModalShow(false)}>Close</Button>
                  </Modal.Footer>
                </Modal>
                <Modal
                  show={showModalFotos}
                  {...props}
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Title id="contained-modal-title-vcenter">
                    Imagenes tomadas
                  </Modal.Title>

                  {imgs.length == 1 ? (
                    <Carousel>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={imgs[0]}
                          alt="First slide"
                        />
                      </Carousel.Item>
                    </Carousel>
                  ) : imgs.length == 2 ? (
                    <Carousel>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={imgs[0]}
                          alt="First slide"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={imgs[1]}
                          alt="Third slide"
                        />
                      </Carousel.Item>{" "}
                    </Carousel>
                  ) : imgs.length == 3 ? (
                    <Carousel>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={imgs[0]}
                          alt="First slide"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={imgs[1]}
                          alt="Third slide"
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={imgs[2]}
                          alt="Third slide"
                        />
                      </Carousel.Item>{" "}
                    </Carousel>
                  ) : null}

                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={imgs.cob_img3}
                      alt="Third slide"
                    />
                  </Carousel.Item>

                  <Modal.Footer>
                    <Button onClick={() => setshowModalFotos(false)}>
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
                
                <Modal
                  show={showModalMap}
                  onHide={() => setshowModalMap(false)}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <Map
                      lat={lat}
                      lon={lon}
                      titulo={moment(horaMapa).format("LLLL")}
                      url="https://firebasestorage.googleapis.com/v0/b/proyecto-bajaj.appspot.com/o/Bajaj%2Fpersonamarker.png?alt=media&token=d943d61c-39fd-4983-acf4-42e8e5dde71b"
                      googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyBWQwWMO0El5LZPg5eECmCQ7bh5o2Zg738"
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `400px` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                    ></Map>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        setshowModalMap(false);
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={showModalDetalleCobros}
                  {...props}
                  onHide={() => setShowModalDetalleCobros(false)}
                  // dialogClassName="modal-90w"
                  dialogClassName="modal-90w"
                  aria-labelledby="example-custom-modal-styling-title"
                  centered
                // className="modal-dialog modal-lg"
                // style = {{width : '80%'}}
                >
                  <Modal.Header closeButton>
                    {/* <p>Cliente: <b>{currentClienteCarte.cli_nombre} {" "} {currentClienteCarte.cli_apellido}</b> <br></br>Celular:<b>{currentClienteCarte.cli_celular}</b></p> */}
                  </Modal.Header>
                  <Modal.Body
                    style={{
                      maxHeight: "calc(100vh - 210px)",
                      overflowY: "auto",
                    }}
                  >
                    <ToolkitProvider
                      keyField="id"
                      data={ltsDetalleCobros}
                      columns={columnsDetalleCobros}
                      search
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col className="align-middle" md="auto">
                              Buscar por fecha:
                            </Col>
                            <Col md="auto">
                              <SearchBar
                                class="align-middle"
                                placeholder="Ingrese una fecha ejm (01/02/2020)"
                                style={{ height: 25, width: 150 }}
                                {...props.searchProps}
                              />
                            </Col>
                          </Row>
                          <Col className="mt-3"></Col>
                          <Form>
                            <Row></Row>
                          </Form>
                          <Col className="mt-3"></Col>

                          <BootstrapTable
                            striped
                            hover
                            condensed
                            wrapperClasses="table-responsive"
                            // class="react-bootstrap-table table"
                            filter={filterFactory()}
                            pagination={paginationFactory(optionsPagination)}
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </Modal.Body>
                </Modal>

                <ToolkitProvider
                  keyField="id"
                  data={products}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <div>
                      <Card
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      >
                        <Card.Header
                          as="h3"
                          style={{
                            backgroundColor: "rgba(236,236,236,0.6)",
                            color: "black",
                          }}
                        >
                          Parámetros de busqueda para cobros
                        </Card.Header>
                        <Card.Body
                          style={{
                            backgroundColor: "rgba(236,236,236,0.3)",
                          }}
                        >
                          <Row>
                            <Col className="align-middle" md="auto">
                              Fecha inicial
                            </Col>
                            <Col md="auto">
                              <DatePicker
                                locale="es"
                                selected={startDate}
                                dateFormat="dd/MM/yyyy"
                                onChange={updateDate}
                              />
                            </Col>

                            <Col className="align-middle" md="auto">
                              Fecha final
                            </Col>
                            <Col md="auto">
                              <DatePicker
                                locale="es"
                                dateFormat="dd/MM/yyyy"
                                selected={lastDate}
                                onChange={updateLastDate}
                              />
                            </Col>
                            <Col>
                              <Button
                                onClick={() =>
                                  getFechaCobros(
                                    moment(startDate).format("yyyy-MM-DD"),
                                    moment(lastDate).format("yyyy-MM-DD")
                                  )
                                }
                              >
                                Buscar por fecha
                              </Button>
                            </Col>
                          </Row>
                        </Card.Body>
                        {/* {products.length > 0 ? (
                          <Card.Footer
                            style={{
                              backgroundColor: "rgba(236,236,236,0.3)",
                            }}
                          >
                            <Col className="align-middle" md="auto">
                              Buscar cliente:
                            </Col>
                            <Col md="auto">
                              <SearchBar
                                class="align-middle"
                                placeholder="Ingrese cédula"
                                style={{ height: 25, width: 150 }}
                                {...props.searchProps}
                              />
                            </Col>
                            <Col className="align-middle" md="auto">
                              Buscar gestor:
                            </Col>
                            <Col md="auto">
                              <SearchBar
                                class="align-middle"
                                placeholder="Ingrese nombre"
                                style={{ height: 25, width: 150 }}
                                {...props.searchProps}
                              />
                            </Col>
                          </Card.Footer>
                        ) : null} */}
                      </Card>
                      <Card
                        className="mt-3"
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      >
                        <Card.Header
                          as="h3"
                          style={{
                            backgroundColor: "rgba(236,236,236,0.9)",
                            color: "black",
                          }}
                        >
                          Listado general de "Cobros"
                        </Card.Header>
                        <Card.Body
                          style={{
                            backgroundColor: "rgba(236,236,236,0.6)",
                          }}
                        >
                          <BootstrapTable
                            wrapperClasses="table-responsive"
                            class="align-middle"
                            filter={filterFactory()}
                            {...props.baseProps}
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          </Container>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );

  // const infoCarteraSeleccionada = () => {
  //   return (
  //     <Modal
  //       show={modalShow}
  //       {...props}
  //       size="lg"
  //       aria-labelledby="contained-modal-title-vcenter"
  //       centered
  //     >
  //       <Modal.Header closeButton>
  //         <Modal.Title id="contained-modal-title-vcenter">
  //           Modal heading
  //         </Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body>
  //         <h4>Centered Modal</h4>
  //         <p>
  //           Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
  //           dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
  //           ac consectetur ac, vestibulum at eros.
  //         </p>
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Button onClick={props.onHide}>Close</Button>
  //       </Modal.Footer>
  //     </Modal>
  //   );
  // };
}

export default Content;
