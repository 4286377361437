import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup, {
  contextTypes,
} from "react-addons-css-transition-group";
import BootstrapTable, {
  ROW_SELECT_DISABLED,
} from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import filterFactory, {
  textFilter,
  selectFilter,
  numberFilter,
  Comparator,
  dateFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import PageTitle from "../Layout/PageTitle";
import optionsPagination from "../../helper/SetupComponents";
import Map from "../screens/Map";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow,
  Polyline,
} from "react-google-maps";
import * as moment from "moment";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";

import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faCalendarCheck,
  faPenSquare,
  faHandHoldingUsd,
  faInfoCircle,
  faMapMarkedAlt,
  faHistory,
  faLocationArrow,
  faImages,
  faUserEdit,
  faDirections,
} from "@fortawesome/free-solid-svg-icons";
import {
  Row,
  Col,
  Container,
  Card,
  Form,
  FormGroup,
  Button,
  Modal,
  Carousel,
  Spinner,
  Tabs,
  Tab,
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
// registerLocale("es", es);
import "react-datepicker/dist/react-datepicker.css";
import { findByDisplayValue } from "@testing-library/react";
import { scryRenderedDOMComponentsWithClass } from "react-dom/test-utils";
import Swal from "sweetalert2";
import * as firebase from "firebase";
import * as SecureLS from "secure-ls";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
registerLocale("es", es);

const tipoRow = (tipo, aprobacion) => {
  if (tipo === "detalle_gar") {
    var desc = "";

    if (aprobacion.detalle_gar === 1) {
      desc = "Mano de Obra";
    } else {
      desc = "Respuesta";
    }
    return (
      <View style={styles.tableCol1}>
        <Text style={styles.tableCell}>{desc}</Text>
      </View>
    );
  } else if (tipo === "detalle_nombre") {
    return (
      <View style={styles.tableCol1}>
        <Text style={styles.tableCell}>{aprobacion.detalle_nombre}</Text>
      </View>
    );
  } else if (tipo === "detalle_valor") {
    return (
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>{aprobacion.detalle_valor}</Text>
      </View>
    );
  }
};

const sumaProductos = (listaProductos, nombre) => {
  var totalP = 0;

  listaProductos.map((item, index) => {
    if (item.tre_nombre === nombre) {
      totalP = parseFloat(totalP) + parseFloat(item.tre_valor);
    }
  });

  return totalP.toFixed(2);
};

{
  /* <Image src={ returnSrc()} /> */
}

const MyDocument = (props) => (
  <Document>
    <Page size="A4" style={styles.body}>
      <Text style={{ fontSize: 18, fontWeight: "bold" }}>
        Garantia : {props.currentClienteCarte.reg_codigo}
      </Text>

      <Text style={{ fontSize: 10, marginTop: 15 }}>
        A favor de : {props.currentClienteCarte.tal_nombre}
      </Text>
      <Text style={{ fontSize: 10 }}>Fecha de Orden : {props.fechaorden}</Text>
      <Text style={{ fontSize: 10 }}>
        Fecha de Garantia : {props.fechaLiquidacion}
      </Text>
      <Text style={{ fontSize: 10 }}>
        Ruc : {props.currentClienteCarte.tal_ruc}
      </Text>
      <Text style={{ fontSize: 10 }}>
        Dirección : {props.currentClienteCarte.tal_direccion}
      </Text>
      <Text style={{ fontSize: 10 }}>
        Telefono : {props.currentClienteCarte.tal_telefono}
      </Text>

      <Text
        style={{
          fontSize: 14,
          fontWeight: "bold",
          marginTop: 15,
          marginBottom: 15,
        }}
      >
        Lista de Registro{" "}
      </Text>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol1Header}>
            <Text style={styles.tableCellHeader}>Tipo</Text>
          </View>
          <View style={styles.tableCol1Header}>
            <Text style={styles.tableCellHeader}>Descripción</Text>
          </View>

          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Valor</Text>
          </View>
        </View>

        {props.LtsItemAprobacion.map((aprobacion) => (
          <View style={styles.tableRow}>
            {Object.keys(aprobacion).map((key) => (
              <>{tipoRow(key, aprobacion)}</>
            ))}
          </View>
        ))}
      </View>

      <Text
        style={{
          fontSize: 16,
          fontWeight: "bold",
          marginTop: 8,
          marginBottom: 8,
          textAlign: "right",
        }}
      >
        Total a Pagar :{" "}
        {(props.currentClienteCarte.total_orden * 1.0).toFixed(2)}
      </Text>

      <View
        style={{
          flexDirection: "row",
          width: "100%",
          alignContent: "center",
          alignItems: "center",
          marginTop: 100,
        }}
      >
        <View
          style={{
            width: "50%",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontSize: 12,
              marginTop: 8,
              width: "100%",
              textAlign: "center",
            }}
          >
            ------------------------------
          </Text>

          <Text
            style={{
              marginTop: 8,
              fontSize: 12,
              width: "100%",
              textAlign: "center",
            }}
          >
            Realizado por
          </Text>
        </View>

        <View
          style={{
            width: "50%",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontSize: 12,
              marginTop: 8,
              width: "100%",
              textAlign: "center",
            }}
          >
            ------------------------------
          </Text>

          <Text
            style={{
              marginTop: 8,
              fontSize: 12,
              width: "100%",
              textAlign: "center",
            }}
          >
            Autorizado por
          </Text>
        </View>
      </View>
    </Page>
  </Document>
);

const BORDER_COLOR = "#bfbfbf";
const BORDER_STYLE = "solid";
const COL1_WIDTH = 20;
const COLN_WIDTH = (100 - COL1_WIDTH * 3) / 4;
const styles = StyleSheet.create({
  body: {
    padding: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol1Header: {
    width: "40%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColHeader: {
    width: "20%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol1: {
    width: "40%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: "20%",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
});

function Content(props) {
  const storage = firebase.storage();
  const server = window.$linkserver;

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });

  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  const [listaImagenes, setlistaImagenes] = useState([]);
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const [modalShow, setModalShow] = React.useState(false);
  const [showModal, setshowModal] = React.useState(false);

  const [fechaordenPdf, setfechaordenPdf] = useState("");
  const [fechaordenPdfgata, setfechaordenPdfgata] = useState("");
  const [cartera, setCartera] = useState();
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const [startDate, setStartDate] = useState(
    new Date(year, month, day - day + 1)
  );

  const [startDatePdf] = useState(new Date());
  const [lastDate, setLastDate] = useState(new Date());
  const [showModalMap, setshowModalMap] = useState(false);
  const [showModalFotos, setshowModalFotos] = useState(false);
  const [showModalPagos, setShowModalPagos] = useState(false);
  const [showModalOrdenes, setShowModalOrdenes] = useState(false);
  const [showModalHistorial, setShowModalHistorial] = useState(false);
  const [showModalMapQlink, setShowModalMapQlink] = useState(false);
  const [showModalMapHistorial, setShowModalMapHistorial] = useState(false);
  const [showModalFotosCliente, setShowModalFotosCliente] = useState(false);
  const [showModalLoadingData, setShowModalLoadingData] = useState(false);
  const [showModalCargando, setShowModalCargando] = useState(false);
  const [startDateHistorial, setStartDateHistorial] = useState(new Date());
  const [search_cedula, setsearch_cedula] = useState("");
  const [motivo, setmotivo] = useState("");
  const [showModalDirecciones, setShowModalDirecciones] = useState(false);

  const [tipoLiquidar, settipoLiquidar] = useState(0);

  const [showInfoMarker, setShowInfoMarker] = useState();
  const [ShowModalAprobacion, setShowModalAprobacion] = useState(false);
  const [showModalDetalleCartera, setShowModalDetalleCartera] = useState(false);
  const [currentClienteCarte, setItemDataOrden] = useState([]);
  const [products, setproducts] = useState([]);
  const [currentCartera, setcurrentCartera] = useState([]);
  const [currentFactura, setcurrentFactura] = useState("");

  const [currentCarge, setcurrentCarge] = useState("");
  const [horaMapa, sethoraMapa] = useState("");
  const [showModalCargandoHistorial, setShowModalCargandoHistorial] = useState(
    false
  );

  const [currentVehiculo, setcurrentVehiculo] = useState([]);

  const [imgVisita1, setIMGVisita1] = useState([]);
  const [imgVisita2, setIMGVisita2] = useState([]);
  const [imgVisita3, setIMGVisita3] = useState([]);
  const [latVisita, setLatVisita] = useState(0);
  const [lonVisita, setLonVisita] = useState(0);
  const [direccionCli, setdireccionCli] = useState("");
  const [fechaCli, setfechaCli] = useState("");
  const [velocidadCli, setvelocidadCli] = useState("");
  const [motorCli, setmotorCli] = useState("");
  const [voltajeCli, setvoltajeCli] = useState("");
  const [key, setKey] = useState("Mapa");
  // const [fechaCli, setfechaCli] = useState('');

  const defaultProps = {
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyBWQwWMO0El5LZPg5eECmCQ7bh5o2Zg738",
  };

  const selectOptions = {
    1: "Éxitosa",
    0: "Fallida",
  };

  const [ltsPagos, setLtsPagos] = useState([]);
  const [ltsOrdenes, setLtsOrdenes] = useState([]);
  const [LtsItemAprobacion, setLtsItemAprobacion] = useState([]);
  const [ltsUbicaciones, setLtsUbicaciones] = useState([]);
  const [ltsDirecciones, setLtsDirecciones] = useState([]);

  const [tipodebusqueda, settipodebusqueda] = useState(0);

  const [ltsHistorial, setLtsHistorial] = useState([]);
  const [ltsLocationHistorial, setLtsLocationHistorial] = useState([]);

  const [urlimageG, setURLimageG] = useState("");
  const [urlCliCedula, setURLCliCedula] = useState("");
  const [urlCliCroquis, setURLCliCroquis] = useState("");
  const [urlCliMatricula, setURLCliMatricula] = useState("");
  const [urlCliActaEntrega, setURLCliActaEntrega] = useState("");

  const [imageG, setimageG] = useState("");
  const [imgCliCedula, setCliCedula] = useState("");
  const [imgCliCroquis, setCliCroquis] = useState("");
  const [imgCliMatricula, setCliMatricula] = useState("");
  const [imgCliActaEntrega, setCliActaEntrega] = useState("");

  const [ltsImgCliente, setltsImgCliente] = useState({
    cedula: "",
    croquis: "",
    matricula: "",
    acta: "",
    cliente: "",
  });

  const selectTipoCartera = {
    "FONDO DE GARANTIA": "FONDO DE GARANTIA",
    "CARTERA BANCO": "CARTERA BANCO",
  };

  const selectGPS = {
    1: "GPS Activado",
    0: "GPS Desactivado",
  };



  const getAllPdf = async (idGarantia, nombre) => {
    setShowModalCargando(true);

    await axios
      .get(server + "api/registro/get_pdf_reclamo/" + idGarantia, { responseType: 'blob' })

      .then(function (response) {
        // setproducts(response.data);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', nombre + '.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();

        setShowModalCargando(false);
      })
      .catch(function (error) {
        setShowModalCargando(false);
      })
      .then(function () { });
    // setShowModalCargandoHistorial(false);
    setShowModalCargando(false);
  };

  const Pdf = () => {
    return (
      <>
        <Button
          onClick={() => {
            console.log(currentClienteCarte)
            getAllPdf(currentClienteCarte.reg_id, currentClienteCarte.reg_codigo)
          }}
        >
          Descargar PDF
        </Button>

        <Button onClick={() => setShowModalAprobacion(false)}>Cerrar</Button>
      </>
    );
  };

  const columnsOrdenes = [
    {
      dataField: "gestor",
      text: "Gestor",
      headerAlign: "center",
      headerStyle: { backgroundColor: "rgba(255, 255, 255, 1)" },
      formatter: (cell, row, index) => infoGestor(row, index),
    },
    {
      dataField: "vis_fecha_visita",
      text: "Fecha de visita",
      formatter: (cell, row, index) => fechaVisita(row, index),
    },
    {
      dataField: "vis_tipo_visita",
      text: "Estado",
      formatter: (cell) => selectOptions[cell],
      filter: selectFilter({
        options: selectOptions,
        withoutEmptyOption: false,
        style: {
          // backgroundColor: "pink",
        },
        className: "test-classname",
        datamycustomattr: "datamycustomattr",
      }),
    },
    {
      dataField: "vis_observaciones",
      text: "Observaciones",
    },
    {
      dataField: "fotos",
      text: "Fotos",
      formatter: (cell, row, index) => showFotos(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "cdp_ubicacion",
      text: "Ubicación",
      formatter: (cell, row, index) => locationClienteVisita(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
  ];

  const selectOptionsAprobacion = {
    1: "Mano de Obra",
    2: "Repuesto",
  };

  const columnsAprobacion = [
    {
      dataField: "detalle_codigo",
      // text: "Código",
      headerAlign: "center",
      footer: "",
      headerStyle: { backgroundColor: "rgba(255, 255, 255, 1)" },
      filter: textFilter({
        placeholder: "Código",
      }),
      // formatter: (cell, row, index) => infoGestor(row, index),
    },

    {
      dataField: "detalle_nombre",
      text: "Descripción",
      headerAlign: "center",
      footer: "",
      headerStyle: { backgroundColor: "rgba(255, 255, 255, 1)" },
      // formatter: (cell, row, index) => infoGestor(row, index),
    },

    {
      dataField: "detalle_valor",
      text: "Valor",
      headerAlign: "center",
      headerStyle: { backgroundColor: "rgba(255, 255, 255, 1)" },
      footer: (columnData) =>
        "$" + columnData.reduce((acc, item) => acc + item, 0),
      // formatter: (cell, row, index) => infoGestor(row, index),
    },
  ];

  const columnsPagos = [
    // {
    //   dataField: "ci",
    //   text: "Cédula",
    //   headerAlign: "center",
    //   headerStyle: { backgroundColor: "rgba(255, 255, 255,0.1)" },
    // },
    // {
    //   dataField: "name",
    //   text: "Nombres",
    //   headerAlign: "center",
    //   headerStyle: { backgroundColor: "rgba(255, 255, 255,0.1)" },
    //   formatter: fechaFormatter,
    // },
    {
      dataField: "gestor",
      text: "Gestor",
      headerAlign: "center",
      headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
      formatter: (cell, row, index) => infoGestor(row, index),
    },
    {
      dataField: "cdp_fecha_actual_compromiso",
      text: "Fecha de visita",
      headerAlign: "center",
      formatter: (cell, row, index) => fechaPagoVisita(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      // formatter: fechaFormatter,
    },
    {
      dataField: "cdp_fecha_pago",
      text: "Fecha de compromiso",
      headerAlign: "center",
      formatter: (cell, row, index) => fechaPagoPago(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          // minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      // formatter: fechaFormatter,
    },

    {
      dataField: "cdp_valor_pago",
      text: "Valor",
      textAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      formatter: priceFormatter,
    },
    // {
    //   dataField: "cdp_observacion",
    //   text: "Observaciones",
    //   headerAlign: "center",
    //   headerStyle: (colum, colIndex) => {
    //     return {
    //       textAlign: "center",
    //       minWidth: "200px",
    //       backgroundColor: "rgba(255, 255, 255,0.1)",
    //     };
    //   },

    //   formatter: observacionesFormatter,
    // },
    // {
    //   dataField: "cdp_estado_pago",
    //   text: "Estado",
    //   headerAlign: "center",
    //   formatter: (cell, row, index) => semaforoEstado(row, index),
    //   headerStyle: (colum, colIndex) => {
    //     return {
    //       textAlign: "center",
    //       backgroundColor: "rgba(255, 255, 255,0.1)",
    //     };
    //   },
    // },
    // {
    //   dataField: "cdp_estado_pago1",
    //   text: "Observación de estado",
    //   headerStyle: (colum, colIndex) => {
    //     return {
    //       textAlign: "center",
    //       alignItems: "center",
    //       minWidth: "200px",
    //       backgroundColor: "rgba(255, 255, 255,0.1)",
    //     };
    //   },
    //   formatter: observacionesFormatter,
    // },
    {
      dataField: "cdp_ubicacion",
      text: "Ubicación",
      formatter: (cell, row, index) => locationPago(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    // {
    //   dataField: "fotos",
    //   text: "Fotos",
    //   formatter: (cell, row, index) => showFotosPagos(row, index),
    //   headerStyle: (colum, colIndex) => {
    //     return {
    //       textAlign: "center",
    //       alignItems: "center",
    //       backgroundColor: "rgba(255, 255, 255,1)",
    //     };
    //   },
    // },
  ];

  const columnsHistorial = [
    // {
    //   dataField: "ci",
    //   text: "Cédula",
    //   headerAlign: "center",
    //   headerStyle: { backgroundColor: "rgba(255, 255, 255,0.1)" },
    // },
    // {
    //   dataField: "name",
    //   text: "Nombres",
    //   headerAlign: "center",
    //   headerStyle: { backgroundColor: "rgba(255, 255, 255,0.1)" },
    //   formatter: fechaFormatter,
    // },
    {
      dataField: "crt_estado_credito",
      text: "Estado",
      headerAlign: "center",
      headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
      // formatter: (cell, row, index) => infoGestor(row, index),
    },
    {
      dataField: "crt_fecha_carga_actualizacion",
      text: "Fecha de carga",
      headerAlign: "center",
      formatter: (cell, row, index) => fechaHistorialCarga(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "crt_fecha_inicio_credito",
      text: "Fecha de inicio",
      headerAlign: "center",
      formatter: (cell, row, index) => fechaHistorial(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "crt_fecha_vencimiento",
      text: "Fecha vencimiento",
      headerAlign: "center",
      formatter: (cell, row, index) => fechaHistorialFin(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          // minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      // formatter: fechaFormatter,
    },

    {
      dataField: "crt_saldo_credito",
      text: "Saldo",
      textAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      formatter: priceFormatter,
    },
    {
      dataField: "crt_monto_credito_original",
      text: "Monto crédito",
      textAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      formatter: priceFormatter,
    },
    {
      dataField: "crt_valor_cuota_credito",
      text: "Cuota",
      textAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
      formatter: priceFormatter,
    },
    {
      dataField: "crt_plazo_credito_original",
      text: "Plazo",
      textAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "crt_dias_retraso",
      text: "Días retraso",
      textAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
    {
      dataField: "crt_cuotas_restantes",
      text: "Cuotas restantes",
      textAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },

    // {
    //   dataField: "cdp_observacion",
    //   text: "Observaciones",
    //   headerAlign: "center",
    //   headerStyle: (colum, colIndex) => {
    //     return {
    //       textAlign: "center",
    //       minWidth: "200px",
    //       backgroundColor: "rgba(255, 255, 255,0.1)",
    //     };
    //   },

    //   formatter: observacionesFormatter,
    // },
    // {
    //   dataField: "cdp_estado_pago",
    //   text: "Estado",
    //   headerAlign: "center",
    //   formatter: (cell, row, index) => semaforoEstado(row, index),
    //   headerStyle: (colum, colIndex) => {
    //     return {
    //       textAlign: "center",
    //       backgroundColor: "rgba(255, 255, 255,0.1)",
    //     };
    //   },
    // },
    // {
    //   dataField: "cdp_estado_pago1",
    //   text: "Observación de estado",
    //   headerStyle: (colum, colIndex) => {
    //     return {
    //       textAlign: "center",
    //       alignItems: "center",
    //       minWidth: "200px",
    //       backgroundColor: "rgba(255, 255, 255,0.1)",
    //     };
    //   },
    //   formatter: observacionesFormatter,
    // },

    // {
    //   dataField: "fotos",
    //   text: "Fotos",
    //   formatter: (cell, row, index) => showFotosPagos(row, index),
    //   headerStyle: (colum, colIndex) => {
    //     return {
    //       textAlign: "center",
    //       alignItems: "center",
    //       backgroundColor: "rgba(255, 255, 255,1)",
    //     };
    //   },
    // },
  ];

  const columnsDirecciones = [
    {
      dataField: "gestor",
      text: "Gestor",
      headerAlign: "center",
      headerStyle: { backgroundColor: "rgba(255, 255, 255, 1)" },
      formatter: (cell, row, index) => infoGestor(row, index),
    },
    {
      dataField: "dir_calle_principal",
      text: "Calle principal",
      // formatter: (cell, row, index) => fechaVisita(row, index),
    },
    {
      dataField: "dir_calle_secundaria",
      text: "Calle secundaria",
    },
    {
      dataField: "cdp_ubicacion",
      text: "Ubicación",
      formatter: (cell, row, index) => locationDirecciones(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
  ];

  const columns = [
    {
      dataField: "reg_codigo",
      text: "Código",
      headerAlign: "center",
      footer: "",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "90px",
      },

      // formatter: (cell, row, index) => infoGestor(row, index),
    },
    {
      dataField: "ord_fecha",
      text: "Fecha",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },
      formatter: (cell, row, index) => fechaPagoPago(row, index),
      footer: "",
    },

    {
      dataField: "tal_ruc",
      text: "Ruc Taller",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },
      footer: "",
      filter: textFilter({
        placeholder: "Ruc Taller",
      }),
    },

    {
      dataField: "tal_nombre",
      text: "Nombre Taller",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },
      footer: "",
      filter: textFilter({
        placeholder: "Nombre Taller",
      }),
    },

    {
      dataField: "tal_cuidad",
      text: "Ciudad",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },
      footer: "",
      filter: textFilter({
        placeholder: "Ciudad",
      }),
    },

    {
      dataField: "total_orden",
      text: "Valor",
      headerAlign: "center",
      headerStyle: {
        backgroundColor: "rgba(255, 255, 255,1)",
        width: "100%",
        minWidth: "150px",
      },
      formatter: (cell, row, index) => verPrecio(row, index),
      footer: (columnData) =>
        "$ " + columnData.reduce((acc, item) => acc + item, 0),
    },

    {
      dataField: "Detalles",
      text: "Detalles",
      headerAlign: "center",
      headerStyle: { backgroundColor: "rgba(255, 255, 255,1)" },
      formatter: (cell, row, index) => verAprobacion(row, index),
      footer: "",
    },
  ];

  const columnsHistorialMapa = [
    {
      dataField: "fechastr",
      text: "Fecha y Hora",
      sort: true,
      fechaHisFormatter,
    },
    {
      dataField: "direccion",
      text: "Dirección",
      sort: true,
    },
    {
      dataField: "velocidad",
      text: "Velocidad",
      sort: true,
    },
    {
      dataField: "cdp_ubicacion",
      text: "Ubicación",
      footer: "",
      formatter: (cell, row, index) => locationClienteHistorial(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255,1)",
        };
      },
    },
  ];

  useEffect(() => {
    // var days = new Date()
    // console.log(days.getDay())
    // console.log(startDate)
  }, []);

  const gestorAsignado = (row, index) => {
    return (
      <div>
        {row.carge_asignado !== "No existe gestor" ? (
          <Form.Label
            className="text-center align-items-center"
            style={{
              fontWeight: "bold",
              width: "100%",
              minWidth: "150px",
              backgroundColor: "rgba(0,255,0,0.15)",
              borderRadius: 10,
              alignSelf: "center",
              justifyContent: "center",
              msAlignSelf: "center",
              align: "middle",
            }}
          >
            {row.carge_asignado}
          </Form.Label>
        ) : (
          <Form.Label
            className="text-center align-items-center"
            style={{
              fontWeight: "bold",
              width: "100%",
              minWidth: "125px",
              backgroundColor: "rgba(255,0,0,0.15)",
              borderRadius: 10,
              align: "middle",
            }}
          >
            Gestor no asignado
          </Form.Label>
        )}
      </div>
    );
  };

  const getFechaOrdendePago = async (inicio, fin) => {
    setShowModalCargando(true);

    settipodebusqueda(1);

    await axios
      .get(
        server + "api/registro/getGarantiasporTipo/" + inicio + "/" + fin + "/1"
      )
      .then(function (response) {
        // setperfiles(row);s
        setproducts(response.data);

        setShowModalCargando(false);
      })
      .catch(function (error) {
        setShowModalCargando(false);
      })
      .then(function () { });

    setShowModalCargando(false);
  };

  const aprobarGarantia = async () => {
    axios
      .post(server + "api/registro/garantiaAprobada", {
        idUser: data.us_id,
        idRegistro: currentClienteCarte.reg_id,
        motivo: "",
        estado: 1,
      })

      .then(function (response) {
        if (response.status === 200) {
          settipoLiquidar(2);
          showMessage("Garantia Aprobanda Exitosamente", "success");

          // if (tipodebusqueda === 1) {
          //   getFechaOrdendePago(
          //     moment(startDate).format("yyyy-MM-DD"),
          //     moment(lastDate).format("yyyy-MM-DD")
          //   );
          // } else if (tipodebusqueda === 2) {
          //   getAllPendientes();
          // }
        } else {
          showMessage("Error en el server intentalo de nuevo", "error");
        }
      })
      .catch(function (error) {
        showMessage("Error en el server intentalo de nuevo", "error");
      });
  };

  const rechazarGarantia = async () => {
    axios
      .post(server + "api/registro/garantiaAprobada", {
        idUser: data.us_id,
        idRegistro: currentClienteCarte.reg_id,
        motivo: motivo,
        estado: 2,
      })

      .then(function (response) {
        if (response.status === 200) {
          // settipoLiquidar(2);
          showMessage("Garantia Rechazada Exitosamente", "success");
          setshowModal(false);
          setShowModalAprobacion(false);

          getFechaOrdendePago(
            moment(startDate).format("yyyy-MM-DD"),
            moment(lastDate).format("yyyy-MM-DD")
          );

          // if (tipodebusqueda === 1) {
          //   getFechaOrdendePago(
          //     moment(startDate).format("yyyy-MM-DD"),
          //     moment(lastDate).format("yyyy-MM-DD")
          //   );
          // } else if (tipodebusqueda === 2) {
          //   getAllPendientes();
          // }
        } else {
          showMessage("Error en el server intentalo de nuevo", "error");
        }
      })
      .catch(function (error) {
        showMessage("Error en el server intentalo de nuevo", "error");
      });
  };

  const getAllPendientes = async () => {
    setShowModalCargando(true);

    settipodebusqueda(2);
    await axios
      .get(server + "api/ordenPago/getAllListaPendientes/3")
      .then(function (response) {
        setproducts(response.data);

        setShowModalCargando(false);
      })
      .catch(function (error) {
        setShowModalCargando(false);
      })
      .then(function () { });
    // setShowModalCargandoHistorial(false);
    setShowModalCargando(false);
  };

  const getCarterasByCIGestor = async (ci) => {
    console.log(server + "api/cartera/getCarterasByCIgestor/" + ci);
    await axios
      .get(server + "api/cartera/getGestorCICartera/" + ci)
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        console.log(response.data);
        setproducts(response.data);
        setShowModalCargandoHistorial(false);
      })
      .catch(function (error) { })
      .then(function () { });
    setShowModalCargandoHistorial(false);
  };

  const getHistorialUbicaciones = async (inicio, fin) => {
    setShowModalCargandoHistorial(true);
    // setItemDataOrden(row)
    console.log("-----" + inicio);
    // console.log("-----" + currentCarge.carge_cedula);

    await axios
      .post("https://apibajaj.curbe.com.ec/api/qlink/history", {
        id: currentCarge.carge_cedula,
        vin: currentCarge.carge_chasis,
        desde: inicio,
        hasta: fin,
      })

      .then(function (response) {
        if (response.status === 200) {
          // console.log(response.data)
          if (response.data != "Access denied") {
            setLtsUbicaciones(response.data);
            let coord = response.data.map((item, key) => {
              return {
                lat: item.latitud,
                lng: item.longitud,
              };
            });
            let lat = "";
            let long = "";
            coord.map((item, key) => {
              lat = item.lat;
              long = item.lng;
            });
            // console.log(coord)
            setLtsLocationHistorial(coord);
            setLatVisita(lat);
            setLonVisita(long);
            setShowModalCargandoHistorial(false);
          } else {
            showMessage("Este vehículo no tiene activado el GPS", "error");
            setShowModalCargandoHistorial(true);
            setShowModalMapHistorial(false);
          }
        } else {
          // showMessage("Intentelo de Nuevo", "error");
        }
      })
      .catch(function (error) { });
    setShowModalCargandoHistorial(false);
  };

  const infoGestor = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {row.gestor.ges_nombre} {row.gestor.ges_apellido}
      </Form.Label>
    );
  };
  const fechaPagoPago = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {moment(row.ord_fecha).format("DD-MM-YYYY")}
      </Form.Label>
    );
  };

  const fechaorden = (fecha) => {
    return moment(fecha).format("DD-MM-YYYY");
  };
  const fechaPagoVisita = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {moment(row.cdp_fecha_actual_compromiso).format("DD-MM-YYYY | HH:mm a")}
      </Form.Label>
    );
  };
  const fechaVisita = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {moment(row.vis_fecha_visita).format("DD-MM-YYYY | HH:mm a")}
      </Form.Label>
    );
  };

  const fechaRegistro = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {moment(row.reg_fecha_registro).format("DD-MM-YYYY | HH:mm a")}
      </Form.Label>
    );
  };
  const fechaHistorialCarga = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {moment(row.crt_fecha_carga_actualizacion).format(
          "DD-MM-YYYY | HH:mm:ss a"
        )}
      </Form.Label>
    );
  };
  const fechaHistorial = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {moment(row.crt_fecha_inicio_credito).format("DD-MM-YYYY")}
      </Form.Label>
    );
  };
  const fechaHistorialFin = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {moment(row.crt_fecha_vencimiento).format("DD-MM-YYYY")}
      </Form.Label>
    );
  };
  const fechaCobro = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {moment(row.cob_fecha_pago).format("DD-MM-YYYY HH:mm a")}
      </Form.Label>
    );
  };
  const locationPago = (row, index) => {
    return (
      <Button
        onClick={() => openModalMap(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center" }}
      >
        <FontAwesomeIcon
          icon={faMapMarkedAlt}
          size="2x"
          textAlign="center"
          style={{ alignSelf: "center", justifyContent: "center" }}
        />
      </Button>
    );
  };
  const getOrdenesByCartera = async () => {
    // http://192.168.1.13:5001/api/asignacionapp/getLstOrdenesbyAsignacion/38

    await axios
      .get(server + "api/asignacionapp/getLstOrdenesbyAsignacion/")
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        // console.log(response.data);
        setproducts(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const showFotos = (row, index) => {
    return (
      <Button
        onClick={() => openModalFotos(row)}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faImages} size="2x" />
      </Button>
    );
  };

  const showFotosPagos = (row, index) => {
    return (
      <Button
        onClick={() => openModalFotos(row)}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faImages} size="2x" />
      </Button>
    );
  };

  function openModalFotos(item) {
    setshowModalFotos(true);
    setIMGVisita1(item.vis_img1);
    setIMGVisita2(item.vis_img2);
    setIMGVisita3(item.vis_img3);
  }

  function openModalFotosCobros(item) {
    setshowModalFotos(true);
    setIMGVisita1(item.cob_img1);
    setIMGVisita2(item.cob_img2);
    setIMGVisita3(item.cob_img3);
  }

  const showFotosCobros = (row, index) => {
    return (
      <Button
        onClick={() => openModalFotosCobros(row)}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faImages} size="2x" />
      </Button>
    );
  };

  function observacionesFormatter(cell, row) {
    return (
      <Form.Label
        className="text-justify"
        style={{ width: "100%", fontSize: 11 }}
      >
        {cell}
      </Form.Label>
    );
  }
  function diasFormatter(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "65px",
        }}
      >
        {cell}
      </Form.Label>
    );
  }

  function fechaDesembolso(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "115px",
        }}
      >
        {moment(cell.carge_fecha_desembolso).format("DD-MM-YYYY")}
      </Form.Label>
    );
  }

  function fechaFormatter(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "115px",
        }}
      >
        {cell}
      </Form.Label>
    );
  }

  function priceFormatter(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "100px",
        }}
      >
        $ {parseFloat(cell).toFixed(2)}
      </Form.Label>
    );
  }

  function fechaHisFormatter(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          fontSize: 50,
        }}
      ></Form.Label>
    );
  }

  const semaforoEstado = (row, index) => {
    if (row.carge_dias_retraso > 10 && row.carge_dias_retraso <= 30) {
      return (
        <Button
          // onClick={() => openModalMap(row)}
          color="green"
          variant="contained"
          textAlign="center"
        >
          <FontAwesomeIcon icon={faClock} color="orange" size="2x" />
        </Button>
      );
    } else if (row.carge_dias_retraso > 30) {
      return (
        <Button
          // onClick={() => openModalMap(row)}
          color="red"
          variant="contained"
        >
          <FontAwesomeIcon icon={faClock} color="#b30000" size="2x" />
        </Button>
      );
    } else if (row.carge_dias_retraso <= 10) {
      return (
        <Button
          // onClick={() => openModalMap(row)}
          color="green"
          variant="contained"
        >
          <FontAwesomeIcon icon={faClock} color="green" size="2x" />
        </Button>
      );
    }
  };

  const locationCliente = (row, index) => {
    return (
      <Button
        onClick={() => openModalMap2(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        {row.carge_gps_activado == 1 ? (
          <FontAwesomeIcon
            icon={faLocationArrow}
            size="2x"
            textAlign="center"
            style={{
              alignSelf: "center",
              justifyContent: "center",
              color: "green",
            }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faLocationArrow}
            size="2x"
            textAlign="center"
            style={{ alignSelf: "center", justifyContent: "center" }}
          />
        )}
      </Button>
    );
  };

  const locationClienteHistorial = (row, index) => {
    return (
      <Button
        onClick={() => openModalMap3(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon
          icon={faLocationArrow}
          size="2x"
          textAlign="center"
          style={{ alignSelf: "center", justifyContent: "center" }}
        />
      </Button>
    );
  };

  const historialUbicacion = (row, index) => {
    return (
      <Button
        onClick={() => openModalMapHistorial(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon
          icon={faMapMarkedAlt}
          size="2x"
          textAlign="center"
          style={{ alignSelf: "center", justifyContent: "center" }}
        />
      </Button>
    );
  };

  const locationDirecciones = (row, index) => {
    return (
      <Button
        onClick={() => openModalDireccionesUbicacion(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon
          icon={faLocationArrow}
          size="2x"
          textAlign="center"
          style={{ alignSelf: "center", justifyContent: "center" }}
        />
      </Button>
    );
  };

  const locationClienteVisita = (row, index) => {
    return (
      <Button
        onClick={() => openModalVisitaUbicacion(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon
          icon={faLocationArrow}
          size="2x"
          textAlign="center"
          style={{ alignSelf: "center", justifyContent: "center" }}
        />
      </Button>
    );
  };

  const locationClienteCobro = (row, index) => {
    return (
      <Button
        onClick={() => openModalCobroUbicacion(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon
          icon={faLocationArrow}
          size="2x"
          textAlign="center"
          style={{ alignSelf: "center", justifyContent: "center" }}
        />
      </Button>
    );
  };

  const openModalVisitaUbicacion = (row) => {
    setshowModalMap(true);
    setLatVisita(row.vis_ubicacion_actual_lat);
    setLonVisita(row.vis_ubicacion_actual_lon);
    sethoraMapa(row.vis_fecha_visita);
  };

  const openModalDireccionesUbicacion = (row) => {
    setshowModalMap(true);
    setLatVisita(row.dir_lat);
    setLonVisita(row.dir_lon);
  };

  const openModalCobroUbicacion = (row) => {
    setshowModalMap(true);
    setLatVisita(row.cob_lat);
    setLonVisita(row.cob_lon);
    sethoraMapa(row.cob_fecha_pago);
  };

  const openModalMap = async (row) => {
    setshowModalMap(true);

    setLatVisita(row.cdp_lat);
    setLonVisita(row.cdp_lon);
    sethoraMapa(row.cdp_fecha_actual_compromiso);
  };

  const openModalMapHistorial = async (row) => {
    setShowModalMapHistorial(true);
    setcurrentCarge(row);
    // await axios
    //   .post("https://apibajaj.curbe.com.ec/api/qlink/history", {
    //     id: row.carge_cedula,
    //     vin: row.carge_chasis,
    //     desde: "2020-11-05T07:00:52.486Z",
    //     hasta: "2020-11-05T07:00:52.486Z"
    //   })

    //   .then(function (response) {
    //     if (response.status === 200) {
    //       // console.log(response.data)
    //       setLtsUbicaciones(response.data)
    //       let coord = response.data.map((item, key) => {
    //         return {
    //           latitude: item.latitud,
    //           longitude: item.longitud,
    //         };
    //       });
    //       let lat = '';
    //       let long = '';
    //       coord.map((item, key) => {
    //         lat = item.latitude;
    //         long = item.longitude;
    //       });
    //       setLatVisita(lat)
    //       setLonVisita(long)

    //     } else {
    //       // showMessage("Intentelo de Nuevo", "error");
    //     }
    //   })
    //   .catch(function (error) { });
  };

  const openUbicacionCliente = (data) => {
    setShowModalMapQlink(true);

    setLatVisita(data.latitud);
    setLonVisita(data.longitud);
    setdireccionCli(data.direccion);
    setfechaCli(data.fecha);
    setmotorCli(data.motor);
    setvoltajeCli(data.voltage);
    setvelocidadCli(data.velocidad);
    setShowModalCargandoHistorial(false);
    console.log(latVisita);
  };

  const openModalMap2 = async (row) => {
    // setShowModalMapQlink(true);
    // console.log("https://apibajaj.curbe.com.ec/api/qlink/status/" + row.carge_cedula + "/" + row.carge_chasis)
    // var cedula = '13123'
    // var chasis = '123123'
    // setShowModalCargandoHistorial(true)

    await axios
      .get(
        "https://apibajaj.curbe.com.ec/api/qlink/status/" +
        row.carge_cedula +
        "/" +
        row.carge_chasis
      )
      .then(function (response) {
        // setperfiles(row);
        // console.log(response)
        if (response.status === 200) {
          // console.log(response);

          if (response.data != "Access denied") {
            console.log(response.data);
            // const dataCliente = JSON.stringify(response.data.data_cartera)
            // let coord = response.data.map((item, key) => {
            //   return {
            //     latitude: item.latitud,
            //     longitude: item.longitud,
            //   };
            // });
            // let lat = '';
            // let long = '';
            // coord.map((item, key) => {
            //   lat = item.latitude;
            //   long = item.longitude;
            // });
            openUbicacionCliente(response.data);

            // setLtsLocationHistorial(coord)
            // setLatVisita(response.data.latitud)
            // setLonVisita(response.data.longitud)

            console.log(latVisita);
          } else {
            showMessage("Este vehículo no tiene activado el GPS", "error");
            setShowModalMapQlink(false);
          }
          // console.log(dataCliente);
        } else {
        }
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const openModalMap3 = async (row) => {
    // setShowModalMapQlink(true);
    // console.log("https://apibajaj.curbe.com.ec/api/qlink/status/" + row.carge_cedula + "/" + row.carge_chasis)
    // var cedula = '13123'
    // var chasis = '123123'
    // setShowModalCargandoHistorial(true)

    openUbicacionCliente(row);
  };

  const updateDate = (date) => {
    setStartDate(date);
  };
  const updateDateHistorial = (date) => {
    setStartDateHistorial(date);
  };

  const updateLastDate = (date) => {
    setLastDate(date);
  };

  const openModalDirecciones = async (item) => {
    console.log(server + "api/cliente/getDirecionByCliCI/" + item.carge_cedula);

    // http://192.168.1.13:5001/api/cartera/getdetalleCartera/2
    setShowModalDirecciones(true);

    await axios
      .get(server + "api/cliente/getDirecionByCliCI/" + item.carge_cedula)
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        if (response.status === 200) {
          setLtsDirecciones(response.data);
          console.log(response.data);
          // const dataCliente = JSON.stringify(response.data.data_cartera)
          // setcurrentCartera(response.data.data_cartera);
          // setcurrentVehiculo(response.data.data_vehiculo);
          // setItemDataOrden(response.data.data_cliente);
          // setcurrentFactura(response.data.numFactura);
          // setShowModalDetalleCartera(true);
          // console.log(dataCliente);
        } else {
        }
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const openModalCartera = async (item) => {
    // console.log(item);

    // http://192.168.1.13:5001/api/cartera/getdetalleCartera/2

    await axios
      .get(server + "api/cartera/getdetalleCartera/" + item.carge_id)
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        if (response.status === 200) {
          // console.log(response.data.data_cartera);
          // const dataCliente = JSON.stringify(response.data.data_cartera)
          setcurrentCartera(response.data.data_cartera);
          setcurrentVehiculo(response.data.data_vehiculo);
          setItemDataOrden(response.data.data_cliente);
          setcurrentFactura(response.data.numFactura);
          setShowModalDetalleCartera(true);
          // console.log(dataCliente);
        } else {
        }
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const verFotosCliente = (row, index) => {
    return (
      <Button
        onClick={() => openModalFotosCliente(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon icon={faUserEdit} size="2x" />
      </Button>
    );
  };

  const verDetalles = (row, index) => {
    return (
      <Button
        onClick={() => openModalCartera(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon icon={faInfoCircle} size="2x" />
      </Button>
    );
  };

  const verDirecciones = (row, index) => {
    return (
      <Button
        onClick={() => openModalDirecciones(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon icon={faDirections} size="2x" />
      </Button>
    );
  };

  const verHistorialCartera = (row, index) => {
    return (
      <Button
        onClick={() => getHistorialCartera(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon icon={faHistory} size="2x" />
      </Button>
    );
  };

  const verAprobacion = (row, index) => {
    return (
      <Button
        onClick={() => getListaItemOrden(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon icon={faHandHoldingUsd} size="2x" />
      </Button>
    );
  };

  const verPrecio = (row, index) => {
    return <Text>$ {(row.total_orden * 1.0).toFixed(2)}</Text>;
  };

  const verOrdenes = (row, index) => {
    return (
      <Button
        onClick={() => getOrdenesbyCliente(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon icon={faPenSquare} size="2x" />
      </Button>
    );
  };

  const verPagos = (row, index) => {
    return (
      <Button
        onClick={() => getPagosbyCliente(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center", width: "100%" }}
      >
        <FontAwesomeIcon icon={faCalendarCheck} size="2x" />
      </Button>
    );
  };

  const getPagosbyCliente = async (row) => {
    setItemDataOrden(row);
    setShowModalPagos(true);
    // console.log(server + "api/asignacionapp/getLstCompromisosbyAsignacion/" + row.carge_id)
    await axios
      .get(
        server +
        "api/asignacionapp/getLstCompromisosbyAsignacion/" +
        row.carge_id
      )
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        // console.log(response.data);
        setLtsPagos(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const openModalFotosCliente = async (row) => {
    // console.log(row)
    getFotosClienteServer(row.carge_cedula);
    setItemDataOrden(row);
    setShowModalFotosCliente(true);
  };

  const getOrdenesbyCliente = async (row) => {
    setItemDataOrden(row);
    setShowModalOrdenes(true);
    // console.log(row)
    await axios
      .get(
        server + "api/asignacionapp/getLstOrdenesbyAsignacion/" + row.carge_id
      )
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        // console.log(response.data);
        setLtsOrdenes(response.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getHistorialCartera = async (row) => {
    // setItemDataOrden(row)
    setShowModalHistorial(true);
    setShowModalCargandoHistorial(true);

    await axios
      .get(server + "api/cartera/getHistorialCartera/" + row.carge_id)
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        console.log(response.data);
        setLtsHistorial(response.data);
        setShowModalCargandoHistorial(false);
      })
      .catch(function (error) { })
      .then(function () { });
    setShowModalCargandoHistorial(false);
  };

  const getListaItemOrden = async (row) => {
    setItemDataOrden(row);
    setlistaImagenes(row.listaImagenes);
    settipoLiquidar(0);
    setShowModalAprobacion(true);
    setURLCliCedula(row.reg_gar_fot_kilometraje)
    setURLimageG(row.reg_gar_fot_chasis)
    setURLCliMatricula(row.reg_gar_fot_manual)
    setURLCliActaEntrega(row.reg_gar_fot_matricula_1)
    setURLCliCroquis(row.reg_gar_fot_matricula_2)
    setfechaordenPdfgata(fechaorden(row.reg_fecha_garantia));
    setfechaordenPdf(fechaorden(row.ord_fecha));
    setLtsItemAprobacion(row.detalletrabajos);
  };

  const CMap = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={16}
        defaultCenter={{ lat: latVisita, lng: lonVisita }}
      >
        {props.children}
      </GoogleMap>
    ))
  );

  const selectFileCedula = async (event) => {
    if (event.target.files[0]) {
      setURLCliCedula(URL.createObjectURL(event.target.files[0]));
      // setCliCedula(event.target.files[0]);
      const val = event.target.files[0];

      setltsImgCliente((ltsImgCliente) => {
        return { ...ltsImgCliente, cedula: val };
      });

      uploadFotosFB("cedula", val);
    }
  };

  const selectFileCliente = async (event) => {
    if (event.target.files[0]) {
      setURLimageG(URL.createObjectURL(event.target.files[0]));
      // setimageG(event.target.files[0]);
      // setltsImgCliente({ cliente: imageG })
      const val = event.target.files[0];
      setltsImgCliente((ltsImgCliente) => {
        return { ...ltsImgCliente, cliente: val };
      });
      uploadFotosFB("cliente", val);
    }
  };

  const selectFileMatricula = async (event) => {
    if (event.target.files[0]) {
      setURLCliMatricula(URL.createObjectURL(event.target.files[0]));
      // setCliMatricula(event.target.files[0]);
      // setltsImgCliente({ matricula: imgCliMatricula })
      const val = event.target.files[0];
      setltsImgCliente((ltsImgCliente) => {
        return { ...ltsImgCliente, matricula: val };
      });
      uploadFotosFB("matricula", val);
    }
  };

  const selectFileActa = async (event) => {
    if (event.target.files[0]) {
      setURLCliActaEntrega(URL.createObjectURL(event.target.files[0]));
      // setCliActaEntrega(event.target.files[0]);
      // setltsImgCliente({ acta: imgCliActaEntrega })
      const val = event.target.files[0];
      setltsImgCliente((ltsImgCliente) => {
        return { ...ltsImgCliente, acta: val };
      });
      uploadFotosFB("actaentrega", val);
    }
  };

  const selectFileCroquis = async (event) => {
    if (event.target.files[0]) {
      setURLCliCroquis(URL.createObjectURL(event.target.files[0]));
      // setCliCroquis(event.target.files[0]);
      // setltsImgCliente({ croquis: imgCliCroquis })
      const val = event.target.files[0];
      setltsImgCliente((ltsImgCliente) => {
        return { ...ltsImgCliente, croquis: val };
      });
      uploadFotosFB("croquis", val);
    }
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  //
  const getFotosClienteServer = async (row) => {
    await axios
      .get(server + "api/cliente/getClienteByCI/" + row)
      .then(function (response) {
        // console.log(response.data)
        if (
          response.data.cli_foto_cedula != "" ||
          response.data.cli_foto_cedula != null
        ) {
          // console.log('response.data.cli_foto_cedula')
          setURLCliCedula(response.data.cli_foto_cedula);
        }
        if (
          response.data.cli_foto_croquis != "" ||
          response.data.cli_foto_croquis != null
        ) {
          // console.log('response.data.cli_foto_matricula')
          setURLCliCroquis(response.data.cli_foto_croquis);
        }
        if (
          response.data.cli_foto_matricula != "" ||
          response.data.cli_foto_matricula != null
        ) {
          // console.log('response.data.cli_foto_matricula')
          setURLCliMatricula(response.data.cli_foto_matricula);
        }
        if (
          response.data.cli_foto_actaentrega != "" ||
          response.data.cli_foto_actaentrega != null
        ) {
          // console.log('response.data.cli_foto_actaentrega')
          setURLCliActaEntrega(response.data.cli_foto_actaentrega);
        }
        if (
          response.data.cli_foto_path != "" ||
          response.data.cli_foto_path != null
        ) {
          // console.log('response.data.cli_foto_path')
          setURLimageG(response.data.cli_foto_path);
        }

        // else if (tipoFoto == "cliente") {
        //   setimageG(url)
        // } else if (tipoFoto == "matricula") {
        //   setCliMatricula(url)
        // } else if (tipoFoto == "actaentrega") {
        //   setCliActaEntrega(url)
        // } else if (tipoFoto == "croquis") {
        //   setCliCroquis(url)
        // }
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const setFotosClienteServer = async () => {
    // console.log(ltsImgCliente)
    // for (var i = 0; i < 5; i++) {
    //   console.log(ltsImgCliente)
    // }
    // console.log(imgCliCedula)
    // console.log(imageG)

    if (
      urlimageG !== "" &&
      urlCliCedula != "" &&
      urlCliActaEntrega != "" &&
      urlCliCroquis != "" &&
      urlCliMatricula != ""
    ) {
      setShowModalLoadingData(true);
      await axios
        .post(server + "api/cliente/uploadFotos", {
          cli_foto_path: imageG,
          cli_foto_cedula: imgCliCedula,
          cli_foto_croquis: imgCliCroquis,
          cli_foto_matricula: imgCliMatricula,
          cli_foto_actaentrega: imgCliActaEntrega,
          cli_cedula: currentClienteCarte.carge_cedula,
        })
        .then(function (response) {
          if (response.status === 200) {
            setShowModalLoadingData(false);

            showMessage("Fotos subidas con éxito", "success");
            setltsImgCliente((ltsImgCliente) => {
              return {
                ...ltsImgCliente,
                cedula: "",
                croquis: "",
                matricula: "",
                acta: "",
                cliente: "",
              };
            });
            setCliCedula("");
            setCliCroquis("");
            setCliMatricula("");
            setCliActaEntrega("");
            setimageG("");
            setShowModalFotosCliente(false);
          } else {
            showMessage("Problemas al crear gestor", "error");
          }
        })
        .catch(function (error) {
          showMessage("Problemas al subir fotos", "error");
        });
    } else {
      showMessage("Debes agregar el todos los campos", "error");
    }
  };

  const uploadFotosFB = async (tipoFoto, foto) => {
    //const ref = storage().ref().child('Bajaj/cartera/' + cartera.cliente.cli_cedula + '/cobros/' + idDate + '/').child(uid);
    console.log(currentClienteCarte.cli_cedula);
    if (foto !== "") {
      console.log("cargando foto...");
      // setIsLoadingGestor("visible");
      const uploadTask = storage
        .ref(`Bajaj/cartera/${currentClienteCarte.carge_cedula}/${tipoFoto}`)
        .put(foto);

      await uploadTask.on(
        "state_changed",
        (snapshot) => { },
        (error) => {
          console.log("erorrL L" + error);
        },
        () => {
          storage
            .ref("Bajaj/cartera/")
            .child(currentClienteCarte.carge_cedula + "/" + tipoFoto)
            .getDownloadURL()
            .then((url) => {
              console.log(url);
              // setImgGestor(url)
              // setGestor((gestor) => {
              //   return { ...gestor, ges_foto: url };
              // });
              console.log("foto cargada...");
              // setIsLoadingGestor("invisible");
              // createGestor(url);

              if (tipoFoto == "cedula") {
                setCliCedula(url);
              } else if (tipoFoto == "cliente") {
                setimageG(url);
              } else if (tipoFoto == "matricula") {
                setCliMatricula(url);
              } else if (tipoFoto == "actaentrega") {
                setCliActaEntrega(url);
              } else if (tipoFoto == "croquis") {
                setCliCroquis(url);
              }
            });
        }
      );
    } else {
      showMessage("Debes agregar el todos los campos", "error");
    }
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <Modal
            show={showModalCargando}
            onHide={() => setShowModalCargando(false)}
            centered
          >
            <Modal.Body class="text-center">
              <p></p>
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
              <p class="text-center">
                Cargando datos de Taller, espere por favor...
              </p>
            </Modal.Body>
          </Modal>

          <PageTitle
            heading="Garantías Aprobadas"
            icon="pe-7s-info icon-gradient bg-mean-fruit"
          />
          <Container fluid={true} className="content-body">
            <Row>
              <Col md="12" lg="12">

                <Modal
                  show={showModalDetalleCartera}
                  {...props}
                  onHide={() => setShowModalDetalleCartera(false)}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Información de Cartera:
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Container>
                      <Row>
                        <Col md="4">
                          {/* <Image
                            className="img-fluid"
                            alt=""
                            src={
                              currentVehiculo.veh_foto != null
                                ? "https://apibajaj.curbe.com.ec/Resources/images/" +
                                  currentVehiculo.veh_foto +
                                  ".png"
                                : "https://firebasestorage.googleapis.com/v0/b/proyecto-bajaj.appspot.com/o/Bajaj%2Fbajajic.png?alt=media&token=5f724062-0f63-4564-9b06-4efd1959b3ce"
                            }
                          ></Image> */}
                        </Col>
                        <Col md="8">
                          <Row
                            style={{
                              backgroundColor: "rgba(19, 122, 209,0.3)",
                              textAlign: "center",
                              fontSize: 24,
                              fontWeight: "bold",
                              alignSelf: "center",
                            }}
                          >
                            {" "}
                            Información de vehiculo
                          </Row>

                          <Container>
                            <Row>
                              <Col>Marca:</Col>
                              <Col>{currentVehiculo.veh_marca}</Col>
                            </Row>
                            <Row>
                              <Col>Motor:</Col>
                              <Col>{currentVehiculo.veh_motor}</Col>
                            </Row>
                            <Row>
                              <Col>Chasis:</Col>
                              <Col>{currentVehiculo.veh_chasis}</Col>
                            </Row>
                            <Row>
                              <Col>Versión:</Col>
                              <Col>{currentVehiculo.veh_version}</Col>
                            </Row>
                            <Row>
                              <Col>Modelo:</Col>
                              <Col>{currentVehiculo.veh_modelo}</Col>
                            </Row>
                            <Row>
                              <Col>Color:</Col>
                              <Col>{currentVehiculo.veh_color}</Col>
                            </Row>
                            <Row>
                              <Col>Año:</Col>
                              <Col>{currentVehiculo.veh_anio}</Col>
                            </Row>
                          </Container>
                        </Col>
                      </Row>

                      <Row
                        style={{
                          backgroundColor: "rgba(19, 122, 209,0.3)",
                          textAlign: "center",
                          fontSize: 24,
                          fontWeight: "bold",
                          alignSelf: "center",
                        }}
                      >
                        {" "}
                        Información de cliente
                      </Row>
                      <Row>
                        <Col
                          sm={2}
                          style={{
                            backgroundColor: "rgba(19, 122, 209,0.1)",
                            borderWidth: 1,
                          }}
                        >
                          Cliente:
                        </Col>
                        <Col
                          sm={7}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {currentClienteCarte.cli_nombre}{" "}
                          {currentClienteCarte.cli_apellido}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          sm={2}
                          style={{
                            backgroundColor: "rgba(19, 122, 209,0.1)",
                            borderWidth: 1,
                          }}
                        >
                          Cédula:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {currentClienteCarte.cli_cedula}
                        </Col>
                        <Col
                          sm={2}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Correo:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {currentClienteCarte.cli_email}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          sm={2}
                          style={{
                            backgroundColor: "rgba(19, 122, 209,0.1)",
                            borderWidth: 1,
                          }}
                        >
                          Teléfono:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {currentClienteCarte.cli_telefono}
                        </Col>
                        <Col
                          sm={2}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Celular:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {currentClienteCarte.cli_celular}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          sm={2}
                          style={{
                            backgroundColor: "rgba(19, 122, 209,0.1)",
                            borderWidth: 1,
                          }}
                        >
                          {" "}
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {" "}
                        </Col>
                      </Row>

                      <Row
                        style={{
                          backgroundColor: "rgba(19, 122, 209,0.3)",
                          textAlign: "center",
                          fontSize: 24,
                          fontWeight: "bold",
                          alignSelf: "center",
                          marginTop: 8,
                        }}
                      >
                        {" "}
                        Información de cartera: {currentFactura}
                      </Row>

                      <Row>
                        <Col
                          sm={4}
                          style={{
                            backgroundColor: "rgba(19, 122, 209,0.1)",
                            borderWidth: 1,
                          }}
                        >
                          Monto del crédito original:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          ${currentCartera.crt_monto_credito_original}
                        </Col>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Saldo a la fecha:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          ${currentCartera.crt_saldo_credito}
                        </Col>
                      </Row>
                      <Col className="mt-3"></Col>
                      <Row>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Valor a pagar a la fecha:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          $
                          {parseFloat(
                            currentCartera.crt_valor_pago_fecha_actual
                          ).toFixed(2)}
                        </Col>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Estado de crédito:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {currentCartera.crt_estado_credito}
                        </Col>
                      </Row>
                      <Col className="mt-3"></Col>
                      <Row>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Plazo del credito original:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {currentCartera.crt_plazo_credito_original}
                        </Col>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Fecha de inicio de crédito:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {moment(
                            currentCartera.crt_fecha_inicio_credito
                          ).format("DD-MM-yyyy")}
                        </Col>
                      </Row>
                      <Col className="mt-3"></Col>
                      <Row>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Valor de la cuota:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          $
                          {parseFloat(
                            currentCartera.crt_valor_cuota_credito
                          ).toFixed(2)}
                        </Col>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Cuotas restantes:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {currentCartera.crt_cuotas_restantes}
                        </Col>
                      </Row>
                      <Col className="mt-3"></Col>
                      <Row>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Cuotas impagas:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {currentCartera.crt_cuota_impaga_inicio}
                        </Col>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Fecha vencimiento:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {moment(currentCartera.crt_fecha_vencimiento).format(
                            "DD-MM-yyyy"
                          )}
                        </Col>
                      </Row>
                      <Col className="mt-3"></Col>
                      <Row>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Días de atraso:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {currentCartera.crt_dias_retraso}
                        </Col>
                        <Col
                          sm={4}
                          style={{ backgroundColor: "rgba(19, 122, 209,0.1)" }}
                        >
                          Fecha de carga de actualización:
                        </Col>
                        <Col
                          sm={2}
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {moment(
                            currentCartera.crt_fecha_carga_actualizacion
                          ).format("DD-MM-yyyy")}
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => setShowModalDetalleCartera(false)}>
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={showModalMap}
                  onHide={!showModalMap}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Body>
                    <Map
                      lat={latVisita}
                      lon={lonVisita}
                      titulo={moment(horaMapa).format("LLLL")}
                      // fecha={"Fecha: " + moment(fechaCli).format('LLLL')}
                      url="https://firebasestorage.googleapis.com/v0/b/maybisneschat.appspot.com/o/markermoto%202.png?alt=media&token=73088975-3993-40d8-9c00-fb0f1619f767"
                      googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyBWQwWMO0El5LZPg5eECmCQ7bh5o2Zg738"
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `400px` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                    ></Map>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        setshowModalMap(false);
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={showModalMapQlink}
                  onHide={!showModalMapQlink}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Body>
                    <Modal
                      show={showModalCargandoHistorial}
                      onHide={() => setShowModalCargandoHistorial(false)}
                      centered
                    >
                      <Modal.Body class="text-center">
                        <p></p>
                        <Spinner
                          as="span"
                          animation="border"
                          size="lg"
                          role="status"
                          aria-hidden="true"
                        />
                        <p class="text-center">
                          Cargando historial, espere por favor.
                        </p>
                      </Modal.Body>
                    </Modal>

                    <Map
                      lat={latVisita}
                      lon={lonVisita}
                      titulo={"Dirección: " + direccionCli}
                      fecha={"Fecha: " + moment(fechaCli).format("LLLL")}
                      velocidad={"Velocidad: " + velocidadCli + " km"}
                      motor={"Motor: " + motorCli}
                      voltaje={"Voltaje: " + voltajeCli + "v"}
                      url="https://firebasestorage.googleapis.com/v0/b/maybisneschat.appspot.com/o/markermoto%202.png?alt=media&token=73088975-3993-40d8-9c00-fb0f1619f767"
                      googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyBWQwWMO0El5LZPg5eECmCQ7bh5o2Zg738"
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `400px` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                    ></Map>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        setShowModalMapQlink(false);
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={showModalMapHistorial}
                  onHide={!showModalMapHistorial}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Title>
                    <Row>
                      <Col className="align-middle" md="auto">
                        Fecha de busqueda
                      </Col>
                      <Col md="auto">
                        <DatePicker
                          locale="es"
                          dateFormat="dd/MM/yyyy"
                          selected={startDateHistorial}
                          // startDate={startDate}
                          // endDate={lastDate}
                          // minDate={startDate}
                          // selectsStart
                          onChange={updateDateHistorial}
                        />
                      </Col>

                      <Button
                        onClick={() => {
                          // console.log('aaaaaaa')

                          startDateHistorial.setHours(
                            startDateHistorial.getHours() - 5
                          );
                          console.log(startDateHistorial.toISOString());

                          getHistorialUbicaciones(
                            startDateHistorial.toISOString(),
                            startDateHistorial.toISOString()
                          );
                        }}
                      >
                        Buscar historial
                      </Button>
                    </Row>
                  </Modal.Title>

                  <Modal.Body>
                    <Modal
                      show={showModalCargandoHistorial}
                      onHide={() => setShowModalCargandoHistorial(false)}
                      centered
                    >
                      <Modal.Body class="text-center">
                        <p></p>
                        <Spinner
                          as="span"
                          animation="border"
                          size="lg"
                          role="status"
                          aria-hidden="true"
                        />
                        <p class="text-center">
                          Cargando historial, espere por favor.
                        </p>
                      </Modal.Body>
                    </Modal>

                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key}
                      onSelect={(k) => setKey(k)}
                    >
                      <Tab eventKey="Mapa" title="Mapa">
                        <CMap
                          // defaultZoom={15}
                          defaultCenter={{ lat: latVisita, lng: lonVisita }}
                          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyBWQwWMO0El5LZPg5eECmCQ7bh5o2Zg738"
                          loadingElement={<div style={{ height: `100%` }} />}
                          containerElement={<div style={{ height: `400px` }} />}
                          mapElement={<div style={{ height: `100%` }} />}
                        >
                          {ltsUbicaciones.map((marker) => (
                            <Marker
                              position={{
                                lat: marker.latitud,
                                lng: marker.longitud,
                              }}
                              key={marker.direccion}
                              icon={{
                                url:
                                  "https://firebasestorage.googleapis.com/v0/b/maybisneschat.appspot.com/o/markermoto%202.png?alt=media&token=73088975-3993-40d8-9c00-fb0f1619f767",
                              }}
                              onClick={() => setShowInfoMarker(marker)}
                            >
                              {showInfoMarker === marker && (
                                <InfoWindow
                                  key={`infowindow-${marker.direccion}`}
                                  visible={false}
                                >
                                  <div>
                                    <div> Detalles: </div>
                                    <div>
                                      {" "}
                                      {"Dirección: " + marker.direccion}
                                    </div>
                                    <div>
                                      {" "}
                                      {"Fecha: " +
                                        moment(marker.fecha).format("LLLL")}
                                    </div>
                                    <div>
                                      {" "}
                                      {"Velocidad: " + marker.velocidad}
                                    </div>
                                    <div> {"Mensaje: " + marker.mensaje}</div>
                                  </div>
                                </InfoWindow>
                              )}
                            </Marker>
                          ))}
                          <Polyline
                            path={ltsLocationHistorial}
                            // geodesic={true}
                            options={{
                              strokeColor: "#00336A",
                              strokeOpacity: 0.75,
                              strokeWeight: 3,
                            }}
                          />
                        </CMap>
                      </Tab>
                      <Tab eventKey="Historial" title="Historial">
                        <BootstrapTable
                          keyField="cli_id"
                          data={ltsUbicaciones}
                          columns={columnsHistorialMapa}
                          pagination={paginationFactory()}
                        />
                      </Tab>
                    </Tabs>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        setShowModalMapHistorial(false);
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={showModalFotos}
                  {...props}
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Title id="contained-modal-title-vcenter">
                    Imagenes tomadas
                  </Modal.Title>
                  <Carousel>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgVisita1}
                        alt="First slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgVisita2}
                        alt="Third slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgVisita3}
                        alt="Third slide"
                      />
                    </Carousel.Item>
                  </Carousel>
                  <Modal.Footer>
                    <Button onClick={() => setshowModalFotos(false)}>
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={showModalPagos}
                  {...props}
                  onHide={() => setShowModalPagos(false)}
                  // dialogClassName="modal-90w"
                  dialogClassName="modal-90w"
                  aria-labelledby="example-custom-modal-styling-title"
                  centered
                // className="modal-dialog modal-lg"
                // style = {{width : '80%'}}
                >
                  <Modal.Header closeButton>
                    {/* <p>Cliente: <b>{currentClienteCarte.cli_nombre} {" "} {currentClienteCarte.cli_apellido}</b> <br></br>Celular:<b>{currentClienteCarte.cli_celular}</b></p> */}
                  </Modal.Header>
                  <Modal.Body
                    style={{
                      maxHeight: "calc(100vh - 210px)",
                      overflowY: "auto",
                    }}
                  >
                    <ToolkitProvider
                      keyField="id"
                      data={ltsPagos}
                      columns={columnsPagos}
                      search
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col className="align-middle" md="auto">
                              Buscar por fecha:
                            </Col>
                            <Col md="auto">
                              <SearchBar
                                class="align-middle"
                                placeholder="Ingrese una fecha ejm (01/02/2020)"
                                style={{ height: 25, width: 150 }}
                                {...props.searchProps}
                              />
                            </Col>
                          </Row>
                          <Col className="mt-3"></Col>
                          <Form>
                            <Row>
                              <Form.Check>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  size="lg"
                                  color="green"
                                />
                                <Form.Check.Label
                                  column
                                  style={{ fontSize: 11 }}
                                >
                                  Más de 5 días para fecha de pago
                                </Form.Check.Label>
                              </Form.Check>

                              <Form.Check>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  size="lg"
                                  color="orange"
                                />
                                <Form.Check.Label
                                  column
                                  style={{ fontSize: 11 }}
                                >
                                  A días 5 para fecha de pago
                                </Form.Check.Label>
                              </Form.Check>

                              <Form.Check>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  size="lg"
                                  color="#b30000"
                                />
                                <Form.Check.Label
                                  column
                                  style={{ fontSize: 11 }}
                                >
                                  Cerca de fecha de pago
                                </Form.Check.Label>
                              </Form.Check>
                            </Row>
                          </Form>
                          <Col className="mt-3"></Col>

                          <BootstrapTable
                            striped
                            hover
                            condensed
                            wrapperClasses="table-responsive"
                            // class="react-bootstrap-table table"

                            filter={filterFactory()}
                            pagination={paginationFactory(optionsPagination)}
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </Modal.Body>
                </Modal>

                <Modal
                  show={showModalHistorial}
                  {...props}
                  onHide={() => setShowModalHistorial(false)}
                  // dialogClassName="modal-90w"
                  dialogClassName="modal-90w"
                  aria-labelledby="example-custom-modal-styling-title"
                  centered
                // className="modal-dialog modal-lg"
                // style = {{width : '80%'}}
                >
                  <Modal.Header closeButton>
                    {/* <p>Cliente: <b>{currentClienteCarte.cli_nombre} {" "} {currentClienteCarte.cli_apellido}</b> <br></br>Celular:<b>{currentClienteCarte.cli_celular}</b></p> */}
                  </Modal.Header>
                  <Modal.Body
                    style={{
                      maxHeight: "calc(100vh - 210px)",
                      overflowY: "auto",
                    }}
                  >
                    <ToolkitProvider
                      keyField="id"
                      data={ltsHistorial}
                      columns={columnsHistorial}
                      search
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col className="align-middle" md="auto">
                              Buscar por fecha:
                            </Col>
                            <Col md="auto">
                              <SearchBar
                                class="align-middle"
                                placeholder="Ingrese una fecha ejm (01/02/2020)"
                                style={{ height: 25, width: 150 }}
                                {...props.searchProps}
                              />
                            </Col>
                          </Row>
                          <Col className="mt-3"></Col>

                          <Col className="mt-3"></Col>

                          <BootstrapTable
                            striped
                            hover
                            condensed
                            wrapperClasses="table-responsive"
                            // class="react-bootstrap-table table"

                            filter={filterFactory()}
                            pagination={paginationFactory(optionsPagination)}
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </Modal.Body>
                </Modal>

                <Modal
                  show={showModalOrdenes}
                  {...props}
                  onHide={() => setShowModalOrdenes(false)}
                  // dialogClassName="modal-90w"
                  dialogClassName="modal-90w"
                  aria-labelledby="example-custom-modal-styling-title"
                  centered
                // className="modal-dialog modal-lg"
                // style = {{width : '80%'}}
                >
                  <Modal.Header closeButton>
                    {/* <p>Cliente: <b>{currentClienteCarte.cli_nombre} {" "} {currentClienteCarte.cli_apellido}</b> <br></br>Celular:<b>{currentClienteCarte.cli_celular}</b></p> */}
                  </Modal.Header>
                  <Modal.Body
                    style={{
                      maxHeight: "calc(100vh - 210px)",
                      overflowY: "auto",
                    }}
                  >
                    <ToolkitProvider
                      keyField="id"
                      data={ltsOrdenes}
                      columns={columnsOrdenes}
                      search
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col className="align-middle" md="auto">
                              Buscar por fecha:
                            </Col>
                            <Col md="auto">
                              <SearchBar
                                class="align-middle"
                                placeholder="Ingrese una fecha ejm (01/02/2020)"
                                style={{ height: 25, width: 150 }}
                                {...props.searchProps}
                              />
                            </Col>
                          </Row>
                          <Col className="mt-3"></Col>
                          <Form>
                            <Row>
                              {/* <Form.Check>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  size="lg"
                                  color="green"
                                />
                                <Form.Check.Label
                                  column
                                  style={{ fontSize: 11 }}
                                >
                                  Más de 5 días para fecha de pago
                                    </Form.Check.Label>
                              </Form.Check>

                              <Form.Check>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  size="lg"
                                  color="orange"
                                />
                                <Form.Check.Label
                                  column
                                  style={{ fontSize: 11 }}
                                >
                                  A días 5 para fecha de pago
                                    </Form.Check.Label>
                              </Form.Check> */}

                              {/* <Form.Check>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  size="lg"
                                  color="#b30000"
                                />
                                <Form.Check.Label
                                  column
                                  style={{ fontSize: 11 }}
                                >
                                  Cerca de fecha de pago
                                    </Form.Check.Label>
                              </Form.Check> */}
                            </Row>
                          </Form>
                          <Col className="mt-3"></Col>

                          <BootstrapTable
                            striped
                            hover
                            condensed
                            wrapperClasses="table-responsive"
                            // class="react-bootstrap-table table"
                            filter={filterFactory()}
                            pagination={paginationFactory(optionsPagination)}
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </Modal.Body>
                </Modal>

                <Modal
                  show={ShowModalAprobacion}
                  {...props}
                  onHide={() => setShowModalAprobacion(false)}
                  // dialogClassName="modal-90w"

                  aria-labelledby="example-custom-modal-styling-title"
                  centered
                  size="xl"
                // className="modal-dialog modal-lg"
                // style = {{width : '80%'}}
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      {currentClienteCarte.reg_codigo} de fecha :{" "}
                      {fechaorden(currentClienteCarte.reg_fecha_registro)}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <ToolkitProvider
                      keyField="id"
                      data={LtsItemAprobacion}
                      columns={columnsAprobacion}
                      search
                    >
                      {(props) => (
                        <>
                          <Container as={Row}>
                            <Col
                              style={{
                                backgroundColor: "rgba(19, 122, 209,0.05781)",
                                // border: '1px solid rgba(19, 122, 209,1)'
                              }}
                            >
                              <Row>
                                <label
                                  style={{
                                    backgroundColor: "rgba(19, 122, 209,0.08)",
                                    textAlign: "center",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                    alignSelf: "center",
                                    width: "100%",
                                  }}
                                >
                                  {"Información Cliente"}</label>

                              </Row>
                              <Container>
                                <Row>
                                  <Col>
                                    <label
                                      style={{
                                        textAlign: "left",
                                        fontSize: 16,
                                        fontWeight: "bold",
                                      }}
                                    >Nombre:</label></Col>
                                  <Col>
                                    <label
                                      style={{
                                        // backgroundColor: "rgba(19, 122, 209,0.3)",
                                        textAlign: "center",
                                        fontSize: 16,

                                        alignSelf: "center",
                                      }}

                                    >{currentClienteCarte.cli_nombre}</label> </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <label
                                      style={{
                                        textAlign: "left",
                                        fontSize: 16,
                                        fontWeight: "bold",
                                      }}
                                    >Cédula:</label></Col>
                                  <Col>
                                    <label
                                      style={{
                                        // backgroundColor: "rgba(19, 122, 209,0.3)",
                                        textAlign: "center",
                                        fontSize: 16,

                                        alignSelf: "center",
                                      }}

                                    >{currentClienteCarte.cli_cedula}</label> </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <label
                                      style={{
                                        textAlign: "left",
                                        fontSize: 16,
                                        fontWeight: "bold",
                                      }}
                                    >Teléfono:</label></Col>
                                  <Col>
                                    <label
                                      style={{
                                        // backgroundColor: "rgba(19, 122, 209,0.3)",
                                        textAlign: "center",
                                        fontSize: 16,

                                        alignSelf: "center",
                                      }}

                                    >{currentClienteCarte.cli_celular}</label> </Col>
                                </Row>

                              </Container>
                            </Col>

                            <Col
                              style={{
                                backgroundColor: "rgba(19, 122, 209,0.05781)",
                                // border: '1px solid rgba(19, 122, 209,1)',
                                // marginLeft:1,
                                borderLeft: '1px solid rgba(111, 111, 111,1)',
                                borderRight: '1px solid rgba(111, 111, 111,1)',
                              }}
                            >
                              <Row>
                                <label
                                  style={{
                                    backgroundColor: "rgba(19, 122, 209,0.081)",
                                    textAlign: 'center',
                                    fontSize: 18,
                                    fontWeight: "bold",
                                    alignSelf: "center",
                                    width: "100%",
                                  }}
                                >{"Información de Vehiculo"}</label>
                              </Row>
                              <Container>
                                <Row>
                                  <Col>
                                    <label
                                      style={{
                                        textAlign: "left",
                                        fontSize: 16,
                                        fontWeight: "bold",
                                      }}
                                    >Chasis:</label></Col>
                                  <Col>
                                    <label
                                      style={{
                                        textAlign: "left",
                                        fontSize: 16,
                                      }}
                                    >{currentClienteCarte.veh_chasis}</label></Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <label
                                      style={{
                                        textAlign: "left",
                                        fontSize: 16,
                                        fontWeight: "bold",
                                      }}
                                    >Modelo:</label></Col>
                                  <Col>
                                    <label style={{
                                      // backgroundColor: "rgba(19, 122, 209,0.3)",
                                      textAlign: "left",
                                      fontSize: 16,
                                    }} >{currentClienteCarte.veh_modelo}</label></Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <label
                                      style={{
                                        textAlign: "left",
                                        fontSize: 16,
                                        fontWeight: "bold",
                                      }}
                                    >Motor:</label></Col>
                                  <Col>
                                    <label style={{
                                      // backgroundColor: "rgba(19, 122, 209,0.3)",
                                      textAlign: "left",
                                      fontSize: 16,
                                    }} >{currentClienteCarte.veh_motor}</label></Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <label
                                      style={{
                                        textAlign: "left",
                                        fontSize: 16,
                                        fontWeight: "bold",
                                      }}
                                    >KM:</label></Col>
                                  <Col>
                                    <label style={{
                                      // backgroundColor: "rgba(19, 122, 209,0.3)",
                                      textAlign: "left",
                                      fontSize: 16,
                                    }} >{currentClienteCarte.veh_kilometraje}</label> </Col>
                                </Row>

                              </Container>
                            </Col>

                            <Col
                              style={{
                                backgroundColor: "rgba(19, 122, 209,0.05781)",
                                // border: '1px solid rgba(19, 122, 209,1)'
                              }}
                            >
                              <Row>
                                <label
                                  style={{
                                    backgroundColor: "rgba(19, 122, 209,0.081)",
                                    textAlign: "center",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                    alignSelf: "center",
                                    width: "100%",
                                  }}
                                >
                                  {"Información de Taller"}</label>

                              </Row>

                              <Container>
                                <Row>
                                  <Col>
                                    <label
                                      style={{
                                        textAlign: "left",
                                        fontSize: 16,
                                        fontWeight: "bold",
                                      }}
                                    >Nombre:</label></Col>
                                  <Col>
                                    <label
                                      style={{
                                        // backgroundColor: "rgba(19, 122, 209,0.3)",
                                        textAlign: "center",
                                        fontSize: 16,

                                        alignSelf: "center",
                                      }}
                                    >{currentClienteCarte.tal_nombre}</label></Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <label
                                      style={{
                                        textAlign: "left",
                                        fontSize: 16,
                                        fontWeight: "bold",
                                      }}
                                    >Ciudad:</label></Col>
                                  <Col>
                                    <label style={{
                                      // backgroundColor: "rgba(19, 122, 209,0.3)",
                                      textAlign: "left",
                                      fontSize: 16,
                                    }} >{currentClienteCarte.tal_cuidad}</label></Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <label
                                      style={{
                                        textAlign: "left",
                                        fontSize: 16,
                                        fontWeight: "bold",
                                      }}
                                    >Fecha compra:</label></Col>
                                  <Col>
                                    <label style={{
                                      // backgroundColor: "rgba(19, 122, 209,0.3)",
                                      textAlign: "left",
                                      fontSize: 16,
                                    }} >{moment(currentClienteCarte.reg_fecha_venta).format("ll")}</label></Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <label
                                      style={{
                                        textAlign: "left",
                                        fontSize: 16,
                                        fontWeight: "bold",
                                      }}
                                    >Teléfono:</label></Col>
                                  <Col>
                                    <label style={{
                                      // backgroundColor: "rgba(19, 122, 209,0.3)",
                                      textAlign: "left",
                                      fontSize: 16,
                                    }} >{currentClienteCarte.tal_telefono}</label></Col>
                                </Row>

                              </Container>
                            </Col>
                          </Container>

                          <Container as={Row}>
                            <Col>

                              <Col style={{ width: "100%" }}>
                                <Row>
                                  <label
                                    style={{
                                      backgroundColor: "rgba(19, 122, 209,0.181)",
                                      textAlign: "center",
                                      fontSize: 18,
                                      fontWeight: "bold",
                                      alignSelf: "center",
                                      width: "100%",
                                      borderTop: '1px solid rgba(111, 111, 111,1)',

                                    }}
                                  >
                                    {"Información de daño"}</label>
                                </Row>
                                <Row>
                                  <label
                                    style={{
                                      textAlign: "center",
                                      fontSize: 16,
                                      fontWeight: "bold",
                                    }}
                                  >{currentClienteCarte.reg_gar_nombre_danio}</label>
                                </Row>
                                <Row>
                                  <Col>
                                    <label>{currentClienteCarte.reg_gar_descripcion_danio}</label></Col>
                                </Row>
                              </Col>
                            </Col>
                          </Container>



                          <Container as={Row}  style={{
                              borderTop: '1px solid rgba(111, 111, 111,1)',
                            }}>
                            <Col
                              style={{
                                // backgroundColor: "rgba(19, 122, 209,0.3)",
                                textAlign: "center",
                                fontSize: 18,
                                fontWeight: "bold",
                                alignSelf: "center",
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            >
                              {" "}
                              Detalle
                            </Col>


                            <Col
                              style={{
                                // backgroundColor: "rgba(19, 122, 209,0.3)",
                                textAlign: "center",
                                fontSize: 18,
                                fontWeight: "bold",
                                alignSelf: "center",
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            >
                              <Button
                                onClick={() => setShowModalFotosCliente(true)}
                                color="primary"
                                variant="contained"
                                style={{
                                  alignSelf: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <Text style={{ fontSize: 12, marginRight: 8 }}>
                                  Ver Imagenes Principales
                                </Text>
                                <FontAwesomeIcon icon={faUserEdit} size="2x" />
                              </Button>
                            </Col>

                            <Col
                              style={{
                                // backgroundColor: "rgba(19, 122, 209,0.3)",
                                textAlign: "center",
                                fontSize: 18,
                                fontWeight: "bold",
                                alignSelf: "center",
                                marginTop: 10,
                                marginBottom: 10,
                              }}
                            >
                              <Button
                                onClick={() => setshowModalFotos(true)}
                                color="primary"
                                variant="contained"
                                style={{
                                  alignSelf: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <Text style={{ fontSize: 12, marginRight: 8 }}>
                                  Ver Imagenes Daños
                                </Text>
                                <FontAwesomeIcon icon={faUserEdit} size="2x" />
                              </Button>
                            </Col>
                          </Container>



                          <Container>
                            <Col>
                              <BootstrapTable
                                striped
                                hover
                                condensed
                                wrapperClasses="table-responsive"
                                // class="react-bootstrap-table table"
                                filter={filterFactory()}
                                pagination={paginationFactory(
                                  optionsPagination
                                )}
                                {...props.baseProps}
                              />
                            </Col>
                          </Container>


                        </>
                      )}
                    </ToolkitProvider>
                  </Modal.Body>

                  <Modal.Footer>{Pdf()}</Modal.Footer>
                </Modal>


                <Modal
                  show={showModalFotosCliente}
                  {...props}
                  onHide={() => setShowModalFotosCliente(false)}
                  dialogClassName="modal-90w"
                  aria-labelledby="example-custom-modal-styling-title"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Fotos informativas para cliente:
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>




                    <Container>
                      {/* <Row>
                        <Col>1 of 2</Col>
                        <Col>2 of 2</Col>
                      </Row> */}
                      <Row className="justify-content-md-center" >
                        <Col></Col>
                        <Col
                          style={{
                            backgroundColor: "rgba(122,122,122,0.1)",
                            borderColor: "rgba(0,0,0,0.5)",
                            borderWidth: 1,
                            borderRadius: 20,
                            marginRight: 8,
                            marginLeft: 8,
                            marginBottom: 16,
                          }}
                        >
                          <Form.Label className="title-create">
                            Foto de Kilometraje:{" "}
                          </Form.Label>

                          {/* <Col > */}
                          <Zoom overlayBgColorEnd='rgba(0, 0, 0, 0.75)' onZoomChange={true} openText='Abrir imagen' closeText='Cerrar'>

                            <img
                              className="contain"
                              style={{
                                width: 210,
                                height: 150,
                                alignSelf: "center",
                                marginBottom: 32,
                              }}
                              src={
                                urlCliCedula
                                  ? urlCliCedula
                                  : "https://firebasestorage.googleapis.com/v0/b/proyecto-bajaj.appspot.com/o/Bajaj%2Fnoimg.jpg?alt=media&token=49b045a5-b8cf-46e7-b1a6-34521beb79df"
                              }
                              alt=""
                            />
                          </Zoom>
                          {/* </Col> */}
                        </Col>
                        <Col
                          style={{
                            backgroundColor: "rgba(122,122,122,0.1)",
                            borderColor: "rgba(0,0,0,0.5)",
                            borderWidth: 1,
                            borderRadius: 20,
                            marginRight: 8,
                            marginLeft: 8,
                            marginBottom: 16,
                          }}
                        >
                          <Form.Label className="title-create" md="auto">
                            Foto Chasis:{" "}
                          </Form.Label>

                          <Col md="auto">
                            <Zoom overlayBgColorEnd='rgba(0, 0, 0, 0.75)' onZoomChange={true} openText='Abrir imagen' closeText='Cerrar'>

                              <img
                                className="contain"
                                style={{
                                  width: 210,
                                  height: 150,
                                  alignSelf: "center",
                                  marginBottom: 32,
                                }}
                                src={

                                  urlimageG
                                    ? urlimageG
                                    : "https://firebasestorage.googleapis.com/v0/b/proyecto-bajaj.appspot.com/o/Bajaj%2Fnoimg.jpg?alt=media&token=49b045a5-b8cf-46e7-b1a6-34521beb79df"
                                }
                                alt=""
                              />
                            </Zoom>
                          </Col>
                        </Col>
                        <Col
                          style={{
                            backgroundColor: "rgba(122,122,122,0.1)",
                            borderColor: "rgba(0,0,0,0.5)",
                            borderWidth: 1,
                            borderRadius: 20,
                            marginRight: 8,
                            marginLeft: 8,
                            marginBottom: 16,
                          }}
                        >
                          <Form.Label className="title-create">
                            Manual de Garantias:{" "}
                          </Form.Label>

                          <Col>
                            <Zoom overlayBgColorEnd='rgba(0, 0, 0, 0.75)' onZoomChange={true} openText='Abrir imagen' closeText='Cerrar'>

                              <img
                                className="contain"
                                style={{
                                  width: 210,
                                  height: 150,
                                  alignSelf: "center",
                                  marginBottom: 32,
                                }}
                                src={


                                  urlCliMatricula
                                    ? urlCliMatricula
                                    : "https://firebasestorage.googleapis.com/v0/b/proyecto-bajaj.appspot.com/o/Bajaj%2Fnoimg.jpg?alt=media&token=49b045a5-b8cf-46e7-b1a6-34521beb79df"
                                }
                                alt=""
                              />
                            </Zoom>
                          </Col>
                        </Col>
                        <Col></Col>
                        <Col
                          style={{
                            backgroundColor: "rgba(122,122,122,0.1)",
                            borderColor: "rgba(0,0,0,0.5)",
                            borderWidth: 1,
                            borderRadius: 20,
                            marginRight: 8,
                            marginLeft: 8,
                            marginBottom: 16,
                          }}
                        >
                          <Form.Label className="title-create" md="auto">
                            Matricula 1:{" "}
                          </Form.Label>

                          <Col md="auto">
                            <Zoom overlayBgColorEnd='rgba(0, 0, 0, 0.75)' onZoomChange={true} openText='Abrir imagen' closeText='Cerrar'>

                              <img
                                className="contain"
                                style={{
                                  width: 350,
                                  height: 150,
                                  alignSelf: "center",
                                  marginBottom: 32,
                                }}
                                src={

                                  urlCliActaEntrega
                                    ? urlCliActaEntrega
                                    : "https://firebasestorage.googleapis.com/v0/b/proyecto-bajaj.appspot.com/o/Bajaj%2Fnoimg.jpg?alt=media&token=49b045a5-b8cf-46e7-b1a6-34521beb79df"
                                }
                                alt=""
                              />
                            </Zoom>
                          </Col>
                        </Col>
                        <Col
                          style={{
                            backgroundColor: "rgba(122,122,122,0.1)",
                            borderColor: "rgba(0,0,0,0.5)",
                            borderWidth: 1,
                            borderRadius: 20,
                            marginRight: 8,
                            marginLeft: 8,
                            marginBottom: 16,
                          }}
                        >
                          <Form.Label className="title-create" md="auto">
                            Matricula 2:{" "}
                          </Form.Label>

                          <Col md="auto">
                            <Zoom overlayBgColorEnd='rgba(0, 0, 0, 0.75)' onZoomChange={true} openText='Abrir imagen' closeText='Cerrar'>

                              <img
                                className="contain"
                                style={{
                                  width: 350,
                                  height: 150,
                                  alignSelf: "center",
                                  marginBottom: 32,
                                }}
                                src={

                                  urlCliCroquis
                                    ? urlCliCroquis
                                    : "https://firebasestorage.googleapis.com/v0/b/proyecto-bajaj.appspot.com/o/Bajaj%2Fnoimg.jpg?alt=media&token=49b045a5-b8cf-46e7-b1a6-34521beb79df"
                                }
                                alt=""
                              />
                            </Zoom>
                          </Col>
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => setShowModalFotosCliente(false)}>
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>





                {/* <Modal
                  show={ShowModalAprobacion}
                  {...props}
                  onHide={() => setShowModalAprobacion(false)}
                  // dialogClassName="modal-90w"

                  aria-labelledby="example-custom-modal-styling-title"
                  centered
                  size="xl"
                  // className="modal-dialog modal-lg"
                  // style = {{width : '80%'}}
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      {currentClienteCarte.reg_codigo} de fecha :{" "}
                      {fechaorden(currentClienteCarte.reg_fecha_registro)}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <ToolkitProvider
                      keyField="id"
                      data={LtsItemAprobacion}
                      columns={columnsAprobacion}
                      search
                    >
                      {(props) => (
                        <>
                          <Container as={Row}>
                            <Col>
                              <Row
                                style={{
                                  // backgroundColor: "rgba(19, 122, 209,0.3)",
                                  textAlign: "center",
                                  fontSize: 18,
                                  fontWeight: "bold",
                                  alignSelf: "center",
                                }}
                              >
                                {" "}
                                Información Cliente
                              </Row>

                              <Container>
                                <Row>
                                  <Col>Nombre:</Col>
                                  <Col>{currentClienteCarte.cli_nombre}</Col>
                                </Row>
                                <Row>
                                  <Col>Identificación:</Col>
                                  <Col>{currentClienteCarte.cli_cedula}</Col>
                                </Row>
                                <Row>
                                  <Col>Teléfono:</Col>
                                  <Col>{currentClienteCarte.cli_telefono}</Col>
                                </Row>
                              </Container>
                            </Col>

                            <Col>
                              <Row
                                style={{
                                  // backgroundColor: "rgba(19, 122, 209,0.3)",
                                  textAlign: "center",
                                  fontSize: 18,
                                  fontWeight: "bold",
                                  alignSelf: "center",
                                }}
                              >
                                {" "}
                                Información de Vehiculo
                              </Row>

                              <Container>
                                <Row>
                                  <Col>Marca:</Col>
                                  <Col>{currentClienteCarte.veh_marca}</Col>
                                </Row>

                                <Row>
                                  <Col>Chasis:</Col>
                                  <Col>{currentClienteCarte.veh_chasis}</Col>
                                </Row>

                                <Row>
                                  <Col>Color:</Col>
                                  <Col>{currentClienteCarte.veh_color}</Col>
                                </Row>
                                <Row>
                                  <Col>Año:</Col>
                                  <Col>{currentClienteCarte.veh_anio}</Col>
                                </Row>
                              </Container>
                            </Col>

                            <Col>
                              <Row
                                style={{
                                  // backgroundColor: "rgba(19, 122, 209,0.3)",
                                  textAlign: "center",
                                  fontSize: 18,
                                  fontWeight: "bold",
                                  alignSelf: "center",
                                }}
                              >
                                {" "}
                                Información de Taller
                              </Row>

                              <Container>
                                <Row>
                                  <Col>Nombre:</Col>
                                  <Col>{currentClienteCarte.tal_nombre}</Col>
                                </Row>
                                <Row>
                                  <Col>RUC:</Col>
                                  <Col>{currentClienteCarte.tal_ruc}</Col>
                                </Row>
                                <Row>
                                  <Col>Teléfono:</Col>
                                  <Col>{currentClienteCarte.tal_telefono}</Col>
                                </Row>
                                <Row>
                                  <Col>Ciudad:</Col>
                                  <Col>{currentClienteCarte.tal_cuidad}</Col>
                                </Row>
                              </Container>
                            </Col>
                          </Container>

                          <Container as={Row}>
                            <Col
                              style={{
                                // backgroundColor: "rgba(19, 122, 209,0.3)",
                                textAlign: "center",
                                fontSize: 18,
                                fontWeight: "bold",
                                alignSelf: "center",
                                marginTop: 8,
                                marginBottom: 8,
                              }}
                            >
                              {" "}
                              Detalle
                            </Col>

                            <Col>
                              <Button
                                onClick={() => setshowModalFotos(true)}
                                color="primary"
                                variant="contained"
                                style={{
                                  alignSelf: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <Text style={{ fontSize: 12, marginRight: 8 }}>
                                  Ver Imagenes
                                </Text>
                                <FontAwesomeIcon icon={faUserEdit} size="2x" />
                              </Button>
                            </Col>
                          </Container>

                          <Container>
                            <Col>
                              <BootstrapTable
                                striped
                                hover
                                condensed
                                wrapperClasses="table-responsive"
                                // class="react-bootstrap-table table"
                                filter={filterFactory()}
                                pagination={paginationFactory(
                                  optionsPagination
                                )}
                                {...props.baseProps}
                              />
                            </Col>
                          </Container>
                        </>
                      )}
                    </ToolkitProvider>
                  </Modal.Body>

                  <Modal.Footer>{Pdf()}</Modal.Footer>
                </Modal>
 */}


                <Modal
                  show={showModalDirecciones}
                  {...props}
                  onHide={() => setShowModalDirecciones(false)}
                  // dialogClassName="modal-90w"
                  dialogClassName="modal-90w"
                  aria-labelledby="example-custom-modal-styling-title"
                  centered
                // className="modal-dialog modal-lg"
                // style = {{width : '80%'}}
                >
                  <Modal.Header closeButton>
                    {/* <p>Cliente: <b>{currentClienteCarte.cli_nombre} {" "} {currentClienteCarte.cli_apellido}</b> <br></br>Celular:<b>{currentClienteCarte.cli_celular}</b></p> */}
                  </Modal.Header>
                  <Modal.Body
                    style={{
                      maxHeight: "calc(100vh - 210px)",
                      overflowY: "auto",
                    }}
                  >
                    <ToolkitProvider
                      keyField="id"
                      data={ltsDirecciones}
                      columns={columnsDirecciones}
                      search
                    >
                      {(props) => (
                        <div>
                          <Row>
                            <Col className="align-middle" md="auto">
                              Buscar:
                            </Col>
                            <Col md="auto">
                              <SearchBar
                                class="align-middle"
                                placeholder=""
                                style={{ height: 25, width: 150 }}
                                {...props.searchProps}
                              />
                            </Col>
                          </Row>
                          <Col className="mt-3"></Col>
                          <Form>
                            <Row>
                              {/* <Form.Check>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  size="lg"
                                  color="green"
                                />
                                <Form.Check.Label
                                  column
                                  style={{ fontSize: 11 }}
                                >
                                  Más de 5 días para fecha de pago
                                    </Form.Check.Label>
                              </Form.Check>

                              <Form.Check>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  size="lg"
                                  color="orange"
                                />
                                <Form.Check.Label
                                  column
                                  style={{ fontSize: 11 }}
                                >
                                  A días 5 para fecha de pago
                                    </Form.Check.Label>
                              </Form.Check> */}

                              {/* <Form.Check>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  size="lg"
                                  color="#b30000"
                                />
                                <Form.Check.Label
                                  column
                                  style={{ fontSize: 11 }}
                                >
                                  Cerca de fecha de pago
                                    </Form.Check.Label>
                              </Form.Check> */}
                            </Row>
                          </Form>
                          <Col className="mt-3"></Col>

                          <BootstrapTable
                            striped
                            hover
                            condensed
                            wrapperClasses="table-responsive"
                            // class="react-bootstrap-table table"
                            filter={filterFactory()}
                            pagination={paginationFactory(optionsPagination)}
                            {...props.baseProps}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </Modal.Body>
                </Modal>

                <ToolkitProvider
                  keyField="ci"
                  data={products}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <div>
                      <Card
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      >
                        <Card.Header
                          as="h3"
                          style={{
                            backgroundColor: "rgba(236,236,236,0.6)",
                            color: "black",
                          }}
                        >
                          Parámetros de busqueda para garantías APROBAdas
                        </Card.Header>
                        <Card.Body
                          style={{
                            backgroundColor: "rgba(236,236,236,0.3)",
                          }}
                        >
                          {/* <Container> */}
                          {/* <FormGroup as={Row}>
                            <Col md="auto">
                              <Form.Label md="auto">Gestor</Form.Label>
                            </Col>
                            <Col md="auto">
                              <Form.Control
                                maxLength={13}
                                placeholder="Número de cédula"
                                id="ges_ci"
                                value={search_cedula} //{gestor.ges_rep_contrasena}
                                onChange={(e) => {
                                  const val = e.target.value;
                                  setsearch_cedula(val);
                                }}
                              />
                            </Col>

                            <Col>
                              <Button
                                onClick={() =>
                                  getCarterasByCIGestor(search_cedula)
                                }
                              >
                                Buscar por cédula de gestor
                              </Button>
                            </Col>
                          </FormGroup> */}

                          <Row>
                            <Col className="align-middle" md="auto">
                              Fecha inicial
                            </Col>
                            <Col md="auto">
                              <DatePicker
                                locale="es"
                                dateFormat="dd/MM/yyyy"
                                selected={startDate}
                                // startDate={startDate}
                                // endDate={lastDate}
                                // minDate={startDate}
                                // selectsStart
                                onChange={updateDate}
                              />
                            </Col>

                            <Col className="align-middle" md="auto">
                              Fecha final
                            </Col>
                            <Col md="auto">
                              <DatePicker
                                locale="es"
                                dateFormat="dd/MM/yyyy"
                                selected={lastDate}
                                // selectsEnd
                                // startDate={startDate}
                                // endDate={lastDate}
                                // minDate={startDate}
                                onChange={updateLastDate}
                              />
                            </Col>
                            <Col>
                              <Button
                                style={{ marginLeft: 10 }}
                                onClick={() =>
                                  getFechaOrdendePago(
                                    moment(startDate).format("yyyy-MM-DD"),
                                    moment(lastDate).format("yyyy-MM-DD")
                                  )
                                }
                              >
                                Buscar por fecha
                              </Button>

                              {/* <Button
                                style={{ marginLeft: 10, marginTop: 10 }}
                                onClick={() => getAllPendientes()}
                              >
                                Todos los Pendientes
                              </Button> */}
                            </Col>

                            <Col></Col>
                          </Row>
                          {/* </Container> */}
                        </Card.Body>
                      </Card>
                      <Card
                        className="mt-3"
                        style={{
                          borderWidth: 1,
                          borderColor: "rgba(128,128,128,0.4)",
                        }}
                      >
                        <Card.Header
                          as="h3"
                          style={{
                            backgroundColor: "rgba(236,236,236,0.9)",
                            color: "black",
                          }}
                        >
                          Listado general de "garantías APROBAdas"
                        </Card.Header>
                        <Card.Body
                          style={{
                            backgroundColor: "rgba(236,236,236,0.6)",
                          }}
                        >
                          {/* <Form>
                            <Row style={{ paddingLeft: 16 }}>
                              <Form.Check>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  size="lg"
                                  color="green"
                                />
                                <Form.Check.Label
                                  column
                                  style={{ fontSize: 11 }}
                                >
                                  Retraso menor a 10 días
                                </Form.Check.Label>
                              </Form.Check>

                              <Form.Check>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  size="lg"
                                  color="orange"
                                />
                                <Form.Check.Label
                                  column
                                  style={{ fontSize: 11 }}
                                >
                                  Retraso de entre 10 a 30 días
                                </Form.Check.Label>
                              </Form.Check>

                              <Form.Check>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  size="lg"
                                  color="#b30000"
                                />
                                <Form.Check.Label
                                  column
                                  style={{ fontSize: 11 }}
                                >
                                  Retraso de más de 30 días
                                </Form.Check.Label>
                              </Form.Check>
                            </Row>
                          </Form> */}

                          <Col className="mt-3"></Col>
                          <BootstrapTable
                            striped
                            hover
                            condensed
                            keyField="ci"
                            wrapperClasses="table-responsive"
                            class="align-middle"
                            filter={filterFactory()}
                            pagination={paginationFactory(optionsPagination)}
                            {...props.baseProps}
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </ToolkitProvider>

                <Modal
                  show={showModal}
                  // onHide={!showModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Motivo de Rechazo de Garantia
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Motivo:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={500}
                            type="text"
                            placeholder="Motivo de Rechazo"
                            value={motivo}
                            onChange={(event) => setmotivo(event.target.value)}
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => rechazarGarantia()}>Rechazar</Button>
                    <Button onClick={() => setshowModal(false)}>Cerrar</Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={showModalFotos}
                  {...props}
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Title id="contained-modal-title-vcenter">
                    Imagenes tomadas
                  </Modal.Title>
                  <Carousel>
                    {listaImagenes.map((item) => (
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={item}
                          alt="First slide"
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <Modal.Footer>
                    <Button onClick={() => setshowModalFotos(false)}>
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>


              </Col>
            </Row>
          </Container>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );

  const infoCarteraSeleccionada = () => {
    return (
      <Modal
        show={modalShow}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Centered Modal</h4>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
            ac consectetur ac, vestibulum at eros.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  };
}

export default Content;
