import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../../Layout/PageTitle";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Modal,
  Card,
  Spinner,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
// import * as SecureLS from "secure-ls";

function Content() {
  const server = window.$linkserver;
  // const [opc_id, setId] = useState("");
  const [opc_titulo, setTitulo] = useState("");
  const [opc_name, setName] = useState("");
  const [opc_type, setType] = useState("");
  const [opc_color, setColor] = useState("");
  const [opc_route, setRoute] = useState("");
  // const [opc_estado, setEstado] = useState(0);

  // const [firstTime, setfirstTime] = useState(true);
  const [dataOpciones, setDataOpciones] = useState([]);
  const [dataTable, setdataTable] = useState([]);
  const [isLoading, setIsLoading] = useState("invisible");
  const [opciones, setopciones] = useState();
  const [index, setIndex] = useState();
  const [showModal, setshowModal] = useState(false);

  // const ls = new SecureLS({
  //   encodingType: "des",
  //   isCompression: false,
  //   encryptionSecret: "admin-hyundai",
  // });
  // const { infoUsuario } = ls.get("ask-hy");
  // const { data } = infoUsuario;

  const columns = [
    {
      dataField: "opc_id",
      text: "Codigo",
      sort: true,
    },
    {
      dataField: "opc_titulo",
      text: "Título",
      sort: true,
    },
    {
      dataField: "opc_name",
      text: "Nombre del icono",
    },
    {
      dataField: "opc_color",
      text: "Color icono",
    },
    {
      dataField: "opc_type",
      text: "Tipo de icono",
    },
    {
      dataField: "opc_route",
      text: "Ruta",
    },
    {
      dataField: "opc_estado",
      text: "Estado",
      formatter: (cell, row, index) => activeDesactive(row, index),
      sort: true,
    },

    {
      dataField: "PER_EDITAR",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const [error, seterror] = useState({
    nombre: "",
    ruta: "",
  });

  const editpermisos = (row, index) => {
    setTitulo(row.opc_titulo);
    setName(row.opc_name);
    setType(row.opc_type);
    setColor(row.opc_color);
    setRoute(row.opc_route);
    setopciones(row);
    setIndex(index);
    setshowModal(true);
  };

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          editpermisos(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const activeDesactive = (row, index) => {
    return (
      <input
        type="checkbox"
        name="something"
        value="asa"
        checked={setEstado(row.opc_estado)}
        onChange={() => changeEstado(row, index)}
      />
    );
  };

  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const setDataOpcionesModal = () => {
    opciones.opc_titulo = opc_titulo;
    opciones.opc_name = opc_name;
    opciones.opc_type = opc_type;
    opciones.opc_color = opc_color;
    opciones.opc_route = opc_route;
    seterror((error) => {
      return { ...error, nombre: "", ruta: "" };
    });

    setTitulo("");
    setName("");
    setType("");
    setColor("");
    setRoute("");
    setshowModal(false);
    updateEstadoOp(opciones, index);
  };

  const changeEstado = async (key, index) => {
    if (key.opc_estado === 1) {
      dataOpciones.data[index].opc_estado = 0;
      updateEstadoOp(dataOpciones.data[index], index);
    } else if (key.opc_estado === 0) {
      dataOpciones.data[index].opc_estado = 1;
      updateEstadoOp(dataOpciones.data[index], index);
    }
  };

  const updateEstadoOp = (itemOpcion, index) => {
    // const dataForm = new FormData();
    // dataForm.append("opc_id", itemOpcion.opc_ic);
    // dataForm.append("opc_titulo", itemOpcion.opc_titulo);
    // dataForm.append("opc_name", itemOpcion.opc_name);
    // dataForm.append("opc_type", itemOpcion.opc_type);
    // dataForm.append("opc_color", itemOpcion.opc_color);
    // dataForm.append("opc_route", itemOpcion.opc_route);
    // console.log(itemOpcion.opc_id);
    console.log(itemOpcion.opc_titulo);
    axios
      .post(server + "gestores/updateOpcionApp", {
        opc_id: itemOpcion.opc_id,
        opc_titulo: itemOpcion.opc_titulo,
        opc_name: itemOpcion.opc_name,
        opc_type: itemOpcion.opc_type,
        opc_color: itemOpcion.opc_color,
        opc_route: itemOpcion.opc_route,
        opc_estado: itemOpcion.opc_estado,
      })
      .then(function (response) {
        if (response.status === 200) {
          showMessage("Opción actualizada con éxito", "success");
          let newArr = [...dataTable];
          newArr[index] = itemOpcion;
          setdataTable(newArr);
        } else {
          showMessage("Problemas al actualizar", "error");
        }
      })
      .catch(function (error) {});
  };

  const getData = async () => {
    setIsLoading("visible");
    //   .get("http://192.168.1.13:8000/api/opciones/allOpciones")

    // await fetch("http://192.168.1.13:5001/opciones/getOpciones", {
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log(data);
    //     setDataOpciones(data);
    //     setdataTable(data.data);
    //     setfirstTime(false);
    //     setIsLoading("invisible");
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    await axios
      .get(server + "gestores/getOpcionesApp", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log(response);
        setDataOpciones(response);
        setdataTable(response.data);
        // setfirstTime(false);
        setIsLoading("invisible");
      })
      .catch(function (error) {
        // setfirstTime(false);
        setIsLoading("invisible");
      })
      .then(function () {
        // always executed
        // setfirstTime(false);
        setIsLoading("invisible");
      });
  };

  useEffect(() => {
    const getDataInicial = async () => {
      setIsLoading("visible");
      //   .get("http://192.168.1.13:8000/api/opciones/allOpciones")

      // await fetch("http://192.168.1.13:5001/opciones/getOpciones", {
      //   method: "GET",
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //   },
      // })
      //   .then((response) => response.json())
      //   .then((data) => {
      //     console.log(data);
      //     setDataOpciones(data);
      //     setdataTable(data.data);
      //     setfirstTime(false);
      //     setIsLoading("invisible");
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      await axios
        .get(server + "gestores/getOpcionesApp", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          console.log(response);
          setDataOpciones(response);
          setdataTable(response.data);
          // setfirstTime(false);
          setIsLoading("invisible");
        })
        .catch(function (error) {
          // setfirstTime(false);
          setIsLoading("invisible");
        })
        .then(function () {
          // always executed
          // setfirstTime(false);
          setIsLoading("invisible");
        });
    };

    getDataInicial();
  }, [server]);

  const createOpciones = async () => {
    seterror((error) => {
      return { ...error, nombre: "", ruta: "" };
    });

    if (
      opc_titulo !== "" &&
      opc_name !== "" &&
      opc_type !== "" &&
      opc_color !== "" &&
      opc_route !== ""
    ) {
      // const dataForm = new FormData();
      // dataForm.append("op_nombre", op_nombre);
      // dataForm.append("op_url", op_url);
      // dataForm.append("usuario", data.US_CODIGO);
      await axios
        .post(server + "gestores/createOpcionApp", {
          opc_titulo: opc_titulo,
          opc_name: opc_name,
          opc_type: opc_type,
          opc_color: opc_color,
          opc_route: opc_route,
          opc_estado: 1,
        })

        .then(function (response) {
          if (response.status === 200) {
            showMessage("Opción creada con éxito", "success");
            setTitulo("");
            setName("");
            setType("");
            setColor("");
            setRoute("");
            // updateItems()
            getData();
          } else {
            showMessage("Problemas al agregar", "error");
          }
        })
        .catch(function (error) {});
    } else {
      if (opc_name === "") {
        seterror((error) => {
          return { ...error, nombre: "Campo requerido" };
        });
      }

      if (opc_titulo === "") {
        seterror((error) => {
          return { ...error, ruta: "Campo requerido" };
        });
      }

      if (opc_type === "") {
        seterror((error) => {
          return { ...error, ruta: "Campo requerido" };
        });
      }

      if (opc_color === "") {
        seterror((error) => {
          return { ...error, ruta: "Campo requerido" };
        });
      }

      if (opc_route === "") {
        seterror((error) => {
          return { ...error, ruta: "Campo requerido" };
        });
      }
      showMessage("Campos incompletos", "error");
    }
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Mantenimiento/Opciones"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <div>
                <Modal
                  show={showModal}
                  onHide={!showModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Editar: {opc_titulo}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            {" "}
                            Título:{" "}
                          </Form.Label>

                          <Form.Control
                            maxLength={150}
                            type="text"
                            placeholder="Título de opción"
                            value={opc_titulo}
                            onChange={(event) => setTitulo(event.target.value)}
                          />
                          <span style={{ color: "red" }}>{error.nombre}</span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            {" "}
                            Nombre del ícono:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            type="text"
                            placeholder="Nombre de ícono (ejm: email)"
                            value={opc_name}
                            onChange={(event) => setName(event.target.value)}
                          />
                          <span style={{ color: "red" }}>{error.ruta}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            {" "}
                            Tipo icono:{" "}
                          </Form.Label>

                          <Form.Control
                            maxLength={150}
                            type="text"
                            placeholder="Tipo de ícono (ejm: font-awesome-5)"
                            value={opc_type}
                            onChange={(event) => setType(event.target.value)}
                          />
                          <span style={{ color: "red" }}>{error.nombre}</span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            {" "}
                            Color del ícono:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            type="text"
                            placeholder="Color de ícono (inglés - ejm: white)"
                            value={opc_color}
                            onChange={(event) => setColor(event.target.value)}
                          />
                          <span style={{ color: "red" }}>{error.ruta}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            {" "}
                            Ruta icono:{" "}
                          </Form.Label>

                          <Form.Control
                            maxLength={150}
                            type="text"
                            placeholder="Ruta de ícono (ejm: nombre pantalla en app)"
                            value={opc_route}
                            onChange={(event) => setRoute(event.target.value)}
                          />
                          <span style={{ color: "red" }}>{error.nombre}</span>
                        </Col>
                        <Col></Col>
                      </FormGroup>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => setDataOpcionesModal()}>
                      Actualizar
                    </Button>
                    <Button
                      onClick={() => {
                        setshowModal(false);
                        setTitulo("");
                        setName("");
                        setType("");
                        setColor("");
                        setRoute("");
                        seterror((error) => {
                          return { ...error, nombre: "", ruta: "" };
                        });
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>

              <div>
                <h3 className="text-center">Gestión de "OPCIONES"</h3>
                <Card>
                  <Card.Header as="h5">
                    Creación de "opcion" para menú de gestores en aplicación
                    móvil
                  </Card.Header>
                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            {" "}
                            Título:{" "}
                          </Form.Label>

                          <Form.Control
                            maxLength={150}
                            type="text"
                            placeholder="Título de opción"
                            value={opc_titulo}
                            onChange={(event) => setTitulo(event.target.value)}
                          />
                          <span style={{ color: "red" }}>{error.nombre}</span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            {" "}
                            Nombre del ícono:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            type="text"
                            placeholder="Nombre de ícono (ejm: email)"
                            value={opc_name}
                            onChange={(event) => setName(event.target.value)}
                          />
                          <span style={{ color: "red" }}>{error.ruta}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            {" "}
                            Tipo icono:{" "}
                          </Form.Label>

                          <Form.Control
                            maxLength={150}
                            type="text"
                            placeholder="Tipo de ícono (ejm: font-awesome-5)"
                            value={opc_type}
                            onChange={(event) => setType(event.target.value)}
                          />
                          <span style={{ color: "red" }}>{error.nombre}</span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            {" "}
                            Color del ícono:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            type="text"
                            placeholder="Color de ícono (inglés - ejm: white)"
                            value={opc_color}
                            onChange={(event) => setColor(event.target.value)}
                          />
                          <span style={{ color: "red" }}>{error.ruta}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            {" "}
                            Ruta icono:{" "}
                          </Form.Label>

                          <Form.Control
                            maxLength={150}
                            type="text"
                            placeholder="Ruta de ícono (ejm: nombre pantalla en app)"
                            value={opc_route}
                            onChange={(event) => setRoute(event.target.value)}
                          />
                          <span style={{ color: "red" }}>{error.nombre}</span>
                        </Col>
                        <Col></Col>
                      </FormGroup>

                      <FormGroup as={Row}></FormGroup>

                      <div className="text-center">
                        <Button
                          variant="primary"
                          onClick={() => createOpciones()}
                        >
                          Agregar opción
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>

                <br></br>

                <Card>
                  <Card.Header as="h5">
                    Listado general de "Opciones"
                  </Card.Header>
                  <Card.Body>
                    <BootstrapTable
                      keyField="opc_id"
                      data={dataTable}
                      columns={columns}
                    />

                    {/* <Table responsive>
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Nombre</th>
                                    <th>URL</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getRows}
                            </tbody>
                        </Table> */}
                    <div className="text-center">
                      <div className={isLoading}>
                        <Spinner animation="border" role="status"></Spinner>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
