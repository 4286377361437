import React, { Fragment, useState } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import filterFactory, {
  selectFilter,
  numberFilter,
  Comparator,
  textFilter
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import PageTitle from "../Layout/PageTitle";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
// registerLocale("es", es);
import "react-datepicker/dist/react-datepicker.css";
import optionsPagination from "../../helper/SetupComponents";
import Map from "../../componentes/screens/Map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import {
  faClock,
  faImages,
  faMapMarkedAlt,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

import {
  Row,
  Col,
  Container,
  Card,
  Spinner,
  Carousel,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
registerLocale("es", es);



function Content(props) {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const server = window.$linkserver;

  const [showModalMap, setshowModalMap] = useState(false);
  const [showModalFotos, setshowModalFotos] = useState(false);
  const [products, setproducts] = useState([]);

  const { SearchBar, ClearSearchButton } = Search;
  const [clienteBuscar, setClienteBuscar] = useState("");
  const { ExportCSVButton } = CSVExport;
  const [modalShow, setModalShow] = React.useState(false);
  const [cartera, setCartera] = useState();
  const [startDate, setStartDate] = useState(new Date(year, month, day - 1));
  const [lastDate, setLastDate] = useState(new Date());
  const [showModalCargando, setShowModalCargando] = useState(false);

  // const setCartera = useState()
  // const selectOptions = {
  //   0: "1-30",
  //   1: "31-60",
  //   2: "61-90",
  //   3: "90+",
  // };

  const [horaMapa, sethoraMapa] = useState("");

  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);

  const selectOptionEstadoPago = {
    1: "Pagado",
    0: "Sin Pago",
  };

  const columns = [
    {
      dataField: "gestor",
      text: "Gestor",
      headerAlign: "center",
      headerStyle: {
        textAlign: "center",
        minWidth: "170px",
        backgroundColor: "rgba(255, 255, 255, 1)"
      },
      filter: textFilter({
        placeholder: 'nombre gestor'
      }),
      formatter: (cell, row, index) => infoGestor(row, index),
    },
    {
      dataField: "ccli_cedula",
      text: "Cédula",
      headerAlign: "center",
      headerStyle: {
        textAlign: "center",
        minWidth: "160px",
        backgroundColor: "rgba(255, 255, 255, 1)"
      },
      filter: textFilter({
        placeholder: '# cédula'
      }),
    },
    {
      dataField: "ccli_nombre",
      text: "Nombres",
      headerAlign: "center",
      headerStyle: {
        textAlign: "center",
        minWidth: "190px",
        backgroundColor: "rgba(255, 255, 255, 1)"
      },
      formatter: fechaFormatter,
      filter: textFilter({
        placeholder: 'Nombre/Apellido'
      }),
    },
    {
      dataField: "ccli_apellido",
      text: "Apellidos",
      headerAlign: "center",
      headerStyle: { backgroundColor: "rgba(255, 255, 255, 1)" },
      formatter: fechaFormatter,
    },
    {
      dataField: "ccli_fecha",
      text: "Fecha de visita",
      headerAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "110px",
          backgroundColor: "rgba(255, 255, 255, 1)",
        };
      },
      formatter: (cell, row, index) => fechaVisita(row, index),
    },
    {
      dataField: "ccli_fecha_fin",
      text: "Fecha de pago",
      headerAlign: "center",

      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255, 1)",
        };
      },
      formatter: (cell, row, index) => fechaVisitaFin(row, index),
    },

    {
      dataField: "valor",
      text: "Valor",
      textAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255, 1)",
        };
      },

      formatter: (cell, row, index) => valorpago(row, index),
    },

    {
      dataField: "ccli_saldo_cartera",
      text: "Saldo",
      textAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255, 1)",
        };
      },

      formatter: (cell, row, index) => valorsaldo(row, index),
    },
    {
      dataField: "ccli_valor_cuota",
      text: "Cuota",
      textAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "100px",
          backgroundColor: "rgba(255, 255, 255, 1)",
        };
      },
      formatter: (cell, row, index) => valorcuota(row, index),
    },
    {
      dataField: "cdp_observacion",
      text: "Observaciones",
      headerAlign: "center",
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          minWidth: "200px",
          backgroundColor: "rgba(255, 255, 255, 1)",
        };
      },

      formatter: (cell, row, index) => observaciones(row, index),
    },
    {
      dataField: "cdp_ubicacion",
      text: "Ubicación",
      formatter: (cell, row, index) => locationPago(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          textAlign: "center",
          alignItems: "center",

          backgroundColor: "rgba(255, 255, 255, 1)",
        };
      },
    },

    {
      dataField: "cdp_estado_pago",
      text: "Estado de pago",
      headerAlign: "center",
      formatter: (cell, row, index) => estadoPago(row, index),
      headerStyle: (colum, colIndex) => {
        return {
          minWidth: "140px",
          textAlign: "center",

          backgroundColor: "rgba(255, 255, 255, 1)",
        };
      },
      // formatter: (cell) => selectTipoCartera[cell],
      // filter: selectFilter({
      //   options: selectTipoCartera,
      //   withoutEmptyOption: false,
      //   style: {
      //     width: "100%",
      //     minWidth: "150px",
      //     alignItems: "center",
      //   },
      //   className: "test-classname",
      //   datamycustomattr: "datamycustomattr",
      // }),
    },
  ];

  const fechaVisita = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "150px",
        }}
      >
        {moment(row.ccli_fecha).format("DD-MM-YYYY | HH:mm a")}
      </Form.Label>
    );
  };

  const observaciones = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          fontSize: 11
        }}
      >
        {row.ccli_compromiso.cdp_observacion}
      </Form.Label>
    );
  };

  const valorpago = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "100px",
        }}
      >
        ${row.ccli_compromiso.cdp_valor_pago}
      </Form.Label>
    );
  };

  const valorsaldo = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "100px",
        }}
      >
        ${row.ccli_saldo_cartera}
      </Form.Label>
    );
  };

  const valorcuota = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "100px",
        }}
      >
        ${row.ccli_valor_cuota.toFixed(2)}
      </Form.Label>
    );
  };

  const fechaVisitaFin = (row, index) => {
    var currentDate = new Date();
    // console.log(moment(row.ccli_fecha_fin).format("DD-MM-YYYY"))
    // console.log(moment(currentDate).format("DD-MM-YYYY"))
    var date1 = new Date(row.ccli_fecha_fin); //.ccli_fecha_fin);
    var date2 = new Date(currentDate);
    // console.log(date1);
    // console.log(date2);
    var Difference_In_Time = date1.getTime() - date2.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    // console.log(Difference_In_Days)
    if (Difference_In_Days > 5) {
      // console.log("fecha maor");
      return (
        <Form.Label
          className="text-center align-items-center"
          style={{
            width: "100%",
            minWidth: "125px",
            backgroundColor: "rgba(0,255,0,0.3)",
          }}
        >
          {moment(row.ccli_fecha_fin).format("DD-MM-YYYY")}
        </Form.Label>
      );
    }

    // else if (Difference_In_Days <= 5) {
    //   // console.log("otra fecha menoooor");
    //   return (
    //     <Form.Label
    //       className="text-center align-items-center"
    //       style={{
    //         width: "100%",
    //         minWidth: "125px",
    //         backgroundColor: "rgba(255,94,19,0.3)",
    //       }}
    //     >
    //       {moment(row.ccli_fecha_fin).format("DD-MM-YYYY")}
    //     </Form.Label>
    //   );

    // } 

    else if (Difference_In_Days < 5) {
      // console.log("otra fecha menoooor");
      return (
        <Form.Label
          className="text-center align-items-center"
          style={{
            width: "100%",
            minWidth: "125px",
            backgroundColor: "rgba(255,0,0,0.3)",
          }}
        >
          {moment(row.ccli_fecha_fin).format("DD-MM-YYYY")}
        </Form.Label>
      );
    }
  };

  const compromiso = (row, index) => {
    // return <Text style={{ backgroundColor: "red" }}></Text>;
  };

  const [isUserAdmin, setisUserAdmin] = useState(false)

  const handleSwitchChange = async (row, checked) => {
    // setisUserAdmin(true)
    // console.log(row.ccli_compromiso.cdp_id)
    var estado = 0
    if (checked === true) {
      estado = 1
    } else if (checked === false) {
      estado = 0
    }
    // console.log(server + "api/asignacionapp/updateCompromisoEstadoPago/" + row.ccli_compromiso.cdp_id + "/" + estado)

    await axios
      .get(
        server + "api/asignacionapp/updateCompromisoEstadoPago/" + row.ccli_compromiso.cdp_id + "/" + estado
      )
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,
        // console.log(response.data);
        if (response.status == 200) {
          showMessage("Compromiso de pago actualizado", "success");
          getFechaCobro(
            moment(startDate).format("yyyy-MM-DD"),
            moment(lastDate).format("yyyy-MM-DD")
          )
        } else {
          showMessage("Error al actualizar el compromiso de pago", "error");
          getFechaCobro(
            moment(startDate).format("yyyy-MM-DD"),
            moment(lastDate).format("yyyy-MM-DD")
          )
        }

      })
      .catch(function (error) { })
      .then(function () { });

  }

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });


  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const estadoPago = (row, index) => {

    if (row.ccli_compromiso.cdp_estado_pago == 1) {
      // setisUserAdmin(true)
      return (
        <BootstrapSwitchButton
          checked={true}
          onlabel='PAGADO'
          onstyle='success'
          offlabel='SIN PAGO'
          offstyle='danger'
          style='w-100'
          onChange={(checked) => handleSwitchChange(row, checked)}
        />
      )

    } else if (row.ccli_compromiso.cdp_estado_pago == 0) {
      // setisUserAdmin(false)
      return (
        <BootstrapSwitchButton
          checked={false}
          onlabel='PAGADO'
          onstyle='success'
          offlabel='SIN PAGO'
          offstyle='danger'
          style='w-100'
          onChange={(checked) => handleSwitchChange(row, checked)}
        />
      )
    }

  };


  const semaforoEstado = (row, index) => {
    if (row.valor == 1223) {
      return (
        <Button
          onClick={() => openModalMap(row)}
          color="green"
          variant="contained"
          textAlign="center"
        >
          <FontAwesomeIcon icon={faClock} color="green" size="2x" />
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => openModalMap(row)}
          color="red"
          variant="contained"
        >
          <FontAwesomeIcon icon={faClock} color="#b30000" size="2x" />
        </Button>
      );
    }
  };

  const locationPago = (row, index) => {
    return (
      <Button
        onClick={() => openModalMap(row)}
        color="primary"
        variant="contained"
        style={{ alignSelf: "center", justifyContent: "center" }}
      >
        <FontAwesomeIcon
          icon={faMapMarkedAlt}
          size="2x"
          textAlign="center"
          style={{ alignSelf: "center", justifyContent: "center" }}
        />
      </Button>
    );
  };

  const showFotos = (row, index) => {
    return (
      <Button
        onClick={() => openModalFotos(row)}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faImages} size="2x" />
      </Button>
    );
  };

  const infoGestor = (row, index) => {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "125px",
        }}
      >
        {row.ccli_compromiso.gestor.ges_nombre}{" "}
        {row.ccli_compromiso.gestor.ges_apellido}
      </Form.Label>
    );
  };

  const openModalMap = async (row) => {
    setshowModalMap(true);
    setLat(row.ccli_compromiso.cdp_lat);
    setLon(row.ccli_compromiso.cdp_lon);
    sethoraMapa(row.ccli_fecha);
    // setIsLoading("visible");
    //getOpcionesApp
    // await axios
    //   .get(
    //     "http://192.168.1.13:5001/gestores/getLstOpcionesbyPerfil/" + row.per_id
    //   )
    //   .then(function (response) {
    //     setperfiles(row);
    //     // console.log(row);,
    //     setRowsModal(response.data);
    //     setIsModalVisible(true);
    //     setIsLoading("invisible");
    //   })
    //   .catch(function (error) {})
    //   .then(function () {});
  };

  function openModalFotos(item) {
    setshowModalFotos(true);
  }

  function priceFormatter(cell, row) {
    return (
      <Form.Label className="text-center" style={{ width: "100%" }}>
        ${cell}
      </Form.Label>
    ); //<span>${cell}</span>;
  }

  function observacionesFormatter(cell, row) {
    return (
      <Form.Label
        className="text-justify"
        style={{ width: "100%", fontSize: 11 }}
      >
        {cell}
      </Form.Label>
    );
  }

  function fechaFormatter(cell, row) {
    return (
      <Form.Label
        className="text-center align-items-center"
        style={{
          width: "100%",
          minWidth: "115px",
        }}
      >
        {cell}
      </Form.Label>
    );
  }

  function openModalCartera(item) {
    // console.log(item);
    setModalShow(true);
  }

  const updateDate = (date) => {
    setStartDate(date);
  };

  const updateLastDate = (date) => {
    setLastDate(date);
  };

  const getFechaCobro = async (inicio, fin) => {
    // console.log(
    //   server + "api/asignacionapp/getCompromisosByFecha/" + inicio + "/" + fin
    // );
    setShowModalCargando(true)
    await axios
      .get(
        server + "api/asignacionapp/getCompromisosByFecha/" + inicio + "/" + fin
      )
      .then(function (response) {
        // setperfiles(row);
        // console.log(row);,

        // console.log(response.data);
        setproducts(response.data);
        setShowModalCargando(false)
      })
      .catch(function (error) {
        setShowModalCargando(false)
      })
      .then(function () { });
  };

  return (
    <Fragment style={{ backgroundColor: "#fcfcfa" }}>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
        style={{ backgroundColor: "#fcfcfa" }}
      >
        <div>


          <Modal
            show={showModalCargando}
            onHide={() => setShowModalCargando(false)}
            centered
          >
            <Modal.Body class="text-center">
              <p></p>
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
              <p class="text-center">
                Cargando compromisos, espere por favor
            </p>
            </Modal.Body>
          </Modal>

          <PageTitle
            marginTop={12}
            heading="Compromisos de pago"
            icon="pe-7s-info icon-gradient bg-mean-fruit"
          />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Modal
              show={showModalMap}
              onHide={!showModalMap}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Body>
                <Map
                  lat={lat}
                  lon={lon}
                  titulo={moment(horaMapa).format("LLLL")}
                  url="https://firebasestorage.googleapis.com/v0/b/proyecto-bajaj.appspot.com/o/Bajaj%2Fpersonamarker.png?alt=media&token=d943d61c-39fd-4983-acf4-42e8e5dde71b"
                  googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyBWQwWMO0El5LZPg5eECmCQ7bh5o2Zg738"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `400px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                ></Map>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  onClick={() => {
                    setshowModalMap(false);
                  }}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              show={showModalFotos}
              {...props}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Title id="contained-modal-title-vcenter">
                Imagenes tomadas
              </Modal.Title>
              <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://www.inmot.com.ec/images/catalogo/ct125.png"
                    alt="First slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://www.inmot.com.ec/images/catalogo/discover-125st.png"
                    alt="Third slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://www.soymotero.net/sites/default/files/2016-12/Bajaj_Pulsar_400_01.jpg"
                    alt="Third slide"
                  />
                </Carousel.Item>
              </Carousel>
              <Modal.Footer>
                <Button onClick={() => setshowModalFotos(false)}>Cerrar</Button>
              </Modal.Footer>
            </Modal>

            <Col>
              <ToolkitProvider
                keyField="id"
                data={products}
                columns={columns}
                search
              >
                {(props) => (
                  <div>
                    <Card
                      style={{
                        borderWidth: 1,
                        borderColor: "rgba(128,128,128,0.4)",
                      }}
                    >
                      <Card.Header
                        as="h3"
                        style={{
                          backgroundColor: "rgba(236,236,236,0.6)",
                          color: "black",
                        }}
                      >
                        Parámetros de busqueda para compromisos de pago
                      </Card.Header>
                      <Card.Body
                        style={{
                          backgroundColor: "rgba(236,236,236,0.3)",
                        }}
                      >
                        {/* <Container> */}
                        <Row>
                          <Col className="align-middle" md="auto">
                            Fecha inicial
                          </Col>
                          <Col md="auto">
                            <DatePicker
                              locale="es"
                              selected={startDate}
                              dateFormat="dd/MM/yyyy"
                              onChange={updateDate}
                            />
                          </Col>

                          <Col className="align-middle" md="auto">
                            Fecha final
                          </Col>
                          <Col md="auto">
                            <DatePicker
                              locale="es"
                              dateFormat="dd/MM/yyyy"
                              selected={lastDate}
                              onChange={updateLastDate}
                            />
                          </Col>
                          <Col>
                            <Button
                              onClick={() =>
                                getFechaCobro(
                                  moment(startDate).format("yyyy-MM-DD"),
                                  moment(lastDate).format("yyyy-MM-DD")
                                )
                              }
                            >
                              Buscar por fecha
                            </Button>
                          </Col>
                        </Row>
                        {/* </Container> */}
                      </Card.Body>

                      {/* {products.length > 0 ? (
                        <Card.Footer
                          style={{
                            backgroundColor: "rgba(236,236,236,0.3)",
                          }}
                        >
                          <Col className="align-middle" md="auto">
                            Buscar cliente:
                          </Col>
                          <Col md="auto">
                            <SearchBar
                              class="align-middle"
                              placeholder="Ingrese cédula/nombres"
                              style={{ height: 25, width: 250 }}
                              {...props.searchProps}
                            />
                          </Col>
                          <Col className="align-middle" md="auto">
                            Buscar gestor:
                          </Col>
                          <Col md="auto">
                            <SearchBar
                              class="align-middle"
                              placeholder="Ingrese nombre"
                              style={{ height: 25, width: 150 }}
                              {...props.searchProps}
                            />
                          </Col>
                        </Card.Footer>
                      ) : null} */}
                      {/* </Container> */}
                    </Card>
                    <Card
                      className="mt-3"
                      style={{
                        borderWidth: 1,
                        borderColor: "rgba(128,128,128,0.4)",
                      }}
                    >
                      <Card.Header
                        as="h3"
                        style={{
                          backgroundColor: "rgba(236,236,236,0.9)",
                          color: "black",
                        }}
                      >
                        Listado general de "Compromisos de pago"
                      </Card.Header>
                      <Card.Body
                        style={{
                          backgroundColor: "rgba(236,236,236,0.6)",
                        }}
                      >
                        <Form>
                          {/* <Col> */}
                          <Row>
                            <div>
                              <FontAwesomeIcon
                                icon={faClock}
                                size="lg"
                                color="green"
                              />
                              Más de 5 días para fecha de pago
                            </div>

                            <div>
                              <FontAwesomeIcon
                                icon={faClock}
                                size="lg"
                                color="orange"
                              />
                              A días 5 para fecha de pago
                            </div>

                            <div>
                              <FontAwesomeIcon
                                icon={faClock}
                                size="lg"
                                color="#b30000"
                              />
                              Cerca de fecha de pago
                            </div>
                          </Row>
                          {/* </Col> */}
                        </Form>
                        <Col className="mt-3"></Col>

                        <BootstrapTable
                          striped
                          hover
                          condensed
                          // wrapperClasses="table-responsive"
                          // class="react-bootstrap-table table"

                          filter={filterFactory()}
                          pagination={paginationFactory(optionsPagination)}
                          {...props.baseProps}
                        />
                      </Card.Body>
                    </Card>
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
