//AIzaSyCOnZQ9bV-d8mTvPr1E3NxbpZd8BvsfUpM
import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faEdit,
//   faPlusSquare,
//   faMapMarkedAlt,
// } from "@fortawesome/free-solid-svg-icons";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";

const Map = (props) => {
  const lat = props.lat;
  const lon = props.lon;
  const titulo = props.titulo;
  const fecha = props.fecha;
  const voltaje = props.voltaje;
  const motor = props.motor;
  const velocidad = props.velocidad;

  const url = props.url;
  return (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={{ lat: lat, lng: lon }}
    // zoom={{ lat: lat, lng: lon }}
    >
      <Marker
        position={{ lat: lat, lng: lon }}
        // label={titulo}
        // label={{
        //   text: titulo,
        //   fontFamily: "Arial",
        //   fontSize: "14px",
        //   // backgroundColor
        // }}
        icon={{
          url: url,
          // labelOrigin: this.props.icon.labelOrigin,
        }}

      >
        <InfoWindow
          key={`infowindow-${titulo}`}
          visible={true}>
          <div>
            <div> Detalles: </div>
            <div> {titulo}</div>
            <div> {fecha}</div>
            <div> {motor}</div>
            <div> {voltaje}</div>
            <div> {velocidad}</div>
          </div>
        </InfoWindow>
      </Marker>

    </GoogleMap>
  );
};

export default withScriptjs(withGoogleMap(Map));
