import React from "react";

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
    Mostrando {from} a {to} de {size} resultados
  </span>
);

const optionsPagination = {
  paginationSize: 4,
  pageStartIndex: 1,
  // alwaysShowAllBtns: true, // Always show next and previous button
  // withFirstAndLast: false, // Hide the going to First and Last page button
  // hideSizePerPage: true, // Hide the sizePerPage dropdown always
  // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
  firstPageText: "Primera",
  prePageText: "Atrás",
  nextPageText: "Siguiente",
  lastPageText: "Anterior",
  nextPageTitle: "Primera página",
  prePageTitle: "Pre página",
  firstPageTitle: "Siguiente página",
  lastPageTitle: "Última página",
  showTotal: true,
  paginationTotalRenderer: customTotal,
  disablePageTitle: true,
  sizePerPageList: [
    {
      text: "100",
      value: 100,
    },
    {
      text: "200",
      value: 200,
    },
    {
      text: "400",
      value: 400,
    },
    {
      text: "5000",
      value: 5000,
    },
  ], // A numeric array is also available. the purpose of above example is custom the text
};

export default optionsPagination;
