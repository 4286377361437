//AIzaSyCOnZQ9bV-d8mTvPr1E3NxbpZd8BvsfUpM
import React, { useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faEdit,
//   faPlusSquare,
//   faMapMarkedAlt,
// } from "@fortawesome/free-solid-svg-icons";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";

// const MapList = (props) => {
function MapList(props) {
  const lat = props.lat;
  const lon = props.lon;
  const titulo = props.titulo;
  const fecha = props.fecha;
  const voltaje = props.voltaje;
  const motor = props.motor;
  const velocidad = props.velocidad;
  const ltsMarcadores = props.ltsMarcadores;

  const url = props.url;

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    ltsMarcadores.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  return (
    <GoogleMap
      onLoad={handleOnLoad}
      // onClick={() => setActiveMarker(null)}

      defaultZoom={14}
      defaultCenter={{ lat: lat, lng: lon }}
      center={{ lat: lat, lng: lon }}
      
    // zoom={{ lat: lat, lng: lon }}
    >
      {ltsMarcadores.map(({ id, name, position }) => (
        <Marker
          key={id}
          position={position}
          icon={{
            url: 'https://firebasestorage.googleapis.com/v0/b/proyecto-bajaj.appspot.com/o/Bajaj%2Ftallerlog.png?alt=media&token=9f8ccafb-f348-4065-96b8-00d166f8504b',
            // labelOrigin: this.props.icon.labelOrigin,
            scale: 0.05, 
          }}
          
          onClick={() => handleActiveMarker(id)}
        >
          {activeMarker === id ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>{name}</div>
            </InfoWindow>
          ) : null}
        </Marker>
      ))}
    </GoogleMap >
  );
};

export default withScriptjs(withGoogleMap(MapList));
