import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Card,
  FormGroup,
  Form,
  Button,
  Spinner,
  Modal,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import axios from "axios";
import Swal from "sweetalert2";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faHammer } from "@fortawesome/free-solid-svg-icons";
import { ColorPicker } from "primereact/colorpicker";
import { Spin } from "react-loading-io";
import Logo from "../Layout/Logo";

import * as firebase from "firebase";
// import * as storage from 'firebase/storage'

function Content() {
  const server = window.$linkserver;
  const [dataTable, setdataTable] = useState([]);
  const [getRowsCiudades, setRowsCiudades] = useState([]);
  const [isLoadingEmpresaCreada, setisLoadingEmpresaCreada] = useState(
    "invisible"
  );
  const [isLoadingTable, setisLoadingTable] = useState("invisible");
  const [isLoadingActualizar, setisLoadingActualizar] = useState("invisible");
  const [showModal, setshowModal] = useState(false);
  const [showModalParametros, setshowModalParametros] = useState(false);
  const storage = firebase.storage();
  const [imagenBackground, setimagenBackground] = useState({
    pae_id: 0,
    pae_parametro: "",
    pae_tipo: 1,
    pae_empresa_id: 0,
  });
  const [imagenMenu, setimagenMenu] = useState({
    pae_id: 0,
    pae_parametro: "",
    pae_tipo: 2,
    pae_empresa_id: 0,
  });
  const [imagenLogo, setimagenLogo] = useState({
    pae_id: 0,
    pae_parametro: "",
    pae_tipo: 3,
    pae_empresa_id: 0,
  });
  const [color, setcolor] = useState({
    pae_id: 0,
    pae_parametro: "",
    pae_tipo: 4,
    pae_empresa_id: 0,
  });

  const [colorbarra, setcolorbarra] = useState({
    pae_id: 0,
    pae_parametro: "",
    pae_tipo: 5,
    pae_empresa_id: 0,
  });

  const [colormenu, setcolormenu] = useState({
    pae_id: 0,
    pae_parametro: "",
    pae_tipo: 6,
    pae_empresa_id: 0,
  });

  const [imagenBackground2, setimagenBackground2] = useState("");
  const [imagenMenu2, setimagenMenu2] = useState("");
  const [imagenLogo2, setimagenLogo2] = useState("");
  const [updateimagenBackground, setupdateimagenBackground] = useState(false);
  const [updateimagenMenu, setupdateimagenMenu] = useState(false);
  const [updateimagenLogo, setupdateimagenLogo] = useState(false);
  const [showModalCargando, setShowModalCargando] = useState(false);
  const [idEmpresa, setidEmpresa] = useState(0);

  const [banderaBackground, setbanderaBackground] = useState(false);
  const [banderaMenu, setbanderaMenu] = useState(false);
  const [banderaLogo, setbanderaLogo] = useState(false);

  const [empresa, setempresa] = useState({
    // emp_codigo: "",
    // emp_id: "",
    emp_razon_social: "",
    emp_nombre_comercial: "",
    emp_cuidad: 0,
    emp_direccion: "",
    emp_telefono: "",
    emp_email_empresa: "",
    emp_estado: 0,
    emp_ruc: "",
    emp_pagina_web: "",
    emp_dominio: "",
    // emp_tipo_empresa: "",
  });

  const [error, seterror] = useState({
    // codigo: "",
    // id: "",
    razon: "",
    nombreco: "",
    direccion: "",
    telefono: "",
    email: "",
    estado: 0,
    pagina: "",
    ruc: "",
    dominio: "",
  });

  const columns = [
    {
      dataField: "emp_id",
      text: "Codigo",
      sort: true,
    },
    {
      dataField: "emp_razon_social",
      text: "Razon Social",
      sort: true,
    },
    {
      dataField: "emp_direccion",
      text: "Dirección",
      sort: true,
    },
    {
      dataField: "emp_telefono",
      text: "Teléfono",
      sort: true,
    },
    {
      dataField: "emp_email_empresa",
      text: "Email",
      sort: true,
    },

    {
      dataField: "emp_ruc",
      text: "RUC",
      sort: true,
    },
    {
      dataField: "emp_pagina_web",
      text: "Página Web",
      sort: true,
    },

    {
      dataField: "EMP_EDITAR",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },

    {
      dataField: "EMP_PARAMETROS",
      text: "Parametros",
      formatter: (cell, row, index) => paramtros(row, index),
      sort: true,
    },
  ];

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          edit(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const paramtros = (row, index) => {
    return (
      <Button
        onClick={() => {
          editParametros(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faHammer} />
      </Button>
    );
  };

  const edit = (row, index) => {
    setempresa((empresa) => {
      return {
        ...empresa,
        emp_codigo: row.emp_codigo,
        emp_id: row.emp_id,
        emp_razon_social: row.emp_razon_social,
        emp_nombre_comercial: row.emp_nombre_comercial,
        emp_cuidad: row.emp_cuidad,
        emp_direccion: row.emp_direccion,
        emp_telefono: row.emp_telefono,
        emp_email_empresa: row.emp_email_empresa,
        emp_ruc: row.emp_ruc,
        emp_pagina_web: row.emp_pagina_web,
        emp_estado: row.emp_estado,
        emp_dominio: row.emp_dominio,
        // emp_tipo_empresa: row.EMP_TIPO_EMPRESA,
      };
    });

    setshowModal(true);
  };

  const editParametros = (row, index) => {
    setidEmpresa(row.emp_id);
    setimagenBackground((imagenBackground) => {
      return {
        ...imagenBackground,
        pae_empresa_id: row.emp_id,
      };
    });

    setimagenMenu((imagenMenu) => {
      return {
        ...imagenMenu,
        pae_empresa_id: row.emp_id,
      };
    });

    setimagenLogo((imagenLogo) => {
      return {
        ...imagenLogo,
        pae_empresa_id: row.emp_id,
      };
    });

    setcolor((color) => {
      return {
        ...color,
        pae_empresa_id: row.emp_id,
      };
    });

    setcolorbarra((colorbarra) => {
      return {
        ...colorbarra,
        pae_empresa_id: row.emp_id,
      };
    });

    setcolormenu((colormenu) => {
      return {
        ...colormenu,
        pae_empresa_id: row.emp_id,
      };
    });

    getParametros(row.emp_id);
  };

  const setDataCiudad = (data) => {
    const rows = data.map((row, index) => (
      <option key={row.cui_id} value={row.cui_id}>
        {row.cui_nombre}
      </option>
    ));
    setRowsCiudades(rows);
  };

  const getEmpresas = async () => {
    setisLoadingTable("visible");
    await axios
      .get(server + "empresas/getListEmpresas")
      .then(function (response) {
        setisLoadingTable("invisible");
        // console.log(response);
        setdataTable(response.data);
      })
      .catch(function (error) {
        setisLoadingTable("invisible");
      })
      .then(function () {});
  };

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const createEmpresa = async () => {
    seterror((error) => {
      return {
        ...error,
        // codigo: "",
        // id: "",
        razon: "",
        nombreco: "",
        direccion: "",
        telefono: "",
        email: "",
        // representante: "",
        pagina: "",
        ruc: "",
        dominio: "",
      };
    });

    if (empresa.emp_cuidad === 0) {
      showMessage("Selecciona una Ciudad", "error");
    } else {
      if (
        // empresa.emp_codigo !== "" &&
        // empresa.emp_id !== "" &&
        empresa.emp_razon_social !== "" &&
        empresa.emp_nombre_comercial !== "" &&
        empresa.emp_direccion !== "" &&
        empresa.emp_telefono !== "" &&
        empresa.emp_email_empresa !== "" &&
        // empresa.emp_representante !== "" &&
        empresa.emp_ruc !== "" &&
        empresa.emp_dominio !== "" &&
        empresa.emp_pagina_web !== ""
      ) {
        setisLoadingEmpresaCreada("visible");
        await axios
          .post(server + "empresas/createEmpresa", {
            emp_razon_social: empresa.emp_razon_social,
            emp_nombre_comercial: empresa.emp_nombre_comercial,
            emp_direccion: empresa.emp_direccion,
            emp_telefono: empresa.emp_telefono,
            emp_ruc: empresa.emp_ruc,
            emp_pagina_web: empresa.emp_pagina_web,
            emp_email_empresa: empresa.emp_email_empresa,
            emp_cuidad: empresa.emp_cuidad,
            emp_dominio: empresa.emp_dominio,
          })

          .then(function (response) {
            setisLoadingEmpresaCreada("invisible");

            if (response.status === 200) {
              showMessage("Empresa creada con éxito", "success");

              setempresa((empresa) => {
                return {
                  ...empresa,
                  // emp_codigo: "",
                  // emp_id: "",
                  emp_razon_social: "",
                  emp_nombre_comercial: "",
                  emp_cuidad: 0,
                  emp_direccion: "",
                  emp_telefono: "",
                  emp_email_empresa: "",
                  // emp_representante: "",
                  emp_ruc: "",
                  emp_pagina_web: "",
                  emp_dominio: "",
                  // emp_tipo_empresa: "0",
                };
              });

              // getData();
            } else {
              showMessage("Error el crear", "error");
            }
          })
          .catch(function (error) {
            showMessage("Campos incompletos", "error");
          });
      } else {
        if (empresa.emp_razon_social === "") {
          seterror((error) => {
            return { ...error, razon: "Campo requerido" };
          });
        }

        if (empresa.emp_dominio === "") {
          seterror((error) => {
            return { ...error, dominio: "Campo requerido" };
          });
        }

        if (empresa.emp_nombre_comercial === "") {
          seterror((error) => {
            return { ...error, nombreco: "Campo requerido" };
          });
        }

        if (empresa.emp_direccion === "") {
          seterror((error) => {
            return { ...error, direccion: "Campo requerido" };
          });
        }

        if (empresa.emp_telefono === "") {
          seterror((error) => {
            return { ...error, telefono: "Campo requerido" };
          });
        }

        if (empresa.emp_email_empresa === "") {
          seterror((error) => {
            return { ...error, email: "Campo requerido" };
          });
        }

        // if (empresa.emp_representante === "") {
        //   seterror((error) => {
        //     return { ...error, representante: "Campo requerido" };
        //   });
        // }

        if (empresa.emp_pagina_web === "") {
          seterror((error) => {
            return { ...error, pagina: "Campo requerido" };
          });
        }

        if (empresa.emp_ruc === "") {
          seterror((error) => {
            return { ...error, ruc: "Campo requerido" };
          });
        }

        showMessage("Campos incompletos", "error");
      }
    }
  };

  const updateEmpresa = async () => {
    seterror((error) => {
      return {
        ...error,
        codigo: "",
        id: "",
        razon: "",
        nombreco: "",
        direccion: "",
        telefono: "",
        email: "",
        estado: "",
        pagina: "",
        ruc: "",
        dominio: "",
      };
    });

    if (empresa.emp_cuidad === 0) {
      showMessage("Selecciona una Ciudad", "error");
    } else {
      if (
        // empresa.emp_codigo !== "" &&
        // empresa.emp_id !== "" &&
        empresa.emp_razon_social !== "" &&
        empresa.emp_nombre_comercial !== "" &&
        empresa.emp_direccion !== "" &&
        empresa.emp_telefono !== "" &&
        empresa.emp_email_empresa !== "" &&
        // empresa.emp_representante !== "" &&
        // empresa.emp_ruc !== "" &&
        empresa.emp_pagina_web !== "" &&
        empresa.emp_dominio !== "" &&
        empresa.emp_estado !== ""
      ) {
        setisLoadingActualizar("visible");
        await axios
          .post(server + "empresas/updateEmpresa", {
            emp_codigo: empresa.emp_codigo,
            emp_razon_social: empresa.emp_razon_social,
            emp_nombre_comercial: empresa.emp_nombre_comercial,
            emp_direccion: empresa.emp_direccion,
            emp_telefono: empresa.emp_telefono,
            emp_pagina_web: empresa.emp_pagina_web,
            emp_email_empresa: empresa.emp_email_empresa,
            emp_estado: empresa.emp_estado,
            emp_cuidad: empresa.emp_cuidad,
            emp_dominio: empresa.emp_dominio,
          })
          .then(function (response) {
            setisLoadingActualizar("invisible");
            if (response.status === 200) {
              showMessage("Datos actualizados con éxito", "success");

              setempresa((empresa) => {
                return {
                  ...empresa,
                  emp_codigo: "",
                  emp_id: "",
                  emp_razon_social: "",
                  emp_nombre_comercial: "",
                  emp_cuidad: 0,
                  emp_direccion: "",
                  emp_telefono: "",
                  emp_email_empresa: "",
                  // emp_representante: "",
                  emp_ruc: "",
                  emp_pagina_web: "",
                  emp_dominio: "",
                  // emp_tipo_empresa: "0",
                };
              });
              setshowModal(false);
              getEmpresas();
              //getData()
            } else {
              showMessage("Error el actualizar datos de empresa", "error");
              setisLoadingActualizar("invisible");
            }
          })
          .catch(function (error) {
            showMessage("Problemas al actualizar empresa", "error");
            setisLoadingActualizar("invisible");
          });
      } else {
        // if (empresa.emp_codigo === "") {
        //   seterror((error) => {
        //     return { ...error, codigo: "Campo requerido" };
        //   });
        // }

        // if (empresa.emp_id === "") {
        //   seterror((error) => {
        //     return { ...error, id: "Campo requerido" };
        //   });
        // }

        if (empresa.emp_razon_social === "") {
          seterror((error) => {
            return { ...error, razon: "Campo requerido" };
          });
        }

        if (empresa.emp_dominio === "") {
          seterror((error) => {
            return { ...error, dominio: "Campo requerido" };
          });
        }

        if (empresa.emp_nombre_comercial === "") {
          seterror((error) => {
            return { ...error, nombreco: "Campo requerido" };
          });
        }

        if (empresa.emp_direccion === "") {
          seterror((error) => {
            return { ...error, direccion: "Campo requerido" };
          });
        }

        if (empresa.emp_telefono === "") {
          seterror((error) => {
            return { ...error, telefono: "Campo requerido" };
          });
        }

        if (empresa.emp_email_empresa === "") {
          seterror((error) => {
            return { ...error, email: "Campo requerido email" };
          });
        }

        if (empresa.emp_estado === 0) {
          seterror((error) => {
            return { ...error, estado: "Campo requerido" };
          });
        }

        if (empresa.emp_pagina_web === "") {
          seterror((error) => {
            return { ...error, pagina: "Campo requerido" };
          });
        }

        if (empresa.emp_ruc === "") {
          seterror((error) => {
            return { ...error, ruc: "Campo requerido" };
          });
        }

        showMessage("Campos incompletos", "error");
      }
    }
  };

  const Imagenbackground = (foto, cargando) => {
    if (cargando) {
      return (
        <div className="product-grid-item-content">
          <Spin size={50} color={"#80E97B"} />
        </div>
      );
    } else {
      if (foto !== "") {
        return (
          <img
            style={{
              marginLeft: 10,
              marginRight: 10,
              width: "90%",
              height: "40%",
            }}
            src={foto}
            alt=""
          />
        );
      } else {
        return <h1 style={{ fontSize: 12 }}>No existe Foto</h1>;
      }
    }
  };

  const imagenMenuModal = (foto, cargando) => {
    if (cargando) {
      return (
        <div className="product-grid-item-content">
          <Spin size={50} color={"#80E97B"} />
        </div>
      );
    } else {
      if (foto !== "") {
        return (
          <div>
            <Col xs={5} md={5} className="mx-auto text-center p-3">
              <img
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  width: "90%",
                  height: "10%",
                }}
                src={foto}
                alt=""
              />
            </Col>
          </div>
        );
      } else {
        return <h1 style={{ fontSize: 12 }}>No existe Foto</h1>;
      }
    }
  };

  const imageLogo = (foto, cargando) => {
    if (cargando) {
      return (
        <div className="product-grid-item-content">
          <Spin size={50} color={"#80E97B"} />
        </div>
      );
    } else {
      if (foto !== "") {
        return (
          <div>
            <Col xs={5} md={5} className="mx-auto text-center p-3">
              <img
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  width: "90%",
                  height: "10%",
                }}
                src={foto}
                alt=""
              />
            </Col>
          </div>
        );
      } else {
        return <h1 style={{ fontSize: 12 }}>No existe Foto</h1>;
      }
    }
  };

  const modalFoto = (foto) => {
    if (foto !== "") {
      return (
        <img
          style={{
            marginLeft: 10,
            marginRight: 10,
            width: "90%",
            height: "40%",
          }}
          src={foto}
          alt=""
        />
      );
    } else {
      return <h1 style={{ fontSize: 12 }}>No existe Foto</h1>;
    }
  };

  const imgBackground = async () => {
    var process = true;

    const uploadTask = storage
      .ref(`Empresas/parametros/${idEmpresa}/background`)
      .put(imagenBackground2);
    return await uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        process = false;
      },
      () => {
        storage
          .ref(`Empresas/parametros/${idEmpresa}/`)
          .child("background")
          .getDownloadURL()
          .then((url) => {
            setimagenBackground((imagenBackground) => {
              return { ...imagenBackground, pae_parametro: url };
            });
          });
      }
    );
  };

  const uploadPhotoEmpresa = async () => {
    if (
      imagenBackground.pae_parametro !== "" &&
      imagenLogo.pae_parametro !== "" &&
      imagenMenu.pae_parametro !== "" &&
      color.pae_parametro !== ""
    ) {
      const listParametros = [];

      listParametros.push(imagenBackground);
      listParametros.push(imagenLogo);
      listParametros.push(imagenMenu);

      listParametros.push(color);
      listParametros.push(colorbarra);
      listParametros.push(colormenu);

      await axios
        .post(server + "empresas/setParametrosEmpresa", {
          listaParametros: listParametros,
        })

        .then(function (response) {
          if (response.status === 200) {
            showMessage("Parametros Actualizados Coreectamente", "success");

            setimagenBackground((imagenBackground) => {
              return {
                ...imagenBackground,
                pae_id: 0,
                pae_parametro: "",
                pae_tipo: 1,
                pae_empresa_id: 0,
              };
            });

            setimagenMenu((imagenMenu) => {
              return {
                ...imagenMenu,
                pae_id: 0,
                pae_parametro: "",
                pae_tipo: 2,
                pae_empresa_id: 0,
              };
            });

            setimagenLogo((imagenLogo) => {
              return {
                ...imagenLogo,
                pae_id: 0,
                pae_parametro: "",
                pae_tipo: 3,
                pae_empresa_id: 0,
              };
            });

            setcolor((color) => {
              return {
                ...color,
                pae_id: 0,
                pae_parametro: "",
                pae_tipo: 4,
                pae_empresa_id: 0,
              };
            });

            setcolorbarra((colorbarra) => {
              return {
                ...colorbarra,
                pae_id: 0,
                pae_parametro: "",
                pae_tipo: 5,
                pae_empresa_id: 0,
              };
            });

            setcolormenu((colormenu) => {
              return {
                ...colormenu,
                pae_id: 0,
                pae_parametro: "",
                pae_tipo: 6,
                pae_empresa_id: 0,
              };
            });
            setShowModalCargando(false);
            setshowModalParametros(false);
          } else {
            showMessage("Error el ingresar parametros", "error");
            setShowModalCargando(false);
          }
        })
        .catch(function (error) {
          showMessage("Campos incompletos", "error");
          setShowModalCargando(false);
        });

      // setShowModalCargando(false);
      // setshowModalParametros(false);
      // console.log(imagenBackground);
      // console.log(imagenLogo);
      // console.log(imagenMenu);
    } else {
      showMessage("Debes agregar el todos los campos", "error");
      setShowModalCargando(false);
    }
    // if (editFoto === true) {
    //   // updateGestor(url)
    //   if (imageG !== "") {
    //     // console.log("cargando foto...");
    //     setIsLoadingGestor("visible");
    //     const uploadTask = storage
    //       .ref(`Bajaj/gestores/${gestor.ges_cedula}`)
    //       .put(fotoGestor);
    //     await uploadTask.on(
    //       "state_changed",
    //       (snapshot) => { },
    //       (error) => {
    //         console.log("erorrL L" + error);
    //       },
    //       () => {
    //         storage
    //           .ref("Bajaj/gestores/")
    //           .child(gestor.ges_cedula)
    //           .getDownloadURL()
    //           .then((url) => {
    //             console.log(url)
    //             // setImgGestor(url)
    //             // setGestor((gestor) => {
    //             //   return { ...gestor, ges_foto: url };
    //             // });
    //             // setGestor((gestor) => {
    //             //   return { ...gestor, ges_foto: url };
    //             // });
    //             seteditFoto(true)
    //             console.log("foto cargada...");
    //             setIsLoadingGestor("invisible");
    //             updateGestor(url)
    //             // createGestor(url);
    //           });
    //       }
    //     );
    //   } else {
    //     showMessage("Debes agregar el todos los campos", "error");
    //   }
    // } else {
    //   updateGestor(imageG)
    // }
  };

  const selectFileMenu = (event) => {
    setbanderaMenu(true);
    const uploadTask = storage
      .ref(`Empresas/parametros/${idEmpresa}/menu`)
      .put(event.target.files[0]);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        process = false;
      },
      () => {
        storage
          .ref(`Empresas/parametros/${idEmpresa}/`)
          .child("menu")
          .getDownloadURL()
          .then((url) => {
            setimagenMenu((imagenMenu) => {
              return { ...imagenMenu, pae_parametro: url };
            });

            setbanderaMenu(false);
          });
      }
    );
  };

  const selectFileLogo = (event) => {
    setbanderaLogo(true);

    const uploadTask = storage
      .ref(`Empresas/parametros/${idEmpresa}/logo`)
      .put(event.target.files[0]);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        process = false;
      },
      () => {
        storage
          .ref(`Empresas/parametros/${idEmpresa}/`)
          .child("logo")
          .getDownloadURL()
          .then((url) => {
            setimagenLogo((imagenLogo) => {
              return { ...imagenLogo, pae_parametro: url };
            });

            setbanderaLogo(false);
          });
      }
    );
  };

  const selectFileBackground = (event) => {
    setbanderaBackground(true);
    const uploadTask = storage
      .ref(`Empresas/parametros/${idEmpresa}/background`)
      .put(event.target.files[0]);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        process = false;
      },
      () => {
        storage
          .ref(`Empresas/parametros/${idEmpresa}/`)
          .child("background")
          .getDownloadURL()
          .then((url) => {
            setimagenBackground((imagenBackground) => {
              return { ...imagenBackground, pae_parametro: url };
            });

            setbanderaBackground(false);
          });
      }
    );
  };

  const estadoParametros = (showModalCargando) => {
    if (showModalCargando) {
      return (
        <div style={{ marginTop: 5 }} className="text-center">
          <div className={showModalCargando}>
            <Spinner animation="border" role="status"></Spinner>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <Button
            onClick={() => {
              setShowModalCargando(true);
              uploadPhotoEmpresa();
            }}
          >
            Actualizar
          </Button>
          <Button
            onClick={() => {
              setshowModalParametros(false);

              setimagenBackground((imagenBackground) => {
                return {
                  ...imagenBackground,
                  pae_id: 0,
                  pae_parametro: "",
                  pae_tipo: 1,
                  pae_empresa_id: 0,
                };
              });

              setimagenMenu((imagenMenu) => {
                return {
                  ...imagenMenu,
                  pae_id: 0,
                  pae_parametro: "",
                  pae_tipo: 2,
                  pae_empresa_id: 0,
                };
              });

              setimagenLogo((imagenLogo) => {
                return {
                  ...imagenLogo,
                  pae_id: 0,
                  pae_parametro: "",
                  pae_tipo: 3,
                  pae_empresa_id: 0,
                };
              });

              setcolor((color) => {
                return {
                  ...color,
                  pae_id: 0,
                  pae_parametro: "",
                  pae_tipo: 4,
                  pae_empresa_id: 0,
                };
              });

              setcolorbarra((colorbarra) => {
                return {
                  ...colorbarra,
                  pae_id: 0,
                  pae_parametro: "",
                  pae_tipo: 5,
                  pae_empresa_id: 0,
                };
              });

              setcolormenu((colormenu) => {
                return {
                  ...colormenu,
                  pae_id: 0,
                  pae_parametro: "",
                  pae_tipo: 6,
                  pae_empresa_id: 0,
                };
              });
            }}
          >
            Cerrar
          </Button>
        </>
      );
    }
  };

  const getParametros = async (idEmpresa) => {
    await axios
      .get(server + "empresas/getParametrosEmpresa/" + idEmpresa)
      .then(function (response) {
        response.data.map((item, key) => {
          if (item.pae_tipo == 1) {
            setimagenBackground((imagenBackground) => {
              return {
                ...imagenBackground,
                pae_id: item.pae_id,
                pae_parametro: item.pae_parametro,
                pae_tipo: item.pae_tipo,
              };
            });
          }

          if (item.pae_tipo == 2) {
            setimagenMenu((imagenMenu) => {
              return {
                ...imagenMenu,
                pae_id: item.pae_id,
                pae_parametro: item.pae_parametro,
                pae_tipo: item.pae_tipo,
              };
            });
          }

          if (item.pae_tipo == 3) {
            setimagenLogo((imagenLogo) => {
              return {
                ...imagenLogo,
                pae_id: item.pae_id,
                pae_parametro: item.pae_parametro,
                pae_tipo: item.pae_tipo,
              };
            });
          }

          if (item.pae_tipo == 4) {
            setcolor((color) => {
              return {
                ...color,
                pae_id: item.pae_id,
                pae_parametro: item.pae_parametro,
                pae_tipo: item.pae_tipo,
              };
            });
          }

          if (item.pae_tipo == 5) {
            setcolorbarra((colorbarra) => {
              return {
                ...colorbarra,
                pae_id: item.pae_id,
                pae_parametro: item.pae_parametro,
                pae_tipo: item.pae_tipo,
              };
            });
          }

          if (item.pae_tipo == 6) {
            setcolormenu((colormenu) => {
              return {
                ...colormenu,
                pae_id: item.pae_id,
                pae_parametro: item.pae_parametro,
                pae_tipo: item.pae_tipo,
              };
            });
          }
        });

        setshowModalParametros(true);
      })
      .catch(function (error) {
        showMessage("Ocurrio un error Intentalo de Nuevo", "error");
      })
      .then(function () {});
  };

  useEffect(() => {
    const getCiudad = async () => {
      await axios
        .get(server + "empresas/getListCuidades")
        .then(function (response) {
          // console.log(response);
          setDataCiudad(response.data);
        })
        .catch(function (error) {})
        .then(function () {});
    };

    const getEmpresasInciial = async () => {
      setisLoadingTable("visible");
      await axios
        .get(server + "empresas/getListEmpresas")
        .then(function (response) {
          setisLoadingTable("invisible");
          // console.log(response);
          setdataTable(response.data);
        })
        .catch(function (error) {
          setisLoadingTable("invisible");
        })
        .then(function () {});
    };

    getEmpresasInciial();
    getCiudad();
  }, [server]);

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          {/* <PageTitle
            heading="Empresa/ Empresa"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          /> */}
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <div>
                <Card>
                  <div className="text-center">
                    <div
                      className={isLoadingEmpresaCreada}
                      style={{ marginTop: 10 }}
                    >
                      <Spinner
                        animation="border"
                        role="status"
                        variant="secondary"
                      ></Spinner>
                    </div>
                  </div>

                  <Card.Header as="h5">Creación de Nueva Empresa </Card.Header>
                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Razón Social:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese un Razón Social"
                            id="emp_razon_soacial"
                            value={empresa.emp_razon_social}
                            onChange={(e) => {
                              const val = e.target.value;
                              setempresa((empresa) => {
                                return { ...empresa, emp_razon_social: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.razon}</span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Nombre Comercial:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese un Nombre Comercial"
                            id="emp_nombre_comercial"
                            value={empresa.emp_nombre_comercial}
                            onChange={(e) => {
                              const val = e.target.value;
                              setempresa((empresa) => {
                                return {
                                  ...empresa,
                                  emp_nombre_comercial: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.nombreco}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Dirección:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Dirección"
                            id="emp_direccion"
                            value={empresa.emp_direccion}
                            onChange={(e) => {
                              const val = e.target.value;
                              setempresa((empresa) => {
                                return { ...empresa, emp_direccion: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {error.direccion}
                          </span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Dominio:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={500}
                            placeholder="Dominio"
                            id="emp_dominio"
                            value={empresa.emp_dominio}
                            onChange={(e) => {
                              const val = e.target.value;
                              setempresa((empresa) => {
                                return { ...empresa, emp_dominio: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.dominio}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Email:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese un Email"
                            id="emp_email_empresa"
                            value={empresa.emp_email_empresa}
                            onChange={(e) => {
                              const val = e.target.value;
                              setempresa((empresa) => {
                                return { ...empresa, emp_email_empresa: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.email}</span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Teléfono:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Teléfono"
                            id="emp_telefono"
                            value={empresa.emp_telefono}
                            onChange={(e) => {
                              const val = e.target.value;
                              setempresa((empresa) => {
                                return { ...empresa, emp_telefono: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.telefono}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Ingrese una Página Web:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={40}
                            placeholder="Ingrese Página Web"
                            id="emp_pagina_web"
                            value={empresa.emp_pagina_web}
                            onChange={(e) => {
                              const val = e.target.value;
                              setempresa((empresa) => {
                                return { ...empresa, emp_pagina_web: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.pagina}</span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            RUC:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={13}
                            placeholder="Ingrese un RUC"
                            id="emp_ruc"
                            value={empresa.emp_ruc}
                            onChange={(e) => {
                              const val = e.target.value;
                              setempresa((empresa) => {
                                return { ...empresa, emp_ruc: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.ruc}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Ciudad:{" "}
                          </Form.Label>
                          <Form.Control
                            as="select"
                            onChange={(e) => {
                              const val = Number(e.target.value);
                              setempresa((empresa) => {
                                return {
                                  ...empresa,
                                  emp_cuidad: val,
                                };
                              });
                            }}
                          >
                            <option value="0">N/A</option>
                            {getRowsCiudades}
                          </Form.Control>
                        </Col>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          onClick={() => {
                            createEmpresa();
                          }}
                          variant="primary"
                        >
                          Crear Empresa
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </div>
              <div>
                <Card className="mt-2">
                  <Card.Header as="h5">
                    Listado general de "Empresas"
                  </Card.Header>
                  <Card.Body>
                    <BootstrapTable
                      keyField="emp_id"
                      wrapperClasses="table-responsive"
                      data={dataTable}
                      columns={columns}
                      pagination={paginationFactory()}
                    />

                    <div className="text-center">
                      <div className={isLoadingTable} style={{ marginTop: 10 }}>
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                        ></Spinner>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>

              <div>
                <Modal
                  show={showModal}
                  // onHide={!showModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Actualización de datos de: {empresa.emp_nombre_comercial}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="text-center">
                      <div className={isLoadingActualizar}>
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                        ></Spinner>
                      </div>
                    </div>

                    <div style={{ marginTop: 10 }}>
                      <Form>
                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Código : {empresa.emp_codigo}
                            </Form.Label>
                          </Col>
                          <Col>
                            <Form.Label className="title-create">
                              ID: {empresa.emp_id}
                            </Form.Label>
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Razón Social:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              placeholder="Ingrese un Razón Social"
                              id="emp_razon_soacial"
                              value={empresa.emp_razon_social}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_razon_social: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>{error.razon}</span>
                          </Col>
                          <Col>
                            <Form.Label className="title-create">
                              Nombre Comercial:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              placeholder="Ingrese un Nombre Comercial"
                              id="emp_nombre_comercial"
                              value={empresa.emp_nombre_comercial}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return {
                                    ...empresa,
                                    emp_nombre_comercial: val,
                                  };
                                });
                              }}
                            />

                            <span style={{ color: "red" }}>
                              {error.nombreco}
                            </span>
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Dirección:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={100}
                              placeholder="Dirección"
                              id="emp_direccion"
                              value={empresa.emp_direccion}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_direccion: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {error.direccion}
                            </span>
                          </Col>

                          <Col>
                            <Form.Label className="title-create">
                              Dominio:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={500}
                              placeholder="Dominio"
                              id="emp_dominio"
                              value={empresa.emp_dominio}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_dominio: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {error.dominio}
                            </span>
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Email:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              placeholder="Ingrese un Email"
                              id="emp_email_empresa"
                              value={empresa.emp_email_empresa}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_email_empresa: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>{error.email}</span>
                          </Col>

                          <Col>
                            <Form.Label className="title-create">
                              Teléfono:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              placeholder="Teléfono"
                              id="emp_telefono"
                              value={empresa.emp_telefono}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_telefono: val };
                                });
                              }}
                            />

                            <span style={{ color: "red" }}>
                              {error.telefono}
                            </span>
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Ingrese una Página Web:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={40}
                              placeholder="Ingrese Página Web"
                              id="emp_pagina_web"
                              value={empresa.emp_pagina_web}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_pagina_web: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>{error.pagina}</span>
                          </Col>

                          <Col>
                            <Form.Label className="title-create">
                              RUC:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={13}
                              placeholder="Ingrese un RUC"
                              id="emp_ruc"
                              value={empresa.emp_ruc}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_ruc: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>{error.ruc}</span>
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Ciudad:{" "}
                            </Form.Label>

                            <Form.Control
                              as="select"
                              value={empresa.emp_cuidad}
                              onChange={(e) => {
                                const val = Number(e.target.value);
                                setempresa((empresa) => {
                                  return { ...empresa, emp_cuidad: val };
                                });
                              }}
                            >
                              <option value="0">N/A</option>
                              {getRowsCiudades}
                            </Form.Control>
                          </Col>
                          <Col>
                            <Form.Label className="title-create">
                              Desactivar empresa:{" "}
                            </Form.Label>

                            <Form.Control
                              as="select"
                              value={empresa.emp_estado}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_estado: val };
                                });
                              }}
                            >
                              <option value="1">Activada</option>
                              <option value="0">Desactivada</option>
                            </Form.Control>
                          </Col>
                        </FormGroup>
                      </Form>
                    </div>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        updateEmpresa();
                      }}
                    >
                      Actualizar
                    </Button>
                    <Button
                      onClick={() => {
                        setshowModal(false);

                        seterror((error) => {
                          return {
                            ...error,
                            codigo: "",
                            id: "",
                            razon: "",
                            nombreco: "",
                            direccion: "",
                            telefono: "",
                            email: "",
                            representante: "",
                            pagina: "",
                            ruc: "",
                          };
                        });
                        setempresa((empresa) => {
                          return {
                            ...empresa,
                            emp_codigo: "",
                            emp_id: "",
                            emp_razon_social: "",
                            emp_nombre_comercial: "",
                            emp_cuidad: 0,
                            emp_direccion: "",
                            emp_telefono: "",
                            emp_email_empresa: "",
                            emp_representante: "",
                            emp_ruc: "",
                            emp_pagina_web: "",
                            emp_tipo_empresa: "0",
                          };
                        });
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </Col>
          </Row>

          {/* <Modal
            show={showModalCargando}
            onHide={!showModalCargando}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body class="text-center">
              <p></p>
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
              <p class="text-center">
                Subiendo datos de cartera, espere por favor.
              </p>
            </Modal.Body>
          </Modal> */}

          <Modal
            show={showModalParametros}
            onHide={!showModalParametros}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Parametros de Empresa
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <FormGroup>
                <Form.Label className="title-create">
                  Imagen de Background:{" "}
                </Form.Label>

                <FormGroup>
                  {Imagenbackground(
                    imagenBackground.pae_parametro,
                    banderaBackground
                  )}
                </FormGroup>

                <FormGroup>
                  <label className="btn btn-default">
                    <input
                      type="file"
                      onChange={selectFileBackground}
                      onClick={(e) => (e.target.value = null)}
                    />
                  </label>
                </FormGroup>
                <span style={{ color: "gray", fontSize: 12 }}>
                  El tamaño sugerido de la imagen es 1200 * 700
                </span>
              </FormGroup>

              <FormGroup>
                <Form.Label className="title-create">
                  Imagen de Menu Superior:{" "}
                </Form.Label>

                <FormGroup>
                  {imagenMenuModal(imagenMenu.pae_parametro, banderaMenu)}
                </FormGroup>

                <FormGroup>
                  <label className="btn btn-default">
                    <input
                      type="file"
                      onChange={selectFileMenu}
                      onClick={(e) => (e.target.value = null)}
                    />
                  </label>
                </FormGroup>
                <span style={{ color: "gray", fontSize: 12 }}>
                  El tamaño sugerido de la imagen es 1200 * 700 sin
                  transparencia en formato .png
                </span>
              </FormGroup>

              <FormGroup>
                <Form.Label className="title-create">Logo: </Form.Label>

                <FormGroup>
                  {imageLogo(imagenLogo.pae_parametro, banderaLogo)}
                </FormGroup>

                <FormGroup>
                  <label className="btn btn-default">
                    <input
                      type="file"
                      onChange={selectFileLogo}
                      onClick={(e) => (e.target.value = null)}
                    />
                  </label>
                </FormGroup>
                <span style={{ color: "gray", fontSize: 12 }}>
                  El tamaño sugerido de la imagen es 512 * 512 sin transparencia
                  en formato .png
                </span>
              </FormGroup>

              <FormGroup>
                <Form.Label className="title-create">
                  Color Aplicación:{" "}
                </Form.Label>
                <FormGroup as={Row}>
                  <Col>
                    <ColorPicker
                      value={color.pae_parametro}
                      onChange={(e) =>
                        setcolor((color) => {
                          return { ...color, pae_parametro: e.value };
                        })
                      }
                    />
                  </Col>

                  <Col>
                    <Form.Control
                      maxLength={6}
                      placeholder="color"
                      id="color"
                      value={color.pae_parametro}
                      onChange={(e) => {
                        const val = e.target.value;
                        setcolor((color) => {
                          return { ...color, pae_parametro: val };
                        });
                      }}
                    />
                  </Col>
                  <Col></Col>
                  <Col></Col>
                  <Col></Col>
                </FormGroup>
              </FormGroup>

              <FormGroup>
                <Form.Label className="title-create">
                  Color Barra Superior:{" "}
                </Form.Label>
                <FormGroup as={Row}>
                  <Col>
                    <ColorPicker
                      value={colorbarra.pae_parametro}
                      onChange={(e) =>
                        setcolorbarra((colorbarra) => {
                          return { ...colorbarra, pae_parametro: e.value };
                        })
                      }
                    />
                  </Col>

                  <Col>
                    <Form.Control
                      maxLength={6}
                      placeholder="color"
                      id="color"
                      value={colorbarra.pae_parametro}
                      onChange={(e) => {
                        const val = e.target.value;
                        setcolorbarra((colorbarra) => {
                          return { ...colorbarra, pae_parametro: val };
                        });
                      }}
                    />
                  </Col>
                  <Col></Col>
                  <Col></Col>
                  <Col></Col>
                </FormGroup>
              </FormGroup>

              <FormGroup>
                <Form.Label className="title-create">
                  Color Menu Lateral:{" "}
                </Form.Label>
                <FormGroup as={Row}>
                  <Col>
                    <ColorPicker
                      value={colormenu.pae_parametro}
                      onChange={(e) =>
                        setcolormenu((colormenu) => {
                          return { ...colormenu, pae_parametro: e.value };
                        })
                      }
                    />
                  </Col>

                  <Col>
                    <Form.Control
                      maxLength={6}
                      placeholder="color"
                      id="color"
                      value={colormenu.pae_parametro}
                      onChange={(e) => {
                        const val = e.target.value;
                        setcolormenu((colormenu) => {
                          return { ...colormenu, pae_parametro: val };
                        });
                      }}
                    />
                  </Col>
                  <Col></Col>
                  <Col></Col>
                  <Col></Col>
                </FormGroup>
              </FormGroup>
            </Modal.Body>

            <Modal.Footer>
              {estadoParametros(showModalCargando)}
              {/* <Button
                onClick={() => {
                  uploadPhotoEmpresa();
                }}
              >
                Actualizar
              </Button>
              <Button
                onClick={() => {
                  setshowModalParametros(false);

                  // seterror((error) => {
                  //   return {
                  //     ...error,
                  //     codigo: "",
                  //     id: "",
                  //     razon: "",
                  //     nombreco: "",
                  //     direccion: "",
                  //     telefono: "",
                  //     email: "",
                  //     representante: "",
                  //     pagina: "",
                  //     ruc: "",
                  //   };
                  // });
                  // setempresa((empresa) => {
                  //   return {
                  //     ...empresa,
                  //     emp_codigo: "",
                  //     emp_id: "",
                  //     emp_razon_social: "",
                  //     emp_nombre_comercial: "",
                  //     emp_cuidad: 0,
                  //     emp_direccion: "",
                  //     emp_telefono: "",
                  //     emp_email_empresa: "",
                  //     emp_representante: "",
                  //     emp_ruc: "",
                  //     emp_pagina_web: "",
                  //     emp_tipo_empresa: "0",
                  //   };
                  // });
                }}
              >
                Cerrar
              </Button> */}
            </Modal.Footer>
          </Modal>
          {/* </Co
          ntainer> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
